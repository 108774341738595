import styled, { css } from "styled-components";

export const Container = styled.div`
    span {
        color: red;
    }
`

export const InputContainer = styled.div`

    position: relative;
    font-family: 'Roboto';
    font-weight: 400;
    padding: 0.4rem 0.625rem;

    label {
        position: absolute;
        pointer-events: none;
        top: 1.8rem;
        right: -2.5rem;
        width: auto;
        transition: .2s ease;
        color: #CBCBCB;
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    input[type="date"]{
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }

    input[type="date"]::before{
        content: attr(placeholder) "";
    }
    input[type="date"]:focus::before {
        content: "" !important;

    @media screen and (max-width: 1000px) {
        input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
            display: block;
            -webkit-appearance: button;
        }
    }
}


    ${props => (props.isFocus) &&
        css`
            input {
                outline: none !important;
                border: 1px solid #007AFF !important;
                border-left: 2px solid #007AFF !important;
            }
        `
    }

    ${props => (props.isFilled) &&
        css`
            input {
                outline: none !important;
                border-bottom: 1px solid #007AFF !important;
            }
        `
    }


    ${props => (props.failed) &&
        css`
            input {
                border-color: red !important;
                color: red !important;
            }
        `
    }

    ${props => (props.format === 'rg') &&
        css `
            width: 192px;
            margin-right: 1.1rem;
        `
    }

    ${props => (props.format === 'birthdate') &&
        css `
            width: 170px;
            margin-right: 1.1rem;
        `
    }

    ${props => (props.format === 'email') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'password') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'full_name') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'password_confirmation') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'ddd') &&
        css `
            width: 52px;
            margin-right: 0.625rem;
        `
    }
    ${props => (props.format === 'phone') &&
        css `
            width: 318px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'city') &&
        css `
            width: 200px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'state') &&
        css `
            width: 5rem;
        `
    }
    ${props => (props.format === 'cpf') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'cep-sm') &&
        css `
            width: 115px;
            margin-right: 0.625rem;
        `
    }
    ${props => (props.format === 'cep') &&
        css `
            width: 15.5rem;
            margin-right: 0.625rem;
       `
    }
    ${props => (props.format === 'district') &&
        css `
            width: 210px;
        `
    }
    ${props => (props.format === 'street') &&
        css `
            width: 283px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'number') &&
        css `
            width: 80px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'neighborhood') &&
        css `
            width: 162px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'esquerda_50') &&
        css `
            width: 162px;
            margin-right: 3.5rem;
        `
    }
    ${props => (props.format === 'direita_50') &&
        css `
            width: 163px;
        `
    }
    ${props => (props.format === 'rg2') &&
        css `
            width: 202px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'birthdate2') &&
        css `
            width: 160px;
            margin-right: 1.1rem;
        `
    }

    ${props => (props.format === 'email2') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'password2') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'full_name2') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'password_confirmation2') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'ddd2') &&
        css `
            width: 52px;
            margin-right: 0.625rem;
        `
    }
    ${props => (props.format === 'phone2') &&
        css `
            width: 318px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'city2') &&
        css `
            width: 240px;
            margin-right: 1.1rem;
            @media screen and (max-width: 1010px){
              width: 255px;
              padding-left: 0;
            }
            @media screen and (max-width: 610px){
              width: 255px;
              padding-left: 0.625rem;
            }
        `
    }
    ${props => (props.format === 'state2') &&
        css `
            width: 5rem;
        `
    }
    ${props => (props.format === 'cpf2') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'cep-sm2') &&
        css `
            width: 208px;
            margin-right: 0.625rem;
            @media screen and (max-width: 1010px && min-width: 610px){
                padding-left: 0;
            }
        `
    }
    ${props => (props.format === 'cep2') &&
        css `
            width: 18.5rem;
            margin-right: 0.625rem;
       `
    }
    ${props => (props.format === 'district2') &&
        css `
            width: 210px;
        `
    }
    ${props => (props.format === 'street2') &&
        css `
            width: 283px;
            margin-right: 1.1rem;
            @media screen and (max-width: 1010px && min-width: 610px){
                padding-left: 0;
            }
        `
    }
    ${props => (props.format === 'number2') &&
        css `
            width: 80px;
            @media screen and (max-width: 1010px){
                margin-right: 1.1rem;
            }
            @media screen and (max-width: 610px){
                margin-right: 0rem;
            }
        `
    }
    ${props => (props.format === 'neighborhood2') &&
        css `
          width: 162px;
          @media screen and (max-width: 1010px){
              margin-right: 1.1rem;
          }
          @media screen and (max-width: 610px){
              margin-right: 0rem;
          }
        `
    }
    ${props => (props.format === 'esquerda_502') &&
        css `
            width: 196px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'direita_502') &&
        css `
            width: 167px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'agency') &&
        css `
            width: 283px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'agency_digit') &&
        css `
            width: 80px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'account_number') &&
        css `
            width: 283px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'account_digit') &&
        css `
            width: 80px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'cnpj') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'icms') &&
        css `
            width: 400px;
            margin-right: 1.1rem;
        `
    }
    ${props => (props.format === 'cep-sm3') &&
        css `
            font-family: 'Poppins', sans-serif;
            label {
                position: absolute;
                pointer-events: none;
                top: -1rem;
                left: 0rem;
                transition: .2s ease;
                color: #CBCBCB;
            }
            width: 115px;
            margin-right: 0.625rem;
        `
    }
    
    input {
        border: 1px solid #CBCBCB;
        border-radius: 5PX;
        margin: 0.5rem 0 0 0;
        width: 100%;
        padding: 0.6rem 0 0.6rem 1rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 1rem;

        :focus ~ label,
        :not(:focus):valid ~ label{
            opacity: 0.9;
            font-size: .8rem;
            font-weight: 600;
        }

        :focus ~ label {
            color: #007AFF;
        }

        :not(:focus):valid ~ label {
            color: black;
        }
    }


    
    @media screen and (max-width: 610px){
        margin: 0rem;
        width: 80%;
        font-size: 0.8rem;
        ${props => (props.format === 'ddd') && css ` width: 80%; margin: 0rem;`}
        ${props => (props.format === 'phone') && css ` width: 80%; margin-right: 0rem;`}
        ${props => (props.format === 'city') && css ` width: 80%; margin-right: 0rem;`}
        ${props => (props.format === 'state') && css ` width: 80%; margin-right: 0rem;`}
        ${props => (props.format === 'cep') && css ` width: 80%; margin-right: 0rem;`}
        ${props => (props.format === 'district') && css ` width: 80%; margin-right: 0rem;`}
        ${props => (props.format === 'street') && css ` width: 80%; margin-right: 0rem;`}
        ${props => (props.format === 'number') && css ` width: 80%; margin-right: 0rem;`}
        ${props => (props.format === 'esquerda_50') && css ` width:80%; margin-right: 0rem;`}
        ${props => (props.format === 'direita_50') && css ` width: 80%; margin-right: 0rem;`}
    }
`