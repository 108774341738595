import { useUserContext } from "../../../components/contexts/useUserContext"
import { useState } from "react"
import {Container, ProfileCard} from "./style"
import UserPlaceholder from '../../../assets/user-placeholder.png'
import Button from '../../../components/Button'
import { Link, useHistory } from "react-router-dom"
import { api } from "../../../services/api"
import { photo } from "../../../services/photo"
import { alert } from "../../../components/Alert"
import InputWithoutIcon from "../../../components/Input/InputWithoutIcon"

function AdminEdit() {
    const history = useHistory();

    const {user, updateAdmin} = useUserContext()
    const [image, setImage] = useState([])
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    
    const changeImage = async (e) => {
        e.preventDefault()
        try{
            const formData = new FormData ()
                formData.append('image[]', image[0])
        

            const response = await api.post(`/admins/add_image/${user.id}`, formData)
            
            if(response.data){
                alert('Foto adicionada', 'success');
                setImage(response.data);
                updateAdmin(response.data);
            }
            
        }catch(error){
            alert('Erro, tente novamente', 'error')
        }
    }

    const handlePasswordChange = async (e) => {
        e.preventDefault()
        if (password.length < 6){
            alert('Senha muito curta', 'warn')
            return
        }

        if (password !== passwordConfirmation){
            alert('Confirmação de senha não bate com a senha', 'warn')
            return
        }

        try{
            await api.patch(`admins/password_edit/${user.id}`, {
                password,
                password_confirmation: passwordConfirmation,
                current_password: currentPassword
            })
            alert("Senha modificada com sucesso.", 'success')
            history.push('/profile')
        }
        catch(e){
            alert("Erro, tente novamente", 'error')
        }
    }

    return (
        <>
        <Container>
                <div className='title'>
                <h1>Editar perfil</h1>
                <h2>Olá, <span>{user? user.name:''}!</span> </h2>

                </div>
            
            <ProfileCard>
                <form onSubmit={changeImage}>
                    <div className='client-info'>
                        <div className='profile-pic'>
                            <img alt="Foto de Perfil" src={user.admin_image_url? `${photo.defaults.baseURL + user.admin_image_url}`: UserPlaceholder} />
                            <input
                                placeholder='Foto' type='file' name='file' id='file'
                                onChange={(value) => setImage(value.target.files)}
                            />
                        </div>
                    </div>
                    <div className='custom-button'>
                    <Button format='profile' type='submit' className='button'>Confirmar nova foto de perfil</Button>

                    </div>
                </form>
                <form onSubmit={handlePasswordChange}>
                    <div className='client-info'>
                        <InputWithoutIcon
                            label='Senha atual'
                            type='password'
                            value={currentPassword}
                            onChange={value => setCurrentPassword(value.target.value)}
                        />
                    </div>

                    <div className='client-info'>
                        <InputWithoutIcon
                            label='Senha'
                            type='password'
                            value={password}
                            onChange={value => setPassword(value.target.value)}
                        />
                    </div>

                    <div className='client-info'>
                        <InputWithoutIcon
                            label='Confirmar Senha'
                            type='password'
                            value={passwordConfirmation}
                            onChange={value => setPasswordConfirmation(value.target.value)}
                        />
                    </div>

                    <div className='custom-button'>
                        <Button type='submit' format='profile'>Alterar Senha</Button>
                    </div>

                </form>
                <form>

                    <Link to='/profile' style={{textDecoration: 'none'}}>
                        <div className='custom-button'>
                            <Button format='logout'>Voltar</Button>
                        </div>
                    </Link>

                </form>

            </ProfileCard>  
        </Container>

        </>
    )
}

export default AdminEdit;