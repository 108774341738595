import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import { Link, useLocation } from "react-router-dom";
import { useUserContext } from "../contexts/useUserContext";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons";
import ModalLogin from "../ModalLogin";
import { NavSideBar } from "../NavSideBar"

export const NavbarHome = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const { user, sign_out } = useUserContext();
  const location = useLocation();

  const [userBar, setUserBar] = useState(false);
  const showUserBar = () => {
    setUserBar(!userBar);
  };

  const [sidebar, setSidebar] = useState(props.sidebarState? props.sidebarState.state : false);
  const showSidebar = () => {
    let temp = sidebar
    setSidebar(!temp);
    if (props.sidebarState){
      props.sidebarState.setState(!temp);
    }
  };

  useEffect(()=>{
    if (props.sidebarState){
      setSidebar(props.sidebarState.state);
    }
  },[props.sidebarState])

  return (
    <>
    <Container home={location.pathname === "/"}>
      <div className="middle">
        <Link to="/" className={location.pathname === "/" ? "current-item" : "other-itens"}>
          Início
        </Link>

        <Link 
          to="/guide" className={location.pathname === "/guide" ? "current-item" : "other-itens"}>
          Como Adquirir Suas Férias
        </Link>
        <Link
          to="/vacation"
          className={location.pathname === "/vacation" ? "current-item" : "other-itens"}
        >
          Marcar Férias
        </Link>

        <Link
            to="/contacts"
            className={location.pathname === "/contacts" ? "current-item" : "other-itens"}
          >
            Contato
        </Link>

        <Link
          to='/retailer/guide' 
          className={location.pathname === '/retailer/guide' ? "current-item" : "other-itens"}
        >
          Seja um Revendedor
        </Link>

        {user ? (
        <a href="/#" onClick={showUserBar} className="other-itens">
          {user.name? `Bem-vindo, ${user.name.split(" ")[0]}!` : "Bem-vindo!"}
        </a>
        ) : (
        <div className="header-login">
          <Link to="/login" className={location.pathname === "/login" ? "current-item" : "other-itens"}>
            Entrar
          </Link>
          <div className="buy">
          <Link to="/sign_up" className={location.pathname === "/sign_up" ? "current-item" : "other-itens"}>
            Comprar
          </Link>
          </div>
        </div>
      )}
      </div>
      
      <IconContext.Provider value={{ color: "#f5f5f5", size: "30px" }}>
        <span className="menu-bars">
          <FaBars onClick={showSidebar} />
        </span>
        <NavSideBar sidebar={sidebar} showSidebar={showSidebar} setOpenModal={setOpenModal}/>
      </IconContext.Provider>
      <div className={userBar ? "arrow-up active" : "arrow-up"}></div>
      <nav className={userBar ? "nav-user active" : "nav-user"}>
        <ul className="user-menu-items">
          {user && user.type === "admin" ? (
            <li className="user-text">
              <Link to="/dashboard" onClick={showUserBar}>
                <p>Dashboard do Admin</p>
              </Link>
            </li>
          ) : (
            ""
          )}
          {user && user.type === "retailer" ? (
            <li className="user-text">
              <Link to="/dashboard" onClick={showUserBar}>
                <p>Dashboard do Revendedor</p>
              </Link>
            </li>
          ) : (
            ""
          )}

          <li className="user-text">
            <Link to="/profile" onClick={showUserBar}>
              <p>Meu perfil</p>
            </Link>
          </li>

          {user && user.type === "client" ? (
            <>
            <li className='user-text'>
              <Link to='/user/my-requests' onClick={showUserBar}>
                <p>Reservas e Pacotes</p>
              </Link>
            </li>
            </>
          ) : (
            ""
          )}

          <li className="user-text logout">
            <p onClick={sign_out}>Sair</p>
          </li>
        </ul>
      </nav>
    </Container>
    {openModal && <ModalLogin closeModal={setOpenModal}/>}
    </>
  );
};
