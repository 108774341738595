import styled from 'styled-components'

export const Container = styled.div`

  background-color: #F09E40;
  padding: 2rem 0 1rem 0;
  color: #3a3b3c;
  position: relative;
  width: 100%; 

  a {
    text-decoration: none;
    color: #3a3b3c;

    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .footer{
    margin: auto;
    width: 80%;
    background-color: #F09E40;
  }

  .footer-top{
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    flex-wrap: wrap;

    @media (max-width: 1100px){ 
      flex-direction: row;
      .footer-heading{
        margin-top: 1rem;
      }
    }
  }

  .footer-bottom{
    margin-top: 1rem;
    border: 1px;
    border-style: solid hidden hidden hidden;
    border-color: #3a3b3c;
    padding-top: 2rem;
    text-align: center;
    p{
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      color: #3a3b3c;
    }
    a {
      font-weight: 400;
    }
  }

  .footer-heading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 100px;
    @media (max-width: 1100px){
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .footer-heading h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
  }


  .footer-logo{
    max-width: 286px;
    display: flex;
    flex-direction: column;
    margin: 0 1.8rem 0 0;
    @media (max-width: 1440px){
      margin: 0 1rem 0 0;
      p{
        width: 70%;
      }
    }
    @media (max-width: 1100px){
      margin: 1rem auto;
    }
    p{
      margin: 0 auto;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;

      /* or 186% */
      text-align: center;
      width: 200px;
      margin-top: 24px;
    }
  }

  .logo {
      width: auto;
      height: auto;
      margin: 0 auto;
      top: 0;
      a{
        margin: 0 auto;
        display: flex;
        align-items: center;
        img {
          width: 236px;
          @media (max-width: 1440px){
            width: 200px;
          }
        }
    }
  }

  .footer-social{
    width: 160px;
    margin:17px 0 30px 0;
    .icon{
      margin-right: 15px;
    }
  }

  .footer-1{
    margin: 0 1.5rem 0 0;
    @media (max-width: 1440px){
      margin: 0 1rem 0 0;
    }
    @media (max-width: 1100px){
      margin: 1rem auto;
    }
    p{
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 26px;
      margin-top: 0;
    }
  }

  .footer-2{
    border-left:1px solid #3a3b3c;
    border-right:1px solid #3a3b3c;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    @media screen and (max-width: 1440px) {
      padding: 0 1rem;
    }
    @media screen and (max-width: 1302px) {
      border-left: 1px solid transparent;
    }
    @media screen and (max-width: 1100px) {
      border-right: 1px solid transparent;
      max-width: 300px;
      margin: auto;
    }
    a{
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;

      /* identical to box height, or 162% */
      text-align: center;
      margin-bottom: 17px;
    }
    a:last-child{
      margin-bottom: 0;
    }
  }

  .footer-3{
    border-right: 1px solid #3a3b3c;
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    @media screen and (max-width: 1440px) {
      padding: 0 1rem;
    }
    @media screen and (max-width: 1100px) {
      border-right:1px solid transparent;
      max-width: 300px;
      margin: auto;
    }
    a{
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      margin-top: 1rem;
      
      img {
        height: 10rem;
      }
      
      /* identical to box height, or 162% */
      text-align: center;
      margin-bottom: 17px;
    }
    a:last-child{
      margin-bottom: 0;
    }
  }

  .footer-4{
    padding: 0 0 0 1.5rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    @media screen and (max-width: 1440px) {
      padding: 0 0 0 1rem;
    }
    @media screen and (max-width: 1371px) {
      padding: 0 0 0 1rem;
    }
    @media screen and (max-width: 1100px) {
      border-left: 1px solid transparent;
      max-width: 300px;
      margin: auto;
    }
    a{
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      margin-top: 3rem;

      img {
        height: 5rem;
      }

      /* identical to box height, or 162% */
      text-align: center;
      margin-bottom: 17px;
    }
    a:last-child{
      margin-bottom: 0;
    }
  }

  .instagram-icon {
    width: 24px;
    height: 24px;
  }
  .facebook-icon {
    width: 14px;
    height: 26px;
  }
  .linkedin-icon {
    width: 21px;
    height: 21px;
  }
  .whatsapp-icon {
    width: 19px;
    height: 19px;
    margin-right: 3px;
  }

  #ronald-logo{
    height: 140px;
    width: auto;
    @media screen and (max-width: 1440px) {
      height: 120px;
    }
  }

  .par {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    @media (max-width: 1100px){
      flex-direction: column;
      width: 100%;
    }  
  }

`