import styled from "styled-components";
import BG from "../../assets/home-bg.png";
import diarias from "../../assets/hotel.svg";
import agendamento from "../../assets/agendamento.svg";
import breakfast from "../../assets/breakfast.svg";
import calendar from "../../assets/calendar.svg";
import institutomc from "../../assets/ronald-logo.svg";
import pagamento from "../../assets/pagamento.svg";
import parcelamento from "../../assets/parcelamento.svg";


export const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8fa;
  z-index: 3;
  #hr {
    margin: 0% 3%;
    margin-top: 5rem;
    width: 94%;
    border-bottom: 1px solid #c4c4c4;
  }
  #hr1 {
    width: 100%;
    border-bottom: 1px solid #c4c4c4;
  }
  span {
    font-size: 1rem;
    margin: 0rem 2.68rem;
    color: #3a3b3c;
  }
  .justify-center {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    z-index: 1;

    .text-button{
        font-size: 1.2rem;
    }
  }

  nav{
    ${(props) => {
        return `display: ${props.displayHomeNav};`;
    }}
  }
`;

export const Grid1 = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  position: relative;

  .landing-logo {
    opacity: .99;
    display: flex;
    margin: 0 8vw;
    min-height: calc(80vh - 28rem);
    margin-top: 8rem;
    padding: 10rem 0;
    gap: 16vw;

    .homepage-logo{
      cursor: pointer;
    }

    img {
      height: 15rem;
      align-self: flex-end;
    }
    .homepage-logo:hover{
      transform: scale(1.01)
    }

    Button {
      width: auto;
      height: fit-content;
      padding: .25rem 2rem;
      font-size: 1.75rem;
      font-weight: 700;

      @keyframes toggleColor {
        0% { background-color: #9E1900; }
        47% { background-color: #9E1900; }
        53% { background-color: #FCC046; }
        100% { background-color: #FCC046; }
      }

      @-webkit-keyframes toggleColor {  
        0% { background-color: #9E1900; }
        47% { background-color: #9E1900; }
        53% { background-color: #FCC046; }
        100% { background-color: #FCC046; }
      }

      background-color: #9E1900;
      animation-name: toggleColor;  
      animation-iteration-count: infinite;  
      animation-direction: alternate;
      animation-duration: 1.5s; 
      -webkit-animation-name: toggleColor;  
      -webkit-animation-direction: alternate;
      -webkit-animation-iteration-count: infinite;  
      -webkit-animation-duration: 1.5s; 
    }
  };

  .landing-text {
    opacity: 0.99;
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin: 0 8vw;
    gap: 1rem;

    svg {
      height: 4rem;
      width: auto;
    }

    h2, span {font-size: 2rem;}

    .toggle-text-1 {

      @keyframes toggleText {
        0% { opacity: 0 }
        20% { opacity: 1 }
        80% { opacity: 1 }
        100% { opacity: 0 }
      }

      @-webkit-keyframes toggleText {
        0% { opacity: 0 }
        20% { opacity: 1 }
        80% { opacity: 1 }
        100% { opacity: 0 }
      }

      opacity: 0;
      animation-name: toggleText;  
      animation-duration: 5s; 
      -webkit-animation-name: toggleText;  
      -webkit-animation-duration: 5s; 
    }

    .toggle-text-2 {
      @keyframes toggleText2 {
        0% { opacity: 0 }
        20% { opacity: 1 }
        80% { opacity: 1 }
        100% { opacity: 0 }
      }

      @-webkit-keyframes toggleText2 {
        0% { opacity: 0 }
        20% { opacity: 1 }
        80% { opacity: 1 }
        100% { opacity: 0 }
      }

      opacity: 0;
      animation-name: toggleText2;  
      animation-duration: 5s; 
      -webkit-animation-name: toggleText2;  
      -webkit-animation-duration: 5s; 

      span {margin-right: 0}
    }

    h2 {
      color: #f8f8f8;
    }

    span {
      color: #FCC046;
      margin: 0 .5rem;
    }
  }

  .photo {
    position: absolute;
    bottom: 0;
    right: 8vw;
    height: 55%;
    z-index: 2;

    // @media screen and (max-width: 1600px) {
    //   height: 24rem;
    // }

    
    @media screen and (max-width: 1500px) {
      right: 5vw;
    }
    
    @media screen and (max-width: 1300px) {
      display: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: url("${BG}") no-repeat center;
    background-position: center top;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 1860px) {
    .landing-logo {
      img{height: 11.5rem;}
      Button{font-size: 1.3125rem;}
      
    }
  }

  @media screen and (max-width: 1440px) {
    .landing-logo {
      gap: 5vw;
      margin-left: 2.5vw;
      margin-right: 2.5vw;

      img{height: 10rem;}
      Button{font-size: 1.25rem;}
    }
      

    .landing-text {
      svg {
        height: 3.5rem;      
      }

      h2, span{font-size: 1.75rem}
    }
  }


  @media screen and (max-width: 1000px) {
    .landing-logo {
      flex-direction: column;
      gap: 8rem;
      margin-top: 0;

      img {
        align-self: center;
      }
    }

    .landing-text {
      margin: 0 2vw;
      justify-self: flex-end;

      svg {
        height: 3rem;      
      }

      h2, span{font-size: 1.5rem}
    }
  }

  @media screen and (max-width: 950px) {
    .landing-text {
      gap: 0.5rem;

      svg {
        height: 2.5rem;      
      }

      h2, span{font-size: 1.25rem}
    }
  }

  @media screen and (max-width: 720px) {
    .landing-logo Button{
      font-size: 1.25rem;
    }

    .landing-text {
      gap: 0.5rem;

      svg {
        height: 2rem;      
      }

      h2, span{font-size: 1rem}
    }
  }

  @media screen and (max-width: 480px) {
    .landing-logo {
      margin: 0 2vw;

      img {height: 6rem;}

      Button{font-size: 1rem;}
    }

    .landing-text {
      svg {height: 1.75rem;}

      h2, span{font-size: 0.875rem}
    }
  }

`;

export const Grid2 = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  color: #3a3b3c;

  .play-link{
    color: #BF1827;
    text-decoration: none;
  }.play-link:hover{
    text-decoration: underline;
  }

  margin-top: 5rem;

  h2 {
    font-weight: 600;
    font-size: 2.5rem;
    margin: 2rem 2.625rem;
  }

  .beneficios {
    padding-left: 3rem;
    margin: 0 3rem 0 10rem;
        
    li {
      font-size: 4rem;
      padding-left: 2rem;
      margin-bottom: .5rem;
      
      p {
        font-size: 1.5rem;
        margin: 0;
      }
    }

    .diarias {
      list-style-image: url(${diarias})
    }

    .pagamento {
      list-style-image: url(${pagamento})
    }

    .parcelamento {
      list-style-image: url(${parcelamento})
    }

    .carencia {
      list-style-image: url(${calendar})
    }

    .hospedagem {
      list-style-image: url(${agendamento})
    }

    .cafe {
      list-style-image: url(${breakfast})
    }

    .mcdonald {
      font-size: 5rem;
      margin-left: .5rem;
      padding-left: 1.5rem;

      @media screen and (max-width: 1150px){
        font-size: 4rem;
      }

      @media screen and (max-width: 899px){
        font-size: 3.5rem;
      }

      @media screen and (max-width: 460px){
        padding-left: .5rem;
        font-size: 3rem;
      }
      list-style-image: url(${institutomc})
    }
  }

  .WhoAreWe{
    font-size: 1.2rem;
  }

  .info {
    display: flex;
    justify-content: center;
    img {
      margin-left: 2.625rem;
      width: 40rem;
      object-fit: cover;
      display: flex;
    }
    .text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 1.8rem;
      margin: 2rem 2.68rem;
      width: 35%
    }
    .Home-btn {
      width: 100%;
    }
    .img {
      position: relative;
      top: -5rem;
      width: 15rem;
      margin: 0rem 2.68rem;
    }
    
  }
  @media screen and (max-width: 1150px) {
    h2 {font-size: 2rem}
    
    .beneficios {
      margin-left: 5rem;

      li {
      font-size: 3.25rem;
      
        p {
          font-size: 1.75rem;
          margin: 0;
        }
      }
    }


    .info {
      img {
        width: 30rem;
      }
    }
  }
  @media screen and (max-width: 920px) {
    h2 {
      text-align: center;
      margin-left: 0;
      margin-right: 0;
      padding: 0 2vw;
      font-size: 1.75rem;
    }

    .info {
      display: flex;
      flex-direction: column;
      .text {
        width: 95%;
        font-size: 1.5rem;
        margin-left: auto;
        margin-right: auto;
      }
      .img {
        display: none;
      }
      img {
        width: 30rem;
        margin: auto;
      }
      .Home-btn {
        width: 90%;
        padding: 0;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 610px) {
      .info{
          img {
              width: 80%;
          }
      }
  }
  @media screen and (max-width: 610px) {
    h2 {font-size: 1.5rem}

    .beneficios {
      margin: 0 .5rem;

      li {
      font-size: 2.5rem;
      
        p {font-size: 1rem}
      }
    }

    .WhoAreWe{
      font-size: 0.95rem;
      margin: 0.75rem;
      text-align: justify;
    }

    .info{
      img {
          width: 80%;
      }
      .text{
        font-size: 1.125rem;
        margin: 2rem 0.5rem;
        text-align: center;
      }
    }

    a{
      text-align: center;

      Button {
        width: 16rem;
        padding: 0;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 460px) {
    h2 {font-size: 1.25rem}

    .beneficios {
      padding-left: 2rem;

      li {
        font-size: 2rem;
        padding-left: 1rem;
      
        p {
          font-size: 0.875rem;
          position: relative;
          left: -.5rem;
        }
      }
    }
  }


  nav {
    position: fixed;
    ${(props) => {
        return `display: ${props.displayNav};`;
    }}
  }
`;

export const RedGrid = styled(Grid2)`
  background-color: #BF1827;
  color: #fff;
  padding-top: 2rem;
  padding-bottom: 6rem;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    h2 {
      width: 85%
    };
    img {
      margin-right: 5%;
      height: 5rem;
    }
  }

  span {
    color: white;
  }
`;

export const OrangeGrid = styled(Grid2)`
  background-color: #E47D3A;
  color: #fff;
  padding-top: 2rem;
  padding-bottom: 6rem;

  h2 {
    text-align: center;
    width: 40rem;
  }

  .info{
    img{
      filter: drop-shadow(10px 10px 4px #723E1D);
    }

    .text {
      flex: 1;
    }
  }

  span {
    color: white;
  }

  @media screen and (max-width: 1605px){
    p {font-size: 1.25rem}
  }

  @media screen and (max-width: 1350px){
    .info img {width: 30rem}

    h2 {
      width: 30rem;
      font-size: 2rem;
    }

    p {font-size: 1rem}
  }

  @media screen and (max-width: 1080px){
    .info img {width: 20rem}

    h2 {
      width: 20rem;
      font-size: 1.25rem;
      padding: 0;
    }

  }

  @media screen and (max-width: 920px){
    h2 {
      width: 100%;
      font-size: 1.75rem;
    }

    .info img {width: 30rem}
  }

  @media screen and (max-width: 610px) {
    h2 {font-size: 1.25rem}

      .info{
          img {
              width: 80%;
          }
      }
  }
`;

export const Info = styled.section`
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 2.6rem;
    margin: 0rem 2.68rem;
    color: #3a3b3c;
  }
  span{
    margin: 1rem;
    font-size: 1.2rem;
    text-align: center;
  }

  div {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  @media screen and (max-width: 1150px) {
    h2 {font-size: 2rem}
  }

  @media screen and (max-width: 899px) {
    h2 {font-size: 1.75rem}
  }

  @media screen and (max-width: 610px) {
    h2 {font-size: 1.5rem;}

    span {font-size: 1.125rem}
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;
