import { useEffect } from "react"
import { useState } from "react"
import Button from "../../../components/Button"
import Form from "../../../components/Forms"
import { api } from "../../../services/api"
import { Page } from "../styles"
import { Container } from "./styles"

const PayOptions = () => {

    const [isChecked, setIsChecked] = useState(false)

    const [isAvailable, setIsAvailable] = useState('')

    const loadPackagePlans = async () => {
        const response = await api.get('packages/package_plans')
        if (response.data) {
            setIsAvailable(response.data.find(plan => plan.payment_method === 'credit_card').is_available)
        }
    }
    
    useEffect(() => {
        loadPackagePlans()
    }, [])

    const handleChange = () => {
        setIsChecked(prev => !prev)
    }

    const handleOptionChange = (e) => {
        if (e.target.value === 'option1') {
            setIsAvailable(false)
        } else if (e.target.value === 'option2') {
            setIsAvailable(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (isChecked) {
            try {
                const response = await api.put('packages/update_package_plan', {
                    is_available: isAvailable
                })
                if (response.data) {
                    alert('Atualizado com sucesso')
                }
            } catch {
                alert('Erro na atualização.')
            }
        } else {
            alert('Marque a caixa de confirmação.')
        }
    }

    return (
        <Page>
            <Container>
                <h1>Opções de Pagamento</h1>
                <p>Escolha os métodos de pagamento disponíveis para os clientes</p>
                <Form
                    onSubmit={handleSubmit}
                    button={
                        <>
                            <div className='confirmation'>
                                <input type='checkbox' className='checkbox' id='checkbox' value='check' onChange={handleChange}/>
                                <label for='checkbox' className='label'>Confirmar Alterações</label>
                            </div>
                            <Button format='submit' type='submit'>Alterar Método de Pagamento</Button>
                        </>
                    }
                >
                    <h3>Pagamento somente por Boleto</h3>
                    <input type="radio" className='radio' id='radio1' value='option1' name='radio-group'
                        checked={!isAvailable} onChange={handleOptionChange}
                    />
                    <label for='radio1' className='label'>Ativar</label>
                    <h3>Pagamento por Boleto e Cartão de Crédito</h3>
                    <input type="radio" className='radio' id='radio2' value='option2' name='radio-group'
                        checked={isAvailable} onChange={handleOptionChange}
                    />
                    <label for='radio2' className='label'>Ativar</label>
                </Form>
            </Container>
        </Page>
    )
}

export default PayOptions