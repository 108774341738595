import { useState, useEffect } from 'react'
import { Container } from "./styles"
import Carousel from 'react-elastic-carousel'
import { api } from "../../services/api"
import { photo } from "../../services/photo"

const CarouselImage = ({request, id, placeholder, images_urls}) => {
    const [images, setImages] = useState(images_urls || null);

    useEffect(() => {
        if (request)
            api.get(`${request}/show/${id}`)
            .then((response) => {setImages(response.data.images_url)})
    },[request, id])

    return(
        <Container >
            { !!images ?
                <Carousel 
                    enableAutoPlay={true}
                    autoPlaySpeed={4000}
                    transitionMs={1000}
                    showArrows={true}
                    itemsToShow={1}
                    loop={true}
                    enableMouseSwipe={false}
                >
                    {images.map((imageUrl, index) => { return(
                        <div key={index} className='image' alt={imageUrl}>
                            <img src={photo.defaults.baseURL + imageUrl} alt={request} />
                        </div>
                    )})}
                </Carousel>
                :
                <div className='image'>
                    <img src={placeholder} alt={request} />
                </div>
            }
        </Container>
    );
}

export default CarouselImage;
