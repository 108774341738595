import { useState } from "react";
import { useHistory } from 'react-router-dom'
import logo from '../../../assets/vdf_logo-black.png'
import Button from '../../../components/Button';
import {alert} from '../../../components/Alert';
import {
    ModalBackground,
    ModalContainer,
    Title,
    Check,
    CloseBtn,
    DivColor,
    TextModal,
    SubTitle,
    SubText,
    CenterText,
    LogoImg,
    DivImage
  } from "./styled";

const Modal = ({ closeModal, handleSubmit }) => {

    const [checked, setChecked] = useState(false)

    const history = useHistory()

    const handleChange = () => {
        setChecked(!checked)  
    }

    const verifyCheckboxIsChecked = () => {
        if (checked) {
            handleSubmit()
            history.push('/')
        } else {
            alert("Para continuar, aceite os termos de uso", 'warn')
        }
    }
    
    return (
        <DivColor>
            <ModalBackground>
                <ModalContainer>
                    <CloseBtn onClick={() => closeModal(false)}>X</CloseBtn>
                    <DivImage className="landing-logo">
                        <LogoImg className="homepage-logo" src={logo} alt="logomarca da página" />
                    </DivImage>
                    <Title>Termos de uso</Title>
                    <SubText>AMERIS REDE DE HOTÉIS INDEPENTENDES</SubText>
                    <CenterText>REGIMENTO INTERNO PROGRAMA “VAI DE FÉRIAS”</CenterText>
                    <TextModal>
                    <b>AMERIS – REDE DE HOTÉIS INDEPENDENTES LTDA.</b>, pessoa jurídica de direito privado,
                    sediada no ST SHIN CA 01 Lote A Bloco A Sala 352 – Brasília – DF, CEP: 71.503-501,
                    inscrita no CNPJ sob n° 30.249.497/0001-47, representada na forma do seu contrato
                    social, adiante designada somente como “AMERIS”, enquanto administradora do
                    PROGRAMA “<b>VAI DE FÉRIAS</b>”, adiante designado somente como “PROGRAMA”,
                    apresenta os termos do Regimento Interno pela adesão aos planos de hospedagem.
                    </TextModal>
                    <SubTitle>DISPOSIÇÕES PRELIMINARES</SubTitle>
                    <TextModal>
                    <b>Art. 1º - </b>A Ameris tem por principal objetivo oferecer um PROGRAMA de férias/
HOSPEDAGEM a seus clientes, através de convênios com hotéis, pousadas, resorts,
apart-hotéis e demais meios de hospedagem.    
                    </TextModal>
                    <TextModal>
                    <b>Art. 2º - </b>O PROGRAMA será administrado com base na legislação vigente e as condições
gerais, as regras e determinações dos Planos de Hospedagem se guiarão pelo presente
Regimento Interno.
                    </TextModal>
                    <TextModal>
                    <b>Art. 3º - </b>- Os clientes que aderirem ao PROGRAMA deverão tomar ciência dos termos
deste Regimento Interno no ato da contratação.
                    </TextModal>
                    <SubTitle>DOS PLANOS DE HOSPEDAGEM E DO PROCEDIMENTO DE ADESÃO AO PROGRAMA</SubTitle>
                    <TextModal>
                    <b>Art. 4º - </b>O plano de hospedagem disponibilizado poderá ser pago em 24 (vinte e quatro)
meses.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>Os planos de hospedagem permitem acomodações de um tipo: 2 (duplo)
sendo possível a hospedagem de 2 (dois) adultos e 1 (uma) criança até 07 anos.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>Caso o proponente deseje a hospedagem de mais pessoas por
apartamento, deverá contratar outro(s) plano(s) disponibilizados pelo PROGRAMA.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 3º - </b>O valor do Plano de Hospedagem poderá ser reajustado pela AMERIS
sempre que necessário, a fim de manter o equilíbrio econômico-financeiro da prestação
dos serviços de intermediação de hospedagens.
                    </TextModal>
                    <TextModal>
                    <b>Art. 5º - </b>Os Planos de Hospedagem podem ser contratados diretamente no site
                    www.vaideferias.com.br ou por meio do aplicativo Vai de Férias.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>O proponente poderá ser pessoa natural (física) ou jurídica.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>Ao manifestar interesse na adesão ao PROGRAMA, o proponente deverá
submeter seus dados pessoais para realização de uma análise de crédito pela AMERIS,
independentemente do local de adesão.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 3º - </b>A AMERIS, para realizar esta análise de crédito, poderá exigir do
proponente cópia do documento de identificação pessoal ou empresarial; do
comprovante de residência ou outros documentos pertinentes.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 4º - </b>O proponente se responsabiliza pela veracidade das informações
prestadas, bem como pela autenticidade dos documentos apresentados.
                    </TextModal>
                    <TextModal>
                    <b>Art. 6º - </b>A AMERIS se reserva ao direito de recusar a adesão ao PROGRAMA com base
na análise cadastral e nenhum valor será cobrado ao Proponente.
                    </TextModal>
                    <TextModal>
                    <b>Art. 7º - </b>A AMERIS se reserva ao direito de definir os preços de adesão aos Planos de
Hospedagem, as formas de pagamento e as condições de utilização, podendo ser
corrigidos ou alterados a qualquer tempo, independentemente de prévia concordância.
                    </TextModal>
                    <TextModal>
                    <b>Art. 8º - </b>- Ao ser aprovada a adesão ao PROGRAMA, caberá ao Proponente o pagamento
dos 24 (vinte e quatro) boletos disponibilizados pela AMERIS.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>Não será cobrada Taxa de Adesão ao PROGRAMA.
                    </TextModal>
                    <TextModal>
                    <b>Art. 9º - </b>Haverá carência para o direito de utilização das diárias disponibilizadas ao
Proponente, ficando determinado que para a efetiva utilização do Plano de
Hospedagem se faz necessário o pagamento do valor integral de ao menos 01 (uma)
parcela, através de boleto de pagamento disponibilizado pela AMERIS, podendo ser
agendada a reserva 30 (trinta) dias após o pagamento.
                    </TextModal>
                    <SubTitle>DOS DIREITOS DOS CLIENTES</SubTitle>
                    <TextModal>
                    <b>Art. 10º - </b>Serão garantidos aos clientes que aderirem aos Planos de Hospedagem, 7 (sete)
diárias, por cada plano, a serem utilizadas no prazo do plano escolhido, a serem
utilizadas na Rede de Estabelecimentos Hoteleiros Conveniada do PROGRAMA. –
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>As reservas deverão obrigatoriamente ser realizadas no prazo do plano
contratado e sob nenhuma hipótese poderá ser realizada ou usufruída fora do prazo
contratado.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>Esse direito somente estará disponível a partir da aprovação da Proposta
de Adesão e do pagamento de ao menos um boleto de cobrança, respeitadas as
condições para utilização de diárias, e os prazos para a apresentação de pedidos de
marcação de reservas estabelecidos neste Regimento.
                    </TextModal>
                    <TextModal>
                    <b>Art. 11º - </b>A utilização das diárias poderá ser cedida a terceiros, desde que autorizada
formalmente pelo cliente, através de formulário próprio disponibilizado pela AMERIS e
de acordo com as regras contidas neste Regimento.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>A marcação de reservas deverá ser realizada pela Central de Reservas do
PROGRAMA, estando sujeita a disponibilidade.
                    </TextModal>
                    <SubTitle>DOS DEVERES DOS CLIENTES</SubTitle>
                    <TextModal>
                    <b>Art. 12º - </b>O cliente compromete-se a cumprir e a fazer seus dependentes ou terceiros
autorizados por ele observarem este Regimento Interno, bem como as demais
determinações do PROGRAMA.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>São considerados dependentes do cliente: o seu cônjuge, seus filhos, bem
como aqueles que, na forma da lei, adquirirem essa condição.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>Após a aprovação da Proposta de Adesão, o Regimento Interno, a relação
de destinos elegíveis e o Perfil do Cliente serão disponibilizados aos clientes, no site e
no aplicativo do PROGRAMA.
                    </TextModal>
                    <TextModal>
                    <b>Art. 13º - </b>O cliente compromete-se a pagar tempestivamente as Parcelas Mensais do(s)
Plano(s) de Hospedagem contratado(s) e demais valores devidos pelos serviços
contratados, estejam ou não previstos neste Regulamento;
                    </TextModal>
                    <TextModal>
                    <b>Art. 14º - </b>Para utilizar as diárias de hospedagem, é indispensável que o cliente esteja em
dia com suas obrigações financeiras e solicite previamente à Central de Reservas da
Ameris, a marcação das reservas com a Rede Conveniada, respeitando as condições para
utilização de diárias e os prazos mínimos e máximos para a apresentação de pedidos de
marcação de reservas previstos neste Regimento Interno.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>O cliente deve manter seus dados cadastrais atualizados, inclusive os
bancários, para facilitar a comunicação e a manutenção em dia de suas obrigações
financeiras junto ao PROGRAMA.
                    </TextModal>
                    <TextModal>
                    <b>Art. 15º - </b>O cliente compromete-se a pagar todas as despesas pelos serviços utilizados,
produtos consumidos e os valores eventualmente cobrados diretamente pelos
Estabelecimentos Hoteleiros, que não estejam incluídos nos planos de hospedagem.
                    </TextModal>
                    <TextModal>
                    <b>Art. 16º - </b>O cliente deverá informar imediatamente a ocorrência de qualquer problema
verificado na utilização de diárias de hospedagem fornecidas pelo Estabelecimento
Hoteleiro parceiro, a fim de que o PROGRAMA adote as providências cabíveis.
                    </TextModal>
                    <TextModal>
                    <b>Art. 17º - </b>O cliente que agir em desacordo com o previsto neste Regimento Interno ou
com qualquer outra norma interna ou externa, estará sujeito, a critério do PROGRAMA,
a qualquer uma das seguintes penalidades: a) advertência por escrito; b) exclusão do
quadro de clientes.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo Único - </b>Caso o cliente seja excluído do PROGRAMA, nenhum valor será
reembolsado.
                    </TextModal>
                    <TextModal>
                    <b>Art. 18º - </b>Em caso de falecimento do cliente, os legítimos sucessores poderão solicitar a
transferência do título para o seu nome, informando seus dados pessoais e bancários
para fins de cadastro e cobrança das obrigações financeiras.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>Os sucessores deverão informar o falecimento em até 20 (vinte)
dias após o óbito, desde que, ainda vigente o prazo do plano contratado.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>Caso o interesse dos sucessores seja pelo cancelamento do título,
este deverá ser previamente quitado, para que seja efetivado o desfazimento do vínculo
obrigacional existente.
                    </TextModal>
                    <SubTitle>DO PAGAMENTO DO PLANO DE HOSPEDAGEM</SubTitle>
                    <TextModal>
                    <b>Art. 19º - </b>As parcelas mensais do Plano de Hospedagem serão pagas através de boleto
bancário a ser emitido pela AMERIS, admitindo-se, inclusive, o pagamento integral do
valor devido ou parcelado.
                    </TextModal>
                    <TextModal>
                    <b>Art. 20º - </b>O pagamento das parcelas mensais do Plano de Hospedagem, em caso de
parcelamento, será totalmente devido até o final, independentemente de o Cliente ter
utilizado as diárias de hospedagem disponibilizadas, haja vista que o Plano de
Hospedagem permanecerá à sua disposição pelo prazo contratado.
                    </TextModal>
                    <TextModal>
                    <b>Art. 21º - </b>Ocorrendo ausência de pagamento dentro do vencimento do boleto, implicará
em multa de 2% (dois por cento) sobre a importância devida, juros de 1% (um por cento)
ao mês ou fração e correção monetária calculada pelo IPCA ou outro índice que vier a
substituí-lo.
                    </TextModal>
                    <TextModal>
                    <b>Art. 22º - </b>O não pagamento das Parcelas Mensais do Plano de Hospedagem pelo período
igual ou superior a 60 (sessenta) dias, não necessariamente consecutivos, implicará no
cancelamento automático do Plano de Hospedagem, independente de notificação
prévia, sem prejuízo da cobrança dos valores devidos, extrajudicialmente ou pela via
judicial.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo Único - </b>A AMERIS ainda se reserva no direito de incluir o nome do Cliente
devedor nos cadastros de proteção ao crédito e cartórios de ofício de protesto de títulos,
sem que disso decorra qualquer direito à indenização, independentemente de prévia
notificação.
                    </TextModal>
                    <SubTitle>DO PRAZO DE UTILIZAÇÃO DAS DIÁRIAS NOS PLANOS DE HOSPEDAGEM E DA DECADÊNCIA DO DIREITO À UTILIZAÇÃO</SubTitle>
                    <TextModal>
                    <b>Art. 23º - </b>O período de utilização das diárias dos Planos de Hospedagem, inicia-se 30
(trinta) dias após o pagamento da primeira parcela do plano.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo Único - </b>O cliente somente poderá utilizar as diárias de hospedagem com base
na carência prevista no artigo 9º.
                    </TextModal>
                    <TextModal>
                    <b>Art. 24º - </b>O prazo de utilização das diárias encerra-se 24 (vinte) meses após a data da
compra.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo Único - </b>As diárias somente poderão ser utilizadas se a hospedagem
pretendida for finalizada até o último dia do período de vigência do Plano de
Hospedagem.
                    </TextModal>
                    <TextModal>
                    <b>Art. 25º - </b>A utilização das diárias somente é garantida pela AMERIS dentro do prazo
contratado no Planos de Hospedagem, ou seja, 24 (vinte e quatro) meses.
                    </TextModal>
                    <TextModal>
                    <b>Art. 26º - </b>As diárias eventualmente existentes ao final do período contratado no Plano
de Hospedagem não podem ser transferidas ou prorrogadas para um período
subsequente, ocorrendo, neste caso, a decadência do direito de utilizá-las, nos termos
do Art. 211 do Código Civil, razão pela qual também não será devolvido qualquer valor
referente a períodos pretéritos, tendo em vista que durante o período contratado as
diárias disponibilizadas se encontravam integralmente disponíveis para utilização.
                    </TextModal>
                    <SubTitle>DA UTILIZAÇÃO DO PLANO POR TERCEIROS</SubTitle>
                    <TextModal>
                    <b>Art. 31º - </b>É permitida a utilização do plano por terceiros indicados pelo cliente, somente
caso o cliente esteja adimplente com os pagamentos mensais.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>A cessão ocorrerá através do site do PROGRAMA.
                    </TextModal>
                    <SubTitle>DO CANCELAMENTO DO PLANO DE HOSPEDAGEM</SubTitle>
                    <TextModal>
                    <b>Art. 32º - </b>O Plano de Hospedagem poderá ser cancelado a qualquer momento, a pedido
do Cliente, automaticamente por iniciativa da AMERIS, conforme previsto no Art. 22
deste Regulamento ou a pedido dos sucessores e/ou espólio do cliente falecido.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>O cancelamento do Plano de Hospedagem a pedido do CLIENTE,
importará na necessidade de pagamento de multa de 30% (trinta por cento) das parcelas
vincendas, caso não tenha havido utilização de diárias pelo cliente.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>Quando solicitado formalmente pelo cliente ou seus sucessores, o
cancelamento somente ocorrerá após pagamento dos valores eventualmente vencidos
e da multa prevista.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 3º - </b>Em quaisquer das hipóteses de cancelamento mencionadas neste artigo,
o cliente não terá direito à restituição de qualquer quantia por ele pago, mesmo que
não tenha havido utilização de diárias no período contratado.
                    </TextModal>
                    <TextModal>
                    <b>Art. 33º - </b>O Cliente que já tenha utilizado diárias do Plano de Hospedagem, somente
poderá cancelar o seu título após o pagamento de multa, correspondente a 100% (cem
por cento) das parcelas vincendas.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>Caso o Cliente tenha utilizado parcialmente as diárias do Plano de
Hospedagem, o valor da multa será apurado por simples cálculo aritmético.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>O cancelamento do Plano de Hospedagem somente estará finalizado após
o pagamento das multas estabelecidas acima.
                    </TextModal>
                    <SubTitle>DO DIREITO AO ARREPENDIMENTO</SubTitle>
                    <TextModal>
                    <b>Art. 34º - </b>Considerando a contratação eletrônica do Plano de Hospedagem e nos termos
do artigo 49 do Código de Defesa do Consumidor, o Cliente poderá desistir do Plano de
Hospedagem contratado no prazo de 07 (sete) dias, a contar da data em que manifestar
o seu “de acordo”. Findo o referido prazo legal sem manifestação do CLIENTE, presumirse-á aceito o presente Regulamento em sua integralidade.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo Único - </b>Caso o Cliente exerça o direito de arrependimento previsto na
legislação, no prazo assinalado, os valores eventualmente pagos, serão devolvidos, de
imediato, monetariamente atualizados, pelo mesmo meio cujo pagamento fora
realizado e de acordo com as regras da instituição financeira depositária.
                    </TextModal>
                    <SubTitle>DO PROCEDIMENTO E DO PRAZO PARA SOLICITAÇÃO DE RESERVAS</SubTitle>
                    <TextModal>
                    <b>Art. 35º - </b>Os pedidos de solicitação de reserva e utilização das diárias de hospedagem
somente serão processados caso o Cliente esteja adimplente com as parcelas mensais.
                    </TextModal>
                    <TextModal>
                    <b>Art. 36º - </b>Os pedidos de marcação de reservas deverão obrigatoriamente ser feitos
dentro do site Vai de Férias ou no aplicativo Vai de Férias, de acordo com os prazos de
antecedência estabelecidos no artigo 39.
                    </TextModal>
                    <TextModal>
                    <b>Art. 37º - </b>A relação dos destinos e hotéis elegíveis estará disponível no site e aplicativo
do PROGRAMA (www.vaideferias.com.br).
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo Único - </b>A AMERIS se reserva ao direito de realizar inclusões e exclusões de
estabelecimentos hoteleiros na relação da Rede Conveniada, a qualquer tempo e sem
prévio aviso.
                    </TextModal>
                    <TextModal>
                    <b>Art. 38º - </b>A hospedagem de clientes é feita em estabelecimentos hoteleiros da Rede
Conveniada da AMERIS, em apartamentos duplos com até 01 criança de até 07 anos
hospedada no mesmo quarto.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>A acomodação do cliente em apartamento com cama de casal ou camas
de solteiro dependerá da disponibilidade do estabelecimento hoteleiro conveniado.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>Caso o cliente pretenda acomodar mais pessoas no apartamento, a
solicitação deverá ser realizada diretamente ao estabelecimento hoteleiro escolhido,
arcando com os custos necessários.
                    </TextModal>
                    <TextModal>
                    <b>Art. 39º - </b>As reservas deverão ser solicitadas com antecedência mínima de 30 (trinta dias)
da data pretendida e de acordo com a disponibilidade de vagas para a hospedagem
informada pela Ameris, observado o período pretendido.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>As reservas não serão garantidas em períodos de alta temporada, em
razão das regras aplicadas pelos Estabelecimentos Hoteleiros conveniados, mesmo que
a reserva seja feita com a antecedência prevista no caput deste artigo.
                    </TextModal>
                    <TextModal>
                    <b>Art. 40º - </b>As reservas solicitadas fora dos prazos estabelecidos no artigo anterior poderão
ser submetidas ao aceite da AMERIS, que poderá confirmá-las ou não, a seu critério,
conforme disponibilidade.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>O Cliente deverá acompanhar a situação do seu pedido, através do site
ou aplicativo do PROGRAMA para se informar sobre a confirmação ou não da reserva.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>Não havendo resposta da AMERIS no período de 72 (setenta e duas)
horas, fica a solicitação automaticamente expirada, independente de prévia notificação.
                    </TextModal>
                    <TextModal>
                    <b>Art. 41º - </b>A AMERIS não se responsabiliza por qualquer atraso na marcação de reserva
ou perda de oportunidade de hospedagem por parte do Cliente, em razão dos prazos
estabelecidos neste Regulamento, e nem garante a confirmação da reserva, mesmo que
realizada dentro daqueles prazos.
                    </TextModal>
                    <TextModal>
                    <b>Art. 42º - </b>Os pedidos de marcação de reserva somente serão registrados no Sistema de
de Reservas Ameris após o aceite do cliente.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>Em períodos de maior concentração de fluxo turístico, como por exemplo,
Réveillon, Carnaval, Alta Temporada em destinos turísticos específicos e outros feriados
prolongados, a AMERIS poderá fazer cobranças suplementares para fazer face aos
custos decorrentes dos pagamentos de tarifas diferenciadas o, caso cobrado pelo
empreendimento hoteleiro conveniado.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - </b>Independentemente dos períodos previstos no parágrafo anterior, a
AMERIS poderá, igualmente, realizar cobranças adicionais para cobrir tarifas situadas
acima da média em algumas praças e, assim, evitar que reflitam negativamente no
cálculo do Plano de Hospedagem.
                    </TextModal>
                    <TextModal>
                    <b>Art. 43º - </b>A AMERIS paga diretamente ao empreendimento hoteleiro conveniado as
despesas de hospedagem relativas exclusivamente às diárias utilizadas pelo cliente.
Qualquer diferença de custo em função de outra forma de utilização - como pedido de
camas extras, tipo de apartamento de categoria superior, refeições ou serviços extras e
quaisquer outras despesas - será paga pelo cliente diretamente ao empreendimento
hoteleiro.
                    </TextModal>
                    <TextModal>
                    <b>Art. 44º - </b>O cliente fica ciente de que as diárias disponibilizadas pela AMERIS somente
dão direito ao café da manhã para no máximo dois adultos e uma criança de até 07 anos,
por plano adquirido.
                    </TextModal>
                    <SubTitle>DA CONFIRMAÇÃO DA RESERVA</SubTitle>
                    <TextModal>
                    <b>Art. 45º - </b>Confirmada a reserva, a AMERIS emitirá o respectivo voucher através do e-mail
informado pelo Cliente. A apresentação do voucher ao estabelecimento hoteleiro é
obrigatória no momento em que o hóspede se registrar para a hospedagem (check-in).
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>O estabelecimento hoteleiro conveniado poderá recusar a entrada do
hóspede que não apresentar o voucher emitido pela AMERIS.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 2º - O cliente que não tiver recebido o voucher, até uma semana antes da
data de início da hospedagem, deverá entrar em contato imediatamente com a
Central de Reservas da AMERIS.</b>
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 3º - </b>O voucher contém as seguintes informações: código da reserva; nome do
hóspede; quantidade de apartamentos; tipo de apartamento; quantidade de pessoas;
período da hospedagem; quantidade de diárias; data da emissão.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 4º - </b>O voucher contempla informações sobre as condições gerais
estabelecidas para a hospedagem autorizada, assim como do estabelecimento hoteleiro
destinatário.
                    </TextModal>
                    <TextModal>
                    <b>Art. 46º - </b>O cliente fica ciente de que nos termos do Art. 82 da Lei nº 8.069/92, caso o
hóspede esteja acompanhado de menor de idade é obrigatória a apresentação de
documento de identidade ou certidão de nascimento que comprove a sua filiação, ou,
caso não seja filho (a), além do documento de identificação da criança, necessária é a
autorização para tanto emitida de acordo com a legislação vigente, sob pena de o
Estabelecimento Hoteleiro não autorizar a efetivação da hospedagem.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>A AMERIS não terá qualquer responsabilidade pelo cancelamento da
reserva, caso o Cliente não observe a determinação acima.
                    </TextModal>
                    <SubTitle>DO CANCELAMENTO OU MODIFICAÇÃO DAS RESERVAS</SubTitle>
                    <TextModal>
                    <b>Art. 47º - </b>Para modificação ou cancelamento das reservas, que somente poderá ser feito
uma única vez, o CLIENTE deverá observar os prazos mínimos, com base na data do início
da hospedagem, para solicitar, quais sejam:
    <br/>&emsp;a) Modificação da reserva: 20 (vinte dias) de antecedência;
    <br/>&emsp;b) Cancelamento da reserva: 15 (quinze) dias de antecedência;
    <br/>&emsp;c) A reserva uma vez cancelada, só poderá ser remarcada no mesmo hotel.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>A AMERIS não garante o cancelamento ou modificação e os prazos acima
podem sofrer alterações de acordo com a política interna de cada estabelecimento
hoteleiro, o que será devidamente informado ao CLIENTE.
                    </TextModal>
                    <TextModal>
                    <b>Art. 48º - </b>Desde que observado o prazo mínimo fixado neste Regimento Interno, os
pedidos modificação de reserva serão processados imediatamente, desde que haja
disponibilidade de apartamentos para atendê-los, com pronta resposta ao CLIENTE.
                    </TextModal>
                    <TextModal>
                    <b>Art. 49º - </b>Os pedidos apresentados fora do prazo mínimo, somente serão processados
após a manifestação dos estabelecimentos hoteleiros que poderão acatar ou não a
solicitação apresentada.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>As reservas, cujos pedidos de cancelamento forem apresentados fora dos
prazos mínimos ficarão sujeitas aos ônus do no-show (não comparecimento) que
deverão ser pagos pelo CLIENTE.
                    </TextModal>
                    <SubTitle>DO NÃO-COMPARECIMENTO (NO-SHOW)</SubTitle>
                    <TextModal>
                    <b>Art. 50º - </b>O CLIENTE tem obrigação de comparecer ao estabelecimento hoteleiro que
realizou a reserva nas primeiras 24 (vinte e quatro) horas do início da hospedagem
(<i>check in</i>) e caso não compareça, será configurado como no-show (não
comparecimento) e a reserva será cancelada pelo estabelecimento hoteleiro
conveniado.
                    </TextModal>
                    <TextModal>
                    <b>Art. 51º - </b>Configurado o no-show (não comparecimento), o CLIENTE perderá o direito a
quantidade das diárias reservadas e não terá direito a qualquer tipo de reembolso.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo 1º - </b>Caso a impossibilidade de comparecimento nas primeiras 24 (vinte e
quatro) horas do início da hospedagem (check-in) ocorrer por motivo de força maior,
provocando apenas um retardamento na chegada do CLIENTE ao estabelecimento
hoteleiro, o CLIENTE tem a obrigação de comunicar o fato ao estabelecimento hoteleiro
conveniado, antes do horário do check-in.
                    </TextModal>
                    <TextModal>
                    <b>Art. 52º - </b>Ocorrendo o no-show (não comparecimento), a AMERIS deduzirá as diárias de
hospedagem correspondentes do saldo de diárias a que o cliente tem direito em seu
Plano de Hospedagem, além de eventuais custos pela marcação da reserva.
                    </TextModal>
                    <SubTitle>DA PROTEÇÃO DE DADOS</SubTitle>
                    <TextModal>
                    <b>Art. 53º - </b>A AMERIS se preocupa com a privacidade e a proteção de dados pessoais dos
CLIENTES e compromete-se a atuar nos exatos termos previstos na Lei 13.709, de 14 de
agosto de 2018 (Lei Geral de Proteção de Dados).
                    </TextModal>
                    <TextModal>
                    <b>Art. 54º - </b>A Política de Privacidade de Dados do PROGRAMA estará disponível em seu
site e em seu aplicativo, se reservando ao direito de modificá-la a qualquer tempo e sem
prévio aviso.
                    </TextModal>
                    <TextModal>
                    <b>Art. 55º - </b>Os CLIENTES comprometem-se a ter ciência integral da Política de Privacidade
de dados que consta do site e do aplicativo do PROGRAMA.
                    </TextModal>
                    <TextModal>
                    <b>Art. 56º - </b>Os pedidos dos titulares de dados deverão ser exercidos nos exatos termos que
constam na Política de Privacidade de Dados do PROGRAMA.
                    </TextModal>
                    <TextModal>
                    <b>Art. 57º - </b>O CLIENTE autoriza a AMERIS
o processamento de seus dados pessoais para consulta de crédito e adesão ao
PROGRAMA.
                    </TextModal>
                    <SubTitle>DO OVERBOOKING</SubTitle>
                    <TextModal>
                    <b>Art. 58º - </b>Em caso de ocorrência de overbooking, o CLIENTE será devidamente
comunicado da disponibilidade nos estabelecimentos hoteleiros conveniados, podendo
cancelar a reserva sem ônus ou modificar a reserva para outro estabelecimento
hoteleiro nos mesmos padrões do anteriormente escolhido.
                    </TextModal>
                    <TextModal>
                    <b>Art. 59º - </b>A ocorrência de overbooking não caracterizará descumprimento contratual por
parte da AMERIS.
                    </TextModal>
                    <TextModal>
                    <b>Art. 60º - </b>O Cliente, ao verificar situação de overbooking quando do check-in no
estabelecimento hoteleiro indicado no voucher, deverá entrar em contato
imediatamente com a AMERIS, por seus canais de atendimento. <b>A AMERIS se
compromete intermediar a sua imediata alocação em outro Estabelecimento
Hoteleiro de qualidade igual ou superior</b>, que possua disponibilidade, prestando-lhe
todo o auxílio necessário, mesmo sem qualquer responsabilidade ou ingerência sobre
esta situação.
                    </TextModal>
                    <TextModal>
                    <b>Parágrafo Único - </b>Caso o estabelecimento hoteleiro reservado ofereça uma outra opção
de hospedagem na cidade, ficará o CLIENTE com a decisão de concordar ou não com a
oferta.
                    </TextModal>
                    <SubTitle>DAS DISPOSIÇÕES GERAIS</SubTitle>
                    <TextModal>
                    <b>Art. 61º - </b>O CLIENTE reconhece que a AMERIS apenas realiza a intermediação das diárias
de hospedagem, assim, ao aderir ao Plano de Hospedagem o CLIENTE a isenta de
qualquer responsabilidade por problemas advindos da prestação dos serviços pelos
Estabelecimentos Hoteleiros conveniados, como também a isenta nas hipóteses de caso
fortuito ou força maior.
                    </TextModal>
                    <TextModal>
                    <b>Art. 62º - </b>O CLIENTE reconhece que a AMERIS, em razão de limitações de natureza
comercial, aumento do fluxo turístico em determinadas épocas como feriados
prolongados, festas locais, alta temporada, etc..., não tem como garantir, em tais
ocasiões e de forma ilimitada, as hospedagens por acaso demandadas pelos seus
CLIENTES para hotéis de determinadas cidades.
                    </TextModal>
                    <TextModal>
                    <b>Art. 63º - </b>As quantidades disponíveis de diárias que constam do site do PROGRAMA, não
representam a capacidade total da Rede Conveniada, porque estabelecimentos
hoteleiros conveniados hospedam tanto os clientes do PROGRAMA, como outros
clientes.
                    </TextModal>
                    <TextModal>
                    <b>Art. 64º - </b>A AMERIS atua constantemente para ampliar as opções de estabelecimentos
hoteleiros e quantidade de diárias disponibilizadas, como também se compromete a
orientar os CLIENTES nas suas pretensões de hospedagem, para épocas, hotéis ou
cidades que ofereçam mais facilidades de acomodações.
                    </TextModal>
                    <TextModal>
                    <b>Art. 65º - </b>A AMERIS poderá conceder benefícios e realizar promoções eventuais, sendo
que, estes casos serão objeto de regulamentação específica, se reservando a AMERIS o
direito de promover alterações a qualquer tempo e sem prévio aviso.
                    </TextModal>
                    <TextModal>
                    <b>Art. 66º - </b>A AMERIS reserva-se o direito de alterar o Regimento Interno a qualquer
tempo, sem aviso prévio. As alterações serão publicadas no site do PROGRAMA e as
novas versões substituirão de imediato as versões antigas.
                    </TextModal>
                    <TextModal>
                    <b>Art. 67º - </b>As partes obrigam-se por si, seus herdeiros e seus sucessores, ao fiel
cumprimento deste Regulamento.
                    </TextModal>
                    <TextModal>
                    <b>Art. 68º - </b>O CLIENTE expressamente, ao aderir ao PROGRAMA, declara e garante, para
todos os fins de direito, que possui capacidade jurídica para celebrar o respectivo
contrato.
                    </TextModal>
                    <TextModal>
                    <b>Art. 69º - </b>O presente Regulamento é regido e interpretado de acordo com as leis da
República Federativa do Brasil.
                    </TextModal>
                    <TextModal>
                    <b>Art. 70º - </b>Fica eleito o Foro de Brasília – DF para dirimir todas as questões que envolvem
este regulamento, com a exclusão de qualquer outro, por mais privilegiado que possa
ser.
                    </TextModal>
                    <SubTitle>GLOSSÁRIO</SubTitle>
                    <TextModal>
                    <b>Plano de Pagamento – </b>Refere-se ao plano disponibilizado no PROGRAMA “Vai de
Férias”, que será de 24 (vinte e quatro) meses.
                    </TextModal>
                    <TextModal>
                    <b>Boleto – </b>Documento financeiro emitido pela AMERIS para que o cliente realize o
pagamento mensal do plano escolhido.
                    </TextModal>
                    <TextModal>
                    <b>Proponente – </b>Pessoa física ou jurídica com interesse em aderir ao PROGRAMA “Vai de
Férias”.
                    </TextModal>
                    <TextModal>
                    <b>PROGRAMA – </b>Refere-se ao PROGRAMA “Vai de Férias” administrado pela AMERIS.
                    </TextModal>
                    <TextModal>
                    <b>Estabelecimento Hoteleiro Conveniado – </b>Hotéis, pousadas, , flats, apart hotéis e demais
empreendimentos que mantém contrato de hospedagem com a AMERIS, aptos a
receber clientes do PROGRAMA.
                    </TextModal>
                    <TextModal>
                    <b>Site – </b>palavra em inglês que se refere à página de internet do PROGRAMA, presente no
endereço www.vaideferias.com.br.
                    </TextModal>
                    <TextModal>
                    <b>Voucher – </b>Documento emitido pela AMERIS para que o CLIENTE possa realizar seu check
in no Estabelecimento Hoteleiro Conveniado.
                    </TextModal>
                    <TextModal>
                    <b>No Show – </b>palavra em inglês que se refere à ausência de comparecimento do hóspede
ao Estabelecimento Hoteleiro, nas primeiras 24 horas do dia da hospedagem.
                    </TextModal>
                    <TextModal>
                    <b>Overbooking – </b>palavra em inglês, que se refere ao fato de o Estabelecimento Hoteleiro
aceitar mais reservas do que sua capacidade de atendimento pode comportar.
                    </TextModal>
                    <Check>
                        <label>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={handleChange}
                            />
                            <span>Confirmo a aceitação do contrato de Usuário(a), estando ciente de todos os seus termos e condições.</span>
                        </label>
                    </Check>
                    <Button format="submit" onClick={() => verifyCheckboxIsChecked()}>OK</Button>
                    
                </ModalContainer>
            </ModalBackground>
        </DivColor>
    );
};

export default Modal;