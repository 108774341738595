import { Container } from "./styles"
import Button from "../../../components/Button"
import IndexTable from '../../../components/IndexTable'
import { useHistory } from "react-router-dom"
import { Page } from '../../AdminDashboard/styles.js'
import { useState, useEffect } from 'react'
import { api } from '../../../services/api'
import Pagination from '../../../components/Pagination'
import { useUserContext } from "../../../components/contexts/useUserContext"

const Tags = () => {
    const { user } = useUserContext();
    const history = useHistory()

    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const paginationLimit = 10;
    const [adminPermissions, setAdminPermissions] = useState([]);

    useEffect(() => {
        api.get('/tags/total_records')
        .then(response => setTotal(response.data))
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
        setOffset(paginationLimit * (page - 1))
    }, [page])

    useEffect(() => {
      api.get('/permissions/index')
      .then(response => {
        let permissions = []
        response.data.forEach((permission) => {
          if (user.permissions.indexOf(permission.id) !== -1)
            permissions.push(permission.name)
        })
        setAdminPermissions(permissions)
      })
  }, [user])

    return (
      <Page>
        <Container>
          <IndexTable
              request='tags'
              caption='Tags'
              pagination
              offset={offset}
              adminPermissions={adminPermissions}
          />
          <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={total}
              pageSize={paginationLimit}
              onPageChange={currentPage => setPage(currentPage)}
          />
          { adminPermissions.indexOf('modify_tags') !== -1 &&   
            <Button format='submit' onClick={() => history.push('/tags/create')} className='add-new'>Nova Tag</Button>
          }
          </Container>
      </Page>
    )
}

export default Tags
