import styled from "styled-components"

export const Container = styled.section`
  border: 1px solid #fff;
  width: 16rem;
  height: 19rem;
  color: #ffffff;
  border-radius: 10px;
  h3 {
    font-size: 1.875rem;
    margin: 0rem 0.5rem;
    font-weight: 400;
  }
  span {
    color: #ffffff;
    font-weight: 200;
    margin: 0rem 0.5rem;
    font-size: 1.125rem;
  }
  
  .image {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    &::before{
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(to top, black -98%, transparent);
      background-position: center top;
      object-fit: cover;
      background-size:cover;
      /* background-attachment: fixed; // da um belo efeito também */
      background-repeat: no-repeat;
      border-radius: 5px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text {
    position: absolute;
    text-align: left;
    width: 100%;
    bottom: 1rem;
    left: 1rem;
  }
  @media screen and (max-width: 490px) {
    width: 100%;
    height: 50vh;
    margin: 0 .5rem;

    img {
      width: 100%;
    }
    h3 {
      font-size: 1.3rem;
    }
    span {
      font-size: 1rem;
    }
  }
`