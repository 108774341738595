import styled from "styled-components";

export const OutterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100vw;

    h1 {
        text-align: center;
        font-family: "Montserrat", sans-serif;
    }
`;

export const InnerContainer = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 765px) {
       flex-direction: column;
    }
`

export const LeftSection = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .upperPart {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .rec-dot {
            display: none;
        }

        .rec-arrow-right {
            position: relative;
            background: #CC2A55;
            color: #fff;
            right: 5%;
            
            &:hover, &:focus {
                transform: translateX(10px);
            }
        }

        .rec-arrow:disabled {
            visibility: hidden;
        }

        .rec-arrow-left {
            position: relative;
            left: 5%;
            background: #CC2A55;
            color: #fff;
            z-index: 1;

            &:hover, &:focus {
                transform: translateX(-10px);
            }
        }

        img {
            max-height: 60vh;
            max-width: 60vw;
            height: 100%;
            width: 100%;
            border-radius: 1.5rem;
            margin: 2rem 0;
            object-fit: cover;
        }
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        justify-content: space-evenly;
    }

    .actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        form {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;

            label {
                transition: 0.5s;
                padding: 1rem 1.5rem;
                font-family: 'Poppins', sans-serif;
                font-size: 1.125rem;
                margin: 0.625rem;
                font-weight: 600;
                border-radius: 0.25rem;
                color: white;
                display: flex;
                flex: 1;
                background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
                align-items: center;
                justify-content: center;

                :hover {
                    transform: translateY(-2px);
                    cursor: pointer;
                }
            }

            input {
                display: none;
            }

            button {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
            }
        }

        .links {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`

export const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    height: 100%;
`

export const Info = styled.div`
    color: #353839;
    display: flex;
    flex-direction: column;
    max-width: 90vw;

    h6 {
        font-size: 1rem;
    }

    p {
        white-space: pre-line;
        padding-left: 1rem;
    }

    div {
        align-self: center;
    }

    iframe {
        max-width: 90%;
    }
`
