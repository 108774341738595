import styled from 'styled-components';

export const Background = styled.div`
  all: initial;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
`;


export const ModalWrapper = styled.div`
  margin: 0;
  z-index: 20;
  position: fixed;
`;