import ArrowBack from '../../../../../components/ArrowBack'
import { Container, Page } from './styles';
import Button from '../../../../../components/Button';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {HiPrinter} from 'react-icons/hi';
import { api } from '../../../../../services/api';

const PackageDetails = () => {
    const {id} = useParams();
    const [isTicket, setIsTicket] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [order, setOrder] = useState({created_at: null, charges: [], status: null});
    const [pacote, setPacote] = useState({package_plan: {name: null, installments: 1, price_in_cents: 0, charges: 1}, available_vacation_days: null})

    const history = useHistory()

    useEffect(() => {
        api.get(`/packages/order/${id}`)
        .then(response => {
            setOrder(response.data);
            if(response.data.charges && response.data.charges[0].payment_method !== "credit_card")
            setIsTicket(true);
        })
        
        api.get(`/packages/show/${id}`)
        .then(response => setPacote(response.data));
    }, [id])

    const formatDateTime = (dateInput) => {
        let options = {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            timeZoneName: 'short'
        };

        let d = new Date(dateInput)
        return Intl.DateTimeFormat('pt-br', options).format(d)
    }

    const formatDate = (dateInput) => {
        let options = {year: 'numeric', month: '2-digit', day: '2-digit'};

        let d = new Date(dateInput)
        return Intl.DateTimeFormat('pt-br', options).format(d)
    }

    const getInstallmentPrice = (installments, princeInCents) => {
        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        let price = princeInCents/(installments*100);
        return formatter.format(price);
    }

    const capitalizeFirstLetter = (string) => {
        return string && string.charAt(0).toUpperCase() + string.slice(1);
    }

    const sortCharges = (charges) => {
        return charges.slice().sort((a,b) => new Date(a.due_at) - new Date(b.due_at))
    }

    const updatePackage = () => {
        api.put(`/packages/update_packages/${id}`, {
            is_active: !pacote.is_active,
        }).then(response => history.go(0))
    }

    return (
        <Page>
            <ArrowBack/>
            <Container>
                <h1>#{id} - {pacote.package_plan.name}</h1>
                <div className={showInfo && "show-info"}>
                    <div className="tabs">
                        <h4 className={showInfo ? 'active' : ''} onClick={() => setShowInfo(true)}>Informações do Pacote</h4>
                        {isTicket && <h4 className={!showInfo ? 'active' : ''} onClick={() => setShowInfo(false)}>Boletos</h4>}
                    </div>
                    {showInfo &&
                        <div>
                            <ul className="package-info">
                                <li>
                                    <b>Data:</b>
                                    <p>{formatDateTime(order.created_at)}</p>
                                </li>
                                <li>
                                    <b>Pacote:</b>
                                    <p>{pacote.package_plan.name}</p>
                                </li>
                                <li>
                                    <b>Número do Pacote:</b>
                                    <p>#{id}</p>
                                </li>
                                <li>
                                    <b>Método de pagamento:</b>
                                    <p>{isTicket ? "Boleto" : "Cartão de Crédito"}</p>
                                </li>
                                <li>
                                    <b>Status do Pacote:</b>
                                    <p>{capitalizeFirstLetter(order.status)}</p>
                                </li>
                                <li>
                                    <b>Diárias restantes</b>
                                    <p>{pacote.available_vacation_days}</p>
                                </li>
                                <li>
                                    <b>Valor:</b>
                                    <p>
                                        {
                                            `${pacote.package_plan.installments || pacote.package_plan.charges} x 
                                            ${getInstallmentPrice(
                                                pacote.package_plan.installments || pacote.package_plan.charges,
                                                pacote.package_plan.price_in_cents)}`
                                        }
                                    </p>
                                </li>
                            </ul>
                            <Button format='submit' onClick={() => updatePackage()}>{pacote.is_active ? 'Desativar Pacote' : 'Ativar Pacote'}</Button>
                        </div>
                    }
                    {!showInfo &&
                        <div className="my-tickets">
                            <div className="row">
                                <h6 className="sm-col">Código do Boleto</h6>
                                <h6 className="sm-col">Valor</h6>
                                <h6 className="sm-col">Status do Boleto</h6>
                                <h6 className="md-col">Data de Vencimento do Boleto</h6>
                                <span className="md-col"/>
                            </div>
                            <ul>
                                {sortCharges(order.charges).map((charge, key) => {return (
                                    <li key={key}>
                                        <span className="sm-col">
                                            <h6 className="responsive-header">Código do Boleto:</h6>
                                            <p>{charge.id}</p>
                                        </span>
                                        <span className="sm-col">
                                            <h6 className="responsive-header">Valor:</h6>
                                            <p>{getInstallmentPrice(1, charge.amount)}</p>
                                        </span>
                                        <span className="sm-col">
                                            <h6 className="responsive-header">Status do Boleto:</h6>
                                            <p className={charge.status}>
                                                {capitalizeFirstLetter(charge.status)}
                                            </p>
                                        </span>
                                        <span className="md-col">
                                            <h6 className="responsive-header">
                                                Data de Vencimento do Boleto:
                                            </h6>
                                            <p>{charge.last_transaction.due_at && formatDate(charge.last_transaction.due_at)}</p>
                                        </span>
                                        <span className="md-col">
                                            <a href={charge.last_transaction.pdf} target="_blank" rel="noreferrer">
                                                <Button format="submit"><HiPrinter/>Imprimir</Button>
                                            </a>
                                        </span>
                                    </li>
                                )})}
                            </ul>
                        </div>
                    }
                </div>
            </Container>
        </Page>
    )
}

export default PackageDetails;