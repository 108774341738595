import ArrowBack from "../../../../components/ArrowBack"
import { Container } from "./styles.js"
import { useParams, useHistory } from "react-router-dom"
import { useState, useEffect } from 'react'
import { api } from "../../../../services/api"
import Button from "../../../../components/Button"
import { alert } from "../../../../components/Alert"
import { Page } from "../../../AdminDashboard/styles"


const ApproveBoleto = () => {

    const {id} = useParams()
    const history = useHistory()

    const payment_method = 'boleto'
    const [client, setClient] = useState({})
    const [disableButton, setDisableButton] = useState(false);


    useEffect(() => {
		api.get(`clients/show/${id}`)
		.then((response) => {
		    setClient(response.data)

        if (response.data.register_approval) {
          history.push('/manager/clients/approval')
        }
		})
    }, [id, history])

    const confirmPackage = async (client) => {
        setDisableButton(true);
        try {
            const response = await api.post("packages/create", {
                payment_method,
                package: {
                    client_id: client.id,
                    package_plan_id: 2, // Fixo Boleto
                    available_vacation_days: 7,
                    is_active: true
                }
            });
            if (response.data) {
                alert(`O Pacote do cliente #${client.id} foi aprovado!.`, 'success');
                history.push('/manager/clients/approval')
            }
        } catch (e) {
            alert(e.response.data.message? e.response.data.message : 'Algum erro ocorreu. Por favor, tente novamente.', e.response.data.type? e.response.data.type : 'error');
        }
    }

    return(
        <Page>
            <Container>
            <ArrowBack/>
                <h2>Confirme o Pedido do cliente</h2>
                <div className='info'>
                    <p><span>Nome:</span> {client.name}</p>
                    <p><span>CPF:</span> {client.cpf}</p>
                    <p><span>Email:</span> {client.email}</p>
                    <p><span>Valor:</span> 24 x R$99,00</p>
                    <p><span>Método de Pagamento: </span>{client.payment_method}</p>
                </div>
                <Button type='submit' cursor={disableButton && "wait"} format='submit' onClick={() => (!disableButton && confirmPackage(client))}>Confirmar Pedido</Button>
            </Container>
        </Page>
    )
}

export default ApproveBoleto