import styled from 'styled-components';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
    color: #3A3B3C;
    width: 90vw;
    margin: auto;

    h2 {
        font-size: 3rem;
        line-height: 100%;
    }

    h3 {
        font-size: 2.25rem;
    }

    h6 {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 0 0 3rem;
    }

    h2, h3, b, h5 { font-weight: 600 }
    
    >div .div-style-arrow {
        margin: 1rem 0 0;
    }
    
    * {margin: 0}

    @media screen and (max-width: 992px){
        h2 {font-size: 2.5rem}
        h3 {font-size: 1.875rem}
        h6 {font-size: 1.25rem}
    }

    @media screen and (max-width: 576px){
        h2 {font-size: 2rem}
        h3 {font-size: 1.5rem}
        h6 {font-size: 1.125rem}
    }
`;

export const Screen1 = styled.section`
    display: flex;
    gap: 4rem;
    border-bottom: 1px solid #3A3B3C;
    padding: 2rem 0 3rem;

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 0.5rem;
        max-width: 50rem;
        margin: auto;
        padding: 0 2rem;

        .images {
            width: 100%;
            height: 35vh;

            .image {height: 35vh}
        }

        p {
            font-size: 1.4rem;
            font-weight: 300;
            /* overflow-y: auto; */
            text-align: justify;
        }
    }

    > div:first-child p {
        font-size: 1.5rem;
    }

    > div:last-child {
        gap: 0;

        p {
            overflow-y: auto;
            padding-right: .5rem;
            text-align: justify;
        }
    }

    .tags{
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
        text-align: center;
    }

    @media screen and (max-width: 992px){
        gap: 2rem;

        > div {
            padding: 0;
        }

        >div p {
            font-size: 1.125rem;
        }

        > div:first-child p {
            font-size: 1.25rem;
        }
    }

    @media screen and (max-width: 768px){
        flex-direction: column;
        gap: 10vh;
    }

    @media screen and (max-width: 576px){

        >div p {
            font-size: 1rem;
        }

        > div:first-child p {
            font-size: 1.125rem;
        }
    }
`
export const Screen2 = styled.section`
    border-bottom: 1px solid #3A3B3C;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem 0 3rem;
    margin-top: 2rem;
    gap: 1rem;

    h3 {margin-bottom: 5rem}

    h5 {
        font-size: 1.75rem;
    }

    .select-options {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        
        > div {
            flex: 1;

            .select-inputs {
                display: flex;

                > div {
                    flex: 1;
                    align-self: flex-end;

                    .css-g1d714-ValueContainer{
                        padding: 0;
                    }
                }
            }

            .select-date {
                position: relative;
                cursor: pointer;

                .css-3iojos-Placeholder {color: #3A3B3C}

                .my-calendar {
                    position: absolute;
                    width: 100%;
                    cursor: default;
                    z-index: 1;
                    padding: 1rem;
                    border: 1px solid #E2E4E5;

                    > div {
                        width: 100%;
    
                        .rdrMonth {
                            flex: 1;
                            width: unset;
                        }
                    }
                }
            }
        }
    }

    .room-cards {
        width: 100%;
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;
        flex-wrap: wrap;
    }


    .label, .checkbox {
        display: inline-block;
        vertical-align: middle;
        margin: 5px;
        cursor: pointer;
    }
    
    .checkbox {
        opacity: 0;
        position: absolute;
    }
    
    .label {
        position: relative;
        font-size: 1.75rem;
        font-weight: 600;
    }

    .checkbox + .label:before {
        content: '';
        background: #fff;
        border: 2px solid #ddd;
        display: inline-flex;
        vertical-align: middle;
        height: 1.75rem;
        width: 1.75rem;
        margin-right: 1rem;
        text-align: center;
        border-radius: 20%;
        align-items: center;
        justify-content: center;
    }

    .checkbox:checked + .label:before {
        content: '\f00c';
        font-family: 'FontAwesome';
        color: #fff;
        background-color: #3A3B3C;
        border-color: #3A3B3C;
        font-size: 1.25rem;
        font-weight: normal;
    }

    .booker-data {
        display: flex;
        gap: 5rem;
        align-items: center;
        margin: 3rem 0;
        
        h5, form {flex: 1}

        .input-line {
            display: flex;
            .select {
                line-height: 32px;
                padding: 0.9rem 0rem;
                padding-left: 1%;
                > div {
                    flex: 1;
                    > div {
                        width: 85%;
                        box-sizing: border-box;
                    }
                }
            }

            > div {
                flex: 1;
                > div {
                    width: 100%;
                    box-sizing: border-box;

                    input {
                        width: calc(100% - 4rem); // Padding do input
                    }
                    label {right: 1.5rem}
                }
            }
        }

        .phone-inputs {
            display: flex;
            > div:first-child {flex: 1}
            > div {flex: 3}
        }

        @media screen and (max-width: 992px){
            flex-direction: column;
            gap: 1rem;
        }

        @media screen and (max-width: 576px){
            .input-line {display: block}
        }
    }

    .confirmation {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-end;

        h4 {
            display: flex;
            flex-direction: column;
            font-size: 2rem;
            font-weight: 500;

            span {
                font-size: 2.75rem;
                font-weight: 600;
            }
        }

        Button {
            width: 40%;
            font-size: 1.8rem;
            font-weight: 600;
            height: 5rem;
        }
    }

    @media (max-width: 1008px){
        .select-options {
            flex-direction: column;

            > div {
                width: 100%;
            }
        }
    }

    @media (max-width: 768px){
        .confirmation {
            justify-content: center;
            gap: 2rem;
            h4 {
                font-size: 1.25rem;

                span {font-size: 1.5rem}
            }

            Button {
                width: 50%;
                font-size: 1.125rem;
            }
        }
    }

    @media (max-width: 576px){
        h3 {text-align: center}

        h5 {font-size: 1.25rem}

        .select-options > div {
            .select-date > div > div {padding: 0;}
        
            .select-inputs {
                flex-direction: column;

                > div {
                    width: 100%;
                    padding: 0;
                }
            }
        }

        .label {font-size: 1.25rem}

        .checkbox + .label:before{
            height: 1.25rem;
            width: 1.25rem;
        }

        .checkbox:checked + .label:before{
            font-size: 1rem;
        }
    }    
`
export const Screen3 = styled.section`
    text-align: center;
    padding: 2rem 0 3rem;
    h3 { margin: 3rem 0 2rem }

    iframe {
        border: 0;
        max-width: 54rem;
        max-height: 40rem;
        width: 90vw;
        height: 70vh;
    }
`

export const Tag = styled.div`
    display: flex;
    gap: .5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    color: #f8f8f8;
    height: 2rem;
    width: calc(33.33% - 3rem);
    min-width: 6rem;
    border-radius: 0.5rem;
    background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
    font-weight: 600;

    @media screen and (max-width: 576px){
        width: calc(50% - 3rem);;
    }
`
