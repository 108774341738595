import React, { useState } from "react";
import { Container } from "./styles";
import { Link } from "react-router-dom";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FiLogIn, FiLogOut, FiArrowRightCircle } from "react-icons/fi";
import { useUserContext } from "../contexts/useUserContext";

export const NavSideBar = (props) => {
  const { user, sign_out } = useUserContext();

  const [dropdown, setDropdown] = useState(false);
  const showDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <Container sidebar={props.sidebar}>
      <ul className="nav-menu-items">
        <li className="navbar-toggle" onClick={props.showSidebar}>
          <span >
            <FiArrowRightCircle/>
          </span>
        </li>

        {user ? (
          <li className="nav-text">
            <Link to="#" onClick={showDropdown}>
              {user.name?
              <span>Bem vindo, {user.name?.split(" ")[0]}</span>
              :
              <span>Bem vindo</span>
              }
              {dropdown ? <AiFillCaretUp /> : <AiFillCaretDown />}
            </Link>
          </li>
        ) : (
          ""
        )}

        <div className={dropdown ? "drop drop-active" : "drop"}>
          {user && user.type === "admin" ? (
            <li className="nav-text">
              <Link to="/dashboard">
                <span>Dashboard do Admin</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          {user && user.type === "retailer" ? (
            <li className="nav-text">
              <Link to="/dashboard">
                <span>Dashboard do revendedor</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          <li className="nav-text">
            <Link to="/profile" onClick={props.showSidebar}>
              <span>Meu Perfil</span>
            </Link>
          </li>

          {user && user.type === "client" ? (
            <>
              <li className='nav-text'>
                <Link to='/user/my-requests' onClick={props.showSidebar}>
                  <span>Reservas e Pacotes</span>
                </Link>
              </li>
            </>
          ) : (
            ""
          )}
        </div>

        <li className="nav-text" onClick={props.showSidebar}>
          <Link to="/">
            <span>Início</span>
          </Link>
        </li>
        <li className="nav-text" onClick={props.showSidebar}>
          <Link to="/guide">
            <span>Como Adquirir suas Férias</span>
          </Link>
        </li>
        <li className="nav-text" onClick={props.showSidebar}>
            <Link to="/vacation">
              <span>Marcar Férias</span>
            </Link>
        </li>
        <li className="nav-text" onClick={props.showSidebar}>
          <Link to="/contacts">
            <span>Contato</span>
          </Link>
        </li>
        <li className='nav-text' onClick={props.showSidebar}>
          <Link to='/retailer/guide'>
            <span>Seja um Revendedor</span>
          </Link>
        </li>

        {user ? (
          <li className="nav-text" onClick={props.showSidebar}>
            <Link to="/" className="header-login" onClick={sign_out}>
              <FiLogIn />
              <span> Logout </span>
            </Link>
          </li>
        ) : (
          <li className="nav-text" onClick={props.showSidebar}>
            <Link className="header-login" to="/login">
              <FiLogOut />
              <span> Login </span>
            </Link>
          </li>
        )}
      </ul>
    </Container>
  )
}