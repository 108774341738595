import styled from 'styled-components'

export const Container = styled.section`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    max-width: 28.5rem;
    h1 {
        font-family: 'Poppins', sans-serif;

        span {
            font-size: 2rem;
        }
    }
    span {
        margin-bottom: 1rem;
    }

`;