import IndexTable from "../../../components/IndexTable"
import { Page } from "../../AdminDashboard/styles"
import { Container } from "./styles"
import { useState, useEffect } from 'react'
import { api } from '../../../services/api'
import Pagination from '../../../components/Pagination'
import { useUserContext } from "../../../components/contexts/useUserContext"

const CategoriesIndex = () => {
    const { user } = useUserContext();
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [adminPermissions, setAdminPermissions] = useState([]);
    const paginationLimit = 10;

    useEffect(() => {
        api.get('/categories/total_records')
        .then(response => setTotal(response.data))
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
        setOffset(paginationLimit * (page - 1))
    }, [page])

    useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          let permissions = []
          response.data.forEach((permission) => {
            if (user.permissions.indexOf(permission.id) !== -1)
              permissions.push(permission.name)
          })
          setAdminPermissions(permissions)
        })
    }, [user])

    return(
        <Page>
            <Container>
                <IndexTable
                    request='categories'
                    caption='Categorias'
                    pagination
                    offset={offset}
                    adminPermissions={adminPermissions}
                />
                <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={total}
                    pageSize={paginationLimit}
                    onPageChange={currentPage => setPage(currentPage)}
                />
            </Container>
        </Page>
    )
}

export default CategoriesIndex