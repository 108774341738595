import styled from "styled-components"

export const Container = styled.section`
  display: flex;
  margin: 0rem 2.68rem 1rem;
  button { // Arrow do Carousel
    color: black;
  }

  .rec-arrow-right {
    /* position: absolute; */
    top: 13%;
    right: 8%;
    background: white;
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
    font-size: 1.25rem;
    line-height: 2rem;
    
    &:hover, &:focus {
      background: black !important;
      color: white;
    }
  }

  .rec-arrow-left {
    /* position: absolute; */
    top: 13%;
    right: 15%;
    background: white;
    min-width: unset;
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
    font-size: 1.25rem;
    line-height: 2rem;

    &:hover, &:focus {
      background: black !important;
      color: white;
    }

  }

  @media (max-width: 520px) {
    margin: 0 2vw 1rem;

    .rec-arrow-left, .rec-arrow-right{
      min-width: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`