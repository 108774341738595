import styled from "styled-components";

export const Container = styled.div`
    font-family: "Poppins", sans-serif;

    .qrdiv {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    color: #3A3B3C;
        
        h1{
            font-size: 2.6rem;          
            
        }
    }
    
    
    Button {
        justify-content: space-evenly;
        display:flex;
        align-items: center;
        margin-top: 1.5rem;
        margin-bottom:1rem;
        height: 3.4rem;
        width: 14rem;
        font-size: 1.4rem;
        background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
        border-radius:10px;
        color: white;

    }

    .qrimg {
        display: flex;
        justify-content: center;
        background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
        border-radius: 10px;
        width:55%;
        margin:auto;
        margin-top:2rem;
        h2 {
            position: absolute;
            margin-top: 6rem;
            margin-right: 5%;
            transform: rotate(90deg);
            opacity:20%;
        }
      
        a {
            font-size:1rem;
            margin-top: 15%;
            margin-left: 6rem;
            color: #F8F8FF;

            text-decoration: solid;
            :hover{
                text-decoration: underline;
            }
        }
    }
    @media (max-width:750px) {
        .qrimg{
            display:flex;
            flex-direction:column ;
            justify-content: space-evenly;
            align-items: center;
            background: linear-gradient(120.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
            border-radius: 12px;
            padding-bottom: 30px;

        
            h2 {
                transform:rotate(180deg);
                margin-top: 50%;
                margin-left:2%;
            }
            a{
                display:flex;
                margin-top:25%;
                margin-right: 40%;
                max-width: 180%;
                font-size: 80%;
            }
        }
        .qrdiv{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align:center;
           h1{
               font-size: fit-content;
           }
        }
    }
    @media (max-width:1000px) {
        .qrimg{
            width: 80%;
        }
        
    @media (max-width:350px) {
        .qrimg{
            display:flex;
            flex-direction:column ;
            justify-content: space-evenly;
            align-items: center;
            background: linear-gradient(120.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
            border-radius: 12px;
            padding-bottom: 30px;
            padding-right: 5%;
            padding-left: 5%;
            

        
            h2 {
                margin-top:59%;
            }
            a{
                display:flex;
                margin-top:25%;
                margin-right: 55%;
                max-width: 180%;
                font-size: 80%;
            }
        }
        .qrdiv{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align:center;
           h1{
               font-size: fit-content;
           }
        }
    }
}
`;
