
import { Container, Page } from "./styles"
import RecordCard from "../../components/RecordCard"
import { useState, useEffect } from "react"
import { api } from "../../services/api"
import { useUserContext } from "../../components/contexts/useUserContext"
import RangedCalendar from "../../components/RangedCalendar"
import {alert} from '../../components/Alert'

const Retailersdashboard = () => {

    const [resales, setResales] = useState(0);
    const [clientRegistrations, setClientResgistrations] = useState(0);
    const [retailerRegistrations, setRetailerResgistrations] = useState(0);
    const { user } = useUserContext();
    const [dateRange, setDateRange] = useState(null)
    const [commissionsToReceive, setCommissionsToReceive] = useState(0)
    const [commissionsReceived, setCommissionsReceived] = useState(0)

    useEffect(() => {
        if (user)
            api.get(`/retailers/records/${user.id}`, {
                params: {
                    time_range: dateRange && dateRange[0] && dateRange[1] ? [dateRange[0].getTime(), dateRange[1].getTime()] : null
                }
            })
            .then(response => {
                setResales(response.data.resales_count);
                setClientResgistrations(response.data.clients_count);
                setRetailerResgistrations(response.data.retailers_count);
                setCommissionsToReceive(response.data.commissions_to_receive)
                setCommissionsReceived(response.data.commissions_received)
            }
        )
        if(user.is_active === false){
            alert("Sua conta se encontra inativa. Algumas funcionalidades do sistema estão restritas.", 'warn')
        }
    }, [user, dateRange])

    const getCommissionValue = (commissionInCents) => {
        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return formatter.format(commissionInCents/100);
    }
    
    return (
        <Page>
            <Container>
                <h1>Dashboard do Revendedor</h1>
                <h5>Filtrar por Período</h5>
                <div className="filter">
                    <RangedCalendar onChange={setDateRange} value={dateRange}/>
                </div>
                <div className="cards">
                    <RecordCard title="Comissão a Receber" value={getCommissionValue(commissionsToReceive)}/>
                    <RecordCard title="Comissão Recebida" value={getCommissionValue(commissionsReceived)}/>
                    <RecordCard title="Revendas no período" value={resales}/>
                    <RecordCard title="Cadastros no período" value={clientRegistrations}/>
                    <RecordCard title="Revendedores convidados no Período" value={retailerRegistrations}/>
                </div>
            </Container>
        </Page>
    )
}

export default Retailersdashboard
