import { useState } from 'react'
import { Container } from "./styles"
import Button from "../../../../components/Button"
import { api } from "../../../../services/api"
import { useHistory, useParams } from "react-router-dom"
import Form from '../../../../components/Forms'
import {alert} from '../../../../components/Alert'
import { Page } from '../../../AdminDashboard/styles.js'
import Input from '../../../../components/Input'

const CreateRetailerCommission = () => {
  const history = useHistory()
  const [receipt, setReceipt] = useState({});
  const [paymentDate, setPaymentDate] = useState('');

  const {retailer_id} = useParams()

  const addRetailerCommission = (e) => {
    e.preventDefault();
    api.post(`commission_payments/create/`, {
          retailer_id,
          payment_date: new Date(paymentDate+"T12:00:00")
    }).then((response) => {
      if (addReceipt(response)) {
        alert("O Pagamento foi atestado com sucesso", 'success')
        history.push("/manager/commission_payments")
      }
      else {
        alert("Não foi possível adicionar o arquivo. Tente novamente", 'error')
      }
    }).catch((error => {
      alert(error.response.data.message, 'error')
    }))
  }

  const addReceipt = async (response) => {
    try {
        const formData = new FormData()
        formData.append('payment_receipt', receipt)

        const post_response = await api.post(`commission_payments/add_receipt/${response.data.id}`, formData)
        if(post_response.data){
          setReceipt(post_response.data)
        }
    } catch(error){
        return false
    }
  }

    return (
      <Page>
        <Container>
            <h1>Atestar Novo Pagamento de Comissão</h1>
            <Form
                onSubmit={addRetailerCommission}
                title='Pagamento de Comissão'
                text='Ateste um novo pagamento de comissão.'
                button={
                    <>
                        <Button format='submit' type='submit'>Atestar Pagamento de Comissão</Button>
                        <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
                    </>
                }
            >
              <h3>Selecione a Data do Pagamento</h3>                
              <Input onChange={(e) => setPaymentDate(e.target.value)} type={'date'}></Input>
              <h3>Adicione o Comprovante de Pagamento</h3>
              <input onChange={(e) => setReceipt(e.target.files[0])} type="file"/>
              
            </Form>
        </Container>
      </Page>
    )
}

export default CreateRetailerCommission
