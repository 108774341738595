import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container } from './styles';
import { api } from '../../../services/api'
import Form from '../../../components/Forms';
import StyledSelect from '../../../components/Select';
import TextArea from '../../../components/TextArea'
import {alert} from '../../../components/Alert'
import { Page } from '../../AdminDashboard/styles.js'

const RoomTypesCreate = () =>{

    const[name, setName] = useState('')
    const[capacity, setCapacity] = useState(0)
    const[description, setDescription] = useState('')
    const[hotelId, setHotelId] = useState(0)
    const[hotels, setHotels] = useState([])

    const history = useHistory()

    const loadHotels = async () => {
        const response = await api.get('/hotels/index')
        setHotels(response.data.map(hotel => {return(
            {label: hotel.name, value: hotel.id}
        )}))
    }
    
    useEffect(() => {
        loadHotels()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(name === ''){
            alert('Nome deve estar preenchido.', 'warn')
        }else if(description === ''){
            alert('Descrição deve estar preenchida.', 'warn')
        }else if(capacity === ''){
            alert('Capacidade deve estar preenchida.', 'warn')
        }

        let room_type = {
            name,
            capacity,
            description,
            hotel_id: hotelId.value,
        }

        try{
            const response = await api.post('/room_types/create', {
                room_type
            })
            if(response.data){
                alert('Tipo de quarto criado.', 'success')
                history.push('/rooms')
            }
        }catch(error){
            alert('Erro tente novamente.','error')
        }

    }

    return(
        <Page>
            <Container>
                <h1>Criar Tipo de Quarto</h1>
                <Form
                    onSubmit={handleSubmit}
                    title='Tipo de Quarto'
                    text='Preencha os campos para adicionar um tipo de quarto.'
                    button={
                        <>
                            <Button format='submit' type='submit'>Adicionar tipo de quarto</Button>
                            <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
                        </>
                    }
                >
                    <Input
                        label='Nome'
                        onChange={(value) => setName(value.target.value)}
                    />
                    <Input
                        label='Capacidade'
                        type='number'
                        onChange={(value) => setCapacity(+value.target.value)}
                    />
                    <TextArea
                        label='Descrição'
                        onChange={(value) => setDescription(value.target.value)}
                    />
                    <StyledSelect
                        label='Hotel'
                        placeholder='Selecione...'
                        options={hotels}
                        value={hotelId}
                        onChange={category => setHotelId(category)}
                    />
                </Form>
            </Container>
        </Page>
    )

}

export default RoomTypesCreate