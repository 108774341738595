import styled from 'styled-components'

export const Container = styled.section`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 28.5rem;
    h1 {
        font-family: 'Poppins', sans-serif;
    }
    //ok
`;