import Button from "../../../components/Button";
import Input from "../../../components/Input/InputSignUp";
import Select from 'react-select'
import MaskInput from "../../../components/Input/MaskInput";
import {alert} from "../../../components/Alert";
import { MdEmail, MdLock, MdPhone } from "react-icons/md";
import { Container } from "./styles";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect,useRef } from 'react'
import { api } from "../../../services/api";
import { useRetailerContext } from "../../../components/contexts/useRetailerContext";
import ModalPF from "./Modal"
import ModalPJ from "./ModalPJ"
import ReCAPTCHA from "react-google-recaptcha";

const RetailerCreate = () => {

  // Account by CPF
  const [email, setEmail] = useState("");
  const [emailFailure, setEmailFailure] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordFailure, setPasswordFailure] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordConfirmationFailure, setPasswordConfirmationFailure] = useState(false);
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [cpfFailure, setCpfFailure] = useState(false);
  const [cep, setCep] = useState("");
  const [cepFailure, setCepFailure] = useState(false);
  const [phone, setPhone] = useState("");
  const [rg, setRg] = useState("");
  const [person_type, setPerson_type] = useState('individual')
  const [ddd, setDdd] = useState('')
  const [street, setStreet] = useState('')
  const [street_number, setStreet_number] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [stateFailure, setStateFailure] = useState(false)
  const [states, setStates] = useState([]);
  const [banks, setBanks] = useState([]);
  const [optionStates, setOptionStates] = useState([]);
  const [options_bank, setOptionBanks] = useState([]);
  const [neighborhood, setNeighborhood] = useState('');
  const [birthdate, setBirthdate] = useState("");
  const [birthdateFailure, setBirthdateFailure] = useState(false);

  // // Account by CNPJ
  const [cnpj, setCnpj] = useState("");
  const [cnpjFailure, setCnpjFailure] = useState(false);
  const [fantasy_name, setFantasy_name] = useState("");
  const [social_name, setSocial_name] = useState("");
  const [icms_contribution, setIcms_contribution] = useState("");
  const [icms_contribution_failure, setIcms_contribution_failure] = useState(false);
  const [national_simple, setNational_simple] = useState(false);
  const [state_registration, setState_registration] = useState("");
  const [municipal_registration, setMunicipal_registration] = useState("");
  
  // Info about bank
  const [bank, setBank] = useState("");
  const [bankFailure, setBankFailure] = useState(false);
  const [branch_number, setBranch_number] = useState("");
  const [branch_check_digit, setBranch_check_digit] = useState("");
  const [account_number, setAccount_number] = useState("");
  const [account_check_digit, setAccount_check_digit] = useState("");
  const [account_type, setAccount_type] = useState("");
  const [account_type_failure, setAccount_type_failure] = useState(false);

  const [submitFailure, setSubmitFailure] = useState(false);

  const [openModalPF, setOpenModalPF] = useState(false);
  const [openModalPJ, setOpenModalPJ] = useState(false);

  const options_person_type = [
    { value: 'individual', label: 'Pessoa Física'},
    { value: 'company', label: 'Pessoa Jurídica'}
  ]

  const options_icms_contribution = [
    { value: 'Contribuinte ICMS', label: 'Contribuinte ICMS'},
    { value: 'Contribuinte Isento de ICMS', label: 'Contribuinte Isento de ICMS'},
    { value: 'Não Contibuinte', label: 'Não Contibuinte'}
  ]

  const options_account_type = [
    { value: 'checking', label: 'Conta Corrente'},
    { value: 'savings', label: 'Poupança'}
  ]

  const { retailer, setRetailer } = useRetailerContext();

  const { id } = useParams();
  setRetailer(!!id ? id : retailer);

  function changeICMStype(value) {
    setIcms_contribution(value);
    setNational_simple(false);
  }

  useEffect(() => {
    api.get('states').then((response) => {
        setStates(response.data);
    })
  },[])

  useEffect(() => {
    setOptionStates(states.map((state) => {
      return { value: state.abbreviation, label: state.name }
    }))
  }, [states, state])

  useEffect(() => {
    fetch("https://guibranco.github.io/BancosBrasileiros/data/bancos.json")
    .then((response) => response.json()).then((data) => {
        setBanks(data);
    })
  },[])

  useEffect(() => {
    setOptionBanks(banks.map((bank) => {
      return { value: bank.COMPE, label: bank.LongName }
    }))
  }, [banks, bank])

  const history = useHistory();


  const siteKey = "6Lene0sfAAAAAG6iDiPbCGQMy3m4qiYKbypE5R4X"
    const recaptchaRef = useRef(null)
    const [captcha, setCaptcha] = useState(false);

    const onChange = () => {
        if (recaptchaRef.current.getValue()) {
        setCaptcha(true)
        }
    }

  const appearModal = (event) => {
    event?.preventDefault()

    if ((state === "" || bank === "" || account_type === "") ||
    (person_type === "company" && icms_contribution === "")) {
      setStateFailure(!state);
      setBankFailure(!bank);
      setAccount_type_failure(!account_type);
      setIcms_contribution_failure(!icms_contribution)
      setSubmitFailure(true);
      return;
    }

    setSubmitFailure(false);

    if (captcha) {
      if(person_type === "company"){
        setOpenModalPJ(true);
      } else {
        setOpenModalPF(true);
      }
    } else {
    alert("Todas as informações devem ser preenchidas", 'warn')
    }
  } 

  const convertToDate = (s) => {
    const parts = s.split('/');
    const date = new Date(parts[2], parts[1]-1, parts[0]);

    return date
  }

  const convertToDateString = (s) => {
      const parts = s.split('/');
      const date = `${parts[2]}-${parts[1]}-${parts[0]}`

      return date
  }

  const handleSubmit = async () => {

    try {
      const response = await api.post("retailers/create", {
        retailer: {
          email,
          password,
          password_confirmation: passwordConfirmation,
          name,
          cpf,
          cep,
          phone,
          rg,
          person_type,
          cnpj,
          fantasy_name,
          social_name,
          icms_contribution,
          national_simple, //boolean
          state_registration,
          municipal_registration,
          bank,
          branch_number,
          branch_check_digit,
          account_number,
          account_check_digit,
          account_type,
          ddd,
          street,
          street_number,
          city,
          state,
          neighborhood,
          birthdate: convertToDateString(birthdate),
          retailer_id: retailer
        }
      });
      if (response.data) {
        alert("Cadastro realizado com sucesso", 'success');
        history.push("/login");
      }    
    } catch (error) {
      alert('Erro, tente novamente.','error');
    }
  }

  function onBlurCep(event) {
    const { value } = event.target;
    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8 && cep?.length > 0) {
      setCepFailure(true);
      return;
    }
    if (cep?.length > 0){
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json()) // Retorna o json da api viacep
        .then((data) => {
          if (data.erro)
            setCepFailure(true);
          else{
            setStreet(data.logradouro)
            setCity(data.localidade)
            setState(data.uf)
            setDdd(data.ddd)
            setNeighborhood(data.bairro)
            setCepFailure(false);
          }
        });
    } else
      setCepFailure(false)
  }

  const getAge = (birthdate) => {
    const birth = convertToDate(birthdate);
    const today = new Date();

    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth - birth.getMonth();
    if(m < 0 || (m === 0 && today.getDate() < birth.getDate()))
      age--;
    
    return age;
}

  const validateBirth = (birthdate) => {
    if (birthdate.length > 0 && birthdate !== '__/__/____') {
        const reg = birthdate.match(/(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))/)
        const age = getAge(birthdate)
        if(reg === null){
            setBirthdateFailure(true);
            return false
        } else if(age < 18 || age > 125) {
            setBirthdateFailure(true);
            return false
        }
        else {
            setBirthdateFailure(false);
            return true
        }
    } else 
        setBirthdateFailure(false);
    return true
  }

  const validateEmail = (email) => {
    if (email.length > 0) {
      let val = email.includes(".")
      if(val){
        setEmailFailure(false);
        return true
      } else {
        setEmailFailure(true);
        return false
      }
    } else 
      setEmailFailure(false);
  }

  const validatePassword = (password) => {
    if (password.length > 0) {
      if(password.length < 6){
        setPasswordFailure(true);
        return false
      } else {
        setPasswordFailure(false);
        return true
      }
    } else 
      setPasswordFailure(false);
  }

  const validatePasswordConfirmation = (passwordConfirmation, password) => {
    if (passwordConfirmation.length > 0) {
      if(passwordConfirmation === password){
        setPasswordConfirmationFailure(false)
        return true
      } else {
        setPasswordConfirmationFailure(true)
        return false
      }
    } else
      setPasswordConfirmationFailure(false);
  }

  const validateCpf = (cpf) => {
    cpf = cpf.replaceAll('.', '').replace('-','').replaceAll('_', '')
    if (cpf.length > 0){
      var Soma;
      var Resto;
      Soma = 0;
      if (cpf === "00000000000") {setCpfFailure(true); return false}
  
      for (let i=1; i<=9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
  
      if ((Resto === 10) || (Resto === 11))  Resto = 0;
      if (Resto !== parseInt(cpf.substring(9, 10)) ) {setCpfFailure(true); return false}
  
      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;
  
      if ((Resto === 10) || (Resto === 11))  Resto = 0;
      if (Resto !== parseInt(cpf.substring(10, 11) ) ) {setCpfFailure(true); return false}
      setCpfFailure(false)
      return true;
    } else
      setCpfFailure(false);
  }

  const validateCnpj = (cnpj) => {
 
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj === ''){
      setCnpjFailure(false);
      return false;
    } 
     
    if (cnpj.length !== 14){
      setCnpjFailure(true);
      return false;
    }
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" || 
        cnpj === "11111111111111" || 
        cnpj === "22222222222222" || 
        cnpj === "33333333333333" || 
        cnpj === "44444444444444" || 
        cnpj === "55555555555555" || 
        cnpj === "66666666666666" || 
        cnpj === "77777777777777" || 
        cnpj === "88888888888888" || 
        cnpj === "99999999999999"){
          setCnpjFailure(true);
          return false;
        }
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(0)){
      setCnpjFailure(true);
      return false;
    }
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(1)){
      setCnpjFailure(true);
      return false;
    }

    setCnpjFailure(false);
    return true;   
  }


  return (
    <Container>
      <h1>Quero me tornar um revendedor Vai de Férias</h1>
      <form onSubmit={appearModal}>
        <div className="form-1">
          <div className="sub-form-left">
            <div className='title-1'>
              <h2>Preencha seus dados abaixo</h2>
            </div>
          </div>
          <div className="sub-form"></div>
        </div>
        <div className="form-1">
          <div className="select_input">
            <div className='select'>
              <Select 
                options={options_person_type} placeholder="Tipo de Pessoa"
                defaultValue= {options_person_type[0]}
                onChange={(value) => setPerson_type(value?.value)}
              /> 
            </div>
            
          </div>
          <div className="sub-form">
          { person_type === 'individual' || person_type === null ? 
          <>
            <Input
              placeholder="Nome Completo"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              failMessage="O campo Nome Completo não pode estar vazio"
              format='full_name2'
            />
          </> 
          : 
          <>
            <MaskInput
              mask="99.999.999/9999-99"
              placeholder="CNPJ"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
              required
              onBlur={() => validateCnpj(cnpj)}
              failed={cnpjFailure}
              failMessage="CNPJ inválido"
              format="cnpj"
            />
          </> }
          </div>
        </div>
        <div className="form-1">
          <div className="sub-form-left">
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => {setEmail(e.target.value)}}
              onBlur={() => {validateEmail(email)}}
              failed={emailFailure}
              failMessage="Email não é válido"
              type="email"
              format='email2'
              Icon={MdEmail}
              required
            />
          </div>
          <div className="sub-form">
            <MaskInput
              mask='(99)'
              placeholder='DDD'
              value={ddd}
              onChange={(e) => setDdd(e.target.value)}
              name='ddd'
              format='ddd2'
              type="tel"
              required
            />
            <MaskInput
              mask="99999-9999"
              placeholder="Telefone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              Icon={MdPhone}
              format="phone2"
              type="tel"
              required
            />
          </div>
        </div>
        <div className="form-1">
          <div className="sub-form-left">
            <Input
              placeholder="Senha"
              value={password}
              onChange={(e) => {setPassword(e.target.value); validatePassword(e.target.value)}}
              failed={passwordFailure}
              failMessage="Senha deve ter ao menos 6 caracteres"
              type="password"
              format="password"
              Icon={MdLock}
              required
            />
          </div>
          <div className="sub-form">
            <Input
              placeholder='Cidade'
              value={city}
              onChange={(e) => setCity(e.target.value)}
              name='localidade'
              format='city2'
              required
            />
            <div className="select_input_right_2">
              <div className='select'>
                <Select
                  options={optionStates} isSearchable placeholder="Estado"
                  onChange={(value) => {setState(value?.value); setStateFailure(!value)}}
                  styles={stateFailure && {control: base => ({...base, borderColor: "red", boxShadow: "none"})}}
                  value={state && optionStates.filter((option) => {return option.value === state})[0]}
                  onBlur={() => setStateFailure(!state)}
                />
                {stateFailure &&
                  <span style={{color: "red "}}>Selecione o Estado</span>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="form-1">
          <div className="sub-form-left">
            <Input
              placeholder="Confirmar Senha"
              value={passwordConfirmation}
              onChange={(e) => {setPasswordConfirmation(e.target.value); validatePasswordConfirmation(e.target.value, password)}}
              failed={passwordConfirmationFailure}
              failMessage="Confirme sua senha"
              format="password_confirmation2"
              type="password"
              Icon={MdLock}
              required
            />
          </div>
          <div className="sub-form">
            <MaskInput
              mask="99999-999"
              placeholder="CEP"
              onBlur={onBlurCep}
              value={cep}
              onChange={(e) => setCep(e.target.value)}
              type="tel"
              format='cep-sm2'
              required
              failed={cepFailure}
              failMessage="CEP inválido"
            />
            <Input
              placeholder='Bairro'
              name='bairro'
              value={neighborhood}
              onChange={(e) => setNeighborhood(e.target.value)}
              format='neighborhood2'
              required
            />
          </div>
        </div>
        <div className='form-1'>
          <div className="sub-form-left">
            { person_type === 'individual' ? 
            <>
              <MaskInput
                        mask="99/99/9999"
                        value={birthdate}
                        onChange={(e) => {setBirthdate(e.target.value)}}
                        failed={birthdateFailure}
                        onBlur={() => {validateBirth(birthdate)}}
                        failMessage="Data inválida"
                        required
                        format='esquerda_502'
                        placeholder="Data de Nascimento"
                    />
              <Input
                placeholder='RG'
                name='rg'
                value={rg}
                onChange={(e) => setRg(e.target.value)}
                format='direita_502'
                required
              />
            </>
            : 
            <>
              <div className="select_input_2">
                <div className='select'>
                  <Select 
                    options={options_icms_contribution} placeholder="Contribuição ICMS"
                    onChange={(value) => {changeICMStype(value?.value); setIcms_contribution_failure(!value)}}
                    styles={icms_contribution_failure && {control: base => ({...base, borderColor: "red", boxShadow: "none"})}}
                    onBlur={() => setIcms_contribution_failure(!icms_contribution)}
                  />
                  {icms_contribution_failure &&
                  <span style={{color: "red "}}>Selecione a contribuição ICMS</span>
                }
                </div>
              </div>
              { icms_contribution === 'Contribuinte ICMS' ?
                <div className='checkbox'>
                  <input id='nacional' value={national_simple} type="checkbox" onChange={(e) => setNational_simple(e.target.checked)}/>
                  <label for='nacional'>Simples Nacional</label>
                </div>
              : 
                <div className='checkbox'>
                  <input checked={false} type="checkbox" disabled={true}/>
                  <label>Simples Nacional</label>
                </div>
              }

            </> }
          </div>
          <div className='sub-form'>
                <Input
                placeholder='Rua'
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                name='logradouro'
                format='street2'
                required
                />
                <Input
                placeholder='Número'
                value={street_number}
                onChange={(e) => setStreet_number(e.target.value)}
                name='numero'
                format='number2'
                required
                />
            </div>
        <div className='form-1'>
            <div className="sub-form-left">
            { person_type === 'individual' ? 
            <>
                <MaskInput
                mask="999.999.999-99"
                placeholder='CPF'
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                name='cpf'
                format='cpf2'
                required
                onBlur={() => {validateCpf(cpf)}}
                failed={cpfFailure}
                failMessage="CPF inválido"
              />
            </>
            :
            <></>
            }
            </div>
        </div>
        </div>
        { person_type === 'company' ? 
          <> 
            <div className='form-1'>
              <div className="sub-form-left">
                <Input
                  placeholder='Razão Social'
                  value={social_name}
                  onChange={(e) => setSocial_name(e.target.value)}
                  format='esquerda_502'
                />
                <Input
                  placeholder='Nome Fantasia'
                  value={fantasy_name}
                  onChange={(e) => setFantasy_name(e.target.value)}
                  format='direita_502'
                />
              </div>
              <div className='sub-form'>
                <Input
                  placeholder='Inscrição Estadual'
                  value={state_registration}
                  onChange={(e) => setState_registration(e.target.value)}
                  format='esquerda_502'
                  required
                />
                { state !== 'DF' ?
                  <Input
                    placeholder='Inscrição Municipal'
                    value={municipal_registration}
                    onChange={(e) => setMunicipal_registration(e.target.value)}
                    format='direita_502'
                  />
                : 
                  <Input
                    placeholder='Inscrição Municipal'
                    value=''
                    disabled
                    format='direita_50'
                    cursor='not-allowed'
                  />
                }
              </div>
            </div>
          </>
        : 
        null }
        <div className="form-1">
          <div className="sub-form-left">
            <div className='title-2'>
              <h2>Preencha seus dados bancários</h2>
            </div>
          </div>
          <div className="sub-form"></div>
        </div>
        <div className="form-1">
          <div className="select_input">
            <div className='select'>
              <Select 
                options={options_bank} placeholder="Banco"
                onChange={(value) => {setBank(value?.value); setBankFailure(!value)}}
                styles={bankFailure && {control: base => ({...base, borderColor: "red", boxShadow: "none"})}}
                onBlur={() => setBankFailure(!bank)}
              />
              {bankFailure &&
                <span style={{color: "red "}}>Selecione o Banco</span>
              }
            </div>
          </div>
          <div className="select_input_right">
            <div className='select'>
              <Select 
                options={options_account_type} placeholder="Tipo da conta"
                onChange={(value) => {setAccount_type(value?.value); setAccount_type_failure(!value)}}
                styles={account_type_failure && {control: base => ({...base, borderColor: "red", boxShadow: "none"})}}
                onBlur={() => setAccount_type_failure(!account_type)}
              />
              {account_type_failure &&
                <span style={{color: "red "}}>Selecione o tipo de conta</span>
              }
            </div>
          </div>
        </div>
        <div className="form-1">
          <div className="sub-form-left">
            <Input
              placeholder="Agência"
              value={branch_number}
              onChange={(e) => setBranch_number(e.target.value)}
              format="agency"
              required
            />
            <Input
              placeholder="Dígito"
              value={branch_check_digit}
              onChange={(e) => setBranch_check_digit(e.target.value)}
              format="agency_digit"
              required
            />
          </div>
          <div className="sub-form">
            <Input
              placeholder="Número da Conta"
              value={account_number}
              onChange={(e) => setAccount_number(e.target.value)}
              format="account_number"
              required
            />
            <Input
              placeholder="Dígito"
              value={account_check_digit}
              onChange={(e) => setAccount_check_digit(e.target.value)}
              format="account_digit"
              required
            />
          </div>
        </div>
        <div className="div-recaptcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={siteKey}
                onChange={onChange}
              />
            </div>
        <div className="div_button">
          <div className="button">
            <Button type="submit" format="submit">Criar Conta</Button>
            {submitFailure &&
              <span style={{color: "red "}}>Preencha todos os campos para continuar</span>
            }
          </div>
        </div>
      </form>
      {openModalPF && <ModalPF closeModal={setOpenModalPF} handleSubmit={handleSubmit} />}
      {openModalPJ && <ModalPJ closeModal={setOpenModalPJ} handleSubmit={handleSubmit} />}
    </Container>
  );
};

export default RetailerCreate;