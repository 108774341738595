import styled from 'styled-components';

export const Container = styled.section`
    background: #fff;
    height: 100%;
    width: 100%;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;


export const DivContactUs = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;

    a{
        text-decoration: none;
    }

    @media (max-width: 768px) {
        padding: 0;
        width: 100%;
        
        .options-div {
            margin: 0 0.5rem 1rem 0.5rem; !important;

            .container-options-div {
                width: 40%;
                height: 50px;
                justify-content: center;
            }
        }
    }
    
    @media (max-width: 565px) {
        
        .options-div {
            flex-direction: column !important;
            height: fit-content;
            justify-content: center;
            align-items: center;
        }
        
    }

    @media (max-width: 360px) {
        .options-div .container-options-div {
            width: 288px !important;
            height: 50px;
            justify-content: center;
            svg {
                display: none;
            }
        }
        
    }

    @media (max-width: 320px) {
        .options-div .container-options-div {
            width: 256px !important;
        }
    }

    .options-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        margin: 3rem 0px 10rem 0;
        
        .container-options-div {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 20px 15px;
            border: 1px solid transparent;
            width: 379px;
            justify-content: center;
            
            svg {
                font-size: 2em;
                color:  #EBDD94;
            }
            
            span, a {
                font-family: Poppins;
                font-style: normal;
                font-weight: 100;
                font-size: 20px;
                line-height: 36px;
                color: #3A3B3C;
                text-decoration: none;
            }

        }

    }

    #whatsapp-div {
        background-color: #128c7e;
        border-radius: 35px;
        width: fit-content;
        height: fit-content;
        align-self: flex-end;
        margin-right: 10%;
        position: fixed;
        bottom: 3rem;
        right: 0;
        z-index: 1000;

        padding: 14px 16px;
        a {
            margin: 0px;
            height: 36px;
        }
    }

    #whatsapp-div:hover {
        background-color: #6b7267;
    }

    
    .container-options-div:hover {
        border: 1px solid #EBDD94;
        border-radius: 10px;
        cursor: pointer;
    }
`;

export const DivForms = styled.div`
    display: flex;
    justify-content: center;
    width: 50%;
    align-items: center;
    margin: auto;

    @media (max-width: 1400px) {
        width: 80%;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 4rem;
        
        .wrapper-content-div {
            height: 90% !important;
            padding: 10px;
            padding: 20px;
            padding-bottom: 35px !important;
        }
    }

    @media (max-width: 420px) {
        margin-bottom: 10px;
        form {
            width: 80% !important;
        }
    }
    
    form {
        display: flex;
        background: linear-gradient(103.6deg, #FCC046 -13.96%, rgba(248, 132, 26, 0.75) 134.95%);
        border: 1px solid #ffffff !important;
        border-radius: 10px;
        flex-direction: column;
        //gap: 3rem;
        padding: 0.8rem;
        width: 60%;
        height: 75%;
        
        .div-text-area {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;

            span {
                color: #575F6E;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                margin-left: 10px;
            }
            
            .text-area-contact {
                resize: none;
                margin-top: 0;
                min-height: 6rem;
                padding: 0.5rem 0 0 0.5rem;
            }
        }


        .button-contact {
            margin: 0 auto;
            background: #BF1827;
            color: #fff;
        }

        input {
            background: inherit;
        }
    }

`;

export const DivTitle = styled.div`
    font-family: Poppins;
    margin-left: 4.5rem;

    @media (max-width: 768px) {
        padding: 0;
        width: 100%;
        margin: 0 0.5rem;
        h1 {
            text-align: center;
        }
        
    }

    @media (max-width: 500px) {
        width: 100%;
        
        h1 {
            font-size: 1.8em !important;
        }
        
    }

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 3em;
        line-height: 36px;
        color: #3A3B3C;
    }
`;