import styled from "styled-components";

export const Container = styled.div`
    // background: linear-gradient(35.62deg, #BF1827 4.01%, #CC2A55 93.58%);
    background-color: #fff;
    padding-bottom: 8rem;
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
    color: #3A3B3C;

    .title{
        border-radius: 0.625rem;
        padding: 1rem 1rem 1rem;
        max-width: 50rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        color: white;
    }
    @media screen and (max-width: 1150px) {
        .title{
            padding-left: 0rem;
            margin: auto;
        }
    }
    @media screen and (max-width: 1150px) {
        padding: 1rem;
    }
`

export const ProfileCard = styled.div`
  border: 1px solid #e2e4e5;
  border-radius: 0.625rem;
  padding: 1rem 3rem 1rem;
  max-width: 45rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #FCC046;

  .client-info {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      border-radius: 50%;
      width: 12.5rem;
      height: 12.5rem;
      object-fit: cover;
    }

    p {
      font-size: 12px;
      color: #aaaaaa;
    }
  }

  .client-email {
    p {
      font-size: 12px;
      color: #aaaaaa;
    }
  }

  .account{
        display: flex;
        flex-wrap: wrap;
        .client-account {
            width: 50%;
            margin: 0.1rem 0;
            p{
                font-size: 12px;
                color: #AAAAAA;
            }
        }
    }
    .sub-title {
        padding-top: 2rem;
        font-size: 1.3rem;
        font-weight: 600;
        color: #3a3b3c;
        text-align: start;
        width: 100%;
        margin: 0;
        padding: 2rem 0rem 0rem 0rem;
    }

  .custom-button {
  }

  @media screen and (max-width: 500px) {
        .account{ .client-account { width: 100%; } }
    }

  @media screen and (max-width: 1150px) {
  }
`;

export const BookingCard = styled.div`
  padding-left: 1rem;
  border: 1px solid #e2e4e5;
  border-radius: 0.625rem;
  padding: 1rem 3rem 1rem;
  max-width: 45rem;
  margin: 5rem auto 5rem;
  display: flex;
  flex-direction: column;
  position: relative;

  background-color: #FCC046;

  .client-history {
    margin: 2rem 0;

    p {
      font-size: 12px;
      color: #aaaaaa;
    }
  }
`;

export const LogoutCard = styled.div`
  padding-left: 1rem;
  border: 1px solid #e2e4e5;
  border-radius: 0.625rem;
  padding: 1rem 3rem 1rem;
  max-width: 45rem;
  margin: 5rem auto 5rem;
  display: flex;
  flex-direction: column;
  position: relative;

  background-color: #FCC046;

  .logout-btn {
    margin: 2rem 0;

    p {
      font-size: 12px;
      color: #aaaaaa;
    }
  }
`;
