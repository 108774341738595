import styled from 'styled-components'

export const Container = styled.section`
    font-family: 'Poppins', sans-serif;

    p {
        margin: 0;
        font-weight: 600;
    }

    .valor {
        margin: 0 0 1rem 0;
    }

   .backbutton{
        margin-left: 2rem;
        margin-top: 2rem;
        max-width: 9rem;
        max-height: 3rem;
        a{
                
                height:2.5rem;
                padding-left: 1rem;
                width:7.5rem;               
                font-size:1.15rem;
                text-decoration: none;
                color:#242731;
            }
        :hover{
           font-weight: bolder;
        }
    }
    .boxall {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h1 {
        color:#3A3B3C;
        font-size:2.813rem;
    
    }

    .boleto{
        display:flex;
        flex-direction: column;
        width: 52%;
        border: 1px;
        border-style: hidden hidden solid hidden;
        padding-bottom: 2rem;
        color: #3A3B3C;
        

        h5{
            text-align: justify;
            font-size:1.75rem;
            font-weight: 600;
            
        }
        h2{
            text-align: justify;
            margin-top: -2rem;
          
            font-weight: 400;    
            font-size:1.125rem;
            width: 100%;
        }
        Button{
            border-radius: 5px;
            font-size:1.125rem;
            color:#FFF;
            width: 21rem;
            height:2.8rem;
            margin: 0rem !important;
            background-color:#007AFF;
            display:flex;
            justify-content: center;
            align-items: center;
            text-decoration: none !important;

        }

        
    }

    
    .cartaoall{
        margin-bottom: 10%;
        display: flex;
        justify-content: space-between;
        color: #3A3B3C;
        
        .Cartao{
            display:flex;
            flex-direction: column;
            width: 100%;
            
            h3{
        
                font-size:1.75rem;
                font-weight: 600;
            }
            h4{
                margin-top: -1rem;
                font-weight: 400;
            
                font-size:1.125rem;
               
            }
            Button{
                border-radius: 5px;
                font-size:1.125rem;
                color:#FFF;
                width:21rem;
                height: 3rem;
                margin: 0rem !important;
                background-color:#007AFF;
                display:flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;

            }
            
        }

        .cardpics{
            margin-top: 3rem;
            
        
            svg {
                height: 4.2rem;
                width: 40%;
            
            }
        }
    }
    


  

    @media(max-width:500px) {
            .backbutton {
                margin-left:0rem;
            }
            h1 {
                font-size: 160%;
                text-align: center;
                
            }
            .boxall{
                align-items: center;
            }
            
            .cartaoall{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                
                .Cartao {
                display: flex;
                flex-direction: column;
                width: 80%;
                margin-left: 10%;
               
                    Button{
                        width: 85%;
                        font-size: 90%;
                        text-align: center;
                        display:flex;
                    }
                    h4{
                        width: 90%;
                    }
                    h3{
                        width: 90%;
                        font-size: 1.6rem;
                    }
            }
            }
            .boleto {
                display: flex;
                flex-direction: column;
                width: 75%;
                margin-left: 5%;                
                Button{
                    width: 95%;
                    font-size: 90%;
                    
                }
                h2{
                    width: 90%;
                }
                h5{
                    width:90%;
                    font-size: 1.6rem;
                }
            }
       
            .cardpics {
                display: flex;
                width: 98%;
            
            svg{
                width: 40%;
                    
            }
            }
        
        }
        @media (min-width:500px) and (max-width:700px) {
            .backbutton {
                margin-left:0rem;
            }
            h1 {
                font-size: 160%;
                text-align: center;
                
            }
            .boxall{
                align-items: center;
            }
            
            .cartaoall{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                
                .Cartao {
                display: flex;
                flex-direction: column;
                width: 92%;
               
                    Button{
                        width: 80%;
                        font-size: 90%;
                        text-align: left;
                        display:flex;
                    }
                    h4{
                        width: 90%;
                    }
            }
            }
            .boleto {
                display: flex;
                flex-direction: column;
                width: 21.5rem;
                margin-right: 7.2%;
                max-width: 75%;
                
                Button{
                    width: 95%;
                    font-size: 90%;
                    
                }
                h2{
                    width: 90%;
                }
            }
       
            .cardpics {
                display: flex;
                width: 98%;
            
            svg{
                width: 40%;
                    
            }
            }
        
        }
        @media (min-width:700px) and (max-width:1000px) {
            .backbutton {
                margin-left:0rem;
            }
            h1{
                margin-top: 3.5rem;
                text-align: center;
                font-size: 215%;
                width: 100%;
            }
            .boleto{
                align-items: left;
                width: 80%;
            }
            .cartaoall{
                display:flex;
                flex-direction: row;
                width: 80%;
                .Cartao{
                    align-items: left;
                    justify-items: left;

                }
                    .cardpics{
                        
                        width: 55%;
                        flex-direction: row;
                        
                }
            }
        }


        @media (min-width:1000px) and (max-width:1200px) {
            .backbutton{
                a{
                    font-size:1.5rem;
                }
            }
            .boleto {
                width: 41.2rem;
                margin-right: 6rem;
            }
            .boxall{
                margin-top: 5rem;
            }
    }

    @media (min-width:1200px) and (max-width:1800px) {
            .backbutton{
                a{
                    font-size:1.5rem;
                }
            }
            .boleto {
                width: 41rem;
                margin-right: 6rem;
            }
            .boxall{
                margin-top: 5rem;
            }
            
    }

    @media (min-width:1800px) {
        .cartaoall{
            width: 52%;
        }
    }
`
