import styled from "styled-components";

export const Container = styled.section`
    font-family: 'Poppins', sans-serif;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 28.5rem;

    p {
        margin: 0.625rem;
        text-align: center;
    }

    .confirmation {
        margin-top: 0.625rem;
    }

    .radio, .checkbox {
        opacity: 0;
        position: absolute;
    }

    .radio, .label, .checkbox {
        display: inline-block;
        vertical-align: middle;
        margin: 5px;
        cursor: pointer;
    }
    
    .label {
        position: relative;
    }

    .radio + .label:before {
        content: '';
        background: #fff;
        border: 2px solid #ddd;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 10px;
        text-align: center;
        border-radius: 50%;
    }

    .checkbox + .label:before {
        content: '';
        background: #fff;
        border: 2px solid #ddd;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 10px;
        text-align: center;
        border-radius: 20%;
    }

    .radio:checked + .label:before {
        content: '\f00c';
        font-family: 'FontAwesome';
        color: #fff;
        background-color: black;
        border-color: black;
    }

    .checkbox:checked + .label:before {
        content: '\f00c';
        font-family: 'FontAwesome';
        color: #fff;
        background-color: black;
        border-color: black;
    }

`