import IndexTable from '../../components/IndexTable'
import { Page } from '../AdminDashboard/styles'
import { Container } from './styles'
import { useState, useEffect } from 'react'
import { api } from '../../services/api'
import Pagination from '../../components/Pagination'

const Bookings = () => {

    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const paginationLimit = 10;

    useEffect(() => {
        api.get('/bookings/total_records')
        .then(response => setTotal(response.data))
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
        setOffset(paginationLimit * (page - 1))
    }, [page])

    return (
        <Page>
            <Container>
                <IndexTable
                    request='bookings'
                    caption='Reservas'
                    pagination
                    offset={offset}
                />
                <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={total}
                    pageSize={paginationLimit}
                    onPageChange={currentPage => setPage(currentPage)}
                />
            </Container>
        </Page>
    )
}

export default Bookings
