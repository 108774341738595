//import Input from "../../components/Input/InputSignUp";
import { useState } from "react";
import { Link } from "react-router-dom"

import { MdEmail, MdLockOpen} from "react-icons/md";
import BG from '../../../assets/admin.jpg'
import { Container } from "./style";

import Button from "../../../components/Button";
import {alert} from "../../../components/Alert";
import { useUserContext } from "../../../components/contexts/useUserContext";
import Input from '../../../components/Tmp'


const LoginPage = () => {

    const {login_admin} = useUserContext();
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [selected, setSelected] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if(email === ''){
            alert('Insira o email', 'warn')
        }else if(password === ''){
            alert('Insira a senha', 'warn')
        }

        await login_admin({email, password})
        
    }
    return (
        <Container>
            <div className='image-container'>
                <img src={BG} alt="imagem-login"/>
            </div>
            <div className='forms-container'>
                <form onSubmit={handleSubmit}>
                    <h3>Administração Vai de Férias</h3>
                        <Input
                            placeholder ='Email' 
                            onChange ={(value) => setEmail(value.target.value)}
                            type='email'
                            Icon = {MdEmail}
                            required
                        />
                        <Input
                            type = 'password'
                            Icon={MdLockOpen}
                            placeholder='Senha'
                            onChange ={(value) => setPassword(value.target.value)}
                            onClick={() => setSelected(!selected)}
                            required
                        />
                    <Link to="/admin/recovery" className="forgot-link">
                        <span>Esqueceu sua senha?</span>
                    </Link>
                    <Button format="submit">Entrar Na Minha Conta</Button>
                </form>
            </div>
        </Container>
    );
}


export default LoginPage;