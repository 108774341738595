import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom'
import { api } from '../../../services/api'
import { useUserContext } from '../../../components/contexts/useUserContext'
import {alert} from '../../../components/Alert'

import { Container } from './styled';
import { Container as ContainerFilter, LoadStyle } from '../Destination2/styled';
import { CarouselItem } from '../../../components/Carousel/Items'
import CarouselImage from '../../../components/CarouselImage';
import ArrowBack from '../../../components/ArrowBack'

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import StyledSelect from '../../../components/Select';
import Pagination from '../../../components/Pagination'

const Destination3 = () => {

    const history = useHistory();
    const { categoryId, destinationId } = useParams()
    const { user, refreshClient, cancelSolicitation } = useUserContext()
    const placeholder = "https://patriciaelias.com.br/wp-content/uploads/2021/01/placeholder.png"
    const [ destinations, setDestinations ] = useState([])

    useEffect(() => {
        api.get(`/destinations/show/${destinationId}`)
        .then((response) => {
            setDestinations(response.data)
        })
    }, [destinationId])

    useEffect(() => {
        if(user && user.type === 'client' && user.is_active === false){
            history.push('/')
        }
    }, [user, history]);
    
    const [ categoryName, setCategoryName ] = useState("")
    const [ hotels, setHotels ] = useState([])
    const [ filteredHotels, setFilteredHotels ] = useState([])
    const [ tags, setTags ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ choosenTag, setChoosenTag ] = useState(-1)
    const [ choosenName, setChoosenName ] = useState("")
    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 9;
    const [disableButton, setDisableButton] = useState(false);
    const initialStateBody = "Descubra Hotéis incríveis em"
    
    const loadHotels = async (pageSize, currentPage, categoryId) => {
        if(user.user_type === "client"){
            await api.get(`/clients/index_hotels_pagination/${user?.id}/${pageSize * (currentPage - 1)}`)
            .then((response) => {
                    const temp_hotels = []
                    response.data.forEach((hotel) =>{
                        if (hotel.category.id === parseInt(categoryId)){
                            temp_hotels.push(hotel)
                        }
                    })
                    setHotels(temp_hotels)
                    setFilteredHotels(temp_hotels)
                
                setLoading(false)
            })

            .catch(() => {
                return <LoadStyle><h1>Algo de errado aconteceu. Tente novamente!</h1></LoadStyle>
            })
        }
        else {
            await api.get(`/hotels/index_destination/${destinationId}/${pageSize * (currentPage - 1)}`)
            .then((response) => {
                    const temp_hotels = []
                    response.data.forEach((hotel) =>{
                        if (hotel.category.id === parseInt(categoryId)){
                            temp_hotels.push(hotel)
                        }
                    })
                    setHotels(temp_hotels)
                    setFilteredHotels(temp_hotels)
                
                setLoading(false)
            })

            .catch(() => {
                return <LoadStyle><h1>Algo de errado aconteceu. Tente novamente!</h1></LoadStyle>
            })
        }
    }

    const loadTags = async () => {

        await api.get('/tags/index')
        
        .then((response) => {
            const options = []
            response.data.forEach((tag)=>{
                options.push({value: tag.id, label: tag.name})
            })
            setTags(options)
        })
    }

    const loadCategory = async (id) => {
        await api.get(`/categories/show/${id}`)
        .then((response) => setCategoryName(response.data.name))
    }

    useEffect(() => {
        loadCategory(categoryId)
        loadTags()
        refreshClient()
    }, [categoryId, refreshClient])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage])

    if (!user) {
        return <LoadStyle>
            <p className='main-text'></p>
            <p className='sub-text'>Aguarde enquanto os hotéis são carregados</p>
        </LoadStyle>
    }

    const handleTagChange = e => {
        try{
            setChoosenTag(e.value)
        } catch {
            setChoosenTag(-1)
        }
    }

    const displayCategory = (categoryName) => {
        if (categoryName === "Praia")
            return "de praia";
        else if (categoryName === "Urbano")
            return "urbanos";
        else if (categoryName === "Rural")
            return "rurais";
        else
            return "";
    }

    const handleNameChange = e => {
        try{
            setChoosenName(e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase())
        } catch {
            setChoosenName("")
        }
    }

    const isSimilar = (userEntry, dbEntry) => {
        let dbEntryNormalized = dbEntry.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
        if (dbEntryNormalized === userEntry || dbEntryNormalized.startsWith(userEntry)){
            return true
        } else{
            let dbArray = dbEntryNormalized.split(" ")
            for (var i = 0; i< dbArray.length; i++){
                if (dbArray[i] === userEntry || dbArray[i].startsWith(userEntry)){
                    return true
                }
            }
            return false
        }
    }
    
    const filter = async () => {
        setFilteredHotels([])
        if (choosenTag !== -1 && choosenName !== ""){

            hotels.forEach((hotel) => {
                if(hotel.tags.map(tag => tag.id).includes(choosenTag) && isSimilar(choosenName, hotel.name)){
                    setFilteredHotels(oldArray => [...oldArray, hotel])
                }
            })

        } else if (choosenTag === -1 && choosenName !== ""){

            hotels.forEach((hotel) => {
                if(isSimilar(choosenName, hotel.name)){
                    setFilteredHotels(oldArray => [...oldArray, hotel])
                }
            })

        } else if (choosenTag !== -1 && choosenName === ""){
            
            hotels.forEach((hotel) => {
                if(hotel.tags.map(tag => tag.id).includes(choosenTag)){
                    setFilteredHotels(oldArray => [...oldArray, hotel])
                }
            })

        }  else {
            setFilteredHotels(hotels)
        }
        setCurrentPage(1)
    }

    const solicitation = async () => {
        setDisableButton(true);
        api.get(`clients/set_solicitation/${user.id}/${destinationId}`).then((response) => {
            setTimeout(refreshClient(), 1000)
            history.push('/')
            alert('Sua solicitação foi enviada e já está sendo analisada.', 'success')
        }).catch((error) => {
            alert(error.response.data.message? error.response.data.message : 'Algum erro ocorreu. Por favor, tente novamente.', error.response.data.type? error.response.data.type : 'error')
            history.push('/')
        })
    }
    
    return (
        <>
            <ArrowBack to={`/vacation/destination/${categoryId}`} />
            <Container>
                <h2>O que posso encontrar em <br />{destinations.name}</h2>
                <div className="images">
                    <CarouselImage request="destinations" id={destinationId} placeholder={placeholder}/>
                </div>
                
                <div className='center'>
                    <p>{destinations.description}</p>
                </div>
            </Container>
            <ContainerFilter>
                { ((user.user_type === "client") && (user.destinations_id !== parseInt(destinationId)))?
                    <>
                    { (user.solicitacao_destino === null)?
                        <div className="btn-destination3">
                            <h4> {initialStateBody} {destinations.name} </h4>
                            <Button format="submit" cursor={disableButton && "wait"} onClick={ () => {!disableButton && solicitation()} } >Solicitar Hotéis Disponíveis</Button>
                        </div>
                        :
                        <>
                        {(user.solicitacao_destino === `${destinationId}`)?
                            <div className="btn-destination3">
                                <h4> {`Recebemos sua solicitação para ${destinations.name} e já estamos a processando!`} </h4>
                                <Button format="submit" cursor={disableButton && "wait"} 
                                onClick={ () => {if (!disableButton) {setDisableButton(true); cancelSolicitation()}}} >
                                    Cancelar Solicitação
                                </Button>
                            </div>
                            :
                            <div className="btn-destination3">
                                <h4> {initialStateBody} {destinations.name} </h4>
                                <p><b>Atenção:</b> Sua solicitação anterior será cancelada ao solicitar uma nova!</p>
                                <Button format="submit" cursor={disableButton && "wait"} onClick={ () => {!disableButton && solicitation()}} >Fazer Nova Solicitação</Button>
                            </div>
                        }
                        </>
                    }
                    </>
                :
                <>
                    {(loading === true)?
                        <div className="btn-destination3">
                            <h4>Encontramos os melhores hotéis de acordo com sua solicitação!</h4>
                            <Button format="submit" onClick={ () => {loadHotels(pageSize, currentPage, categoryId)} } >Ver Hotéis</Button>
                        </div>
                        :
                        <>
                        <h2>Busque Hotéis {displayCategory(categoryName)} em {destinations.name}
                        </h2>
                        <p>Escolha um dos nossos <b>hotéis parceiros</b> e faça umas solicitação de reserva.</p>
                        <div className="div-wrapper-search-destination2">
                            <div className="div-search-filter">
                                <div className="div-search-destination">
                                    <label>Pesquisar por Nome</label>
                                    <Input placeholder="Buscar hotel" type='text' onChange={handleNameChange}/>
                                </div>
                                <div className="div-filter-destination">
                                    <label>Comodidades</label>
                                    <StyledSelect id='select-state' className="style-select-destination" placeholder="Comodidades" options={tags} onChange={handleTagChange}/>
                                </div>
                            </div>
                            <div className="btn-destination2">
                                <Button format="submit" onClick={filter}>Filtrar Hotéis</Button>
                            </div>
                        </div>
                        <div className='grid'>
                            {filteredHotels.length > 0? 
                            filteredHotels.slice((currentPage - 1)*pageSize, Math.min(filteredHotels.length, currentPage*pageSize)).map((hotel) => (
                                <Link to={`/vacation/hotel/${hotel.id}` }>
                                    <CarouselItem
                                        key={hotel.id}
                                        city= {hotel.name}
                                        state = {hotel.category.name}
                                        image={hotel.images_url ? `${"https://vaideferias.com.br" + hotel.images_url}` : '' }
                                    />
                                </Link>
                            ))
                            :
                        
                                        <div className='not-found'>
                                            <p className='main-text'>Nenhum hotel encontrado!</p>
                                            <p className='sub-text'>Tente novamente com outros parâmetros de busca</p>
                                        </div>
                            }
                            
                        </div>
                        < div className = 'pagination-box'>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={filteredHotels.length}
                            pageSize={pageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                        </div>
                        </>
                    }
                </>
            }
        
            </ContainerFilter>
        </>
    );
}

export default Destination3;