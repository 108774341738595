import styled from "styled-components";

export const Container = styled.section`
    font-family: "Poppins", sans-serif;
    width: 75vw;
    margin: 0 auto;

    h1, h4 {font-weight: 600}

    h1 {
        font-size: 2.5rem;
        margin-top: 0;
    }

    h4 {
        font-size: 1.5rem;
        margin: 0 0 1.5rem;
        cursor: pointer;
    }

    > div {margin: 0 2rem}

    .show-info {
        margin: 8rem auto;
        max-width: 40rem;

        .tabs {
            justify-content: space-around;
            gap:2rem;
        }
    }

    .tabs {
        display: flex;
        gap: 10rem;
        text-align: center;

        h4:after {
            display: block;
            content: '';
            border-bottom: 3px solid rgba(247, 158, 27,0.5);
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
        }

        h4:hover:after {
            transform: scaleX(1);
        }

        h4.active:after {
            transform: scaleX(1);
            border-bottom: 3px solid #F79E1B;
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        padding: 0 2rem;

        h6 {
            font-size: 1.1rem;
            font-weight: 600;
            margin: 0;
        }
    }

    span, h6 {
        flex: 1;
    }



    ul {
        list-style: none;
        margin: 0;
        height: 45vh;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        padding: 0 2rem;
        overflow-y: auto;

        li {
            display: flex;
            justify-content: space-between;
            font-size: 1.1rem;
            min-height: calc(25% - 1px);
            align-items: center;
            border-bottom: 1px solid rgba(158, 158, 167, 0.3);
            flex-wrap: wrap;
            text-align: center;

            .responsive-header{ display: none }

            a {text-decoration: none}

            .Aprovado, .ativo {color: #04BC00}
            .Cancelado, .inativo {color: #BE1622}
            .Pendente, .pending {color: #FCC046}

            .break-lines {
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow-y: auto;
                width: 25%;

                p { margin: 0 }
            }

            Button {
                color: #fff;
                width: 80%;
                font-size: 1.1rem;
                padding: .5rem;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 4px;
                justify-content: center;
            }

            .sm-col {width: 10%}
            .md-col {width: 20%}
            .lg-col {width: 30%}
        }
    }

    .package-info{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 3rem 2rem;
        height: auto;
    
        li{
            justify-content: start;
            text-align: start;
            min-height: auto;
            border-bottom: none;

            b, p {flex: 1}

            b {font-weight: 600}

            p {margin: 0}
        }
    }

    @media screen and (max-width: 1440px){
        width: 96vw;

        .tabs {gap: 5rem}
    }

    @media screen and (max-width: 992px){

        width: 92vw;
        h1 {font-size: 1.5rem}
        h4 {font-size: 1.3rem}
    > div {margin: 0}

        .tabs {
            gap: 0;
            justify-content: space-around;
        }

        ul {
            padding: 0 2vw;
            height: 65vh;
            margin-bottom: 2rem;
            li{
                font-size: .8rem;

                Button {font-size: .8rem}
            }
        }
    }

    @media screen and (max-width: 768px){

        h1{text-align: center}

        .row {display: none}
        
        span, h6 {
            flex: unset;
        }

        ul li {
            margin-top: .5rem;
            justify-content: space-around;
            flex-direction: column;
            text-align: start;
            padding: 1rem;

            p { 
                margin: 0;
                flex: 1;
            }
  
            .responsive-header {
                display: block;
                margin: 0;
                font-weight: 600;
                font-size: .8rem;
                flex: 1;
            }

            .lg-col, .md-col, .sm-col{width: 100%}

            span {
                margin-bottom: .5rem;
                display: flex;
                gap: .8rem;
                align-items: center;
            }

            a, Button { width: 100%}
        }
    }
`

export const Page = styled.div`
    min-height: calc(100vh - 4.5rem); // Navbar size
    display: flex;
    flex-direction: column;
    color: #3A3B3C;
`