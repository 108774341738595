import styled , {css} from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vh 0;
    width: 100%;
    overflow-x: scroll;
    * {
        font-family: 'Roboto', sans-serif;
    }
    h1 { 
        font-family: 'Poppins', sans-serif;
        font-size: 3rem;
    }

    h4 {
        margin: 0 5% 1rem;
        align-self: flex-start;
    }

    table{
        width: 90%;
        border-collapse: collapse;
        text-align: center;
    }
    thead {
        font-weight: bold;
        color: #53535F;
        border-bottom: 1px solid #CBCBCB;
        font-size: 1.125rem;
        th {
        padding-bottom: 1.5rem;
        }
    }
    tr{   
        border-bottom: 1px solid #CBCBCB;
        td {
        padding: 2.5rem 1rem 1rem;
        height: 5rem;
        Button {
            color: #3A3B3C;
            min-width: 8rem;
            font-weight: normal;
            margin: 0 1.25rem
        }
        .info {
            background-color: #EBDD94;
            margin-bottom: 1rem;
        }
        }
    }
    a {
        text-decoration: none;
    }
@media all and (max-width: 1080px) {
    h1 {
    font-size: 1.8rem;
    }

    .filter > div {min-width: 100%;}

    table {
    width: 95%;
    tr th {
        display: none;
    }
    td {
        float: left;
        min-width: 90%;
        width: 90%;
        height: unset;
        padding: 1rem 0;
        font-size: .8rem;
        Button {
        min-width: 6rem;
        font-size: .9rem;
        }
    }
    }
    .add-button {
        width: 90vw;
        font-size: 1rem;
        }
}
.pagination-box{
        display: flex;
        justify-content: center;
    }
`;

export const Filter = styled.div`
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
    align-items: center;
    font-size: 1.5rem;
    flex-wrap: wrap;

    svg:hover {
        color: #bf1827;
        cursor: pointer;
    }

    @media screen and (max-width: 1080px) {
        justify-content: center;
        button { width: 100%}
    }
`

export const FilterOption = styled.button`
    min-width: 12rem;
    border: 1px solid #04BC00;
    padding: .25rem .5rem;
    border-radius: 1rem;
    background: none;
    cursor: pointer;

    :hover {
        background-color: rgba(4, 188, 0, 0.7);
    }

    ${props => (props.selected) &&
        css `
            background-color: #04BC00;
            font-weight: 600;
        `
    }
`