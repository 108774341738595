import Input from '../../components/Input/index';
import Button from '../../components/Button/index'

import { Container, DivContactUs, DivForms, DivTitle } from './styled';
import { MdEmail, MdForum } from 'react-icons/md'
import TextArea from '../../components/TextArea';
import {alert} from '../../components/Alert';

import axios from 'axios';
import { useState } from 'react'
import WpIcon from '../../assets/whatsapp-icon.png'
import ArrowBack from "../../components/ArrowBack";
import { Link } from 'react-router-dom';

const Contacts = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [mensagem, setMensagem] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://vaideferias.com.br/api/contact', {
                email,
	            name, 
                message: mensagem,
                phone: telefone
            })
            if(response.data) {
                alert("Mensagem enviada!", 'success')
            }
            
        } catch (error) {
            alert('Erro, tente novamente.','error');
        }
    };

    return (
        <Container>
            <ArrowBack />
            <DivTitle>
                <h1>Entrar em contato conosco</h1>
            </DivTitle>
            <DivForms>
                <form onSubmit={handleSubmit}>
                    <Input 
                        placeholder="Nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        lessMargin={true}
                    />
                    <Input 
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        lessMargin={true}
                    />
                    <Input
                        placeholder="Telefone"
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                        lessMargin={true}
                    />
                    <div className="div-text-area">
                        <span>Mensagem</span>
                        <TextArea
                            className="text-area-contact"
                            placeholder="Digite aqui sua mensagem..."
                            value={mensagem}
                            onChange={(e) => setMensagem(e.target.value)}
                            largerArea={true}
                        />
                    </div>
                    <Button 
                        className="button-contact" 
                        type="submit"
                        format="submit"
                    >Confirmar</Button>
                </form>
            </DivForms>
            <DivContactUs>
                <div id="whatsapp-div">
                    <a href="https://wa.me/message/HW2QAB55QCZIN1" target="_blank" rel="noreferrer" ><img src={WpIcon} alt="Logo do WhatsApp"/></a>
                </div>
                <div className="options-div">
                    <Link to='/FAQ'>
                    <div className="container-options-div">
                        <MdForum />
                        <span>Dúvidas frequentes</span>
                    </div>
                    </Link>
                    <div className="container-options-div">
                        <MdEmail />
                        <span>contato@vaideferias.com.br</span>
                    </div>
                </div>
            </DivContactUs>
        </Container>
        
    );
}

export default Contacts;