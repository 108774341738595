import { Container, Item, Background } from './styled';
import Praia from '../../../assets/Destinations/praia.svg'
import Urbano from '../../../assets/Destinations/urbano.svg'
import Rural from '../../../assets/Destinations/rural.svg'
import { Link } from 'react-router-dom';
import React from 'react';

import ArrowBack from '../../../components/ArrowBack';

const Destination1 = () => {

    return (
        <Background>
            <ArrowBack to="/"/>
            <Container>
                <h2>Para onde gostaria de ir?</h2>
                <p>Escolha a <b>estada ideal</b> para suas próximas férias, parceladas em <b>24x.</b></p>
                <div className='grid'>
                    <Link to={{pathname: '/vacation/destination/1', category_id: 1}}><Item><div><img src={Praia} alt='Praia' /></div><h2>Praia</h2></Item></Link>
                    <Link to={{pathname: '/vacation/destination/2', category_id: 2}}><Item><div><img src={Urbano} alt='Cidade' /></div><h2>Cidade</h2></Item></Link>
                    <Link to={{pathname: '/vacation/destination/3', category_id: 3}}><Item><div><img src={Rural} alt='Campo' /></div><h2>Campo</h2></Item></Link>
                </div>
            </Container>
        </Background>
    );
}

export default Destination1;