import { Route, Switch } from 'react-router-dom'
import { useUserContext } from "../components/contexts/useUserContext";

import Retailersdashboard from '../pages/RetailersDashboard'
import RetailerReports from '../pages/RetailersDashboard/RetailerReports'
import Qrcodepage from '../pages/RetailersDashboard/QrPage'
import QrcodepageRev from '../pages/RetailersDashboard/QrPageRetailer'
import RetailerCommission from '../pages/RetailersDashboard/RetailerCommission'
import Sidebar from '../components/SidebarRetailer';
import RetailerGuide from '../pages/RetailersDashboard/RetailerGuide';

const RetailerRoutes = () => {
    const { user } = useUserContext()

    return (
        <>
        {user && user.type === 'retailer'  ?
        <Switch>
                <Route exact path='/profile'/>
                <Route exact path='/dashboard'>
                    <Sidebar />
                    <Retailersdashboard />
                </Route>
                <Route exact path='/QRCode/user'>
                    <Sidebar />
                    <Qrcodepage />
                </Route>
                <Route exact path='/QRCode/retailer'>
                    <Sidebar />
                    <QrcodepageRev />
                </Route>
                <Route exact path='/dashboard/reports'>
                    <Sidebar />
                    <RetailerReports />
                </Route>
                <Route exact path='/dashboard/commission'>
                    <Sidebar />
                    <RetailerCommission />
                </Route>
                <Route exact path='/dashboard/guia'>
                    <Sidebar />
                    <RetailerGuide />
                </Route>
        </Switch>
        :
        <Switch></Switch>}
        </>
    )
}

export default RetailerRoutes
