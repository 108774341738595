import { Container, FormContainer } from "./styles"

const Form = ({title, text, children, button, ...props}) => {

    return (
        <Container
            {...props}
        >
            <FormContainer>
                <h1>
                    {title}
                </h1>
                <span>
                    {text}
                </span>
                {children}
            </FormContainer>
            {button}
        </Container>
    )
}

export default Form