import { useHistory } from "react-router-dom"

import {
  ModalBackground,
  ModalContainer,
  Title,
  WrapSides,
  LeftSide,
  ModalButton,
  CloseBtn,
  DivColor,
  InColumn
} from "./styles";


const ModalInativo = ({closeModal}) => {

  const history = useHistory()

  return (
    <DivColor>
    <ModalBackground>
      <ModalContainer>
        <CloseBtn onClick={() => closeModal(false)}>X</CloseBtn>
        <Title>
          Você não possui um Pacote Vai de Férias ativo
        </Title>

        <InColumn>
          <h2>Minhas diárias</h2>
          <h2>0 diárias</h2>
        </InColumn>

        <WrapSides>
          <LeftSide>
            <h3>Compre seu Pacote Vai de Férias e garanta:</h3>
            <ul>
            <li>7 diárias para os melhores destinos no Brasil</li>
            <li>Parcelamento em até 24 vezes</li>
            <li>Até 2 reservas únicas</li>
            </ul>
          </LeftSide>
        </WrapSides>
        <ModalButton onClick={() => {history.push("/payment"); }}>
              <h1>Adquirir Pacote Vai de Férias</h1>
        </ModalButton>
      </ModalContainer>
    </ModalBackground>
    </DivColor>
  );
};

export default ModalInativo;

