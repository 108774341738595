import styled from 'styled-components'

export const Container = styled.section`
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 4.25rem;
        font-weight: 600;
        margin: 0 0 2rem 0;
        text-align: center;
    }

    .info {
        border: 1px #3A3B3C solid;
        max-width: 50vw;
        min-width: 30vw;
        height: 100%;
        width: 100%;
        padding: 5rem;
        margin-bottom: 1rem;
        border-radius: 1rem;
    }

    .fluxo {
        display: flex;
    }

    h5 {
        font-size: 1.5rem;
        margin: 0;
        font-weight: 600;
        span {
            background: -webkit-linear-gradient(270deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    p {
        font-size: 1.1rem;
        span {
            font-weight: bold;
        }
    }

    a:hover {
        background: -webkit-linear-gradient(270deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: 0.2s;
        cursor: pointer;
    }

    @media(max-width: 500px) {
        h1 {
            font-size: 1.6rem;
            width: 90vw;
            margin-top: 15px;
        }

        h5 {
            font-size: 1.2rem;
        }

        .info {
            padding: 2rem;
            height: 100%;
        }

        .fluxo {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        Button {
            width: 90%;
            margin-bottom: 30px;
        }
    }

    @media(max-width: 1000px) and (min-width: 500px) {
        h1 {
            font-size: 3.0rem;
            text-align: center;
        }

        h5 {
            font-size: 1.2rem;
        }

        .fluxo {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .info {
            padding: 3rem;
        }
    }

`