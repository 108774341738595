import { useState, useRef } from 'react'
import { useHistory } from "react-router-dom"
import { api } from "../../services/api"
import Button from "../Button"
import { Container } from "./styles"
import {alert} from '../Alert'
import Modal from '../../pages/SignUpPage/Modal'
import ReCAPTCHA from "react-google-recaptcha";

const PaymentConfirmation = ({
    firstPage,
    secondPage,
    id,
    name,
    email,
    password,
    password_confirmation,
    cpf,
    rg,
    ddd,
    number,
    street,
    street_number,
    city,
    state,
    cep,
    neighborhood,
    birthdate,
    payment_method,
    boleto_payment_day, 
    holder_name,
    card_number,
    exp_month,
    exp_year,
    cvv
}) => {

    const history = useHistory()

    const siteKey = "6Lene0sfAAAAAG6iDiPbCGQMy3m4qiYKbypE5R4X"
    const recaptchaRef = useRef(null)
    const [captcha, setCaptcha] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const onChange = () => {
        if (recaptchaRef.current.getValue()) {
        setCaptcha(true)
        }
    }

    const convertToDateString = (s) => {
      const parts = s.split('/');
      const date = `${parts[2]}-${parts[1]}-${parts[0]}`

      return date
  }

    const appearModal = (event) => {
        event?.preventDefault()

        if (captcha) {
        setOpenModal(true);
        } else {
        alert("O Recaptcha falhou. Tente novamente", 'warn')
        }
    } 

    const handleSubmit = async () => {

        try {
          const response = await api.post("/clients/create", {
            client: {
              name,
              email,
              password,
              password_confirmation,
              cpf,
              rg,
              retailer_id: id,
              ddd,
              phone: number,
              street,
              street_number,
              city,
              state,
              cep,
              neighborhood,
              birthdate: convertToDateString(birthdate),
              payment_method,
              boleto_payment_day
            },
          });
          if (response.data) {
            history.push('/')
            alert("Seu cadastro foi concluido com sucesso e em breve receberá sua avaliação de compra.", 'success');
          }
        } catch (error) {
          alert('Não foi possível concluir o cadastro. Tente novamente.',"error");
        }
      };

    return (
        <Container>
            {payment_method === 'credit_card' ?
                <div className='fluxo'>
                    <h5><a href="#/" onClick={() => firstPage()}>1. Dados de Pagamento</a>&nbsp;</h5>
                    <h5> -&gt; <span>2. Confirme seus dados</span></h5>
                </div> :
                 <div className='fluxo'>
                 <h5><a  href="#/" onClick={() => firstPage()}>1. Dados Cadastrais&nbsp;</a></h5>
                 <h5> -&gt; <a href="#/" onClick={() => secondPage()}>2. Pagamento</a>&nbsp;</h5>
                 <h5> -&gt; <span>3. Confirme sua Solicitação</span></h5>
             </div>
            }
            <h1>Confira seus dados e solicite sua compra</h1>
            <div className='warning-message'>
              <p><span>Atenção:</span> Não será possível a alteração do Nome, Data de Nascimento, CPF, RG após o cadastro.</p>
            </div>
            <div className='info'>
                <p><span>Nome:</span> {name}</p>
                <p><span>CPF:</span> {cpf}</p>
                <p><span>RG:</span> {rg}</p>
                <p><span>Data de Nascimento:</span> {birthdate}</p>
                <p><span>Email:</span> {email}</p>
                <p><span>Valor:</span> 24 x R$99,00</p>
                {payment_method === 'credit_card' ?
                <>
                    <p><span>Método de Pagamento: </span>Cartão de Crédito</p>
                    <p><span>Número do cartão:</span> **** **** **** {card_number.slice(-4)}</p>
                    <p><span>Nome no cartão:</span> {holder_name}</p>
                    <p><span>Validade do cartão:</span> {exp_month}/{exp_year}</p>
                </> :
                <>
                    <p><span>Método de Pagamento: </span>Boleto</p>
                    <p><span>Vencimentos: </span>Dia {boleto_payment_day}</p>
                </>
                }
            </div>
            <div className="div-recaptcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={siteKey}
                onChange={onChange}
              />
            </div>
            <div className="confirm-button">
                <Button type='submit' format='submit' onClick={() => appearModal()}>Confirmar Pedido</Button>
            </div> 
                {openModal && <Modal closeModal={setOpenModal} handleSubmit={handleSubmit} />}
        </Container>
    )
}

export default PaymentConfirmation