import { useState, useEffect } from 'react'
import { Container, Filter, FilterOption } from '../Index/styles'
import Button from '../../../../components/Button'
import { api } from '../../../../services/api' 
import { useHistory } from 'react-router-dom'
import { useUserContext } from '../../../../components/contexts/useUserContext'
import Pagination from '../../../../components/Pagination';
import { Page } from "../../../AdminDashboard/styles"
import { alert } from "../../../../components/Alert"
import { RiFilterOffLine } from "react-icons/ri"

const ClientManager = () => {
    const { user } = useUserContext()
    const [clients, setClients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [showPermission, setShowPermission] = useState(false)
    const [showPermission2, setShowPermission2] = useState(false)
    const [filter, setFilter] = useState({})
    const [selectedFiler, setSelectedFilter] = useState(0)

    const Client_regain = (client) => {
      api.patch(`clients/regain/${client.id}`)
      alert('O acesso do usuário ao site foi autorizado!', 'success')
      client.is_active = true
      setClients(
        clients.map((item) => {
          return item.id === client.id? client: item;
        })
      )
    }

    const Client_revoke = (client) => {
      api.patch(`clients/restrict/${client.id}`)
      alert('O acesso do usuário ao site foi revogado!', 'success')
      client.is_active = false
      setClients(
        clients.map((item) => {
          return item.id === client.id? client: item;
        })
      )
    }

    const history = useHistory()

    useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          response.data.forEach((permission) => {
            if (permission.name === 'show_clients' &&  user.permissions.indexOf(permission.id) !== -1)
              setShowPermission(true)
          })
        })
      }, [user])

      useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          response.data.forEach((permission) => {
            if (permission.name === 'modify_access_clients' &&  user.permissions.indexOf(permission.id) !== -1)
              setShowPermission2(true)
          })
        })
      }, [user])

    useEffect(() => {
        window.scrollTo(0, 0)
        let offset = (currentPage - 1)*10
        api.get('clients/index/'+offset, { params: filter })
        .then((response) => {
            setClients(response.data)
        })
        api.get('clients/total_records/', { params: filter })
        .then((response) => {
          setTotalItems(response.data)
        })
    },[currentPage, user, filter])

    const selectFilter = (filter, index) => {
      setFilter(filter);
      setSelectedFilter(index);
    }

    return (
      <Page>
        <Container>
        <h1>Clientes</h1>
        <h4>Filtrar por:</h4>
          <Filter>
            <RiFilterOffLine onClick={() => selectFilter({}, 0)}/>
            <FilterOption selected={selectedFiler === 1} onClick={() => selectFilter({is_active: true}, 1)}>Ativos</FilterOption>
            <FilterOption selected={selectedFiler === 2} onClick={() => selectFilter({is_active: false}, 2)}>Inativos</FilterOption>
            <FilterOption selected={selectedFiler === 3} onClick={() => selectFilter({approved: false}, 3)}>Cadastros reprovados</FilterOption>
          </Filter>
        <table>
            <thead>
            <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Opções</th>
                <th>Gerenciar Acesso</th>
            </tr>
            </thead>
            <tbody>
            {clients.map((client, index) => { return(
                <tr key={client.id}>
                <td>{client.name}</td>
                <td>{client.email}</td>
                <td className="buttons" style={{minWidth: "35%"}}>
                { showPermission &&   
                    <Button className="info" format="show" onClick={() => history.push(`clients/show/${client.id}`)}>Ver</Button>
                }
                <Button className='info' format='show' onClick={() => history.push(`clients/packages/${client.id}`)}>Ver pacotes</Button>
                </td>
                
                {client.is_active === false ? 
                <td onClick={ () => {Client_regain(client)}}  className="buttons" style ={{minWidth: "35%"}}>
                 { showPermission2 &&   
                        <Button format="update">Permitir</Button>
                    
                }
                </td>
                :
                <td onClick={ () => {Client_revoke(client)}}  className="buttons" style ={{minWidth: "35%"}}>
                 { showPermission2 &&   
                        <Button format="delete">Revogar</Button>
                    
                }
                </td>
                }
                </tr>
            )})}
            </tbody>
        </table>
        < div className = 'pagination-box'>
            <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalItems}
            pageSize={10}
            onPageChange={page => setCurrentPage(page)}
            />
        </ div>
        </Container>
      </Page>
    )
}

export default ClientManager