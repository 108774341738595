import styled from "styled-components";

export const Container = styled.nav`
  position: relative;
  top: 0;
  margin: 0;
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(103.6deg, #cc302a -13.96%, #bf1827 134.95%);
  z-index: 2;

  a {
    cursor: pointer;
  }

  ${(props) => {
    if (props.home) {
      return "margin-bottom: 4.5rem;";
    }
  }}
  .home-navbar {
    margin-bottom: -4.5rem;
    background: transparent;
  }

  li span {
    text-decoration: none;
    color: #f2f2f2;
    font-size: 24px;
    font-weight: bolder;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }

  .middle {
    display: flex;
    column-gap: 3rem;
    justify-content: space-between;
    .header-login {
      font-size: 1.8rem;
    }
  }

  .middle a {
    text-decoration: none;
    font-style: normal;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 600;
    font-family: Poppins;

    @media (max-width: 1396px){
      font-size: 1rem;
    }
  }
  .current-item {
    border-style: none none solid none;
    border-color: #fcc046;
  }
  .other-itens {
    border-style: none none solid none;
    border-color: transparent;
    transition: border-width 0.1s linear;
  }
  .other-itens:hover {
    border-style: none none solid none;
    border-color: #fcc046;
  }

  .logo a {
    display: flex;
    align-items: center;
    height: 4rem;
    img {
      margin-left: 4vw;
      height: 3rem;
    }
  }

  .header-login {
    color: #f9f6f7;
    font-size: 1.8rem;
    margin-right: 2vw;
    cursor: pointer;
    font-weight: 900;
    height: 100%;
    display: table;
    border-style: none none solid none;
    border-color: transparent;

    span {
      height: 100%;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      display: table-cell;
      vertical-align: middle;
      color: #fff;

      @media (max-width: 1396px){
        font-size: 1rem;
      }
    }

    button {
      border: none;
      background-color: transparent;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      margin: 5px;

      border-radius: 5px;
      color: #fff;
    }

    button:hover {
      cursor: pointer;
    }

    .reg-buttom {
      width: 9.3125rem;
      height: 3.75rem;
      background-color: #fcc046;
    }

    .src-icon {
      width: 1.75rem;
      height: 1.75rem;
      vertical-align: middle;
    }
  }
  .menu-bars {
    margin-right: 2rem;
    margin-top: 1rem;
    font-size: 2rem;
    background: none;
    color: #f9f6f7;
    cursor: pointer;
  }

  .nav-menu {
    background-color: #e04013;
    width: 250px;
    height: 100vh;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
  }

  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
  
  span {
    margin-right: 16px;
  }

  .nav-menu-right {
    background-color: #1e2535;
    width: 250px;
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
  }

  .nav-menu-right.active {
    right: 0;
    transition: 350ms;
  }

  .hide {
    display: none;
  }

  @media (min-width: 1240px){
    .menu-bars {
      display: none;
    }
  }

  @media (max-width: 1240px) {
    .middle,
    .header-login {
      display: none;
    }
    .menu-bars {
      display: block;
    }
  }

  .nav-user {
    display: none;
    background-color: #0d0c22;
    width: 250px;
    overflow-y: auto;
    justify-content: center;
    position: absolute;
    top: 5rem;
    right: 1rem;
    transition: 850ms;
    border-radius: 5px;
  }

  .nav-user.active {
    display: flex;
  }

  .arrow-up {
    display: none;
    position: absolute;
    overflow-y: auto;
    top: 5rem;
    right: 4rem;
    margin-top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid #0d0c22;
  }

  .arrow-up.active {
    display: flex;
  }

  .user-text {
    display: flex;
    justify-content: start;
    align-items: left;
    list-style: none;
  }

  .user-text a {
    text-decoration: none;
    width: 100%;
    padding: 0 7px;
  }

  .user-text p {
    font-family: Poppins;
    font-style: normal;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .user-text:hover {
    transition-timing-function: ease;
    transition-duration: 0.6s;
    background-color: #E47D3A;
  }
  p:hover {
    /* color:#FCC046;
        transition-timing-function: ease;
        transition-duration: 0.6s; */
  }

  .user-menu-items {
    padding: 0;
    width: 100%;
  }

  .user-text.logout {
    margin-top: 1rem;
    height: 42px;
    p{
        width: 100%;
        height: 80%;
        padding: 0 8px;
        padding-top: 8px;
        margin: auto 0;
    }

    p:hover {
      cursor: pointer;
    }
  }
`;