import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    
    > div {
        width: 30rem;

        .react-daterange-picker__inputGroup {
            display: flex;
            justify-content: center;
            gap: 2px;
            /* flex-wrap: wrap; */

            .react-daterange-picker__inputGroup__input{
                padding: 0;
            }
        }

        @media screen and (max-width: 680px) {
            width: 90%;
        }

    }
    @media screen and (max-width: 480px) {

        > div { position:relative }

        .react-daterange-picker__wrapper{
            flex-direction: column;
            padding: .5rem;

            .react-daterange-picker__clear-button {
                position: absolute;
                top: 0;
                right: 0;
            }

            .react-daterange-picker__calendar-button {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
`