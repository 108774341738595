import styled from "styled-components";


export const DivColor = styled.div`
  max-width: 100vw !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  background-color: rgba(30,30,30,0.6);
  margin-bottom: 3rem;
  padding-top: 1rem;
  @media (max-width: 800px) {
    /* height: 100%; */
  }
` 

export const ModalBackground = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
  
  @media (max-width: 800px) {
    width: 100vw;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
`;

export const ModalContainer = styled.div`
  padding: 1.5rem;
  width: 80%;
  height: 22rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
  border: solid 5px;
  border-image-slice: 1;
  border-image-source: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%) !important;
  border-radius: 8px; /* Não funciona com o gradient */
  overflow: hidden;
  overflow-y: scroll;

  @media (max-width: 800px) {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    width: 90%;
    height: auto;
  }
`;

export const CloseBtn = styled.button`
  font-family: Poppins;
  border-radius: 50px;
  border-color: black;
  background: white;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: black;
  position: absolute;
  top: 0%;
  right: 7%;
  font-weight: 700;
  border: solid black;
  
  @media (max-width: 800px) {
    right: 0;
    top: 2%;
    @media (max-height: 500px) and (orientation: landscape) {
      top: 2%;
      right: 1%;
    }
  }  
`;

export const Title = styled.h1`
  font-family: Poppins;
  font-size: 2.2rem;
  font-weight: 600;
  height: 70px;
  text-align: center;
  
  @media (max-width: 800px) {
    font-size: 1rem;
    line-height: 20px;
    height: 30px;
    padding-bottom: 1rem;
  }
`;

export const Check = styled.div`
  padding-bottom: 1rem;
  padding-top: 1rem;
  label {
    margin: 0;
    padding-left: 1rem;
    span {
      font-family: Poppins;
      font-size: 1rem;
      font-weight: 600;
      padding-left: 0.75rem;
    }
    input[type="checkbox"] {
      height: 1.1rem;
      width: 1.1rem;
      filter: grayscale(100%);
      cursor: pointer;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
    padding-top: 0.3rem;
    label {
      padding-left: 0;
      span {
        font-size: 0.9rem;
      }
    }
    @media (max-height: 500px) and (orientation: landscape) {
      padding-top: 8rem;
    }
  }
`;

export const ModalButton = styled.button`
  cursor: pointer;
  height: 3.4rem;
  border: none;
  border-radius: 0.25rem;
  color: white;
  background-color: #007AFF;
  width: 10rem;
  margin-top: 3rem;
  font-size: 1.2em;
  font-weight: 600;
  
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0rem;
    h1 {
      font-size: 1rem;
    }
  }
`;

export const TextModal = styled.p`
    text-align: justify;
    font-size: 1.1em;
    padding-right: 1rem;
    padding-left: 1rem;
`;

export const SubText = styled.p`
  text-align: center;
  text-decoration: underline;
  font-size: 1.1em;
  font-weight: bold;
`;

export const CenterText = styled.p`
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
`;

export const SubTitle = styled.p`
  font-size: 1.1em;
  font-weight: bold;
`;