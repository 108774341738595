import { useState } from "react";
import Button from '../../../../components/Button';
import {alert} from '../../../../components/Alert';
import logo from '../../../../assets/vdf_logo-black.png'
import {
    ModalBackground,
    ModalContainer,
    Title,
    Check,
    CloseBtn,
    DivColor,
    TextModal,
    SubTitle,
    SubText,
    LogoImg,
    DivImage
  } from "./styled";

const ModalPF = ({ closeModal, handleSubmit }) => {

    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        setChecked(!checked)  
    }

    const verifyCheckboxIsChecked = () => {
        if (checked) {
            handleSubmit()
        } else {
            alert("Para continuar, aceite os contrato de prestação de serviços", 'warn')
        }
    }
    
    return (
        <DivColor>
            <ModalBackground>
                <ModalContainer>
                    <CloseBtn onClick={() => closeModal(false)}>X</CloseBtn>
                    <DivImage className="landing-logo">
                        <LogoImg className="homepage-logo" src={logo} alt="logomarca da página" />
                    </DivImage>
                    <Title>Termos de uso</Title>
                    <SubText>CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE REVENDA</SubText>
                    <TextModal>
                    De um lado,
                    </TextModal>
                    <TextModal>
                    <b>AMERIS – REDE DE HOTÉIS INDEPENDENTES LTDA.</b>, pessoa jurídica de direito privado,
                    sediada no ST SHIN CA 01 Lote A Bloco A Sala 352 – Brasília – DF, CEP: 71.503-501,
                    inscrita no CNPJ sob n° 30.249.497/0001-47, representada na forma do seu contrato
                    social, adiante designada somente como “<b>CONTRATANTE</b>”, enquanto administradora do
                    Clube “<b>VAI DE FÉRIAS</b>”, adiante designado somente como “<b>CLUBE</b>”,
                    e, de outro lado, <b>REVENDEDOR</b> que confirmou o aceite ao presente contrato.
                    </TextModal>
                    <TextModal>
                    têm justo e acertado o presente Contrato de Prestação de Serviços de Assessoria de Imprensa, a que mutuamente se obrigam com as cláusulas seguintes:
                    </TextModal>
                    <SubTitle>CLÁUSULA PRIMEIRA - DO OBJETO</SubTitle>
                    <TextModal>
                    <b>1.1. </b>O presente Contrato tem por objeto estabelecer as condições pelas quais o <b>REVENDEDOR</b> promoverá a venda dos planos de hospedagem do clube “VAI DE FÉRIAS” administrado pela <b>CONTRATANTE</b>.    
                    </TextModal>
                    <TextModal>
                    <b>1.2. </b>O presente contrato será firmado através da inserção dos dados do <b>REVENDEDOR</b> no site <a href="https://www.vaideferias.com.br" target="_blank" rel="noreferrer">www.vaideferias.com.br</a>, com a devida ciência de todos os termos aqui dispostos.
                    </TextModal>
                    <SubTitle>CLÁUSULA SEGUNDA - DAS CONDIÇÕES</SubTitle>
                    <TextModal>
                    <b>2.1. </b>A presente contratação é regida pelo disposto no art. 442-B, da CLT, alterada pela Lei 13.467/17, não estabelecendo entre as partes qualquer vínculo de emprego.
                    </TextModal>
                    <TextModal>
                    <b>2.2. </b>Os serviços serão executados no horário em que o <b>REVENDEDOR</b> decidir, não cabendo qualquer poder diretivo da <b>CONTRATANTE</b> a este respeito.
                    </TextModal>
                    <TextModal>
                    <b>2.3. </b>É livre à <b>CONTRATADA</b> ter seus próprios clientes, fora do âmbito deste contrato, desde que atendida a obrigação de confidencialidade ajustada no presente instrumento.
                    </TextModal>
                    <SubTitle>CLÁUSULA TERCEIRA - DA REMUNERAÇÃO DO REVENDEDOR</SubTitle>
                    <TextModal>
                    <b>3.1. </b>Em razão da parceria ora firmada, pagará a <b>CONTRATANTE</b> ao <b>REVENDEDOR</b>, como remuneração pela venda realizada, o percentual de 5% (cinco por cento) do valor do Plano de Hospedagem vendido.
                    </TextModal>
                    <TextModal>
                    <b>3.2. </b>Será considerado para a remuneração supracitada os contratos devidamente assinados entre a <b>CONTRATANTE</b> e os clientes captados pela <b>REVENDEDOR</b>, bem como, o pagamento somente será realizado após a compensação bancária do primeiro boleto do pacote vendido.
                    </TextModal>
                    <TextModal>
                    <b>3.3. </b>O Planos de Hospedagem vendidos pelo <b>REVENDEDOR</b> serão atribuídas à conta deste no aplicativo do CLUBE através do QR Code remete a indicação realizada pelo <b>REVENDEDOR</b>.
                    </TextModal>
                    <TextModal>
                    <b>3.4. </b>O valor devido ao <b>REVENDEDOR</b> será depositado em 05 (cinco) úteis após o cumprimento dos requisitos da Cláusula 3.1, em conta bancária de titularidade do <b>REVENDEDOR</b>.
                    </TextModal>
                    <TextModal>
                    <b>3.4.1 </b>Em nenhuma hipótese a remuneração devida ao <b>REVENDEDOR</b> será depositada em contas de terceiros.
                    </TextModal>
                    <TextModal>
                    <b>3.5. </b>Se houver o atraso no pagamento por mais de 60 (sessenta) dias corridos após a data de vencimento, poderá o <b>REVENDEDOR</b> suspender os serviços até o efetivo pagamento, bem como poderá rescindir o presente instrumento.
                    </TextModal>
                    <TextModal>
                    <b>3.6. </b>Todos os pagamentos do presente contrato devem ser feitos com a devida apresentação de RPA – Recibo de Pagamento de Autônomo.
                    </TextModal>
                    <TextModal>
                    <b>3.7. </b>Todos os pagamentos deverão ser realizados após as devidas deduções de impostos presentes ou futuros, incluindo, mas não se limitando, o ISS, PIS, COFINS, impostos locais ou outros encargos (coletivamente "Imposto Local") e encargos previdenciários.
                    </TextModal>
                    <TextModal>
                    <b>3.8. </b>O <b>REVENDEDOR</b> poderá optar, ao invés do recebimento em depósito bancário, pela troca em um dos Planos de Hospedagem disponibilizados pela <b>CONTRATANTE</b>.
                    </TextModal>
                    <TextModal>
                    <b>3.9. </b>Para que seja possível a troca por um dos Planos de Hospedagem fornecidos pela <b>CONTRATANTE</b> o <b>REVENDEDOR</b> deverá ter realizado a venda de no mínio 10 (dez) Planos de Hospedagem a <b>CLIENTES</b>.
                    </TextModal>
                    <TextModal>
                    <b>3.9.1. </b>O <b>REVENDEDOR</b> ao optar pelo recebimento da remuneração na forma de Plano de Hospedagem, deverá se submeter a todas as regras e condições do Regimento Interno do clube “Vai de Férias”.
                    </TextModal>
                    <TextModal>
                    <b>3.10. </b>Caso o dia ajustado para pagamento recaia em final de semana ou feriado, prorroga-se para o dia útil imediatamente posterior.
                    </TextModal>
                    <TextModal>
                    <b>3.11. </b>Uma vez paga cada RPA, a <b>CONTRATANTE</b> estará total e automaticamente quitada com relação aos serviços da competência a que se referir, para o <b>REVENDEDOR</b> nada mais poder reclamar a qualquer título ou tempo.
                    </TextModal>
                    <SubTitle>CLÁUSULA QUARTA - DA VIGÊNCIA E DA RESCISÃO</SubTitle>
                    <TextModal>
                    <b>4.1. </b>Este Contrato entra em vigor na data de sua assinatura e vigerá por prazo indeterminado, facultando-se às partes rescindi-lo de pleno direito, sem ônus a qualquer tempo, mediante comunicação à outra parte.
                    </TextModal>
                    <SubTitle>CLÁUSULA QUINTA - DAS CONDIÇÕES DA PRESTAÇÃO DE SERVIÇOS</SubTitle>
                    <TextModal>
                    <b>5.1. </b>O <b>REVENDEDOR</b> está autorizado a realizar os Planos de Hospedagem disponibilizados pela <b>CONTRATANTE</b> mediante uso de marcas e identificações desta.
                    </TextModal>
                    <TextModal>
                    <b>5.2. </b>Para cada cliente que contratar os serviços objeto deste contrato, o <b>REVENDEDOR</b> deverá celebrar um contrato, conforme modelo fornecido pela <b>CONTRATANTE</b>, contendo todas as condições estabelecidas.
                    </TextModal>
                    <TextModal>
                    <b>5.3. </b>O <b>REVENDEDOR</b> no ato da comercialização deverá dar ciência ao <b>CLIENTE</b> dos termos do Regimento Interno do Clube “Vai de Férias” disponibilizado no site da <b>CONTRATANTE</b> (www.vaideferias.com.br).
                    </TextModal>
                    <TextModal>
                    <b>5.4. </b>As especificações e características dos serviços prestados aos clientes do revendedor podem ser modificadas de forma unilateral pela <b>CONTRATANTE</b>, conforme as regras presentes no Regimento Interno do Clube “Vai de Férias”.
                    </TextModal>
                    <TextModal>
                    <b>5.5. </b>A cada novo contrato celebrado, o <b>REVENDEDOR</b> deverá enviar as especificações para a prestação dos serviços, contendo todas as informações sobre <b>CLIENTE</b>, conforme modelo fornecido pela <b>CONTRATANTE</b>.
                    </TextModal>
                    <SubTitle>CLÁUSULA SEXTA – DA POLÍTICA DE NÃO CONCORRÊNCIA</SubTitle>
                    <TextModal>
                    <b>6.1. </b>O <b>REVENDEDOR</b> não poderá iniciar ou manter relações comerciais que envolvam o mesmo serviço, com prospects e clientes que estejam sendo atendidos pela <b>CONTRATANTE</b> ou por outros revendedores.
                    </TextModal>
                    <TextModal>
                    <b>6.2. </b>A <b>CONTRATANTE</b> garante através deste instrumento que todas as negociações com clientes ou prospects conduzidas pelo <b>REVENDEDOR</b> serão preservadas com exclusividade a este.
                    </TextModal>
                    <TextModal>
                    <b>6.3. </b>O <b>REVENDEDOR</b> não poderá atuar como fornecedor de serviços similares, destinados ao mesmo fim, fornecido por empresas concorrentes, durante toda a vigência deste Contrato.
                    </TextModal>
                    <SubTitle>CLÁUSULA SÉTIMA – DO ATENDIMENTO AOS CLIENTES</SubTitle>
                    <TextModal>
                    <b>7.1. </b>É de responsabilidade do <b>REVENDEDOR</b> garantir o fornecimento das corretas informações aos <b>CLIENTES</b>, resolução de dúvidas e acompanhamento durante todo o processo de venda.
                    </TextModal>
                    <TextModal>
                    <b>7.2. </b>Todos os contatos serão realizados apenas entre a <b>CONTRATANTE</b> e o <b>REVENDEDOR</b>, especificadamente a qualquer solicitação esclarecimento adicionais que sejam solicitados pelos <b>CLIENTES</b>.
                    </TextModal>
                    <SubTitle>CLÁUSULA OITAVA - DA CONFIDENCIALIDADE</SubTitle>
                    <TextModal>
                    <b>8.1. </b>As partes convencionam que toda e qualquer informação transferida entre elas em decorrência deste contrato, será considerada confidencial e privilegiada, e não será divulgada a terceiros sem o expresso consentimento de ambas.
                    </TextModal>
                    <SubTitle>CLÁUSULA NONA - CONDIÇÕES GERAIS</SubTitle>
                    <TextModal>
                    <b>9.1. </b>O presente Contrato não estabelece entre as Partes, qualquer vínculo de imagem, natureza societária, associativa ou empregatícia.
                    </TextModal>
                    <TextModal>
                    <b>9.2. </b>Nenhum atraso, omissão ou falha no exercício de qualquer direito ou faculdade prevista na lei ou neste Contrato, será tido como novação ou renúncia a tal direito ou faculdade, nem como aquiescência ao evento que lhe tenha dado origem. A renúncia de qualquer das partes a direito ou faculdade conferida neste Contrato deverá ser manifestada sempre por escrito.
                    </TextModal>
                    <TextModal>
                    <b>9.3. </b>Não será admitida qualquer alteração, modificação ou acréscimo ao presente Contrato, salvo mediante avença escrita assinada pelas partes.
                    </TextModal>
                    <TextModal>
                    <b>9.4. </b>Fica eleito o Foro de Brasília - DF, como competente para dirimir todas e quaisquer questões oriundas da interpretação do presente <b>Contrato</b>, com renúncia de qualquer outro, por privilegiado que seja. 
                    </TextModal>
                    <Check>
                        <label>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={handleChange}
                            />
                            <span>Confirmo a aceitação do contrato de Revendedor(a), estando ciente de todos os seus termos e condições.</span>
                        </label>
                    </Check>
                    <Button format="submit" onClick={() => verifyCheckboxIsChecked()}>OK</Button>
                    
                </ModalContainer>
            </ModalBackground>
        </DivColor>
    );
};

export default ModalPF;