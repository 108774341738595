import { useEffect, useState } from 'react'
import { Container } from "./styles"
import Button from "../../../components/Button"
import { api } from "../../../services/api"
import { useLocation, useHistory, useParams } from "react-router-dom"
import { Calendar } from "../../../components/Calendar"
import Form from '../../../components/Forms'
import StyledSelect from '../../../components/Select'
import {alert} from '../../../components/Alert'
import { Page } from '../../AdminDashboard/styles'

const EditBooking = (props) => {
  const history = useHistory()
  const location = useLocation()

  const {id} = useParams()

  const [datas, setDatas] = useState([
    {
      startDate: new Date(location.state?.booking.start_time || null),
      endDate: new Date(location.state?.booking.end_time),
      key: 'selection'
    }
  ] || {});

  const options = [{value: 'Aprovado', label: 'Aprovado'}, {value: 'Cancelado', label: 'Cancelado'}, {value: 'Pendente', label: 'Pendente'}]

  const [room_type_id, setRoom_type_id] = useState(location.state?.booking.room_type_id || {});

  const [status, setStatus] = useState('');

  useEffect(() => {
    if (!location.state) {
      api.get(`bookings/show/${id}`)
      .then((response) => {
        setDatas([{startDate: new Date(response.data.start_time), endDate: new Date(response.data.end_time), key: 'selection'}])
        setStatus({label: response.data.status, value: response.data.status})
        setRoom_type_id({label: response.data.room_type.name, value: response.data.room_type.id})
      })
    }
  }, [id, location])

  const[roomTypes, setRoomTypes] = useState([])

  const loadRoomTypes = async () => {
      const response = await api.get('room_types/index')
      if(response){
          setRoomTypes(response.data.map(room_type => {return(
            {label: room_type.name, value: room_type.id}
          )}))
      }
  }

  useEffect(() => {
      loadRoomTypes()
  }, [])

  const addBookings = (e) => {
    e.preventDefault();
    api.put(`bookings/update/${id}`, {
      booking: {
        start_time: datas[0].startDate,
        end_time: datas[0].endDate,
        status: e.target.st.value,
        room_type_id: room_type_id.value,
      }
    }).then((response) => {
      response.status===200 ? alert("atualizado com sucesso", 'success') : alert("erro tente novamente", 'error')
      history.push("/bookings")
    }).catch((error => {
      alert("erro, tente novamente", 'error')
    }))
  }

    return (
      <Page>
        <Container>
            <h1>Editar Reserva</h1>
            <Form 
                onSubmit={addBookings}
                title='Reserva'
                text='Modifique os campos para editar a reserva.'
                button={
                    <>
                        <Button format='submit' type='submit'>Atualizar Reserva</Button>
                        <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
                    </>
                }
            >
                <label>Data da Reserva:</label>
                <Calendar state={datas} setState={setDatas}/>
                <StyledSelect
                    label='Status'
                    name="st"
                    id="st"
                    defaultValue={status}
                    options={options}
                    value={status}
                    onChange={status => setStatus(status)}
                />
                <StyledSelect
                    label='Tipo de quarto'
                    options={roomTypes}
                    value={room_type_id}
                    onChange={room_type => setRoom_type_id(room_type)}
                />
            </Form>
        </Container>
      </Page>
    )
}

export default EditBooking