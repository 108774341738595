// import { Container } from './styles'
import { 
  Document, 
  Page, 
  Text, 
  View, 
  StyleSheet, 
  Image, 
  Link
} from '@react-pdf/renderer';

// Adjust link from iframe
const convertIframe = (frame) => {
  // eslint-disable-next-line
  const regex1 = /!2d([0-9\.\-]*)/;
  // eslint-disable-next-line
  const regex2 = /!3d([0-9\.\-]*)/;

  let coord1 = regex2.exec(frame)
  let coord2 = regex1.exec(frame)

  if(coord1 == null || coord2 == null){
    return frame;
  }
  else {
    let link = `https://www.google.com/maps/@${coord1[1]},${coord2[1]},17z`;

    return link;
  }
}

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'collumn',
    backgroundColor: '#FFFFFF'
  },
  section: {
    flexDirection: 'row',
    margin: 5,
    padding: 5,
    // flexGrow: 1
  },
  sub_section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    paddingTop: 0,
    marginBottom: 5,
  },
  img: {
    margin: 8,
    paddingRight: 20,
    width: '205px',
    height: '80px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  reserva: {
    color: '#ff5b5b',
    fontSize: '14px',
    paddingBottom: '5px',
  },
  text_1: {
    color: '#000',
    fontSize: '14px',
    textAlign: 'justify',
  },
  text_2: {
    color: '#3A3B3C',
    fontSize: '12px',
    textAlign: 'justify',
  },
  hr: {
    border: '1px solid #000',
    width: '96%',
    margin: '2%',
  },
  text_end: {
    padding: 10,
    fontSize: '12px',
    fontWeight: 'bold',
  },
  text_end_2: {
    paddingLeft: 10,
    color: '#3A3B3C',
    fontSize: '12px',
  },
  info: {
    border: '1px solid #000',
    margin: '10%',
    padding: '2%',
    position: 'absolute',                                          
    bottom: 90,     
  },
  info2: {
    border: '1px solid #000',
    margin: '10%',
    padding: '2%',
    position: 'absolute',                                          
    bottom: 10,     
  },
  section_hotel_1: {
    width: '55%',
  },
  section_hotel_2: {
    width: '45%',
  },
});

// Create Document Component
const Voucher = (
  {logo, nome_hotel, cep_hotel, rua_hotel,
  numero_hotel, bairro_hotel, mapa_hotel,
  numero_reserva, nome_hospede, numero_adulto,
  numero_crianca, data_de_chegada, data_de_partida,
  tipo_de_quarto, data_da_reserva, informacoes_gerais,
  telefone, email}) => {
  return (
    
    <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={logo} style={styles.img} />
        <View>
          <Text style={styles.title}>Vai de Férias!</Text>
          <Text style={styles.reserva}>Confirmação da reserva: {numero_reserva}</Text>
          <Text style={styles.text_2}>Contato Vai de Férias</Text>
          <Text style={styles.text_2}>Telefone: (61) 98298-2468</Text>
          <Text style={styles.text_2}>Email: contato@vaideferias.com.br</Text>
        </View>
      </View>
      <View style={styles.hr}></View>
      <View style={styles.sub_section}>
          <View style={styles.hospede_info}>
            <Text style={styles.text_2}>Nome do hóspede</Text>
            <Text style={styles.text_1}>{nome_hospede}</Text>
          </View>
          <View style={styles.hospede_info}>
            <Text style={styles.text_2}>Data de Chegada</Text>
            <Text style={styles.text_1}>{data_de_chegada}</Text>
          </View>
          <View style={styles.hospede_info}>
            <Text style={styles.text_2}>Data de Partida</Text>
            <Text style={styles.text_1}>{data_de_partida}</Text>
          </View>
          <View style={styles.hospede_info}>
            <Text style={styles.text_2}>Tipo de quarto</Text>
            <Text style={styles.text_1}>{tipo_de_quarto}</Text>
          </View>
      </View>
      <Text style={styles.text_end}>Total de Hóspede(s): {numero_adulto + numero_crianca}</Text>
      <Text style={styles.text_end_2}>
        {numero_adulto ? numero_adulto : null}
        {numero_adulto === 1 ? ' Adulto' : numero_adulto > 1 ? ' Adultos' : null}
        {numero_crianca ? `, ${numero_crianca}` : null}
        {numero_crianca === 1 ? ' Criança ' : numero_crianca > 1 ? ' Crianças ' : null}
      </Text>
      
      <View style={styles.hr}></View>
      <View style={styles.sub_section}>
        <View style={styles.section_hotel_1}>
          <Text style={styles.text_2}>Hotel: <Text style={styles.text_1}>{nome_hotel}</Text></Text>
          <Text style={styles.text_2}>CEP: <Text style={styles.text_1}>{cep_hotel}</Text></Text>
          <Text style={styles.text_2}>Rua: <Text style={styles.text_1}>{rua_hotel}</Text></Text>
          <Text style={styles.text_2}>Número: <Text style={styles.text_1}>{numero_hotel}</Text></Text>
          <Text style={styles.text_2}>Bairro: <Text style={styles.text_1}>{bairro_hotel}</Text></Text>
          {/* <Text style={styles.text_2}>Contato: <Text style={styles.text_1}>(61) 9 9999-9999</Text></Text> */}
          <Text style={styles.text_2}>Informações do Hotel: </Text>
          <Text style={styles.text_1}>{informacoes_gerais}</Text>
          <Text style={styles.text_2}>Informações Gerais: </Text>
          <Text style={styles.text_1}>- Café da Manhã incluso na diária.</Text>
          <Text style={styles.text_1}>- Proibido animais de estimação.</Text>
          <Text style={styles.text_1}>- Confirmar chegada no hotel com pelo menos 15 minutos antes do horário de check-in</Text>
        </View>
        <View style={styles.section_hotel_2}>
          <Text style={styles.text_2}>Data de confirmação da reserva: <Text style={styles.text_1}>{data_da_reserva}</Text></Text>
          <Text style={styles.text_2}>Localização: <Link src={convertIframe(mapa_hotel)}><Text style={styles.text_1}>Abrir Google Maps</Text></Link></Text>
        </View>
      </View>
      <View style={styles.hr}></View>
      <View style={styles.info}>
        <Text style={styles.text_1}>Hospedagem de Menores de Idade:</Text>
        <Text style={styles.text_2}> 
          É obrigatória a apresentação do RG ou Certidão de Nascimento do menor. 
          Caso não haja parentesco de 1º grau ou o menor esteja desacompanhado é 
          obrigatória a apresentação de autorização expedida pelo juizado de menores 
          e do documento de identificação do mesmo.
        </Text>
      </View>
      <View style={styles.info2}>
        <Text style={styles.text_1}>Consumo na Hospedagem:</Text>
        <Text style={styles.text_2}> 
        Todos os consumos extras gerados durante a hospedagem são de responsabilidade do cliente.
        </Text>
      </View>
      </Page>
    </Document>
  )
}

export default Voucher