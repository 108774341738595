import { useState, useEffect } from 'react'
import { Container } from './styles'
import Button from '../../../../components/Button'
import { api } from '../../../../services/api' 
import { useParams, useHistory } from 'react-router-dom'
import { useUserContext } from '../../../../components/contexts/useUserContext'
import Pagination from '../../../../components/Pagination';
import { Page } from "../../../AdminDashboard/styles"

const SelectHotels = () => {
    const {client_id} = useParams()
    const history = useHistory()
    const { user } = useUserContext()
    const [client, setClient] = useState();
    const [hotels, setHotels] = useState([]);
    const [clientHotels, setClientHotels] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [showPermission, setShowPermission] = useState(false)
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          response.data.forEach((permission) => {
            if (permission.name === 'show_clients' && user.permissions.indexOf(permission.id) !== -1)
              setShowPermission(true)
          })
        })
      }, [user])


    useEffect(()=>{
      api.get('clients/show/'+client_id)
        .then((response) => {
          setClient(response.data)
        })
    }, [client_id])

    useEffect(() => {
        window.scrollTo(0, 0)
        let offset = (currentPage - 1)*10

        if(client){
          api.get("clients/index_hotels/"+client_id)
          .then((response) => {
            setClientHotels(response.data)
          })

          api.get('hotels/index_destination/'+client.solicitacao_destino+'/'+offset)
          .then((response) => {
            setHotels(response.data)
          })

          api.get('hotels/total_destination/'+client.solicitacao_destino)
          .then((response) => {
            setTotalItems(response.data)
          })
        }
      },[currentPage, user, client, client_id])


    const addHotel = (hotel_id) => {
      api.post('clients/add_hotel/'+client_id+'/'+hotel_id)
      .then((response) => {
        setClientHotels(response.data)
      })
    }

    const deleteHotel = (hotel_id) => {
      api.get('clients/delete_hotel/'+client_id+'/'+hotel_id)
      .then((response) => {
        setClientHotels(response.data)
      })
    }

    const finishSelection = () => {
      setDisableButton(true);
      api.get('clients/unset_solicitacao/'+client_id)
      .then((response) => {
        history.push('/manager/clients_solicitations')
      })
    }

    return (
      <Page>
        <Container>
        <h1>Selecionar Hoteis</h1>
        <table>
            <thead>
            <tr>
                <th>Nome do Hotel</th>
                <th>Rua</th>
                <th>Categoria</th>
                <th>Opções</th>
            </tr>
            </thead>
            <tbody>
            {hotels.map((hotel, index) => {
              let HoteldoCliente = false
              for(var i =0; i < clientHotels.length; i++){
                if (clientHotels[i].id === hotel.id){
                  HoteldoCliente = true;
                  break;
                }
              }
              return(
                <tr key={hotel.id}>
                <td>{hotel.name}</td>
                <td>{hotel.street}</td>
                <td>{hotel.category.name}</td>
                <td className="buttons" style={{minWidth: "35%"}}>
                { showPermission && HoteldoCliente &&   
                  <>
                    <Button className='info' format="delete" onClick={()=>deleteHotel(hotel.id)}>Remover</Button>
                  </>
                       
                } 
                {
                  showPermission && !HoteldoCliente &&   
                  <>
                    <Button className='info' format="accept" onClick={()=>addHotel(hotel.id)}>Adicionar</Button>
                  </>
                }
                </td>
                </tr>
            )})}
            </tbody>
        </table>
        < div className = 'pagination-box'>
            <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalItems}
            pageSize={10}
            onPageChange={page => setCurrentPage(page)}
            />
        </ div>
        <Button format='submit' cursor={disableButton && "wait"} onClick={() => !disableButton && finishSelection()} className='add-new'>Finalizar Seleção</Button>
        </Container>
      </Page>
    )
}

export default SelectHotels