import { useState, useEffect } from 'react'
import { Container } from '../Index/styles'
import Button from '../../../../components/Button'
import { api } from '../../../../services/api' 
import { Link } from 'react-router-dom'
import { useUserContext } from '../../../../components/contexts/useUserContext'
import Pagination from '../../../../components/Pagination';
import { Page } from "../../../AdminDashboard/styles"
import { alert } from "../../../../components/Alert"


const ClientBookingRequests = () => {
    const { user } = useUserContext()
    const [bookings, setBookings] = useState([{}]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
        let offset = (currentPage - 1)*10
        api.get('bookings/pending_bookings/'+offset)
        .then((response) => {
            setBookings(response.data)
        })
        api.get('bookings/total_pending/')
        .then((response) => {
          setTotalItems(response.data)
        })
    },[currentPage, user])

    const formatDate = (dateInput) => {
        if(dateInput){
        let d = new Date(dateInput)
        let ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('pt-br', { month: 'long' }).format(d);
        let da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
        return(`${da} de ${mo} de ${ye}`);
        }
    }

    const bookingApprove = (booking) => {
        try{
            api.put(`bookings/approve_booking/${booking.id}`)
            alert('A reserva foi autorizada com sucesso!', 'success')
            const new_bookings = bookings.filter((item) => item.id !== booking.id)
            setBookings(new_bookings)
        }catch(e){
            alert('Algum erro ocorreu. Por favor, tente novamente.', 'error')
        }
    }

    const bookingReprove = (booking) => {
        try{
            api.put(`bookings/reprove_booking/${booking.id}`)
            alert('A reserva foi cancelada com sucesso!', 'success')
            const new_bookings = bookings.filter((item) => item.id !== booking.id)
            setBookings(new_bookings)
        }catch(e){
            alert('Algum erro ocorreu. Por favor, tente novamente.', 'error')
        }
    }

    return (
      <Page>
        <Container>
        <h1>Aprovação de Reservas</h1>
        <table>
            <thead>
            <tr>
                <th>Início</th>
                <th>Fim</th>
                <th>Cliente</th>
                <th>Hotel</th>
                <th>Quarto</th>
                <th>Adultos e Crianças</th>
                <th>Gerenciar Aprovação</th>
            </tr>
            </thead>
            <tbody>
            {bookings.map((booking, index) => {
                return(
                <tr key={booking.id}>
                <td>{formatDate(booking.start_time)}</td>
                <td>{formatDate(booking.end_time)}</td>
                <td><Link to={`/manager/clients/show/${booking.client ? booking.client.id : ''}`}>
                    {booking.client ? booking.client.name : ''}
                </Link></td>
                <td><Link to={`/hotels/show/${booking.hotel ? booking.hotel.id : ''}`}>
                    {booking.hotel ? booking.hotel.name : ''}
                </Link></td>
                <td><Link to={`/rooms/show/${booking.room_type ? booking.room_type.id : ''}`}>
                    {booking.room_type ? booking.room_type.name : ''}
                </Link></td>
                <td>{booking.adults} adultos e {booking.children} crianças</td>
                
                <td className="buttons" style={{minWidth: "35%"}}>
                  <Button format="update" onClick={() => {bookingApprove(booking)}}>Aprovar</Button>
                  <Button format='delete' onClick={() => {bookingReprove(booking)}}>Reprovar</Button>
                </td>
                </tr>
                )
            })}
            </tbody>
        </table>
        <div className = 'pagination-box'>
            <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalItems}
            pageSize={10}
            onPageChange={page => setCurrentPage(page)}
            />
        </div>
        </Container>
      </Page>
    )
}

export default ClientBookingRequests