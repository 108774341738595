import {Container} from './styles'

const RecordCard = (props) => {
  return(
    <Container>
      <h6>{props.title}</h6>
      <span>{props.value}</span>
    </Container>
  );
}

export default RecordCard;