import { Route, Switch } from 'react-router-dom'
import { useUserContext } from "../components/contexts/useUserContext";
import React from 'react'
import AdminDashboard from '../pages/AdminDashboard/'

import ClientManager from '../pages/Manager/ClientManager/Index'
import ClientApproval from '../pages/Manager/ClientManager/ClientApproval'
import ClientSolicitations from '../pages/Manager/ClientManager/ClientSolicitations'
import ApproveCreditCard from '../pages/Manager/ClientManager/ApproveCreditCard'
import ApproveBoleto from '../pages/Manager/ClientManager/ApproveBoleto';
import SelectHotels from '../pages/Manager/ClientManager/SelectHotels'
import RetailerManager from '../pages/Manager/RetailerManager/Index'
import ShowRetailer from '../pages/Manager/RetailerManager/Show';
import RetailerCommissions from '../pages/Manager/RetailerManager/IndexRetailerCommissions'
import CreateRetailerCommissions from '../pages/Manager/RetailerManager/CreateRetailerCommissions'
import ShowRetailerCommissions from '../pages/Manager/RetailerManager/ShowRetailerCommissions'
import EditRetailerCommissions from '../pages/Manager/RetailerManager/EditRetailerCommissions'
import UpdateRetailerPercentage from '../pages/Manager/RetailerManager/UpdateRetailerPercentage'
import ShowRetailerReports from '../pages/Manager/RetailerManager/ShowRetailerReports'
import ShowClient from '../pages/Manager/ClientManager/Show';
import PayOptions from '../pages/AdminDashboard/PayOptions';
//import PaymentMethods from '../pages/PaymentMethods'
import { Sidebar } from '../components/Sidebar'
import CategoriesIndex from '../pages/CategoriesPages/CategoriesIndex'
import CategoriesUpdate from '../pages/CategoriesPages/CategoriesUpdate'

import Tags from '../pages/TagsPages/Tags'
import CreateTag from '../pages/TagsPages/createTag'
import EditTag from '../pages/TagsPages/editTag'

import Destinations from '../pages/Destinations/Index'
import CreateDestination from '../pages/Destinations/Create'
import EditDestination from '../pages/Destinations/Edit'
import ShowDestination from '../pages/Destinations/Show'

import RoomTypesIndex from '../pages/RoomTypesPages/RoomTypesIndex'
import RoomTypesShow from '../pages/RoomTypesPages/RoomTypesShow'
import RoomTypesCreate from '../pages/RoomTypesPages/RoomTypesCreate'
import RoomTypesUpdate from '../pages/RoomTypesPages/RoomTypesUpdate'

import Bookings from '../pages/Bookings'
import EditBooking from '../pages/Bookings/BookingsEdit'
import AdminList from '../pages/AdminDashboard/AdminList';
import AdminInvite from '../pages/AdminDashboard/AdminInvite';

import Hotels from '../pages/Hotels'
import CreateHotel from '../pages/Hotels/createHotel'
import EditHotel from '../pages/Hotels/editHotel'
import ShowHotel from '../pages/Hotels/showHotel'
import { Container } from './adminStyle';
import CategoriesShow from '../pages/CategoriesPages/CategoriesShow';
import ShowPackages from '../pages/Manager/ClientManager/ShowPackages';
import PackageDetails from '../pages/Manager/ClientManager/ShowPackages/PackageDetails';
import ClientBookingRequests from '../pages/Manager/ClientManager/ClientBookingRequests';


const AdminRoutes = () => {
    const { user } = useUserContext()

    return (
        <>
        {user && user.type === 'admin'  ?
        <Switch>
                <Route exact path='/profile'/>  
                <Route exact path='/dashboard'>
                    <Container>
                        <Sidebar />
                        <AdminDashboard />
                    </Container>
                </Route>
                <Route exact path='/dashboard/payment'>
                    <Container>
                        <Sidebar />
                        <PayOptions />
                    </Container>
                </Route>
                {/* <Route exact path='/paymentmethods' component={PaymentMethods}/> */}
                <Route path='/manager'>
                <Container>
                    <Sidebar />
                    <Route exact path='/manager/clients' component={ClientManager}/>
                    <Route exact path='/manager/clients/approval' component={ClientApproval}/>
                    <Route exact path='/manager/clients/approval/boleto/:id' component={ApproveBoleto}/>
                    <Route exact path='/manager/clients/approval/credit_card/:id' component={ApproveCreditCard}/>
                    <Route exact path='/manager/clients/show/:id' component={ShowClient}/>
                    <Route exact path='/manager/clients/packages/:id' component={ShowPackages}/>
                    <Route exact path='/manager/clients/packages/details/:id' component={PackageDetails}/>
                    <Route exact path='/manager/clients_solicitations' component={ClientSolicitations}/>
                    <Route exact path='/manager/booking_requests' component={ClientBookingRequests}/>
                    <Route exact path='/manager/retailers' component={RetailerManager}/>
                    <Route exact path='/manager/commission_payments' component={RetailerCommissions}/>
                    <Route exact path='/manager/commission_payments/:retailer_id/create' component={CreateRetailerCommissions}/>
                    <Route exact path='/manager/commission_payments/show/:id' component={ShowRetailerCommissions}/>
                    <Route exact path='/manager/commission_payments/edit/:id' component={EditRetailerCommissions}/>
                    <Route exact path='/manager/commission_payments/edit/percentage/:id' component={UpdateRetailerPercentage}/>
                    <Route exact path='/manager/retailer_reports/show/:id' component={ShowRetailerReports}/>
                    <Route exact path='/manager/retailers/show/:id' component={ShowRetailer}/>
                    <Route exact path='/manager/admins' component={AdminList}/>
                    <Route exact path='/manager/admins/invite' component={AdminInvite}/>
                    <Route exact path='/manager/select_hotels/:client_id' component={SelectHotels}/>
                  </Container>
                </Route>
                <Route path='/bookings'>
                <Container>
                    <Sidebar />
                    <Route exact path='/bookings' component={Bookings}/>
                    <Route exact path='/bookings/edit/:id' component={EditBooking}/>
                </Container>
                </Route>
                <Route path='/categories'>
                <Container>
                    <Sidebar />
                    <Route exact path='/categories' component={CategoriesIndex}/>
                    <Route exact path='/categories/update/:id' component={CategoriesUpdate}/>
                    <Route exact path='/categories/show/:id' component={CategoriesShow}/>
                </Container>
                </Route>
                <Route path='/tags'>
                <Container>
                    <Sidebar />
                    <Route exact path='/tags' component={Tags}/>
                    <Route exact path='/tags/create' component={CreateTag}/>
                    <Route exact path='/tags/edit/:id' component={EditTag}/>
                </Container>
                </Route>
                <Route path='/destinations'>
                <Container>
                    <Sidebar />
                    <Route exact path='/destinations' component={Destinations}/>
                    <Route exact path='/destinations/create' component={CreateDestination}/>
                    <Route exact path='/destinations/edit/:id' component={EditDestination}/>
                    <Route exact path='/destinations/show/:id' component={ShowDestination}/>
                </Container>
                </Route>
                <Route path='/rooms'>
                <Container>
                    <Sidebar />
                    <Route exact path='/rooms' component={RoomTypesIndex}/>
                    <Route exact path='/rooms/show/:id' component={RoomTypesShow}/>
                    <Route exact path='/rooms/create' component={RoomTypesCreate}/>
                    <Route exact path='/rooms/update/:id' component={RoomTypesUpdate}/>
                    <Route exact path='/rooms/index/:url_filter?' component={RoomTypesIndex}/>
                </Container>
                </Route> 
                <Route path='/hotels'>
                <Container>
                    <Sidebar />
                    <Route exact path='/hotels' component={Hotels}/>
                    <Route exact path='/hotels/create' component={CreateHotel}/>
                    <Route exact path='/hotels/update/:id' component={EditHotel}/>
                    <Route exact path='/hotels/show/:id' component={ShowHotel}/>
                    <Route exact path='/hotels/index/:url_filter?' component={Hotels}/>
                </Container>
                </Route>        
        </Switch>
        :
        <Switch></Switch>}
        </>
    )
}

export default AdminRoutes
