import styled, { css } from 'styled-components';

export const ButtonContainer = styled.button`
    border: none;
    transition: 0.5s;
    width: 100%;
    padding: 1rem 1.5rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1.125rem;
    margin: 0.625rem;
    font-weight: 600;

    ${props => (props.format === 'submit') &&
        css `
            border-radius: 0.25rem;
            color: white;
            background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
            @media screen and (max-width: 1000px){
                width: 90%;
            }
        `
    }

    ${props => (props.format === 'return') &&
        css `
            border-radius: 0.25rem;
            color: white;
            background-color: #DD3434;
            @media screen and (max-width: 1000px){
                width: 90%;
            }
        `
    }

    ${props => (props.format === 'delete') &&
        css `
            padding: 0.5rem 1rem;
            width: 8rem;
            border-radius: 1.563rem;
            color: #3A3B3C;
            background-color: #F56D6D;
        `
    }

    ${props => (props.format === 'update') &&
        css `
            padding: 0.5rem 1rem;
            width: 8rem;
            border-radius: 1.563rem;
            color: #3A3B3C;
            background-color: #94A7EB;
        `
    }

    ${props => (props.format === 'show') &&
        css `
            padding: 0.5rem 1rem;
            width: 8rem;
            border-radius: 1.563rem;
            color: #3A3B3C;
            background-color: #EBDD94;
        `
    }

    ${props => (props.format === 'show_item') &&
        css `
            padding: 0.5rem 1rem;
            width: 8rem;
            border-radius: 1.563rem;
            color: #3A3B3C;
            background-color: #82E0AA ;
        `
    }

    ${props => (props.format === 'home') &&
        css `
            padding: 0.5rem 1rem;
            height: 3.5rem;
            width: 25rem;
            border-radius: 0.5rem;
            font-size: 1.0rem;
            color: #f8f8f8;
            background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
        `
    }

    ${props => (props.format === 'profile') &&
        css `
            border-radius: .5rem;
            color: #f8f8f8;
            background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
        `
    }

    ${props => (props.format === 'logout') &&
        css `
            border-radius: .5rem;
            color: #f8f8f8;
            background-color: #BF1827;
            // background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
        `
    }

${props => (props.format === 'accept') &&
        css `
            padding: 0.5rem 1rem;
            width: 8rem;
            border-radius: 1.563rem;
            color: #3A3B3C;
            background-color: #5bd34d;
        `
    }

    :hover {
        transform: translateY(-2px);
        cursor: ${props => props.cursor || "pointer"};
    }
`;