import { useEffect, useState } from "react"
import { Container } from "./styles"
import { api } from '../../services/api'
import { useHistory } from "react-router-dom"
import Button from "../Button"
import PermissionsModal from "../BlankModal/PermissionsModal"
import { photo } from "../../../src/services/photo"

const TableRows = ({request, params, pagination, offset, adminPermissions, filter, hasFilter, setTotal, setPage}) => {
    const [apiRequest, setApiRequest] = useState([])
    const [filtered, setFiltered] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [adminId, setAdminId] = useState(0)

    const history = useHistory()

    const isSimilar = (userEntry, dbEntry) => {
        userEntry = userEntry.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
        let dbEntryNormalized = dbEntry.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
        if (dbEntryNormalized === userEntry || dbEntryNormalized.startsWith(userEntry)){
            return true
        } else{
            let dbArray = dbEntryNormalized.split(" ")
            for (var i = 0; i< dbArray.length; i++){
                if (dbArray[i] === userEntry || dbArray[i].startsWith(userEntry)){
                    return true
                }
            }
            return false
        }
    }

    useEffect(() => {
        !!pagination ?
            api.get(`${request}/index_pagination/${offset}`, { params: params })
            .then((response) => setApiRequest(response.data))
        : 
            api.get(`${request}/index`, { params: params })
            .then((response) => {
                setFiltered(response.data)
                setApiRequest(response.data)
                
                
            })
    }, [params, request, offset, pagination])

    useEffect(()=>{
        const filterFunction = () => {
            if (filter !== ""){
                setFiltered([])
                if (request === 'hotels'){
                    apiRequest.forEach((hotel) => {
                        if(isSimilar(filter, hotel.destination.name)){
                            setFiltered(oldArray => [...oldArray, hotel])
                        }else if(isSimilar(filter, hotel.name)){
                            setFiltered(oldArray => [...oldArray, hotel])
                        }
                    })
                } else if (request === 'room_types'){
                    apiRequest.forEach((room) => {
                        if(isSimilar(filter, room.hotel.name)){
                            setFiltered(oldArray => [...oldArray, room])
                        }else if(isSimilar(filter, room.name)){
                            setFiltered(oldArray => [...oldArray, room])
                        }
                    })
                }
            }  else {
                setFiltered(apiRequest)
            }
        };
        
        filterFunction();

    },[apiRequest, filter, request])

    const deleteBookings = (booking, bookings, index) => {
        if (window.confirm("Realmente deseja excluir a reserva #"+booking.id+"?")){
            api.delete('bookings/delete/'+booking.id)
            .then(() => {
                let temp = bookings;
                temp.splice(index,1);
                setApiRequest([...temp]);
            })
        }
    }

    const deleteDestinations = (destination, destinations, index) => {
        if (window.confirm("Realmente deseja excluir o destino #"+destination.id+"?")){
            api.delete('destinations/delete/'+destination.id)
            .then(() => {
                let temp = destinations;
                temp.splice(index,1);
                setApiRequest([...temp]);
            })
        }
    }

    const deleteRoomTypes = (room_type, room_types, index) => {
        if (window.confirm("Realmente deseja excluir o tipo de quarto #"+room_type.id+"?")){
            api.delete('room_types/delete/'+room_type.id)
            .then(() => {
                let temp = room_types;
                temp.splice(index,1);
                setApiRequest([...temp]);
            })
        }
    }

    const deleteHotels = (hotel, hotels, index) => {
        if (window.confirm("Realmente deseja excluir o hotel #"+hotel.id+"?")){
            api.delete('hotels/delete/'+hotel.id)
            .then(() => {
                let temp = hotels;
                temp.splice(index,1);
                setApiRequest([...temp]);
            })
        }
    }

    const deleteTags = (tag, tags, index) => {
        if (window.confirm("Realmente deseja excluir o tipo de quarto #"+tag.id+"?")){
            api.delete('tags/delete/'+tag.id)
            .then(() => {
                let temp = tags;
                temp.splice(index,1);
                setApiRequest([...temp]);
            })
        }
    }

    const RequestRequest = ({props, apiRequest, index}) => {
        const formatDate = (dateInput) => {
            let d = new Date(dateInput)
            let ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('pt-br', { month: 'long' }).format(d);
            let da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
            return(`${da} de ${mo} de ${ye}`);
        }
        if(request === 'bookings'){
            return (
                <>
                    <td style={{minWidth: '5rem'}} data-label='ID'>{props.id}</td>
                    <td style={{minWidth: '10rem'}} data-label='Início'>{formatDate(props.start_time)}</td>
                    <td style={{minWidth: '10rem'}} data-label='Término'>{formatDate(props.end_time)}</td>
                    <td style={{minWidth: '10rem'}} data-label='Status'>{props.status}</td>
                    {props.client &&
                        <td style={{minWidth: '10rem'}} data-label='Cliente'>{props.client.name}</td>
                    }
                    {props.room_type &&
                        <td style={{minWidth: '10rem'}} data-label='Quarto'>{props.room_type.name}</td>
                    }
                    <td className='buttons' style={{minWidth: '20rem'}} data-label='Opções'>
                        <Button format='update' onClick={() => history.push(`bookings/edit/${props.id}`)}>Editar</Button>
                        <Button format='delete' onClick={() => {deleteBookings(props, apiRequest, index)}}>Remover</Button>
                    </td>
                </>
            )
        }else if(request === 'bookings/resold'){
            return (
                <>
                    <td style={{minWidth: '10%'}} data-label='ID'>{props.id}</td>
                    <td style={{minWidth: '20%'}} data-label='Status'>{props.status}</td>
                    <td style={{minWidth: '35%'}} data-label='Cliente'>{props.client.name}</td>
                    <td style={{minWidth: '35%'}} data-label='Data'>{formatDate(props.created_at)}</td>
                </>
            )
        }
        else if(request === 'categories'){
            return(
                <>
                    <td style={{minWidth: '5rem'}} data-label='ID'>{props.id}</td>
                    <td style={{minWidth: '15rem'}} data-label='Nome'>{props.name}</td>
                    <td style={{minWidth: '25rem'}} data-label='Descrição'><div className='scroll'><p>{props.description}</p></div></td>
                    <td className='buttons' style={{minWidth: '30rem'}} data-label='Opções'>
                        <Button format='show' onClick={() => history.push(`/categories/show/${props.id}`)}>Info</Button>
                    { adminPermissions.indexOf('modify_categories') !== -1 &&
                    <>
                        <Button format='update' onClick={() => history.push(`/categories/update/${props.id}`)}>Editar</Button>
                    </>
                    }
                    </td>
                </>
            )
        }else if(request === 'destinations'){
            return(
                <>
                    <td style={{minWidth: '5rem'}} data-label='ID'>{props.id}</td>
                    {props.images_url ?
                        <td style={{minWidth: '12rem'}} data-label='Imagem'><img alt="destino" src={`${photo.defaults.baseURL + props.images_url[0]}`}/></td>
                        :
                        <td style={{minWidth: '12rem'}} data-label='Imagem'>Sem Imagem</td>
                    }
                    <td style={{minWidth: '10rem'}} data-label='Nome'>{props.name}</td>
                    {props.state &&
                        <td style={{minWidth: '5rem'}} data-label='Estado'>{props.state.abbreviation}</td>
                    }
                    <td className='buttons' style={{minWidth: '30rem'}} data-label='Opções'>
                        
                        <Button format='show' onClick={() => history.push(`destinations/show/${props.id}`)}>Info</Button>
                        { adminPermissions.indexOf('modify_destinations') !== -1 &&
                            <>
                                <Button format='update' onClick={() =>history.push(`destinations/edit/${props.id}`)}>Editar</Button>
                                <Button format='delete' onClick={() => {deleteDestinations(props, apiRequest, index)}}>Remover</Button>
                            </>
                        }
                    </td>
                </>
            )
        }else if(request === 'room_types'){
            return(
                <>
                    <td style={{minWidth: '3rem'}} data-label='ID'>{props.id}</td>
                    {props.images_url ?
                        <td style={{minWidth: '15rem'}} data-label='Imagem'><img src={`${photo.defaults.baseURL + props.images_url[0]}`} alt="Tipo de Quarto"/></td>
                        :
                        <td style={{minWidth: '15rem'}} data-label='Imagem'>Sem Imagem</td>
                    }
                    <td style={{minWidth: '8rem'}} data-label='Nome'>{props.name}</td>
                    <td style={{minWidth: '7rem'}} data-label='Capacidade'>{props.capacity}</td>
                    {props.hotel &&
                        <td style={{minWidth: '7rem'}} data-label='Hotel'><div className='scroll'><p>{props.hotel.name}<br/></p></div></td>
                    }
                    <td className='buttons' style={{minWidth: '30rem'}}>
                        <div>
                            <Button format='show' onClick={() => history.push(`rooms/show/${props.id}`)}>Info</Button>
                            { adminPermissions.indexOf('modify_room_types') !== -1 &&   
                                <>
                                    <Button format='update' onClick={() => history.push(`rooms/update/${props.id}`)}>Editar</Button>
                                    <Button format='delete' onClick={() => {deleteRoomTypes(props, apiRequest, index)}}>Remover</Button>
                                </>
                            }
                        </div>
                    </td>
                </>
            )
        }else if(request === 'hotels'){
            return(
                <>
                    <td style={{minWidth: '3rem'}} data-label='ID'>{props.id}</td>
                    {props.images_url ?
                        <td style={{minWidth: '15rem'}} data-label='Imagem'><img src={`${photo.defaults.baseURL + props.images_url[0]}`} alt="Hotel"/></td>
                        :
                        <td style={{minWidth: '15rem'}} data-label='Imagem'>Sem Imagem</td>
                    }
                    <td style={{minWidth: '8rem'}} data-label='Nome'>{props.name}</td>
                    {/* <td style={{minWidth: '8rem'}} data-label='Link Mapa'>{props.link_maps}</td> */}
                    {props.category &&
                        <td style={{minWidth: '8rem'}} data-label='Categoria'>{props.category.name}</td>
                    }
                    {props.destination &&
                        <td style={{minWidth: '8rem'}} data-label='Destino'>{props.destination.name}</td>
                    }
                    <td className='buttons' style={{minWidth: '40rem'}}>
                        <div>
                            <Button format='show_item' onClick={() => history.push(`vacation/hotel/${props.id}`)}>Visualizar</Button>
                            <Button format='show' onClick={() => history.push(`hotels/show/${props.id}`)}>Info</Button>
                            { adminPermissions.indexOf('modify_hotels') !== -1 &&   
                                <>
                                    <Button format='update' onClick={() => history.push(`hotels/update/${props.id}`)}>Editar</Button>
                                    <Button format='delete' onClick={() => {deleteHotels(props, apiRequest, index)}}>Remover</Button>
                                </>
                            }
                        </div>
                    </td>
                </>
            )
        }else if(request === 'tags'){
            return(
                <>
                    <td style={{minWidth: '5rem'}} data-label='ID'>{props.id}</td>
                    <td style={{minWidth: '35rem'}} data-label='Nome'>{props.name}</td>
                    <td className='buttons' style={{minWidth: '30rem'}}>
                        { adminPermissions.indexOf('modify_tags') !== -1 &&   
                        <div>
                            <Button format='update' onClick={() => history.push(`tags/edit/${props.id}`)}>Editar</Button>
                            <Button format='delete' onClick={() => {deleteTags(props, apiRequest, index)}}>Remover</Button>
                        </div>
                        }
                    </td>
                </>
            )
        }else if(request === 'admins'){
            return(
                <>
                    <td data-label='ID' style={{minWidth: '5rem'}}>{props.id}</td>
                    <td data-label='Nome' style={{minWidth: '20rem'}}>{props.name}</td>
                    <td data-label='Email' style={{minWidth: '20rem'}}>{props.email}</td>
                    <td className='buttons' style={{minWidth: '25rem'}}>
                        <div>
                            { !props.is_super &&
                                <Button format='update' onClick={() => {setShowModal(true); setAdminId(props.id)}} className='permissions'>Atualizar Permissões</Button>                        
                            }
                        </div>
                    </td>
                </>
            )
        }
        else {
            return null;
        }
    }

    const RenderRequest = () => {
        if (hasFilter){
            if (setTotal)
                setTotal(filtered.length)
            return(
                filtered.slice(offset, Math.min(filtered.length, offset+10)).map((thisRequest, index) => {return (
                    <Container key={thisRequest.id}>
                        <RequestRequest props={thisRequest} apiRequest={apiRequest} index={index}/>
                    </Container>
                )})
            )
        } else{
            return(
                apiRequest.map((thisRequest, index) => {return (
                    <Container key={thisRequest.id}>
                        <RequestRequest props={thisRequest} apiRequest={apiRequest} index={index}/>
                    </Container>
                )})
            )
        }
    }

    if(request === 'admins') {
        return(
            <>
                <tr>
                    <td>
                    <PermissionsModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        id={adminId}
                    />
                    </td>
                </tr>
                <RenderRequest/>
            </>
        )
    }else {
        return(
            <RenderRequest/>
        )
    }

}

export default TableRows