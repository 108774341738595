import { useState, useEffect } from 'react'
import { Container } from "./styles"
import Button from "../../../../components/Button"
import { api } from "../../../../services/api"
import { useHistory, useParams } from "react-router-dom"
import Form from '../../../../components/Forms'
import {alert} from '../../../../components/Alert'
import { Page } from '../../../AdminDashboard/styles.js'
import Input from '../../../../components/Input'

const EditRetailerCommission = () => {
  const history = useHistory()
  const [receipt, setReceipt] = useState({});
  const [paymentDate, setPaymentDate] = useState('');
  const [fileChange, setFileChange] = useState(false);

  const {id} = useParams()

  const [retailerId, setRetailerId ] = useState(0)

  const addRetailerCommissions = (e) => {
    e.preventDefault();
    api.patch(`commission_payments/update/${id}`, {
          retailer_id: retailerId,
          payment_date: new Date(paymentDate+"T12:00:00")
    }).then((response) => {
      if (addReceipt(response)) {
        alert("O Pagamento foi atestado com sucesso", 'success')
        history.push(`/manager/commission_payments/show/${retailerId}`)
      }
      else {
        alert("Não foi possível adicionar o arquivo. Tente novamente", 'error')
      }
    }).catch((error => {
      alert(error.response.data.message, 'error')
    }))
  }

  const addReceipt = async (response) => {
    try {
        if(fileChange){
          const formData = new FormData()
          formData.append('payment_receipt', receipt)
  
          const post_response = await api.post(`commission_payments/update_receipt/${id}`, formData)
          if(post_response.data){
            setReceipt(post_response.data)
          }
        }
    } catch(error){
        return false
    }
  }

  useEffect(() => {
      api.get(`commission_payments/show/${id}`)
      .then((response) => {
        setPaymentDate(response.data.payment_date)
        setRetailerId(response.data.retailer_id)
      })
  }, [id])

    return (
      <Page>
        <Container>
            <h1>Atualizar Pagamento de Comissão</h1>
            <Form
                onSubmit={addRetailerCommissions}
                title='Pagamento de Comissão'
                text='Preencha os campos para atualizar o pagamento de comissão.'
                button={
                    <>
                        <Button format='submit' type='submit'>Editar pagamento de comissão</Button>
                        <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
                    </>
                }
            >
              <h3>Selecione a Data do Pagamento</h3>                
              <Input onChange={(e) => setPaymentDate(e.target.value)} value={paymentDate} type={'date'}></Input>
              <h3>Adicione o Comprovante de Pagamento</h3>
              <input onChange={(e) => {setReceipt(e.target.files[0]); setFileChange(true)}} type="file"/>

            </Form>
        </Container>
      </Page>
    )
}

export default EditRetailerCommission
