import styled from 'styled-components';

export const ContainerWrapper = styled.section`
    width: auto;
    height: 100%;
    max-width: 1400px;
    margin: 0 0 0 18.75rem;
    color: #3A3B3C;
    padding: 0 2rem;
    
    .header-style {
        padding: 0 20px;

        h1 {
            font-size: 50px;
            font-weight: bold;
            font-family: Poppins;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            gap: 1rem;

            img {height: 4.5rem}
        }
    }

    @media(max-width: 1080px) {
        .header-style {            
            h1 {
                font-size: 2rem;

                img {height: 3rem}
            }

            p {text-align: justify}
        }
    }

    @media screen and (max-width: 800px){
        margin: 0 auto;
        padding: 0;
    }

    @media(max-width: 540px) {
        .header-style {

            h1 {
                font-size: 1.25rem;

                img {height: 2.5rem}
            }
            
            p {
                font-size: 16px;
            }
        }
    }

`;

export const ContainerCards = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    justify-content: center;
    column-gap: 3rem;
    row-gap: 1rem;
    padding: 4rem 3rem 1rem;

    @media(max-width: 411px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        padding: 1rem;
    }
`;