import styled from "styled-components";


export const DivColor = styled.div`
  max-width: 100vw !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  background-color: rgba(30,30,30,0.6);
  margin-bottom: 3rem;
  padding-top: 1rem;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
` 

export const ModalBackground = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
  position: relative;
  
  @media (max-width: 800px) {
    width: 100vw;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
`;

export const ModalContainer = styled.div`
  padding: 1.5rem 5rem;
  width: 100%;
  height: 40rem;
  max-height: 86vh;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
  border: solid 5px;
  border-image-slice: 1;
  border-image-source: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%) !important;
  border-radius: 8px; /* Não funciona com o gradient */
  overflow: hidden;
  overflow-y: scroll;
  font-size: 1.375rem;

  h5, p {margin: 0}

  h5 {font-weight: 600}

  @media (max-width: 1200px){
    font-size: 1rem;
  }

  @media (max-width: 800px) {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    width: 90%;
    height: auto;
  }
`;

export const CloseBtn = styled.button`
  font-family: Poppins;
  border-radius: 50px;
  border-color: black;
  background: white;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: black;
  position: absolute;
  top: -3%;
  right: -1.5%;
  font-weight: 700;
  border: solid black;
  
  @media (max-width: 800px) {
    right: 0;

    @media (max-height: 500px) and (orientation: landscape) {
      top: 2%;
      right: 1%;
    }
  }  
`;

export const Title = styled.h1`
  font-size: 2.2rem;
  line-height: 52px;
  font-weight: 600;

  @media (max-width: 1200px){
    font-size: 1.5rem;
  }

  
  @media (max-width: 800px) {
    font-size: 1rem;
    line-height: 20px;
    height: 30px;
    display: flex;
    flex: 1;
    padding-bottom: 1rem;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 576px){
    justify-content: space-around;

    .responsive-col {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-start;

      div {
        width: 45%;
        display: flex;
        flex-direction: column;
      }

      div:first-child {
        width: 95%;
        margin-bottom: 1rem;
      }
    }
  }
`

export const SmCol = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  margin-bottom: 1rem;

  @media (max-width: 576px){
    width: 50%;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
  }
`

export const LgCol = styled.div`
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .wrapper {
    border-radius: 1rem;
    width: 100%;
    background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
    padding: 5px;
    height: fit-content;
    
    div {
      background: #fff;
      border-radius: 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
      padding: .5rem;

      @media (max-width: 800px){
        padding: 0;
      }

      Button {
        margin: 0 2rem 1rem;
        width: auto;
        font-size: 1.75rem;
        height: auto;

        .download-link{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          text-decoration: none;
        }

        @media screen and (max-width: 1200px){
          font-size: 1.125rem;
        }

        @media (max-width: 480px){
          svg {display: none}
        }
      }

      h2 {
        margin: 0;
        font-weight: 600;
        padding: 0 2rem;
        font-size: 2rem;

        @media (max-width: 1200px){
          font-size: 1.25rem;
        }

  
        @media (max-width: 576px) {
          margin: 1rem 0;
        }
      }
    }
  }

  @media (max-width: 576px){
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: center;
  }
`

export const MdCol = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  height: fit-content;

  @media (max-width: 576px){
    align-self: flex-start;
  }
`
