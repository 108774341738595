import { Container } from "./styles"
import Button from "../../../components/Button"
import IndexTable from '../../../components/IndexTable'
import { Link } from "react-router-dom"
import { Page } from '../../AdminDashboard/styles.js'
import { useState, useEffect } from 'react'
import { api } from '../../../services/api'
import { useUserContext } from "../../../components/contexts/useUserContext"

const AdminList = () => {
    const { user } = useUserContext();
    const [showPermission, setShowPermission] = useState(false)

    useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          response.data.forEach((permission) => {
            if (permission.name === 'invite_admins' &&  user.permissions.indexOf(permission.id) !== -1)
              setShowPermission(true)
          })
        })
    }, [user])
    
    return (
        <Page>
            <Container>
                <IndexTable
                    caption='Administradores'
                    request='admins'
                />
                { showPermission &&                     
                        <Link to={{pathname: `admins/invite` }}>
                            <Button className="submit" format="home">Convidar Novo Administrador</Button>
                        </Link>
                }
            </Container>
        </Page>
    )
}

export default AdminList