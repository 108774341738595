import TableRows from "../IndexItem"
import { Container } from "./styles"
import { useState } from 'react'

const IndexTable = ({request, caption, params, offset, pagination, adminPermissions, setPage, setTotal,filter}) => {

    const [ hasFilter, setHasFilter] = useState(false)
    const RenderKeys = ({request}) => {
        if(request === 'bookings'){
            return (
                <>
                    <th>ID</th>
                    <th>Início</th>
                    <th>Término</th>
                    <th>Status</th>
                    <th>Cliente</th>
                    <th>Quarto</th>
                    <th>Opções</th>
                </>
            )
        }else if(request === 'bookings/resold'){
            return(
                <>
                    <th>ID</th>
                    <th>Status</th>
                    <th>Cliente</th>
                    <th>Data efetuada</th>
                </>
            )
        }
        else if(request === 'categories'){
            return(
                <>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Opções</th>
                </>
            )
        }else if(request === 'destinations'){
            return(
                <>
                    <th>ID</th>
                    <th>Imagem</th>
                    <th>Nome</th>
                    <th>Estado</th>
                    <th>Opções</th>
                </>
            )
        }else if(request === 'room_types'){
            setHasFilter(true)
            return(
                <>
                    <th>ID</th>
                    <th>Imagem</th>
                    <th>Nome</th>
                    <th>Capacidade</th>
                    <th>Hotel</th>
                    <th>Opções</th>
                </>
            )
        }else if(request === 'tags'){
            return(
                <>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Opções</th>
                </>
            )
        }else if(request === 'admins'){
            return(
                <>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Permissões</th>
                </>
            )
        }else if(request === 'hotels'){
            setHasFilter(true)
            return(
                <>
                    <th>ID</th>
                    <th>Imagem</th>
                    <th>Nome</th>
                    {/* <th>Link Mapa</th> */}
                    <th>Categoria</th>
                    <th>Destino</th>
                    <th>Opções</th>
                </>
            )
        }
    }



   

    return(
        <>
        
        <Container>
            <caption>{caption}</caption>
            <thead>
                <tr>
                    <RenderKeys request={request}/>
                </tr>
            </thead>
            <tbody>
                <TableRows
                    request={request}
                    params={params}
                    offset={offset}
                    pagination={pagination}
                    adminPermissions={adminPermissions}
                    filter={filter}
                    hasFilter={hasFilter}
                    setTotal={setTotal}
                    setPage={setPage}
                />
            </tbody>
        </Container>
        </>
    )
}

export default IndexTable