import styled from "styled-components";

export const DivColor = styled.div`
  max-width: 100vw !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  background-color: rgba(30,30,30,0.6);
  margin-bottom: 3rem;
  padding-top: 1rem;
  .disabled {
    padding-top: 1rem;
    color: red;
  }
  
  @media (max-width: 876px) {
    /* height: 100%; */
  }
` 

export const ModalBackground = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
  
  @media (max-width: 876px) {
    width: 100vw;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
  `;

export const ModalContainer = styled.div`
  padding: 1.5rem;
  width: 80%;
  min-height: 35rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #3A3B3C;
  
  border: solid 5px;
  border-image-slice: 1;
  border-image-source: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%) !important;
  border-radius: 8px; /* Não funciona com o gradient */
  
  @media (max-width: 876px) {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    width: 90%;
    height: auto;
  }
  `;

export const CloseBtn = styled.button`
  font-family: Poppins;
  border-radius: 50px;
  border-color: black;
  background: white;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: black;
  position: absolute;
  top: 0%;
  right: 7%;
  font-weight: 700;
  border: solid black;
  
  @media (max-width: 876px) {
    right: 0;
    top: 2%;
    @media (max-height: 500px) and (orientation: landscape) {
      top: 2%;
      right: 1%;
    }
  }
  
  `;

export const Title = styled.h1`
  font-family: Poppins;
  font-size: 2.2rem;
  line-height: 52px;
  font-weight: 600;
  height: 90px;
  
  @media (max-width: 876px) {
    font-size: 1rem;
    line-height: 20px;
    height: 30px;
    display: flex;
    flex: 1;
    padding-bottom: 1rem;
  }
  `;

export const WrapSides = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 876px) {
    display: block;
  }
`

export const LeftSide = styled.div`
position: relative;
width: 39%;
height: fit-content;
z-index: 10;

@media (max-width: 1000px){
  height: 68vh;
}

h3 {
    font-size: 0.85rem;
    margin: 0;
    font-weight: 600;
    font-family: Poppins;
    padding-bottom: 0.5rem;
  }
  
  h4 {
    font-family: Poppins;
    margin: 0;
    font-weight: normal;
    font-size: 0.85rem;
    padding-bottom: 0.75rem;
  }
  
  @media (max-width: 876px) {
    width: 100%;
    height: 4vh;
    padding-bottom: 15rem;
    h3 {
      font-size: 0.8rem;
    }
    h4 {
      font-size: 0.6rem;
    }
  }
`;

export const RightSide = styled.div`
  position: relative;
  z-index: 10;
  border-left: 1px solid #222222;
  padding-bottom: 1.8rem;

  h3 {
    padding-left: 2rem;
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
    font-family: Poppins;
    padding-bottom: 0.5rem;
  }

  h4 {
    font-family: Poppins;
    margin: 0;
    font-weight: normal;
    font-size: 1rem;
    padding-bottom: 0.75rem;
    padding-left: 2rem;
  }

  @media (max-width: 876px) {
    width: 100%;
    border: none;

    h3 {
      font-size: 0.8rem;
      padding-left: 0;
    
    }
    h4 {
      font-size: 0.6rem;
      padding-left: 0;
    }
  }
`;

export const FlexDivLeft = styled.div`
  width: 50%;
  @media (max-width: 876px) {
    width: 100%;
    h3 {
      font-size: 0.8rem;
      margin-top:11rem;
    }
    h4 {
      font-size: 0.6rem;
    }
  }
`;

export const FlexDivRight = styled.div`
  width: 50%;
  @media (max-width: 876px) {
    width: 100%;
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 0.8rem;
    }
  }
`;

export const BorderDiv = styled.div`
  position: relative;
  padding-left: 2rem;
  box-sizing: border-box;

  h1 {
    margin: 0;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 600;
  }

  @media (max-width: 876px) {
    padding-left: 0;
    top: auto;
    width: 100%;
    h3 {
      font-size: 1.2rem;
    }
    h4 {
      font-size: 1rem;
    }
  }
`;

export const MakeSideDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;


export const LeftDiv = styled.div`
  display: inline-block;
  @media (max-width: 876px) {
    h1 {
      font-size: 1rem;
    }
  }
`;

export const RightDiv = styled.div`
  display: inline-block;
  text-align: end;

  .gradienteH1 {
    background-color: #fcc046;
    background-image: linear-gradient(
      103.6deg,
      #fcc046 -13.96%,
      #cc2a55 58.95%,
      #bf1827 134.95%
    );
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  @media (max-width: 876px) {
    h1 {
      font-size: 1rem;
    }
  }
`;

export const BorderDivIn = styled.div`
  width: 39vw;
  /* border-radius: 10px;
  border: 3px solid blue; */
  margin-top: 0.5rem;
  padding: 1rem;
  border: 5px solid transparent;
  border-radius: 15px;
  background: linear-gradient(white, white) padding-box,
    /*this is your grey background*/
      linear-gradient(
        103.6deg,
        #fcc046 -13.96%,
        #cc2a55 58.95%,
        #bf1827 134.95%
      )
      border-box;

  @media (max-width: 876px) {
    width: 83%;  
  }
`;

export const Check = styled.div`
  padding-bottom: 1rem;
  padding-top: 1rem;

  label {
    margin: 0;
    padding-left: 1rem;

    span {
      font-family: Poppins;
      font-size: 1rem;
      font-weight: 600;
      padding-left: 0.75rem;
      .lost_days {
        color: red;
        padding-left: 0rem;
      }
    }

    input[type="checkbox"] {
      height: 1.1rem;
      width: 1.1rem;
      filter: grayscale(100%);
      cursor: pointer;
    }
  }

  @media (max-width: 876px) {
    width: 100%;
    padding-top: 0.3rem;

    label {
      padding-left: 0;

      span {
        font-size: 0.9rem;
      }
    }

    @media (max-height: 500px) and (orientation: landscape) {
      padding-top: 8rem;
    }
  }

`;

export const ButtonDiv = styled.div`
  padding-left: 2rem;
`;

export const ModalButton = styled.button`
  cursor: pointer;
  height: 3.4rem;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(
    103.6deg,
    #fcc046 -13.96%,
    #cc2a55 58.95%,
    #bf1827 134.95%
  );
  width: 100%;
  margin-right: 2rem;
  margin-bottom: 2rem;
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    color: white;
    margin: 0;
  }
  @media (max-width: 876px) {
    width: 100%;
    margin-bottom: 0rem;

    h1 {
      font-size: 1rem;
    }
  }

`;
