import Button from "../Button";
import { Container } from "./styles";
import { ImCheckboxChecked } from "react-icons/im";
import { photo } from "../../services/photo";
import ReactElasticCarousel from "react-elastic-carousel";

const RoomCard = ({selected, setSelectedRoom, id, name, capacity, description, images}) => {
    const placeholder = "https://patriciaelias.com.br/wp-content/uploads/2021/01/placeholder.png"
    const image_urls  = images?.map(image => photo.defaults.baseURL + image)

    return(
        <Container>
            <ReactElasticCarousel className="images">
                {
                    image_urls ?
                        image_urls.map((image, index) => <img src={image} alt='Quarto' key={index} />)
                    :
                        <img src={placeholder} alt="placeholder" />
                }
            </ReactElasticCarousel>
            <div className="info">
                <h4>
                    Quarto {name}
                </h4>
                <ul>
                    <li>
                        Acomoda {capacity} pessoa(s)
                    </li>
                    <li>
                        {description}
                    </li>
                </ul>
            </div>
            { !!selected ?
                <Button format="submit" style={{background: "#007AFF"}} onClick={() => setSelectedRoom(id)}><ImCheckboxChecked /> Selecionado</Button>
                :
                <Button format="home" onClick={() => setSelectedRoom(id)}>Selecionar Quarto</Button>
            }
        </Container>
        
    )
}

export default RoomCard;