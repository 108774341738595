// Arquivo do CRUD de tgs
import { useState } from 'react'
import { Container } from "./styles"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import { api } from "../../../services/api"
import { useHistory } from "react-router-dom"
import Form from '../../../components/Forms'
import {alert} from '../../../components/Alert'
import { Page } from '../../AdminDashboard/styles'

const CreateTag = () => {
  const history = useHistory();

  const [name, setName] = useState('');
  const [icon, setIcon] = useState('');

  const addTags = async (e) => {
    e.preventDefault();
    await api.post('tags/create', {
      tag: {
        name: name,
        icon_name: icon
      }
    }).then((response) => {
      response.status===201 ? alert("criado com sucesso", 'success') : alert("erro, tente novamente", 'error')
      setName('')
      setIcon('')
      history.push('/tags')
    }).catch((error) => {
      alert("erro, tente novamente", 'error')
    })
  }

    return (
      <Page>
        <Container>
          <h1>Criar Tag</h1>
          <Form
            onSubmit={addTags}
            title='Tag'
            text='Preencha os campos para adicionar uma tag.'
            button={
              <>
                  <Button format='submit' type='submit'>Adicionar tag</Button>
                  <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
              </>
            }
          >
            <Input label='Nome' value={name} onChange={(e)=>setName(e.target.value)}></Input>
            <span>
                O nome do ícone deve ser pego do site 
                <a href='https://react-icons.github.io/react-icons/icons?name=fi' target='_blank' rel="noopener noreferrer"> react-icons</a>,
                qualquer ícone desse site <strong>COM A EXEÇÃO</strong> dos icones de:
                Ionicons 5 e VS Code Icons
            </span>
            <Input label='Nome do ícone' value={icon} onChange={(e)=>setIcon(e.target.value)}></Input>
          </Form>
        </Container>
      </Page>
    )
}

export default CreateTag
