import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ArrowBack from '../../../components/ArrowBack'
import { api } from '../../../services/api'
import { Container } from './styles'

const CategoriesShow = () => {

    let { id } = useParams()
    const [category, setCategory] = useState({})

    useEffect(() => {
        const loadCategory = async () => {
            const response = await api.get(`categories/show/${id}`)
            setCategory(response.data)
        };

        loadCategory()
    }, [id])

    return (
        <Container>
            <ArrowBack className='voltar' />
            <h1>{category?.name}</h1>
            <p>{category?.description}</p>
        </Container>
    )
}

export default CategoriesShow
