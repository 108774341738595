import { Container } from './styles'
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import logo from '../../assets/logo.png'
import logo_ameris from '../../assets/logo-ameris.png'
import logo_ronald from '../../assets/logo vertical instituto ronald.png'
import { Link } from 'react-router-dom';
import pdf from '../../assets/Terms/Politica de privacidade - Vai de Ferias - 22-03-2022.pdf'

export const Footer = () => {


  return (
    <Container>
      <div className='footer'>
        <div className='footer-top'> 
          <div className='par'>
            <div className='footer-logo'>
            <div className='logo'>
                  <a href='/'>
                      <img src={logo} alt="logomarca da página" />
                  </a>
              </div>
              <p>Hotéis e pousadas que cabem no seu bolso!</p>
              
            </div>
            <div className='footer-heading footer-1'>
              <h2>Redes Sociais</h2>
              <div className='footer-social'>
                <a href='https://www.instagram.com/vaideferias_/'><FaInstagram  className='icon instagram-icon'/></a>
                <a href='https://www.facebook.com/vaideferias'><FaFacebookF  className='icon facebook-icon'/></a>
                <a href='/#'><FaLinkedinIn className='icon linkedin-icon'/></a>
              </div>
              <p>Fale Conosco: contato@vaideferias.com.br</p>
              <a href='https://wa.me/message/HW2QAB55QCZIN1'>
                <p><FaWhatsapp className='icon whatsapp-icon'/>WhatsApp Comercial: (61) 98298-2468</p>
              </a>
            </div>
          </div>
          <div className='par'>
            <div className='footer-heading footer-2'>
              <Link to='/#Quem-Somos'>Quem Somos</Link>
              <Link to='/FAQ'>Dúvidas Frequentes</Link>
              <a href={pdf} download="Politica de privacidade - Vai de Ferias - 22-03-2022">Política de Privacidade</a>
            </div>
            <div className='footer-heading footer-3'>
              <h2>Apoio:</h2>
              <a href='/#'>
                <img src={logo_ronald} alt="Instituto Ronald McDonald"  id='ronald-logo'/>
              </a>
            </div>
            <div className='footer-heading footer-4'>
              <h2>Idealização:</h2>
              <a href='/#'>
                <img src={logo_ameris} alt="Ameris" id='ameris-logo'/>
              </a>
            </div>
          </div>
          </div>
          
        <div className='footer-bottom'>
          <p>2022 AMERIS. Todos os direitos reservados. Feito por 
            <a href="http://www.struct.unb.br/">{' {'}Struct{'}'}</a>
          </p>
        </div>
      </div>
    </Container>
  )
}