import { useState, useEffect } from 'react'
import { Container } from "./styles"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import { api } from "../../../services/api"
import { useHistory } from "react-router-dom"
import Form from '../../../components/Forms'
import TextArea from '../../../components/TextArea'
import StyledSelect from '../../../components/Select'
import {alert} from '../../../components/Alert'
import { Page } from '../../AdminDashboard/styles.js'

const CreateDestination = () => {
  const history = useHistory();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    api.get('states').then((response) => {
        setStates(response.data);
    })
  },[])

  useEffect(() => {
    setOptions(states.map((state) => {
      return { value: state.id, label: state.name }
    }))
  }, [states])

  const addDestinations = (e) => {
    e.preventDefault();
    api.post('destinations/create', {
      destination: {
        name,
        description,
        state_id: stateId?.value
      }
    }).then((response) => {
      response.status===201 ? alert("criado com sucesso", 'success') : alert("erro, tente novamente", 'error')
      setName('')
      setDescription('')
      setStateId(1)
      history.push('/destinations')
    }).catch(() => {
      stateId ? alert("Erro, tente novamente!", 'error')
        : alert("Erro: selecione a Unidade Federativa!", 'warn')
    })
  }

    return (
      <Page>
        <Container>
            <h1>Criar Destino</h1>
            <Form
                onSubmit={addDestinations}
                title='Destino'
                text='Preencha os campos para adicionar um destino.'
                button={
                    <>
                        <Button format='submit' type='submit'>Adicionar destino</Button>
                        <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
                    </>
                }
            >
                <Input
                    label='Nome'
                    onChange={value => setName(value.target.value)}
                />
                <TextArea
                    label='Descrição'
                    onChange={value => setDescription(value.target.value)}
                />
                <StyledSelect
                    options={options}
                    label='Unidade Federativa'
                    placeholder='Selecionar...'
                    value={stateId}
                    onChange={state => setStateId(state)}
                />
            </Form>
        </Container>
      </Page>
    )
}

export default CreateDestination