import styled from 'styled-components'

export const Container = styled.div`

    width: 30%;
    min-width: 20rem;
    min-height: 14rem;
    border: 2px solid #0D0C22;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1rem 4rem;
    justify-content: space-between;

    h5 {
        font-size: 1.5rem;
        margin: 0;
    }

    .row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-width: 0;

        .name {
            white-space: nowrap; 
            max-width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        h6 {
            font-size: 1.125rem;
            margin: 0;
        }
    }

    ol {
        counter-reset: section;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            gap: .5rem;
            margin-bottom: .5rem;
            font-size: 1.125rem;
            font-weight: 500;
        }

        li::before {
            counter-increment: section;
            content: counter(section) ".";
            width: 1rem;
            text-align: right;
        }
    }

    @media screen and (max-width: 700px){

        width: 100%;
        min-height: 8rem;

        h5 {
        font-size: 1.2rem;
        }
    }
    
    @media screen and (max-width: 320px){
        h5 {
        font-size: .9rem;
        }
    }
`;