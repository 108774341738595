import React from "react"
import CardHowItWork from '../../../components/CardHowItWork'
import ArrowBack from '../../../components/ArrowBack'

import { ContainerWrapper, ContainerCards } from './styles'

import img1 from '../../../assets/ImagesRetailerGuide/image-1.png'
import img2 from '../../../assets/ImagesRetailerGuide/image-2.png'
import img3 from '../../../assets/ImagesRetailerGuide/image-3.png'
import img4 from '../../../assets/ImagesRetailerGuide/image-4.png'
import img5 from '../../../assets/ImagesRetailerGuide/image-5.png'
import img6 from '../../../assets/ImagesRetailerGuide/image-6.jpg'
import info from '../../../assets/info.svg'


const RetailerGuide = () => {

    const returnElements = [
        {
            id: 1,
            title: "Acesse sua dashboard",
            phrases: ["Entre no site e explore a sua dashboard de revendedor"],
            image: img1, 
            color: "#FCC046"
        },

        {
            id: 2,
            title: "Convide clientes",
            phrases: ["Pegue seu link de cadastro e convide cliente para participar do programa Vai de Férias"],
            image: img2,
            color: "#F09E40"
        },

        {
            id: 3,
            title: "Indique novos revendedores",
            phrases: ["Pegue seu link para convidar revendedores e os indique para ser um revendedor Vai de Férias"],
            image: img3,
            color: "#E47D3A"
        },

        {
            id: 4,
            title: "Acompanhe suas revendas",
            phrases: ["Verifique seus clientes convidados que estão comprando o pacote"],
            image: img4,
            color: "#D75B33"
        },
        {
            id: 5,
            title: "Receba suas comissões",
            phrases: ["Veja o dinheiro das suas comissões ir direto para sua conta", "Faça o download dos seus comprovantes"],
            image: img5,
            color: "#CB3A2D"
        },
        {
            id: 6,
            title: "Aproveite as funcionalidades",
            phrases: ["Explore todas as vantagens que ser um revendedor Vai de Férias te proporciona"],
            image: img6,
            color: "#BF1827"
        }
    ]

    return(
        <>
            <ArrowBack/>
            <ContainerWrapper>
                <div className="header-style">
                    <h1><img src={info} alt="Ícone do Guia do Revendedor"/>Guia do Revendedor</h1>
                </div>
                    <ContainerCards>
                        {returnElements?.map((element) => (
                            <CardHowItWork
                                key={element.id}
                                id={element.id}
                                title={element.title}
                                description={element.phrases}
                                image={element.image}
                                titleColor={element.color}
                            />
                        ))}
                    </ContainerCards>
            </ContainerWrapper>
        </>
    )
}

export default RetailerGuide