import styled, { css } from "styled-components";

export const Container = styled.div`
    span {
        color: red;
    }
`

export const InputContainer = styled.div`
    position: relative;
    font-weight: 400;
    padding: 0.4rem 0.625rem;

    label {
        position: absolute;
        pointer-events: none;
        top: -1rem;
        left: 0rem;
        transition: .2s ease;
        color: #CBCBCB;
    }


    ${props => (props.isFocus) &&
        css`
            input {
                outline: none !important;
                border: 1px solid #007AFF !important;
                border-left: 2px solid #007AFF !important;
            }
        `
    }

    ${props => (props.isFilled) &&
        css`
            input {
                outline: none !important;
                border-bottom: 1px solid #007AFF !important;
            }
        `
    }


    ${props => (props.failed) &&
        css`
            input {
                border-color: red !important;
                color: red !important;
            }
        `
    }
    ${props => (props.format === 'email') &&
        css `
            width: 400px;
        `
    }
    ${props => (props.format === 'password') &&
        css `
            width: 400px;
        `
    }

    ${props => (props.format === 'ddd') &&
        css `
            width: 36px;
            margin-right: 3.5rem;
        `
    }
    ${props => (props.format === 'phone') &&
        css `
            width: 288px;
        `
    }
    ${props => (props.format === 'rg') &&
        css `
            width: 262px;
        `
    }
    ${props => (props.format === 'city') &&
        css `
            width: 250px;
            margin-right: 0rem;
        `
    }
    ${props => (props.format === 'state') &&
        css `
            width: 80px;
        `
    }
    ${props => (props.format === 'cep-sm') &&
        css `
            width: 115px;
            margin-right: 3.5rem;
        `
    }
    ${props => (props.format === 'cep') &&
        css `
            width: 15.5rem;
            margin-right: 0.625;
        `
    }
    ${props => (props.format === 'district') &&
        css `
            width: 210px;
        `
    }
    ${props => (props.format === 'street') &&
        css `
            width: 260px;
            margin-right: 3.5rem;
        `
    }
    ${props => (props.format === 'number') &&
        css `
            width: 65px;
        `
    }
    ${props => (props.format === 'neighborhood') &&
        css `
            width: 8rem;
        `
    }
    ${props => (props.format === 'esquerda_50') &&
        css `
            width: 162px;
            margin-right: 3.5rem;
        `
    }
    ${props => (props.format === 'direita_50') &&
        css `
            width: 163px;
        `
    }
    ${props => (props.format === 'birthdate') &&
        css `
            width: 160px;
        `
    }
    
    input {
        border: 1px solid #CBCBCB;
        border-radius: 5PX;
        margin-top: 0.5rem;
        width: 100%;
        padding: 0.6rem 1rem 0.6rem 1rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        box-sizing: border-box;

        :focus ~ label,
        :not(:focus):valid ~ label{
            opacity: 0.9;
            font-size: .8rem;
            font-weight: 600;
        }

        :focus ~ label {
            color: #007AFF;
        }

        :not(:focus):valid ~ label {
            color: black;
        }
    }


    
    @media screen and (max-width: 610px){
        margin: auto;
        width: 80%;
        font-size: 0.8rem;
    }
`