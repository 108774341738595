import ArrowBack from '../../../../components/ArrowBack'
import { Container, Page } from './styles';
import Button from '../../../../components/Button';
import Title from '../../../../components/Title'
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {HiPrinter} from 'react-icons/hi';
import { useUserContext } from '../../../../components/contexts/useUserContext'
import { api } from '../../../../services/api';
import { Link } from 'react-router-dom';
import { photo } from '../../../../services/photo';

const RetailerCommissionsInfo = () => {
    const { user } = useUserContext()
    const {id} = useParams();
    const [showInfo, setShowInfo] = useState(true);
    const [commissionPayments, setCommissionPayments] = useState({created_at: null, payment_value_in_cents: 0, payment_date: null});
    const [retailer, setRetailer] = useState({name: null, email: null, commission_in_cents: 0, commission_percentage: 0, is_active: null})
    const [showPermission, setShowPermission] = useState(false)
    
    useEffect(() => {
        api.get(`/commission_payments/retailer_index/${id}`)
        .then(response => {
            setCommissionPayments(response.data)
        });

        api.get(`/retailers/show/${id}`)
        .then(response => {
            setRetailer(response.data)
        });
    }, [id])

    useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          response.data.forEach((permission) => {
            if (permission.name === 'modify_commission_payments' &&  user.permissions.indexOf(permission.id) !== -1)
              setShowPermission(true)
          })
        })
    }, [user])

    const formatDate = (dateInput) => {
        let options = {year: 'numeric', month: '2-digit', day: '2-digit'};

        let d = new Date(dateInput + "T12:00:00-03:00")
        return Intl.DateTimeFormat('pt-br', options).format(d)
    }

    const getCommissionValue = (commissionInCents) => {
        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return formatter.format(commissionInCents/100);
      }

    return (
        <Page>
            <ArrowBack/>
            <Container>
                <Title>#{retailer.id} - {retailer.name}</Title>
                <div className={showInfo && "show-info"}>
                    <div className="tabs">
                        <h4 className={showInfo ? 'active' : ''} onClick={() => setShowInfo(true)}>Informações</h4>
                        <h4 className={!showInfo ? 'active' : ''} onClick={() => setShowInfo(false)}>Comissões do Revendedor</h4>
                    </div>
                    {showInfo &&
                        <div>
                            <ul className="retailer-info">
                                <li>
                                    <b>Nome do Revendedor:</b>
                                    <p>{retailer.name}</p>
                                </li>
                                <li>
                                    <b>Número do Revendedor:</b>
                                    <p>#{retailer.id}</p>
                                </li>
                                <li>
                                    <b>Email do Revendedor:</b>
                                    <p>{retailer.email}</p>
                                </li>
                                <li>
                                    <b>Status:</b>
                                    <p>{retailer.is_active ? "Ativo" : "Inativo"}</p>
                                </li>
                                <li>
                                    <b>Porcentagem de Comissão:</b>
                                    <p>{retailer.commission_percentage || 0 }%</p>
                                </li>
                                <li>
                                    <b>Comissão Acumulada:</b>
                                    <p>{`${getCommissionValue(retailer.commission_in_cents)}`}</p>
                                </li>
                            </ul>
                        </div>
                    }
                    {!showInfo &&
                        <div className="my-payments">
                            <div className="row">
                                <h6 className="sm-col">Número do Pagamento de Comissão</h6>
                                <h6 className="sm-col">Valor do Pagamento</h6>
                                <h6 className="md-col">Data do Pagamento</h6>
                                <h6 className="md-col">Envio de Comprovante</h6>
                                <h6 className="md-col">Download de Comprovante</h6>
                            </div>
                            <ul>
                                {commissionPayments.map((commissionPayment, key) => {return (
                                    <li key={key}>
                                        <span className="sm-col">
                                            <h6 className="responsive-header">Número do Pagamento:</h6>
                                            <p>{commissionPayment.id}</p>
                                        </span>
                                        <span className="sm-col">
                                            <h6 className="responsive-header">Valor do Pagamento:</h6>
                                            <p>{getCommissionValue(commissionPayment.payment_value_in_cents)}</p>
                                        </span>
                                        <span className="md-col">
                                            <h6 className="responsive-header">
                                                Data do Pagamento:
                                            </h6>
                                            <p>{formatDate(commissionPayment.payment_date)}</p>
                                        </span>
                                        <span className="md-col">
                                        { showPermission &&                     
                                            <Link to={{ pathname: `/manager/commission_payments/edit/${commissionPayment.id}`, state: { commissionPayment }}}>
                                                <Button className="info" format="update">Atualizar</Button>
                                            </Link>
                                        }
                                        </span>
                                        <span className="md-col download-button">
                                                <Button onClick={()=>window.open(photo.defaults.baseURL+commissionPayment.payment_receipt_url, '_blank').focus()} format="submit"><HiPrinter/>Baixar Comprovante</Button>
                                        </span>
                                    </li>
                                )})}
                            </ul>
                        </div>
                    }
                    <div className="create-button">
                        { showPermission &&                     
                                <Link to={{ pathname: `/manager/commission_payments/${retailer.id}/create`}}>
                                    <Button className="info" format="home">Atestar Novo Pagamento</Button>
                                </Link>
                        }
                    </div>

                    <div className="create-button">
                        { showPermission &&                     
                                <Link className="perc-btn" to={{ pathname: `/manager/commission_payments/edit/percentage/${retailer.id}`}}>
                                    <Button className="info" format="home">Atualizar Porcentagem de Comissão</Button>
                                </Link>
                        }
                    </div>

                    <div className="create-button">
                        { showPermission &&                     
                            <Link to={{ pathname: `/manager/retailer_reports/show/${retailer.id}`}}>
                                <Button className="info" format="home">Relatório de Revendas</Button>
                            </Link>
                        }
                    </div>
                </div>
            </Container>
        </Page>
    )
}

export default RetailerCommissionsInfo;