import {Container} from './styles'
import { useState, useEffect } from 'react'
import { api } from "../../services/api";
import Input from '../../components/Input/InputSignUp'
import MaskInput from "../../components/Input/MaskInput";
import Button from "../../components/Button";
import Select from 'react-select';
import { alert } from "../../components/Alert";
import { MdEmail, MdLock, MdPhone } from "react-icons/md";

const SignUpInformation = ({
    secondPage,
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    password_confirmation,
    setPasswordConfirmation,
    cpf,
    setCpf,
    cep,
    setCep,
    rg,
    setRg,
    street,
    setStreet,
    street_number,
    setStreetNumber,
    city,
    setCity,
    state,
    setState,
    ddd,
    setDdd,
    number,
    setNumber,
    neighborhood,
    setNeighborhood,
    birthdate,
    setBirthdate
    }) => {

    const [nameFailure, setNameFailure] = useState(false);
    const [emailFailure, setEmailFailure] = useState(false);
    const [passwordFailure, setPasswordFailure] = useState(false);
    const [passwordConfirmationFailure, setPasswordConfirmationFailure] = useState(false);
    const [cpfFailure, setCpfFailure] = useState(false);
    const [cepFailure, setCepFailure] = useState(false);
    const [rgFailure, setRgFailure] = useState(false);
    const [streetFailure, setStreetFailure] = useState(false);
    const [streetNumberFailure, setStreetNumberFailure] = useState(false);
    const [cityFailure, setCityFailure] = useState(false);
    const [stateFailure, setStateFailure] = useState(false);
    const [dddFailure, setDddFailure] = useState(false);
    const [numberFailure, setNumberFailure] = useState(false);
    const [neighborhoodFailure, setNeighborhoodFailure] = useState(false);
    const [birthdateFailure, setBirthdateFailure] = useState(false);
    const [optionStates, setOptionStates] = useState([]);

    const [states, setStates] = useState([]);

    useEffect(() => {
        api.get('states').then((response) => {
            setStates(response.data);
        })
    },[])

    useEffect(() => {
        setOptionStates(states.map((state) => {
            return { value: state.abbreviation, label: state.name }
        }))
    }, [states, state])

    const convertToDate = (s) => {
        const parts = s.split('/');
        const date = new Date(parts[2], parts[1]-1, parts[0]);

        return date
    }
    
    const checkParams = () => {

        if (name === '') {
            setNameFailure(true);
            return false;
        }
    
        if (email === '') {
            setEmailFailure(true);
            return false;
        }
    
        if (password === '') {
            setPasswordFailure(true);
            return false;
        }
    
        if (password_confirmation === '') {
            setPasswordConfirmationFailure(true);
            return false;
        }
    
        if (cpf === '') {
            setCpfFailure(true);
            return false;
        }
    
        if (cep === '') {
            setCepFailure(true);
            return false;
        }
    
        if (rg === '') {
            setRgFailure(true);
            return false;
        }
    
        if (street === '') {
            setStreetFailure(true);
            return false;
        }
    
        if (street_number === '') {
            setStreetNumberFailure(true);
            return false;
        }
    
        if (city === '') {
            setCityFailure(true);
            return false;
        }
    
        if (state === '') {
            setStateFailure(true);
            return false;
        }
    
        if (ddd === '') {
            setDddFailure(true);
            return false;
        }
    
        if (number === '') {
            setNumberFailure(true);
            return false;
        }
    
        if (neighborhood === '') {
            setNeighborhoodFailure(true);
            return false;
        }
    
        if (birthdate === '') {
            setBirthdateFailure(true);
            return false;
        }
        
        if (cpfFailure || passwordConfirmationFailure || passwordFailure || emailFailure || cepFailure || birthdateFailure) {
            return false;
        }  
    
        return true;
    }

    function onBlurCep(event) {
        const { value } = event.target;
        const cep = value?.replace(/[^0-9]/g, "");

        if (cep?.length !== 8) {
            setCepFailure(false)
            return;
        }
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json()) // Retorna o json da api viacep
        .then((data) => {
            if (data.erro)
                setCepFailure(true);
            else {
                setStreet(data.logradouro)
                setCity(data.localidade)
                setState(data.uf)
                setDdd(data.ddd)
                setNeighborhood(data.bairro)
            }
        });
  }

  const getAge = (birthdate) => {
      const birth = convertToDate(birthdate);
      const today = new Date();

      let age = today.getFullYear() - birth.getFullYear();
      const m = today.getMonth - birth.getMonth();
      if(m < 0 || (m === 0 && today.getDate() < birth.getDate()))
        age--;
      
      return age;
  }

  const validateBirth = (birthdate) => {
    if (birthdate.length > 0 && birthdate !== '__/__/____') {
        const reg = birthdate.match(/(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))/)
        const age = getAge(birthdate)
        if(reg === null){
            setBirthdateFailure(true);
            return false
        } else if(age < 18 || age > 125) {
            setBirthdateFailure(true);
            return false
        }
        else {
            setBirthdateFailure(false);
            return true
        }
    } else 
        setBirthdateFailure(false);
    return true
  }

  const validateEmail = (email) => {
    if (email.length > 0) {
      let val = email.includes(".")
      if(val){
        setEmailFailure(false);
        return true
      } else {
        setEmailFailure(true);
        return false
      }
    } else 
      setEmailFailure(false);
  }

  const validatePassword = (password) => {
    if (password.length > 0) {
      if(password.length < 6){
        setPasswordFailure(true);
        return false
      } else {
        setPasswordFailure(false);
        return true
      }
    } else 
      setPasswordFailure(false);
  }

  const validatePasswordConfirmation = (passwordConfirmation, password) => {
    if (passwordConfirmation.length > 0) {
      if(passwordConfirmation === password){
        setPasswordConfirmationFailure(false)
        return true
      } else {
        setPasswordConfirmationFailure(true)
        return false
      }
    } else
      setPasswordConfirmationFailure(false);
  }

  const validateCpf = (cpf) => {
    cpf = cpf.replaceAll('.', '').replace('-','').replaceAll('_', '')
    if (cpf.length > 0){
      var Soma;
      var Resto;
      Soma = 0;
      if (cpf === "00000000000") {setCpfFailure(true); return false}
  
      for (let i=1; i<=9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
  
      if ((Resto === 10) || (Resto === 11))  Resto = 0;
      if (Resto !== parseInt(cpf.substring(9, 10)) ) {setCpfFailure(true); return false}
  
      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;
  
      if ((Resto === 10) || (Resto === 11))  Resto = 0;
      if (Resto !== parseInt(cpf.substring(10, 11) ) ) {setCpfFailure(true); return false}
      setCpfFailure(false)
      return true;
    } else
      setCpfFailure(false);
}

    const proceedToPayment = (event) => {
        event?.preventDefault()
    
        if (checkParams()) {
          secondPage();
        } else {
          alert("Todas as informações devem ser preenchidas", 'warn')
        }
      }

    return(
    <>
        <Container>
            
            <div className='fluxo'>
                <h5><span>1. Cadastro&nbsp;</span></h5>
                <h5> -&gt; 2. Pagamento</h5>
                <h5> -&gt; 3. Confirme sua Solicitação</h5>
            </div>
            <h1>Dê play nas suas férias</h1>
            <h2>Preencha seus dados abaixo</h2>
            <form>
            <div className="sign-up-form">
                <div className="form-1">
                    <div className="sub-form-left">
                    <Input
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {setEmail(e.target.value); validateEmail(email)}}
                        failed={emailFailure}
                        failMessage="Email não é válido"
                        type="email"
                        format="email"
                        Icon={MdEmail}
                        required
                    />
                    </div>
                    <div className="sub-form-right">
                    <Input
                        placeholder="Nome Completo"
                        value={name}
                        onChange={(e) => {setName(e.target.value); setNameFailure(false)}}
                        failed={nameFailure}
                        failMessage="O campo Nome Completo não pode estar vazio"
                        format='full_name'
                        required
                    />
                    </div>
                </div>
                <div className="form-1">
                    <div className="sub-form-left">
                        <Input
                            placeholder="Senha"
                            value={password}
                            onChange={(e) => {setPassword(e.target.value); validatePassword(e.target.value)}}
                            failed={passwordFailure}
                            failMessage="Senha deve ter ao menos 6 caracteres"
                            type="password"
                            format="password"
                            Icon={MdLock}
                            required
                        />
                    </div>
                    <div className="sub-form-right">
                        <Input
                            placeholder="Confirmar Senha"
                            value={password_confirmation}
                            onChange={(e) => {setPasswordConfirmation(e.target.value); validatePasswordConfirmation(e.target.value, password)}}
                            failed={passwordConfirmationFailure}
                            failMessage="Confirme sua senha"
                            type="password"
                            format="password_confirmation"
                            Icon={MdLock}
                            required
                        /> 
                    </div>
                </div>
                <div className="form-1">
                    <div className="sub-form-left">
                    <MaskInput
                        mask='(99)'
                        placeholder='DDD'
                        name='ddd'
                        value={ddd} onChange={(e)=> {setDdd(e.target.value); setDddFailure(false)}}
                        failed={dddFailure}
                        failMessage="O campo DDD não pode estar vazio"
                        format='ddd'
                        required
                    />
                    <MaskInput
                        mask="99999-9999"
                        value={number}
                        onChange={(e) => {setNumber(e.target.value); setNumberFailure(false)}}
                        failed={numberFailure}
                        failMessage="O campo Telefone não pode estar vazio"
                        placeholder="Telefone"
                        Icon={MdPhone}
                        format="phone"
                        required
                    />
                    </div>
                    <div className="sub-form-right">
                        <MaskInput
                            mask="99999-999"
                            onBlur={onBlurCep}
                            value={cep}
                            onChange={(e) => {setCep(e.target.value)}}
                            failed={cepFailure}
                            failMessage="CEP inválido"
                            required
                            placeholder="CEP"
                            format="cep"
                        />
                        <div className="select_input_right">
                            <div className='select'>
                                <Select
                                    name='uf'
                                    options={optionStates} isSearchable placeholder="Estado"
                                    onChange={(value) => {setState(value?.value); setStateFailure(false)}}
                                    failed={stateFailure} value={state && optionStates.filter((option) => {return option.value === state})[0]}
                                    failMessage="O campo Estado não pode estar vazio"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-1">
                    <div className="sub-form-left">
                    <MaskInput
                        mask="999.999.999-99"
                        value={cpf}
                        onChange={(e) => {setCpf(e.target.value)}}
                        onBlur={() => {validateCpf(cpf)}}
                        failed={cpfFailure}
                        failMessage="CPF inválido"
                        required
                        format='cpf'
                        placeholder="CPF"
                    />
                    </div>
                    <div className='sub-form-right'>
                    <Input
                        placeholder='Cidade'
                        name='localidade'
                        value={city} onChange={(e) => {setCity(e.target.value); setCityFailure(false)}}
                        failed={cityFailure}
                        failMessage="O campo Cidade não pode estar vazio"
                        format='city'
                    />
                    <Input
                        placeholder='Bairro'
                        value={neighborhood} onChange={(e) => {setNeighborhood(e.target.value); setNeighborhoodFailure(false)}}
                        failed={neighborhoodFailure}
                        failMessage="O campo Bairro não pode estar vazio"
                        required
                        format="neighborhood"
                    />
                    </div>
                </div>
                <div className='form-1'>
                    <div className="sub-form-left">
                    <Input
                        placeholder="RG"
                        value={rg}
                        onChange={(e) => {setRg(e.target.value); setRgFailure(false)}}
                        failed={rgFailure}
                        failMessage="O campo RG não pode estar vazio"
                        required
                        format="rg"
                    />
                    <MaskInput
                        mask="99/99/9999"
                        value={birthdate}
                        onChange={(e) => {setBirthdate(e.target.value)}}
                        failed={birthdateFailure}
                        onBlur={() => {validateBirth(birthdate)}}
                        failMessage="Data inválida"
                        required
                        format='birthdate'
                        placeholder="Data de Nascimento"
                    />
                    </div>
                    <div className='sub-form-right'>
                    <Input
                        placeholder='Rua'
                        name='logradouro'
                        value={street} onChange={(e) => {setStreet(e.target.value); setStreetFailure(false)}}
                        failed={streetFailure}
                        failMessage="O campo Rua não pode estar vazio"
                        format='street'
                        required
                    />
                    <Input
                        placeholder='Número'
                        name='numero'
                        value={street_number} onChange={(e) => {setStreetNumber(e.target.value); setStreetNumberFailure(false)}}
                        failed={streetNumberFailure}
                        failMessage="O campo Número não pode estar vazio"
                        format='number'
                    />
                    </div>

                    <div className="div_button">
                        <div className="button">
                            <Button
                            className='active'
                            onClick={() => proceedToPayment()}
                            type="submit"
                            format="submit"
                            >
                                Prosseguir
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            </form>
            </Container>
    </>
    )
}



export default SignUpInformation