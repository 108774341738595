import { Container } from "./styles"
import Button from "../../components/Button"
import IndexTable from '../../components/IndexTable'
import { useHistory, useParams } from "react-router-dom"
import { Page } from '../AdminDashboard/styles.js'
import { useState, useEffect } from 'react'
import { api } from '../../services/api'
import Pagination from '../../components/Pagination'
import Input from '../../components/Input'
import Title from '../../components/Title'
import { useUserContext } from "../../components/contexts/useUserContext"

const Hotels = () => {
    const { user } = useUserContext();
    const history = useHistory()
    const {url_filter} = useParams()

    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const paginationLimit = 10;
    const [adminPermissions, setAdminPermissions] = useState([]);

    const [ filter, setFilter ] = useState(url_filter?url_filter:"")


    useEffect(() => {
        window.scrollTo(0,0)
        setOffset(paginationLimit * (page - 1))
    }, [page, offset])


    useEffect(() => {
      api.get('/permissions/index')
      .then(response => {
        let permissions = []
        response.data.forEach((permission) => {
          if (user.permissions.indexOf(permission.id) !== -1)
            permissions.push(permission.name)
        })
        setAdminPermissions(permissions)
      })
  }, [user])

  const handleUserEntry = e => {
    setPage(1)
    try{
        setFilter(e.target.value)
    } catch {
        setFilter("")
    }
}

    return (
      <Page>
        <Container>
          <Title>Hotéis</Title>
          <Input 
            label='Encontre por nome ou destino'
            type='text'
            value={filter}
            onChange={handleUserEntry}
          />
                    
          <IndexTable
              request='hotels'
              caption=''
              pagination = {false}
              offset={offset}
              adminPermissions={adminPermissions}
              setPage={setPage}
              setTotal={setTotal}
              filter={filter}
          />
          <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={total}
              pageSize={paginationLimit}
              onPageChange={currentPage => setPage(currentPage)}
          />
          { adminPermissions.indexOf('modify_tags') !== -1 &&   
            <Button format='submit' onClick={() => history.push('/hotels/create')} className='add-new'>Novo Hotel</Button>
          }
          </Container>
      </Page>
    )
}

export default Hotels
