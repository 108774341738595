import { BrowserRouter } from "react-router-dom";
import UserProvider from "./components/contexts/useUserContext";
import Routes from './Routes';
import { GlobalStyle } from "./styles/Global";
import AdminRoutes from './Routes/admin.js';
import {Alert} from './components/Alert'
import RetailerRoutes from "./Routes/retailer";
import { Footer } from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import RetailerProvider from "./components/contexts/useRetailerContext";
import CookieConsent from "react-cookie-consent";
import CookiePDF from './assets/Terms/Politica de Cookies - Vai de Ferias - 22-03-2022.pdf'
import ReactPixel from 'react-facebook-pixel';

function App() {

  const advancedMatching = { em: 'nuno@vaideferias.com.br' };
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('750416085936359', advancedMatching, options);

  ReactPixel.pageView();
  ReactPixel.track('PageView');

  return (
    <BrowserRouter>
      <CookieConsent
        buttonText="Aceitar"
        style={{
          backgroundColor: "#F1F1F1",
          fontFamily: "Poppins",
          color: "#3A3B3C",
          fontWeight: "600",
          fontSize: "1.3rem",
          textAlign: "justify",
          textJustify: "inter-word"
        }}
        buttonStyle={{
          background: "linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%)",
          fontFamily: "Poppins",
          color: "#FFF",
          fontWeight: "500",
          fontSize: "1.125rem",
          borderRadius: "0.25rem",
          padding: "1rem 1.5rem",
        }}
        expires={1}
        overlay
      >
        <p>Esse site faz uso de cookies necessários para sua correta operação e úteis para as funções definidas na política de privacidade.</p>
        <p>Ao continuar você concorda com o uso para as funções definidas.</p>
        <a href={CookiePDF} style={{color: '#000'}} download="Politica de Cookies - Vai de Ferias - 22-03-2022">Saiba mais sobre nossa política de cookies.</a>
      </CookieConsent>
      <Alert />
      <GlobalStyle />
      <UserProvider>
        <RetailerProvider>
          <ScrollToTop />
          <div className="site-content">
            <Routes />
            <AdminRoutes />
            <RetailerRoutes />
          </ div>
          <Footer />
        </RetailerProvider>
      </UserProvider>
    </BrowserRouter>
    
  );
}

export default App;
