import { useState, useEffect } from 'react'
import { Container } from "./styles"
import { api } from "../../../../services/api"
import { useLocation, useParams } from "react-router-dom"
import { Page } from "../../../AdminDashboard/styles"
import ArrowBack from '../../../../components/ArrowBack'

const ShowRetailer = () => {

    const location = useLocation()
    const {id} = useParams()

	const [retailer, setRetailer] = useState(location.state?.retailer || {})

    useEffect(() => {
		api.get(`retailers/show/${id}`)
		.then((response) => {
		setRetailer(response.data)	
		})
    }, [id, location])

	const formatDate = (dateInput) => {
		if(dateInput){
			let d = new Date(dateInput)
			let ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
			let mo = new Intl.DateTimeFormat('pt-br', { month: 'long' }).format(d);
			let da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
			return(`${da} de ${mo} de ${ye}`);
		}
		return '';
	}

    return (
		<Page>
			<Container>
				<ArrowBack/>
				<h2>{retailer?.person_type === 'company'? retailer?.fantasy_name : retailer?.name}</h2>
				<div className="info">
					<h4>{retailer?.person_type === 'company'? 'Informações da Pessoa Jurídica' : 'Informações Pessoais'}</h4>
					<p><span>Email: </span>{retailer?.email}</p>
					{ retailer?.person_type === 'company'?
						<>
							<p><span>Razão Social: </span>{retailer?.social_name}</p>
							<p><span>CNPJ: </span>{retailer?.cnpj}</p>
							<p><span>Contribuição ICMS: </span>{retailer?.icms_contribution}</p>
							<p><span>Simples Nacional: </span>{retailer?.national_simple}</p>
							<p><span>Resgistro Estadual: </span>{retailer?.state_registration}</p>
							{retailer?.state === 'DF'?
							<></>
							:
							<p><span>Resgistro Municipal: </span>{retailer?.municipal_registration}</p>
							}
						</>
					:
						<>
							<p><span>CPF: </span>{retailer?.cpf}</p>
							<p><span>RG: </span>{retailer?.rg}</p>
							<p><span>Data de Nascimento: </span>{formatDate(retailer?.birthdate)}</p>
						</>
					}
					<p><span>Telefone: </span>({retailer?.ddd}) {retailer?.phone}</p>
					<h4>Endereço</h4>
					<p><span>Cidade: </span>{retailer?.city} - {retailer?.state}</p>
					<p><span>CEP: </span>{retailer?.cep}</p>
					<p><span>Rua: </span>{retailer?.street} - {retailer?.street_number}</p>
					<p><span>Bairro: </span>{retailer?.cep}</p>
					<p><span>Endereço: </span>{retailer?.neighborhood}</p>
					<h4>Informações Bancárias</h4>
					<p><span>Número do Banco: </span>{retailer?.bank}</p>
					<p><span>Tipo de Conta: </span>{retailer?.account_type === 'savings'? "Poupança" : "Conta Corrente"}</p>
					<p><span>Número da Agência: </span>{retailer?.branch_number}-{retailer?.branch_check_digit}</p>
					<p><span>Número da Conta: </span>{retailer?.account_number}-{retailer?.account_check_digit}</p>
				</div>
			</Container>
		</Page>
    )
}

export default ShowRetailer
