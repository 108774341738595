import { useState, useEffect } from 'react'
import Button from "../../../components/Button"
import {alert} from "../../../components/Alert"
import { api } from "../../../services/api"
import { useLocation, useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import Carousel from 'react-elastic-carousel'
import { useUserContext } from "../../../components/contexts/useUserContext"
import { photo } from "../../../services/photo"
import { Info, InnerContainer, LeftSection, OutterContainer, RightSection } from '../../Hotels/showHotel/styles'
import ArrowBack from '../../../components/ArrowBack'

const RoomTypesShow = () => {
    const { user } = useUserContext();
    const location = useLocation()
    const {id} = useParams()
    const [adminPermissions, setAdminPermissions] = useState([]);
	const [roomType, setRoomType] = useState(location.state?.room_type || {})
	const [photos, setPhotos] = useState([])

	const history = useHistory()

    useEffect(() => {
		api.get(`room_types/show/${id}`)
		.then((response) => {
		setRoomType(response.data)	
		})
      }, [id, location])

	useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          let permissions = []
          response.data.forEach((permission) => {
            if (user.permissions.indexOf(permission.id) !== -1)
              permissions.push(permission.name)
          })
          setAdminPermissions(permissions)
        })
    }, [user])

    const handleSubmit = async (e) => {
      	e.preventDefault()
      	try{
          	const formData = new FormData ()

          	for(let i = 0; i < photos.length; i++){
              	formData.append('photos[]', photos[i])
          }

          	const response = await api.post(`/room_types/add_photos/${id}`, formData)

          	if(response.data){
              	alert('Fotos adicionadas', 'success');
				setRoomType(response.data)
          	}
      	}catch(error){
          	alert('Erro, tente novamente.',"error")
      }
    }

	const deleteImages = async () => {
        try{
            if(window.confirm('Você tem certeza?')){
                await api.delete(`/room_types/delete_photos/${id}`)
                history.push('/rooms')
            }
        }catch(e){
            alert('Erro, tente novamente.', 'error')
        }
    }

    return (
        <OutterContainer>
            <ArrowBack />
            <h1>Quarto - {roomType?.name}</h1>
            <InnerContainer>
                <LeftSection>

                    <Carousel className="image-container" itemsToShow={1}>
                        {roomType.images_url?.map((image, index) => {return(
                            <img src={`${photo.defaults.baseURL + image}`} alt="Tipo de quarto" key={index}/>
                        )})}
                    </Carousel>
                    <div className='actions'>
                    { adminPermissions.indexOf('modify_room_types') !== -1 &&
                    <>
                        <form onSubmit={handleSubmit}>
                            {photos.length === 0 ? <label for='file'>Selecione fotos</label> : <label for='file'>{photos.length} foto(s) selecionadas</label>}
                            <input
                                placeholder='Fotos' type='file'	multiple name='file' id='file'
                                onChange={(value) => setPhotos(value.target.files)}
                            />
                            <Button format='submit' type='submit' className='button'>Adicionar fotos</Button>
                        </form>
                        <div className='links'>
                            <Button format='submit' onClick={deleteImages} type='button'>Deletar TODAS as Fotos</Button>
                            <Button format='submit' className='submit' type='button' onClick={() => history.push(`/rooms/update/${roomType?.id}`)}>Editar</Button>
                        </div>
                    </>
                    }
                    </div>
                </LeftSection>
                <RightSection>
                   <Info>
                       <h6>
                           Descrição
                       </h6>
                       <p>
                           {roomType?.description}
                       </p>
                   </Info>
                   <Info>
                       <h6>
                           Hotel
                       </h6>
                       <p>
                           {roomType?.hotel?.name}
                       </p>
                   </Info>
                   <Info>
                       <h6>
                           Capacidade
                       </h6>
                       <p>
                           {roomType?.capacity}
                       </p>
                   </Info>
                </RightSection>
            </InnerContainer>
        </OutterContainer>
    )
}

export default RoomTypesShow