import React from 'react'
import { Container } from './styles'

const Input = ({ Icon, ...props}) => {
    return (
        <Container>
            <input
                {...props}
            />
            { Icon ? <Icon/> : null}
            {/* <Icon/> */}
        </Container>
    )
}

export default Input
