import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    padding: 2rem;
    color: #353839;

    .voltar {
        align-self: start;
        justify-self: start;
    }

    p {
        text-align: justify;
    }
`