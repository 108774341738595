import { useState, useEffect } from 'react'
import { Container } from '../Index/styles'
import Button from '../../../../components/Button'
import { api } from '../../../../services/api'
import { useHistory } from 'react-router-dom'
import { useUserContext } from '../../../../components/contexts/useUserContext'
import Pagination from '../../../../components/Pagination';
import { Page } from "../../../AdminDashboard/styles"
import { alert } from "../../../../components/Alert"


const ClientApproval = () => {
  const { user } = useUserContext()
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showPermission, setShowPermission] = useState(false)
  const [showPermission2, setShowPermission2] = useState(false)
  const [disableButton, setDisableButton] = useState(false);

  const clientReprove = (client) => {
    setDisableButton(true);
    api.patch(`clients/reprove/${client.id}`)
    alert('O cadastro do usuário ao site foi reprovado!', 'success')
    client.register_approval = false
    setClients(
      clients.map((item) => {
        return item.id === client.id ? client : item;
      })
    )
  }

  const history = useHistory()

  useEffect(() => {
    api.get('/permissions/index')
      .then(response => {
        response.data.forEach((permission) => {
          if (permission.name === 'show_clients' && user.permissions.indexOf(permission.id) !== -1)
            setShowPermission(true)
        })
      })
  }, [user])

  useEffect(() => {
    api.get('/permissions/index')
      .then(response => {
        response.data.forEach((permission) => {
          if (permission.name === 'modify_access_clients' && user.permissions.indexOf(permission.id) !== -1)
            setShowPermission2(true)
        })
      })
  }, [user])

  useEffect(() => {
    window.scrollTo(0, 0)
    let offset = (currentPage - 1) * 10
    api.get('clients/index/' + offset)
      .then((response) => {
        setClients(response.data)
      })
    api.get('clients/total_records/')
      .then((response) => {
        setTotalItems(response.data)
      })
  }, [currentPage, user])

  return (
    <Page>
      <Container>
        <h1>Aprovação de Cadastro</h1>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Email</th>
              <th>Opções</th>
              <th>Gerenciar Aprovação</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client, index) => {
              if (client.register_approval == null) {
                return (
                  <tr key={client.id}>
                    <td>{client.name}</td>
                    <td>{(client.cpf)}</td>
                    <td>{client.email}</td>
                    <td className="buttons">
                      {showPermission &&
                        <Button className="info" format="show" onClick={() => history.push(`/manager/clients/show/${client.id}`)}>Ver</Button>
                      }
                    </td>

                    <td className="buttons" style={{ minWidth: "35%" }}>
                      {showPermission2 &&
                        <Button format="update" onClick={() => history.push(`approval/${client.payment_method}/${client.id}`)}>Aprovar</Button>
                      }
                      {showPermission2 &&
                        <Button format='delete' cursor={disableButton && "wait"} onClick={() => {!disableButton && clientReprove(client)}}>Reprovar</Button>

                      }
                    </td>
                  </tr>
                )
              }else{
                return(<></>)
              }
            })}
          </tbody>
        </table>
        < div className='pagination-box'>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalItems}
            pageSize={10}
            onPageChange={page => setCurrentPage(page)}
          />
        </ div>
      </Container>
    </Page>
  )
}

export default ClientApproval