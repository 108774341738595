import styled from 'styled-components';

export const Page = styled.section`
    min-height: calc(100vh - 4.5rem); // Navbar size
    margin-left: 3rem;           // Distâncai da sidebar
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins", sans-serif;
    color: #3A3B3C;

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    @media screen and (max-width: 992px){
        margin-left: 0;
        padding: 4rem 0;
    }
`;

export const Container = styled.div`

    margin: 0 auto 0 auto;
    width: 80%;

    
    .filter {
        margin-bottom: 3rem;
        display: flex;

        > div {
            width: 30rem;
            padding: 0;
            margin: 0;
        }
    }


    table {
        width: 100%;
        border-collapse: collapse;

        thead{
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 23px;
            line-height: 4rem;
            color: #3a3b3c;
        }

        tbody{
            box-shadow: 0 0 0 1px #dadadd;
            border-radius: 15px;

            td{
                label{
                    line-height: 4rem;
                    margin: 2rem;

                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 4rem;
                    color: #3a3b3c;

                }
                
                border-bottom: 1px solid #dadadd;
            }
            

            tr:last-child{
                td{
                border-bottom: none;
            }
            }
        }

    }

    

    @media screen and (max-width: 576px){
        width: 100%;
        padding: .5rem;
    }
`;