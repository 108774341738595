import styled from "styled-components";

export const Container = styled.div`
    background-color: #04BC00;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    position: fixed;
    bottom: 0;
    max-width: 100vw;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    z-index: 100;

    .mensagem {
        display: flex;
        flex-wrap: wrap;
        max-width: 88vw;
        width: 100%;
        justify-content: left;
        align-items: center;
    }
    
    p {
        padding-left: 1rem;
        color: #fff;
        margin: 0.5rem;
    }
    
    .mobile {
        display: none;
    }
    
    a {
        text-decoration: none;
        color: #fff;
        margin: 0.5rem;
    }

    .closes {
        background-color: #04BC00;
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        bottom: 0;
        right: 0;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }

    @media(max-width: 650px) {
        svg {
            width: 20px;
        }

        p {
            font-size: 1rem;
            padding: 0;
            width: 80%;
        }

        .desktop {
            display: none;
        }
        
        .mobile {
            display: unset;
        }
        
        a {
            font-size: 1rem;
        }
    }
`
