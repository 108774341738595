import { useState, useEffect } from 'react'
import { Container } from "./styles"
import Carousel from 'react-elastic-carousel'
import { CarouselItem } from '../Items'
import { api } from "../../../services/api"
import { photo } from "../../../../src/services/photo"

const myBreakpoints = [
    {width: 550, itemsToShow: 1},
    {width: 600, itemsToShow: 1.5},
    {width: 768, itemsToShow: 2},
    {width: 890, itemsToShow: 2.5},
    {width: 1050, itemsToShow: 3},
    {width: 1295, itemsToShow: 4},
    {width: 1550, itemsToShow: 5}

]

export const CarouselGrid = () => {
    const [destinations, setDestinations] = useState([]);

    useEffect(() => {
        api.get('destinations/index_pagination/0')
        .then((response) => {setDestinations(response.data)})
    },[])
        

    return(
        <Container >
            {/* <button onClick={() => this.carousel.slidePrev()}>Prev</button>
            <button onClick={() => this.carousel.slideNext()}>Next</button> */}
            <Carousel 
                pagination={false} 
                enableAutoPlay={true}
                autoPlaySpeed={5000}
                transitionMs={1000}
                showArrows={true}
                breakPoints={myBreakpoints}
                loop={true}
                // ref={ref => (this.carousel = ref)} // Maneira de utilizar as flexas do Carousel
            >
            
                {destinations.map((destination, index) => { return(
                    <CarouselItem
                        key={index}
                        city= {destination.name}
                        state = {destination.state.name}
                        country = 'Brasil'
                        image = {destination.images_url ? `${photo.defaults.baseURL + destination.images_url[0]}` : ''}
                    />
                )})}

            </Carousel>
        </Container>
    );
}