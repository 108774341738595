import { Container } from "./styles"
import { Page } from "../styles"
import { useState, useEffect } from "react"
import RangedCalendar from "../../../components/RangedCalendar"
import Title from "../../../components/Title"
import { useUserContext } from "../../../components/contexts/useUserContext"
import ArrowBack from '../../../components/ArrowBack'
import { api } from '../../../services/api'

const RetailerReports = () => {

    const [dateRange, setDateRange] = useState(null)
    const { user } = useUserContext();
    const [packages, setPackages] = useState([])
    
    useEffect(() => {
        const params={
            retailer_id: user?.id, 
            time_range: dateRange && dateRange[0] && dateRange[1] ?
                [dateRange[0].getTime(), dateRange[1].getTime()] : null
        }


        api.get('packages/resold/index', { params: params })
            .then((response) => {
                setPackages(response.data)
            })
    }, [user, dateRange])

    return (
        <>
        <ArrowBack/>
        <Page>
            <Container>
                <Title left>Relatório de revendas</Title>
                <h5>Pesquisar por data</h5>
                <div className="filter">
                    <RangedCalendar onChange={setDateRange} value={dateRange}/>
                </div>

                <table>
                    <thead>
                        <tr>
                            <td>Dono do Pacote</td>
                            <td>Data da Compra</td>
                            <td>Comissão</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            packages.map((pack, index) => {
                                if(pack.first_payment){
                                    return(
                                        <tr key={index}>
                                            <td><label>{pack.client.name}</label></td>
                                            <td><label>{pack.creation_date}</label></td>
                                            {pack.commission? 
                                            <td><label>R$ {(pack.commission/100.0).toFixed(2).replace("." , ",")}</label></td>
                                            :
                                            <td><label>Valor não encontrado</label></td>
                                            }
                                        </ tr>
                                    )
                                }else{
                                    return(<></>)
                                }
                            })
                         }
                    </tbody>
                </table>
            </Container>
        </Page>
        </>
    )
}

export default RetailerReports
