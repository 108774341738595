import { useState, useEffect } from 'react'
import { Container } from "./styles"
import Button from "../../../../components/Button"
import { api } from "../../../../services/api"
import { useHistory, useParams } from "react-router-dom"
import Form from '../../../../components/Forms'
import {alert} from '../../../../components/Alert'
import { Page } from '../../../AdminDashboard/styles.js'
import Input from '../../../../components/Input'

const UpdateRetailerPercentage = () => {
  const history = useHistory()
  const [value, setValue] = useState(0);

  const {id} = useParams()

  const addRetailerCommission = (e) => {
    e.preventDefault();
    api.patch(`retailers/comission_percentage/update/${id}/${value}`).then((response) => {
        alert("A porcentagem foi atualizada com sucesso", 'success')
        history.push(`/manager/commission_payments/show/${id}`)
    }).catch((error => {
      alert("Não foi possível atualizar a porcentagem. Tente novamente", 'error')
    }))
  }

  useEffect(() => {
    api.get(`retailers/show/${id}`)
    .then((response) => {
      setValue(response.data.commission_percentage)
    })
}, [id])

    return (
      <Page>
        <Container>
            <h1>Atualizar Porcentagem de Comissão</h1>
            <Form
                onSubmit={addRetailerCommission}
                title='Pagamento de Comissão'
                text='Altere a procentagem de comissões que o revendedor irá receber a cada nova venda associada a ele.'
                button={
                    <>
                        <Button format='submit' type='submit'>Alterar Procentagem</Button>
                        <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
                    </>
                }
            >
              <h3>Insira a Porcentagem das Comissões do Revendedor (%)</h3>                
              <Input onChange={(e) => setValue(e.target.value)} value={value} type={'number'} min={"0"} step={"1"}></Input>
              
            </Form>
        </Container>
      </Page>
    )
}

export default UpdateRetailerPercentage
