import styled from 'styled-components';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
    text-align: center;
    color: #3A3B3C;
    margin-bottom: 5rem;
    h2 {
        font-size: 3.12rem;
        margin-bottom: 0;
        color: #3A3B3C;
        font-weight: 600;
    }
    p{
        font-size: 1.37rem;
        opacity: 0.9;
    }
    .grid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .div-wrapper-search-destination2 {
        width: 100%;
        margin: 4rem 0;
        
        .div-search-filter {
            display: flex;
            justify-content: center;
            margin: 0 15rem;
            
            .div-search-destination {
                width: 60%;
                
                label {
                    display: flex;
                    margin-left: 10px;
                    margin-bottom: -40px;
                }

                input {
                    margin-top: 20px;
                }
            }

            .div-filter-destination {
                width: 40%;

                label {
                    display: flex;
                    margin-left: 10px;
                    margin-bottom: -40px;
                }

            }
        }

        .btn-destination2 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            margin: auto;

            button {
                background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
                border-radius: 10px;
                padding: 10px 40px;
            }
        }

    }

    .not-found{
        .main-text{
            font-weight: bold;
        }
        .sub-text{
            font-size: 1rem;
        }
    }

    .btn-destination3 {
            display: flex;
            flex-direction: column;
            align-items:center;
            margin-bottom: 5%;
            justify-content:center;

            h4 {
                font-size: 2.6rem;
                width:70%;
                font-weight: 700;
            }
            button {
                background: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
                border-radius: 10px;
                font-size: 140%;
                width: 20rem;
                padding: 10px 40px;
            }
        }
    @media (max-width: 1024px) {
        .div-search-filter {
            display: flex;
            flex-direction: column;
            
            margin: 0 2rem !important;

            .div-search-destination {
                width: 100% !important;
            }

            .div-filter-destination {
                width: 100% !important;
            }
        }
        .btn-destination3 {
            width: 100%;
        }
    }

    @media (max-width: 420px) {
        .div-search-filter {
            display: flex;
            flex-direction: column;
            
            margin: 0 1rem !important;

            .div-search-destination {
                width: 100% !important;
            }

            .div-filter-destination {
                width: 100% !important;
            }
        }
        .btn-destination3 {
            width: 100%;
        }
    }

    .pagination-box{
        display: flex;
        justify-content: center;
    }

    @media (max-width: 300px) {
        .div-search-filter {
            display: flex;
            flex-direction: column;
            
            margin: 0 1rem !important;

            .div-search-destination {
                width: 100% !important;
            }

            .div-filter-destination {
                width: 100% !important;
            }
        }
        .btn-destination3 {
            width: 90%;
        }
    }


`;
export const LoadStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    width: auto;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #3A3B3C;
    p{
        font-size: 1.37rem;
        opacity: 0.9;
    }

    .main-text:before{
        content: 'Carregando';
        font-weight: bold;
        animation-name: head;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }
    @keyframes head {
        25% {content: "Carregando."}
        50% {content: "Carregando.."}
        75% {content: "Carregando..."}
        100% {content: "Carregando"}
    }
    .sub-text{
        font-size: 1rem;
    }
`;