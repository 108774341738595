import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react"

const RetailerContext = createContext();

const RetailerProvider = ({ children }) => {

    const [retailer, setRetailer] = useState(null)

    return (
        <RetailerContext.Provider value={{retailer, setRetailer}}>
            {children}
        </RetailerContext.Provider>

    )

}

export const useRetailerContext = () => {
    return useContext(RetailerContext)
}

export default RetailerProvider