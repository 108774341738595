// list must be of type [{name: "", value: ""}, {name: "", value: ""}...]
// table = "Hotel", "Destino", "Revendedor"...

import {Container} from './styles'

const RankingCard = ({title, list, table}) => {
    return(
        <Container>
            <h5>{title}</h5>
            <div className="row">
                <h6>{table}</h6>
                <h6>Reservas</h6>
            </div>
            <ol>
                {list.slice(0, 5).map((item, key) => { return(
                    <li key={key}>
                        <div className="row">
                            <span className="name">{item.name}</span>
                            <span>{item.value}</span>
                        </div>
                    </li>)
                })}
            </ol>
        </Container>
  );
}

export default RankingCard;