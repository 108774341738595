import styled from "styled-components";

export const DivColor = styled.div`
  max-width: 100vw !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  background-color: rgba(30,30,30,0.6);
  margin-bottom: 3rem;
  padding-top: 1rem;
  .disabled {
    padding-top: 1rem;
    color: red;
  }
  
  @media (max-width: 800px) {
    /* height: 100%; */
  }
` 

export const ModalBackground = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
  
  @media (max-width: 800px) {
    width: 100vw;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
  `;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  color: #3A3B3C;
  padding: 1.5rem;
  width: 70%;
  min-height: 35rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
  border: solid 5px;
  border-image-slice: 1;
  border-image-source: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%) !important;
  border-radius: 8px; /* Não funciona com o gradient */
  
  @media (max-width: 800px) {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    width: 90%;
    height: auto;
  }
  `;

export const CloseBtn = styled.button`
  font-family: Poppins;
  border-radius: 50px;
  border-color: black;
  background: white;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: black;
  position: absolute;
  top: 0%;
  right: 12%;
  font-weight: 700;
  border: solid black;
  
  @media (max-width: 800px) {
    right: 0;
    top: 2%;
    @media (max-height: 500px) and (orientation: landscape) {
      top: 2%;
      right: 1%;
    }
  }
  
  `;

export const Title = styled.h1`
  text-align: center;
  font-family: Poppins;
  font-size: 2.2rem;
  line-height: 52px;
  font-weight: 600;
  margin: 0;
  
  @media (max-width: 800px) {
    font-size: 1.6rem;
    line-height: 30px;
    height: 30px;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }
  `;

export const WrapSides = styled.div`
  display: flex;
  justify-content: center;
  align-self: flex-start;
  margin-left: 5rem;

  @media (max-width: 800px) {
    display: block;
    align-self: flex-start;
    margin: 0;
  }
`

export const LeftSide = styled.div`
align-self: flex-start;
position: relative;
width: 100%;
z-index: 10;

ul {
    font-family: Poppins;
    font-size: 1.2rem;
  }

h3 {
  font-family: Poppins;
  font-size: 1.5rem;
}
  
  @media (max-width: 800px) {
    width: 100%;
    height: 4vh;
    padding-bottom: 15rem;
    align-items: center;
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 0.8rem;
    }
  }
`;

export const ModalButton = styled.button`
  cursor: pointer;
  height: 3.4rem;
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(
    103.6deg,
    #fcc046 -13.96%,
    #cc2a55 58.95%,
    #bf1827 134.95%
  );
  width: 40%;
  margin-bottom: 2rem;
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
    color: white;
    margin: 0;
  }
  @media (max-width: 800px) {
    width: 70%;
    margin-bottom: 0rem;

    h1 {
      font-size: 1rem;
    }
  }
`;

export const InColumn = styled.div`
  display: flex;
  justify-content: center;
  gap: 5rem;
  border-bottom: 1px solid black;
  width: 55%;

  h2 {
    font-size: 1.7rem;
    font-family: Poppins;
    margin: 0;
  }

  @media (max-width: 800px) {
    gap: 2rem;
    width: 100%;

    h2 {
      font-size: 1rem;
    }
  }
`;