import styled from 'styled-components'

export const Container = styled.section`
width: 50vw;
max-width: 40rem;
margin: auto;
text-align: center;
font-family: 'Poppins', sans-serif;

h1 { font-size: 2.5rem }
p { 
    font-size: 1.25rem;
    word-wrap: break-word;
}

>div >div {
    margin: 0 0 1rem;
}

input{
    border: 1px solid #E2E4E5 ;
    padding: 0.75rem;
    border-radius: .5rem;
    width: calc(100% - 1.5rem);

    :focus {border-color: #007AFF}
}

label {
    top: 3rem;
    left: 1.5rem;
}

.div-recaptcha {
    display: flex;
    margin: 0 auto;
    justify-content: center;
}

Button {
    margin: 0;
    width: 80%;
    padding: .5rem 0;
}

@media screen and (max-width: 810px) {
    width: 95vw;
}

@media screen and (max-width: 480px) {
    h1 { font-size: 1.5rem }
    p { font-size: 1rem }
}
`;