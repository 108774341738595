import { useState, useEffect } from 'react'
import { Container } from "./styles"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import { api } from "../../../services/api"
import { useLocation, useHistory, useParams } from "react-router-dom"
import TextArea from '../../../components/TextArea'
import StyledSelect from '../../../components/Select'
import Form from '../../../components/Forms'
import {alert} from '../../../components/Alert'
import { Page } from '../../AdminDashboard/styles.js'

const EditDestination = () => {
  const history = useHistory()
  const location = useLocation()
  const [name, setName] = useState(location.state?.destination.name || '');
  const [description, setDescription] = useState(location.state?.destination.description || '');
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState({label: location.state?.destination.state.name, value: location.state?.destination.state.id} || {});
  const [options, setOptions] = useState([]);

  const {id} = useParams()

  const addDestinations = (e) => {
    e.preventDefault();
    api.patch(`destinations/update/${id}`, {
        destination: {
            name,
            description,
            state_id: stateId.value
          }
    }).then((response) => {
      response.status===200 ? alert("atualizado com sucesso", 'success') : alert("erro, tente novamente", 'error')
      history.push("/destinations")
    }).catch((error => {
      alert("erro, tente novamente", 'error')
    }))
  }

  useEffect(() => {
    api.get('states').then((response) => {
        setStates(response.data)
    })
  },[])

  useEffect(() => {
    setOptions(states.map((state) => {
      return { value: state.id, label: state.name }
    }))
  }, [states])

  useEffect(() => {
    if (!location.state) {
      api.get(`destinations/show/${id}`)
      .then((response) => {
        setName(response.data.name)
        setDescription(response.data.description)
        setStateId({label: response.data.state.name, value: response.data.state.id})
      })
    }
  }, [id, location])

    return (
      <Page>
        <Container>
            <h1>Editar Destino</h1>
            <Form
                onSubmit={addDestinations}
                title='Destino'
                text='Preencha os campos para adicionar um destino.'
                button={
                    <>
                        <Button format='submit' type='submit'>Editar destino</Button>
                        <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
                    </>
                }
            >
                <Input
                    label='Nome'
                    value={name}
                    onChange={value => setName(value.target.value)}
                />
                <TextArea
                    label='Descrição'
                    value={description}
                    onChange={value => setDescription(value.target.value)}
                />
                <StyledSelect
                    options={options}
                    label='Unidade Federativa'
                    placeholder='Selecione...'
                    defaultValue={stateId}
                    value={stateId}
                    onChange={state => setStateId(state)}
                />
            </Form>
        </Container>
      </Page>
    )
}

export default EditDestination
