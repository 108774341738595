import {Container} from './styles'

const Title = (props) =>{
  return (
    <Container {...props}>
      <h1>{props.children}</h1>
    </Container>
  )
}

export default Title