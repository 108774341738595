import { Container, Page } from "./styles"
import RangedCalendar from "../../components/RangedCalendar"
import RecordCard from "../../components/RecordCard"
import { useState, useEffect } from "react"
import RankingCard from "../../components/RankingCard"
import { api } from "../../services/api"

const AdminDashboard = () => {
    
    const [dateRange, setDateRange] = useState(null)
    const [destinationRanking, setDestinationRanking] = useState([])
    const [retailersRanking, setRetailersRanking] = useState([])
    const [hotelsRanking, setHotelsRanking] = useState([])
    const [bookingsCount, setBookingsCount] = useState(0)
    const [approvedCount, setApprovedCount] = useState(0)
    const [activePackagesCount, setActivePackagesCount] = useState(0)
    
    useEffect(() => {
        api.get("/destinations/most_booked", {
            params: {
                time_range: dateRange && dateRange[0] && dateRange[1] ? [dateRange[0].getTime(), dateRange[1].getTime()] : null
            }
        })
        .then(response => setDestinationRanking(response.data))

        api.get("/retailers/most_efficient", {
            params: {
                time_range: dateRange && dateRange[0] && dateRange[1] ? [dateRange[0].getTime(), dateRange[1].getTime()] : null
            }
        })
        .then(response => setRetailersRanking(response.data))

        api.get("/hotels/most_booked", {
            params: {
                time_range: dateRange && dateRange[0] && dateRange[1] ? [dateRange[0].getTime(), dateRange[1].getTime()] : null
            }
        })
        .then(response => setHotelsRanking(response.data))

        api.get("/bookings/total_records", {
            params: {
                time_range: dateRange && dateRange[0] && dateRange[1] ? [dateRange[0].getTime(), dateRange[1].getTime()] : null
            }
        })
        .then(response => setBookingsCount(response.data))

        api.get("/clients/approved_records", {
            params: {
                time_range: dateRange && dateRange[0] && dateRange[1] ? [dateRange[0].getTime(), dateRange[1].getTime()] : null
            }
        })
        .then(response => setApprovedCount(response.data))


        api.get("/packages/active_records", {
            params: {
                time_range: dateRange && dateRange[0] && dateRange[1] ? [dateRange[0].getTime(), dateRange[1].getTime()] : null
            }
        })
        .then(response => setActivePackagesCount(response.data))
    }, [dateRange])

    return (
        <Page>
            <Container>
                <h1>Dashboard de Administração</h1>
                <h5>Filtrar por Período</h5>
                <div className="filter">
                    <RangedCalendar onChange={setDateRange} value={dateRange}/>
                </div>
                <div className="cards">
                    <RecordCard title="Reservas no Período" value={bookingsCount}/>
                    <RecordCard title="Clientes aprovados" value={approvedCount}/>
                    <RecordCard title="Número de Pacotes Ativos" value={activePackagesCount}/>
                </div>
                <hr/>
                <div className="cards">
                    <RankingCard title="Destinos mais reservados no período"
                        table="Destino" list={destinationRanking}/>
                    <RankingCard table="Hotéis" title="Hotéis mais reservados no Período" 
                        list={hotelsRanking}/>
                    <RankingCard table="Revendedores" title="Revendedores mais eficientes no Período" 
                        list={retailersRanking}/>
                </div>
            </Container>
        </Page>
    )
}

export default AdminDashboard