import React from 'react';
import QRCode from "qrcode.react";
import { useUserContext } from '../../../components/contexts/useUserContext'
import { Container } from './style'
import Button from '../../../components/Button'
import { FiLink } from "react-icons/fi";
function QrcodegenRev() {

  const { user } = useUserContext()
  
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  
  return(  
  <Container>
    <div className='qrdiv'>
      <h1>Convide Revendedores!</h1>
      <Button onClick={downloadQRCode}>Salvar QRCode</Button>
    </div>
    <div className='qrimg'>
      <QRCode
          id="qr-gen"
          value={`https://vaideferias.com.br/retailer/sign_up/${user.id}`}
          size={200}
          level={"H"}
          includeMargin={true}
          bgColor={"transparent"}

        />
      <a href={`https://vaideferias.com.br/api/retailer/sign_up/${user.id}`}><FiLink size='14px'/> https://vaideferias.com.br/api/retailer/sign_up/{user.id}</a>
    </div>
  </Container>
  )
} 
export default QrcodegenRev