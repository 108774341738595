import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;

  background-color: #E47D3A;
  position: fixed;
  top: 0;
  padding-top: 4.5rem;
  z-index: 1;

  width: 18.75rem;
  left:-18.75rem;
  transition: 350ms;

  overflow-y: auto; // Habilita scroll-down quando o tamanho exceder.

  ${(props) => {
    if (props.active){
      return(
        "left: 0; transition: 350ms;"
      )
    } 
  }}


  .sidebar{
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    margin-top: 1rem;

   
  }

  .text-link {
    color: inherit;
    text-decoration: inherit;
  }

  .icon {
    height: 25px;
    width: 25px;
    color: #FFF;
    margin-top: 5px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .icon.close{
    height: 40px;
    width: 40px;
    &:hover{
      cursor: pointer;
    }
  }

  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 108%;
    color: #FFF;
    padding: 0.5rem 0;
  }

  .top-list span{
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
  }

  .top-list .title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    span {
      font-size: 1.25rem;
    }
  }

  .sidebar ul{
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 0.5rem 0;
    display: table;
  }

  .midle-list{
    border-style: solid none solid none;
    border-color: #FFF;
    border-width: 1px;
    
    a{
      text-decoration: none;
    }

    span{
      font-size: 1.25rem;
    }
  }

  .midle-list .icon{
    /* display: table-cell; */
    vertical-align: middle;
    height: 100%;
    width: 20px;
    margin: 0 10px 5px 0;
  }

  .bottom-list{
    span{
      font-size: 1.125rem;
    }
  }
`

export const FloatButton = styled.div`

  &:hover{
    cursor:pointer;
  }
	position:absolute;
	width:60px;
	height:60px;
	top:5rem;
	left:2.5rem;
	background-color:#E47D3A;
	color:#FFF;
	border-radius:50%;
	text-align:center;
	box-shadow: 2px 2px 3px #999;


  .icon{
    margin-top:18px;
    height: 20px;
    width: auto;
  }

  @media screen and (max-width: 576px) {
    left: 2vw;
  }
`