import ArrowBack from "../../components/ArrowBack";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { Container } from "../PasswordRecovery/styles"
import { api } from "../../services/api";
import { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MdLock } from "react-icons/md";
import ReCAPTCHA from "react-google-recaptcha";
import { alert } from "../../components/Alert"

const PasswordNew = () => {
    const history = useHistory();
    const {email, token} = useParams();

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [disableButton, setDisableButton] = useState(false);
    const [passwordFailure, setPasswordFailure] = useState(false);
    const [passwordConfirmationFailure, setPasswordConfirmationFailure] = useState(false);
    const [authenticationToken, setAuthenticationToken] = useState(token)

    const siteKey = "6Lene0sfAAAAAG6iDiPbCGQMy3m4qiYKbypE5R4X"
    const recaptchaRef = useRef(null)
    const [captcha, setCaptcha] = useState(false);

    const onChange = () => {
        if (recaptchaRef.current.getValue()) {
        setCaptcha(true)
        }
    }

    const handleSubmit = async () => {
        setDisableButton(true);
        if (captcha){
            let request = '/clients';
            let path = '';
            if (window.location.pathname.startsWith('/admin')){
                request = '/admins';
                path = '/admin';
            }
            else if (window.location.pathname.startsWith('/revendedor')){
                request = '/retailers';
                path = '/revendedor'
            }
            try {
                const response = await api.post(`${request}/password_reset`, {
                    email,
                    token: authenticationToken,
                    password,
                    password_confirmation: passwordConfirmation
                })
                if (response.data) {
                    alert("Sua senha foi alterada com sucesso!", 'success');
                    history.push(`/login${path}`);
                }
            } catch (error) {
                alert('Não foi possível alterar sua senha. Confira o e-mail e token inseridos e tente novamente.', 'error')
            }
        } else 
            alert('Confirme que você não é um robô!', 'error');
        setDisableButton(false)
    }

    const validatePassword = (password) => {
        if (password.length > 0) {
          if(password.length < 6){
            setPasswordFailure(true);
            return false
          } else {
            setPasswordFailure(false);
            return true
          }
        } else 
           setPasswordFailure(false);
    }

    const validatePasswordConfirmation = (passwordConfirmation, password) => {
        if (passwordConfirmation.length > 0) {
          if(passwordConfirmation === password){
            setPasswordConfirmationFailure(false)
            return true
          } else {
            setPasswordConfirmationFailure(true)
            return false
          }
        } else
            setPasswordFailure(false);
    }

    return (
        <>
            <ArrowBack/>
            <Container>
                <h1>Recupere sua Senha</h1>
                <p>
                    Insira o token enviado para o seu e-mail (<b>{email}</b>) e escolha sua nova senha
                </p>
                <Input fullBorder className="full-border-input" 
                    value={authenticationToken}
                    onChange={(e) => setAuthenticationToken(e.target.value)}
                    label="Token de Recuperação"
                />
                <Input
                    label="Nova Senha"
                    value={password}
                    onChange={(e) => {setPassword(e.target.value); validatePassword(e.target.value) && validatePasswordConfirmation(passwordConfirmation, e.target.value)}}
                    failed={passwordFailure}
                    failMessage="Senha deve ter ao menos 6 caracteres"
                    type="password"
                    format="password"
                    Icon={MdLock}
                    required
                />
                <Input
                    label="Confirmar Senha"
                    value={passwordConfirmation}
                    onChange={(e) => {setPasswordConfirmation(e.target.value); validatePasswordConfirmation(e.target.value, password)}}
                    failed={passwordConfirmationFailure}
                    failMessage="As senhas não coincidem"
                    type="password"
                    format="password_confirmation"
                    Icon={MdLock}
                    required
                />
                <div className="div-recaptcha">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={siteKey}
                        onChange={onChange}
                    />
                </div>
                <Button 
                    cursor={disableButton && "wait"} 
                    onClick={() => {
                        !disableButton && !passwordFailure && !passwordConfirmationFailure && handleSubmit()}
                    }
                    format="submit">
                        Confirmar Senha
                </Button>
            </Container>
        </>
    )
}

export default PasswordNew;