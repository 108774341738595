import { useState } from "react"
import { Container, InputContainer } from "./styles"

const InputSignUp = ({ Icon, format, failed, failMessage, label, ...props}) => {

    const [isFocus, setIsFocus] = useState(false)
    const [isFilled, setIsFilled] = useState(false)
    const [hasBlured, setHasBlured] = useState(false)

    const handleOnFocus = () => {
        setIsFilled(false)
        setIsFocus(true)
    }

    const handleOnBlur = (event) => {
        setIsFilled(!!event.target.value)
        setIsFocus(false)
        setHasBlured(true)
    }


    return (
        <Container>
            <InputContainer isFocus={isFocus} isFilled={isFilled} failed={failed ? failed && hasBlured : failed} format={format}>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    {...props}
                    required
                />
                
                <label>{label} {(Icon) ? (<Icon size={18} />) : (<></>)}</label>
            </InputContainer>
            {(failed && hasBlured) && <span>{failMessage}</span>}
        </Container>
    )
}

export default InputSignUp