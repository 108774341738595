import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const config_alert = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
}

export const alert = (message, type='default') => {
  if (type === 'warn'){
    toast.warn(message, config_alert);
  }else if(type === 'success'){
    toast.success(message, config_alert);
  }else if (type === 'error'){
    toast.error(message, config_alert);
  }else if (type === 'info'){
    toast.info(message, config_alert);
  }else{ 
    toast(message, config_alert);
  }
}
export const Alert = () => {

  return (
      <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
  );
}

export default Alert