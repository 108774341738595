import styled from "styled-components";

export const Container = styled.tr`
    border-bottom: 1px solid #CBCBCB;

    td {
        text-align: center;
        vertical-align: middle;
        padding: 0.5rem;

        div.scroll {
            line-height: 4rem;
            height: 4rem;
            text-align: center;
            overflow-y: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
        }

        div.scroll::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        div.scroll p {
            line-height: 1.5;
            display: inline-block;
            vertical-align: middle;
        }
        
        img {
            height: 10rem;
            width: 15rem;
            object-fit: cover;
        }
    }

    .buttons {
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }

    .permissions {
        width: 15rem;
    }

    @media screen and (max-width: 1024px){
        td{
            max-width: 95%;
            min-width: 1rem !important;
            div.scroll {
                text-align: right;
            }
        }

        .buttons {
            text-align: center;

            Button {
                font-size: 0.8rem;
                width: 4rem;
                padding: 0;
            }
        }

        .permissions {
            width: 15rem !important;
            height: 2rem !important;
        }
    }
`