import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    margin: auto;
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    display:flex;
    
    

    ${props => props.left? 
    'justify-content: flex-start; margin-left:0;'  
    : 
    'justify-content: center;' 
    }
    

    h1 {
        font-size: 3.125rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }

    @media screen and (max-width: 1024px){
        width: 90%;

        h1{
            font-size: 1.8rem;
        }
    }

    @media screen and (max-width: 576px) {
        h1 {
            font-size: 1.6rem;
        }
    }
`