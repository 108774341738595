import { Container } from "./styles"
import { PDFDownloadLink } from '@react-pdf/renderer';
import Voucher from '../../components/Voucher'
import Logo from '../../assets/vdf_logo-black.png'
import Button from '../../components/Button'
import { useParams, useHistory } from "react-router-dom"
import { useEffect, useState } from "react";
import {alert} from '../../components/Alert'
import { api } from "../../services/api"

const verifyVoucher = async (info, id, setter, history) => {
    await api.get(`bookings/verify_booking/${id}/${info}`).then((response) => {
      if(response.status===200){
        setter(response.data)
        return true
      }
      else{
        return false
      }
    }).catch((error) => {
      alert("Não conseguimos achar seu voucher. Por favor, o acesse pela sua página de perfil.", 'error')
      history.push('')
    })
}

const getInfo = (base64, setter, history) => {
  try{
    const string = window.atob(base64)
    const data = JSON.parse(string)
    const response = verifyVoucher(base64, data.numero_reserva, setter, history)
    if(response === false){
      alert("Não conseguimos validar seu voucher. Por favor, o acesse pela sua página de perfil.", "error")
      history.push('')
    }
  }
  catch{
    alert("Não conseguimos encontrar seu voucher. Por favor, o acesse pela sua página de perfil.", "error")
    history.push('')
  }
}

const VoucherPage = () => {
  const [data, setData] = useState({})

  const history = useHistory()
  const { info } = useParams()

  useEffect(() => {
    getInfo(info, setData, history);
  }, [info, history])

    return (
        <Container>
          <h1>O voucher da sua viagem está aqui!</h1>
                <p>
                    O documento com todas as informações da sua viagem está pronto para ser baixado.
                    <br></br>
                    Você também pode encontrá-lo na sua página de perfil após fazer login na plataforma.
                </p>

          {data?
            <Button format='home'>
            <PDFDownloadLink style={{color: 'white'}}document={<Voucher 
              logo = {Logo} 
              nome_hotel = {data.nome_hotel} 
              cep_hotel = {data.cep_hotel}
              numero_hotel = {data.numero_hotel} 
              bairro_hotel = {data.bairro_hotel}
              rua_hotel = {data.rua_hotel}
              mapa_hotel = {data.mapa_hotel} 
              numero_reserva = {data.numero_reserva} 
              nome_hospede = {data.nome_hospede} 
              numero_adulto = {data.numero_adulto} 
              numero_crianca = {data.numero_crianca} 
              data_de_chegada = {data.data_de_chegada} 
              data_de_partida = {data.data_de_partida} 
              tipo_de_quarto = {data.tipo_de_quarto} 
              data_da_reserva = {data.data_da_reserva} 
              observacoes = {data.observacoes} 
              informacoes_gerais = {data.informacoes_gerais} 
              telefone = {data.telefone} 
              email = {data.email} 
            />} fileName="voucher.pdf">
              {({ blob, url, loading, error }) =>
                loading ? 'Carregando documento...' : 'Download do Voucher'
              }
            </PDFDownloadLink>
            </Button>
          :
          <></>
          }
        </Container>
    )
}

export default VoucherPage
