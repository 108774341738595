import { useHistory, useParams } from "react-router-dom"
import Button from "../../../components/Button"
import IndexTable from "../../../components/IndexTable"
import { Page } from "../../AdminDashboard/styles"
import { Container } from "./styles"
import { useState, useEffect } from 'react'
import { api } from '../../../services/api'
import Pagination from '../../../components/Pagination'
import Input from '../../../components/Input'
import Title from '../../../components/Title'
import { useUserContext } from "../../../components/contexts/useUserContext"

const RoomTypesIndex = () => {
    const { user } = useUserContext();
    const history = useHistory();
    const {url_filter} = useParams()

    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const paginationLimit = 10;
    const [adminPermissions, setAdminPermissions] = useState([]);

    const [ filter, setFilter ] = useState(url_filter?url_filter:"")



    useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          let permissions = []
          response.data.forEach((permission) => {
            if (user.permissions.indexOf(permission.id) !== -1)
              permissions.push(permission.name)
          })
          setAdminPermissions(permissions)
        })
    }, [user])

    useEffect(() => {
        window.scrollTo(0,0)
        setOffset(paginationLimit * (page - 1))
    }, [page])

    const handleUserEntry = e => {
        setPage(1)
        try{
            setFilter(e.target.value)
        } catch {
            setFilter("")
        }
    }


    return(
        <Page>
            <Container>
            <Title>Tipos de Quartos</Title>
            <Input 
                label='Encontre por nome ou hotel'
                type='text'
                value={filter}
                onChange={handleUserEntry}
            />
                <IndexTable
                    request='room_types'
                    caption=''
                    pagination = {false}
                    offset={offset}
                    adminPermissions={adminPermissions}
                    setPage={setPage}
                    setTotal={setTotal}
                    filter={filter}
                />
                <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={total}
                    pageSize={paginationLimit}
                    onPageChange={currentPage => setPage(currentPage)}
                />
                { adminPermissions.indexOf('modify_room_types') !== -1 &&   
                    <Button format='submit' onClick={() => history.push('/rooms/create')} className='add-new'>Novo Tipo de Quarto</Button>
                }
            </Container>
        </Page>
    )
}

export default RoomTypesIndex