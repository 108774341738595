import { useState, useEffect } from 'react'
import { api } from '../../../services/api'
import { Link, useLocation, useParams } from 'react-router-dom';
import { photo } from "../../../services/photo"
import { Container, LoadStyle } from './styled';
import { CarouselItem } from '../../../components/Carousel/Items'
import { useUserContext } from "../../../components/contexts/useUserContext"
import ModalLogin from "../../../components/ModalLogin";

import ArrowBack from '../../../components/ArrowBack';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import StyledSelect from '../../../components/Select';
import Pagination from '../../../components/Pagination'

const Destination2 = () => {

    const {categoryId} = useParams()
    let location = useLocation()

    const [openModal, setOpenModal] = useState(false);

    const { user } = useUserContext();

    const [ destinations, setDestination ] = useState([])
    const [ filteredDestinations, setFilteredDestinations ] = useState([])
    const [ states, setStates ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ choosenState, setChoosenState ] = useState(-1)
    const [ choosenName, setChoosenName ] = useState("")
    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 9

    useEffect(() => {
        const loadDestination = async () => {

            await api.get('destinations/index', { params: {category_id: categoryId}})
            
            .then((response) => {
                setDestination(response.data)
                setFilteredDestinations(response.data)
                setLoading(false)
            })
    
            .catch(() => {
                return <LoadStyle><h1>Algo de errado aconteceu. Tente novamente!</h1></LoadStyle>
            })
    
        };

        const loadState = async () => {

            await api.get('states')
            
            .then((response) => {
                const options = []
                response.data.forEach((state)=>{
                    options.push({value: state.id, label: state.name})
                })
                setStates(options)
            })
        };

        loadDestination();
        loadState();
    }, [categoryId])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage])

    if (loading) {
        return <LoadStyle>
            <p className='main-text'></p>
            <p className='sub-text'>Aguarde enquanto os destinos são carregados</p>
        </LoadStyle>
    }

    const handleStateChange = e => {
        try{
            setChoosenState(e.value)
        } catch {
            setChoosenState(-1)
        }
        
    }

    const handleNameChange = e => {
        try{
            setChoosenName(e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase())
        } catch {
            setChoosenName("")
        }
    }

    const isSimilar = (userEntry, dbEntry) => {
        let dbEntryNormalized = dbEntry.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
        if (dbEntryNormalized === userEntry || dbEntryNormalized.startsWith(userEntry)){
            return true
        } else{
            let dbArray = dbEntryNormalized.split(" ")
            for (var i = 0; i< dbArray.length; i++){
                if (dbArray[i] === userEntry || dbArray[i].startsWith(userEntry)){
                    return true
                }
            }
            return false
        }
    }


    
    const filter = async () => {
        setFilteredDestinations([])
        if (choosenState !== -1 && choosenName !== ""){

            destinations.forEach((destination) => {
                if(destination.state.id === choosenState && isSimilar(choosenName, destination.name)){
                    setFilteredDestinations(oldArray => [...oldArray, destination])
                }
            })

        } else if (choosenState === -1 && choosenName !== ""){

            destinations.forEach((destination) => {
                if(isSimilar(choosenName, destination.name)){
                    setFilteredDestinations(oldArray => [...oldArray, destination])
                }
            })

        } else if (choosenState !== -1 && choosenName === ""){
            
            destinations.forEach((destination) => {
                if(destination.state.id === choosenState){
                    setFilteredDestinations(oldArray => [...oldArray, destination])
                }
            })

        }  else {
            setFilteredDestinations(destinations)
        }
        setCurrentPage(1)
    }

    return (
        <>
            <ArrowBack to="/vacation"/>
            <Container>
                <h2>Qual é o seu destino favorito?</h2>
                <p>Escolha um dos nossos <b>destinos incríveis</b> e faça sua reserva.</p>
                <div className="div-wrapper-search-destination2">
                    <div className="div-search-filter">
                        <div className="div-search-destination">
                            <label>Pesquisar por Nome</label>
                            <Input placeholder="Buscar destino" type='text' onChange={handleNameChange}/>
                        </div>
                        <div className="div-filter-destination">
                            <label>Pesquisar por Estado</label>
                            <StyledSelect id='select-state' className="style-select-destination" placeholder="Estado" options={states} onChange={handleStateChange}/>
                        </div>
                    </div>
                    <div className="btn-destination2">
                        <Button format="submit" onClick={filter}>Filtrar Destinos</Button>
                    </div>
                </div>
                <div className='grid'>
                    {(filteredDestinations.length > 0 && user)? 
                    filteredDestinations.slice((currentPage - 1)*pageSize, Math.min(filteredDestinations.length, currentPage*pageSize)).map((destination) => 
                            ( 
                            <Link to={{ pathname: `/vacation/city/${categoryId}/${destination.id}`, category_id: location.category_id}}>
                                <CarouselItem
                                    key={destination.id}
                                    city= {destination.name}
                                    state = {destination.state.name}
                                    country = 'Brasil'
                                    image={destination.images_url ? `${photo.defaults.baseURL + destination.images_url}` : '' }
                                />
                            </Link>
                            )
                        )
                    : (filteredDestinations.length > 0)?
                    filteredDestinations.slice((currentPage - 1)*pageSize, Math.min(filteredDestinations.length, currentPage*pageSize)).map((destination) => 
                            ( 
                            <Link to="#" onClick={() => {setOpenModal(true); }}>
                                <CarouselItem
                                    key={destination.id}
                                    city= {destination.name}
                                    state = {destination.state.name}
                                    country = 'Brasil'
                                    image={destination.images_url ? `${photo.defaults.baseURL + destination.images_url}` : '' }
                                />
                            </Link>
                            )
                        )
                    :
                    <div className='not-found'>
                        <p className='main-text'>Nenhum destino encontrado!</p>
                        <p className='sub-text'>Tente novamente com outros parâmetros de busca</p>
                    </div>
                    }
                </div>
                < div className = 'pagination-box'>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={filteredDestinations.length}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
                </div>
            </Container>
            {openModal && <ModalLogin closeModal={setOpenModal}/>}
        </>
    );
}

export default Destination2;