import styled from 'styled-components'

export const Container = styled.div`

  min-height: 12rem;
  width: 30rem;
  border: 2px solid #0D0C22;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: space-between;

  h6 {
    font-size: 1.5rem;
    margin: 0;
  }

  span {
    font-size: 4rem;
    font-weight: 700;
    align-self: flex-end;
  }

  @media screen and (max-width: 700px){

    width: 100%;
    min-height: 8rem;

    h6 {
      font-size: 1.2rem;
    }

    span {
      font-size: 2.5rem;
    }
  }
  
  @media screen and (max-width: 320px){
    h6 {
      font-size: .9rem;
    }

    span {
      font-size: 1.5rem;
    }
  }
`;