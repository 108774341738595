import styled from 'styled-components';

export const Container = styled.div`
    
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    align-items: stretch;

    .div-text-card-tutorial {
        color: #3a3b3c;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        font-family: Poppins;
        width: 26rem;
        height: 100%;
        gap: 1rem;

        .title {
            display: flex;
            align-items: center;
            margin-left: -2rem;

            span {
                color: #fff;
                font-size: 1.1rem;
                font-weight: 500;
                padding: .5rem 1.5rem;
                border-radius: 5px;
                width: 95%;
            }

            h4 {
                margin: 0;
                font-size: 2rem;
                font-weight: 500;
                width: 5%;
                padding: 0 1rem;
            }
        }
        
        .border-left {
            padding-left: 1rem;
            border-left: 1px solid rgba(0, 0, 0, 0.4);
            height: 100%;
        }
        
        ul {
            li {
                color: #3a3b3c;
                font-size: 1.0em;
                font-weight: 300;
            }
        }
        
        img {
            width: 25rem;
            height: 13rem;
            object-fit: cover;
            object-position: top;
            border-radius: 10px;
            border: 0.5px solid #c4c4c4;
            border-radius: 10px;
        }
        @media (max-width: 480px){
            width: 90vw;

            .title {
                margin-left: -1rem;
                
                span {font-size: 1rem}
            }
    
            img{
                width: 100%;
                height: 10rem;
            }

            .border-left {
                padding-left: 0;
                border-left: 0;
            }
        }
    }

    @media (max-width: 992px){
        align-items: center;
    }

`;
