import { Container } from "./styles"
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { pt } from 'date-fns/locale';


export const Calendar = (props) => {
  
  
 return(
  <Container >
    <DateRange
      locale={ pt }
      editableDateInputs={true}
      onChange={item => props.setState([item.selection])}
      moveRangeOnFirstSelection={false}
      ranges={props.state}
      {...props}
    />
  </Container>
 );
}