import ArrowBack from "../../components/ArrowBack";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { Container } from "./styles"
import { api } from "../../services/api";
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { alert } from "../../components/Alert"

const PasswordRecovery = () => {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    const siteKey = "6Lene0sfAAAAAG6iDiPbCGQMy3m4qiYKbypE5R4X"
    const recaptchaRef = useRef(null)
    const [captcha, setCaptcha] = useState(false);

    const onChange = () => {
        if (recaptchaRef.current.getValue()) {
        setCaptcha(true)
        }
    }

    const handleSubmit = async () => {
        setDisableButton(true);
        if (captcha){
            let request = '/clients';
            let path = '/';
            if (window.location.pathname.startsWith('/admin')){
                request = '/admins';
                path = '/admin/';
            }
            else if (window.location.pathname.startsWith('/retailer')){
                request = '/retailers';
                path = '/retailer/'
            }
            try {
                const response = await api.post(`${request}/password_recovery`, {
                    email
                })
                if (response.data) {
                    alert("Uma menasgem de recuperação de senha foi enviada ao seu e-mail.", 'success')
                    history.push(`${path}recovery/${email}`)
                }
            } catch (error) {
                alert('Não foi possível recuperar senha. Confira o e-mail inserido e tente novamente.', 'error')
            }
        } else
            alert('Confirme que você não é um robô!', 'error');
        setDisableButton(false)
    }

    return (
        <>
            <ArrowBack/>
            <Container>
                <h1>Recupere sua Senha</h1>
                <p>
                    Insira seu e-mail cadastrado no campo abaixo para receber <br/> 
                    um e-mail com informações para cadastrar uma nova senha.
                </p>
                <Input fullBorder className="full-border-input" label="E-mail cadastrado"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="div-recaptcha">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={siteKey}
                        onChange={onChange}
                    />
                </div>
                <Button cursor={disableButton && "wait"} onClick={() => !disableButton && handleSubmit()} format="submit">Enviar</Button>
            </Container>
        </>
    )
}

export default PasswordRecovery;