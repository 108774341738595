
import {Link} from "react-router-dom";
import { useUserContext } from "../contexts/useUserContext.js";

import { FaHome, FaBook, FaReceipt } from 'react-icons/fa'
import { HiDocumentReport } from 'react-icons/hi'
import { ImQrcode } from 'react-icons/im'

import { Container, FloatButton } from './styles'
import { RiSideBarFill, RiCloseFill, RiFileDownloadFill, RiFileDownloadLine } from 'react-icons/ri'
import { useState } from 'react'

import ClientPDF from '../../assets/Terms/Regimento Interno Vai de Ferias - 29032022.pdf'
import PjPDF from '../../assets/Terms/Contrato Revendedor PJ - Vai de Ferias - 17032022.pdf'
import PfPDF from '../../assets/Terms/Contrato Revendedor PF - Vai de Ferias - 17032022.pdf'

export const Sidebar = () => {
  const { user } = useUserContext();
  const [sidebar, setSidebar] = useState(true);

  const showSidebar = () => {
    setSidebar(!sidebar)
  }

  return (
    <>
    <FloatButton onClick={showSidebar}>
      <RiSideBarFill className='icon'/>
    </FloatButton>
    <Container active={sidebar}>
      <div className='sidebar'>
        <RiCloseFill className='icon close' onClick={showSidebar}/>
        <div className='top-list'>
          <span>Usuário Logado:</span>
          <span>{user.name}</span>
          <Link to='/dashboard' className="text-link">
          <div className='title'>
            <FaHome className='icon'/>
            <span>Página Inicial</span>
          </div>
          </Link>
        </div>
        <div className='midle-list'>
          <ul>
            <Link to='/dashboard/guia' className="text-link">
              <li><FaBook className='icon'/><span>Guia do Revendedor</span></li>
            </Link>
            <Link to='/dashboard/reports' className="text-link">
              <li><HiDocumentReport className='icon'/><span>Relatório de Revenda</span></li>
            </Link>
            <Link to='/dashboard/commission' className="text-link">
              <li><FaReceipt className='icon'/><span>Recibos de Comissões</span></li>
            </Link>
            {user && user.is_active === true ? (
              <>
            <Link to='/QRCode/user' className="text-link">
              <li><ImQrcode className='icon'/><span>Convidar Usuários</span></li>
            </Link>
            <Link to='/QRCode/retailer' className="text-link">
              <li><ImQrcode className='icon'/><span>Convidar Revendedores</span></li>
            </Link>
            </>
            ) : (
              ""
            )}
          </ul>
        </div>
        <div className='midle-list'>
          <ul>
            {user && user.person_type === 'individual' ? (
              <>
            <a href={PfPDF} download="CONTRATO_REVENDEDOR_PF">
              <li><RiFileDownloadFill className='icon'/><span>Contrato de Revendedor</span></li>
            </a>
            </>
            ) : (
              <a href={PjPDF} download="CONTRATO_REVENDEDOR_PJ">
                <li><RiFileDownloadFill className='icon'/><span>Contrato de Revendedor</span></li>
              </a>
            )}
            <a href={ClientPDF} download="REGIMENTO-INTERNO_VAI-DE-FERIAS">
              <li><RiFileDownloadLine className='icon'/><span>Termos do Cliente</span></li>
            </a>
          </ul>
        </div>
      </div>
    </Container>
    </>
  )
}

export default Sidebar