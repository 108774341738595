import { Container } from './styled'

const CardHowItWork = ({title, description, image, titleColor, id}) => {

    return (
        <Container>
            <div className="div-text-card-tutorial">
                <div className='title'>
                    <span style={{backgroundColor: titleColor}}>{title}</span>
                    <h4 style={{color: titleColor}}>{id}</h4>
                </div>
                <div className='border-left'>
                    <img src={image} alt="Imagem tutorial" />
                    <ul>
                        {description.map((element, id) => (
                            <li key={id}>{element}</li>
                        ))}
                    </ul>
                </div>
            </div>

        </Container>
    )
}

export default CardHowItWork
