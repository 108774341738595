import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vh 0;
    width: 100%;
    * {
        font-family: 'Roboto', sans-serif;
    }
    h1 { 
        font-family: 'Poppins', sans-serif;
        font-size: 3rem;
    }
    table{
        width: 90%;
        border-collapse: collapse;
        text-align: center;
    }
    thead {
        font-weight: bold;
        color: #53535F;
        border-bottom: 1px solid #CBCBCB;
        font-size: 1.125rem;
        th {
        padding-bottom: 1.5rem;
        }
    }
    tr{   
        border-bottom: 1px solid #CBCBCB;
        td {
        padding: 2.5rem 1rem 1rem;
        height: 5rem;
        Button {
            color: #3A3B3C;
            min-width: 8rem;
            font-weight: normal;
            margin: 0 1.25rem;
        }
        .info{
            margin-bottom: 1rem;
        }
        }
    }
    a {
        text-decoration: none;
    }
@media all and (max-width: 576px) {
    h1 {
    font-size: 1.8rem;
    }
    table {
    width: 95%;
    tr th {
        display: none;
    }
    td {
        float: left;
        min-width: 90%;
        width: 90%;
        height: unset;
        padding: 1rem 0;
        font-size: .8rem;
        Button {
        min-width: 6rem;
        font-size: .9rem;
        }
    }
    }
    .add-button {
        width: 90vw;
        font-size: 1rem;
        }
}
.pagination-box{
        display: flex;
        justify-content: center;
    }
`;