import { useUserContext } from "../../../components/contexts/useUserContext"
import { Container, ProfileCard, LogoutCard } from "../../ClientProfile/styles.js"
import UserPlaceholder from '../../../assets/user-placeholder.png'
import Button from '../../../components/Button'
import { Link } from "react-router-dom"
import { photo } from "../../../../src/services/photo"

function RetailerProfile() {
    const { user, logout } = useUserContext()

    const formatDate = (dateInput) => {
        let year = dateInput.slice(0,4)
        let month = dateInput.slice(5,7)
        let day = dateInput.slice(8,10)
        return(`${day}/${month}/${year}`);
    }

    const formatAccount = (account_type) => {
        if (account_type === "checking"){
            return("Conta Corrente")
        }else if (account_type === "savings"){
            return("Poupança")
        }
    }

    return (
        <>        
        <Container>
            <div className='title'>
                <h1>Meu perfil de Revendedor</h1>
                <h2>Olá, <span>{user? user.name:''}!</span> </h2>
            </div>
            <ProfileCard>
                <div className='client-info'>
                    <div className='profile-pic'>
                        <img alt="Foto de Perfil" src={user.retailer_image_url? `${photo.defaults.baseURL + user.retailer_image_url}`: UserPlaceholder} />
                    </div>
                </div>

                <p className='sub-title'>Informações Pessoais</p>
                { user.person_type === 'individual' ?
                    <div className="account">
                        <div className='client-account'>
                            <p>Nome</p>
                            <span>{user.name}</span>
                        </div>
                        <div className='client-account'>
                            <p>E-mail</p>
                            <span>{user.email}</span>
                        </div>

                        <div className='client-account'>
                            <p>CPF</p>
                            <span>{user.cpf}</span>
                        </div>
                        <div className='client-account'>
                            <p>RG</p>
                            <span>{user.rg}</span>
                        </div>
                        <div className='client-account'>
                            <p>Data de Nascimento</p>
                            <span>{user.birthdate ? formatDate(user.birthdate) : ''}</span>
                        </div>
                        <div className='client-account'>
                            <p>Telefone</p>
                            <span>({user.ddd}) {user.phone}</span>
                        </div>
                    </div>
                : 
                    <div className="account">
                        <div className='client-account'>
                            <p>CNPJ</p>
                            <span>{user.cnpj}</span>
                        </div>
                        <div className='client-account'>
                            <p>Contribuição ICMS</p>
                            <span>{user.icms_contribution}</span>
                        </div>

                        <div className='client-account'>
                            <p>Razão Social</p>
                            <span>{user.social_name}</span>
                        </div>
                        <div className='client-account'>
                            <p>Nome Fantasia</p>
                            <span>{user.fantasy_name}</span>
                        </div>

                        <div className='client-account'>
                            <p>Simples Nacional</p>
                            <span>{user.national_simple ? "Sim" : "Não"}</span>
                        </div>

                        <div className='client-account'>
                            <p>Inscrição Estadual</p>
                            <span>{user.state_registration}</span>
                        </div>
                        <div className='client-account'>
                            <p>Inscrição Municipal</p>
                            <span>{user.municipal_registration? user.municipal_registration : "Não consta"}</span>
                        </div>
                        <div className='client-account'>
                            <p>Telefone</p>
                            <span>({user.ddd}) {user.phone}</span>
                        </div>
                    </div>
                }
                
                <p className='sub-title'>Informações Bancárias</p>
                <div className="account">
                    <div className='client-account'>
                        <p>Tipo de conta</p>
                        <span>{formatAccount(user.account_type)}</span>
                    </div>
                    <div className='client-account'>
                        <p>Banco</p>
                        <span>{user.bank}</span>
                    </div>

                    <div className='client-account'>
                        <p>Agência</p>
                        <span>{user.branch_number}-{user.branch_check_digit}</span>
                    </div>

                    <div className='client-account'>
                        <p>Conta</p>
                        <span>{user.account_number}-{user.account_check_digit}</span>
                    </div>
                </div>

                <p className='sub-title'>Endereço</p>
                <div className="account">
                    <div className='client-account'>
                        <p>Cidade</p>
                        <span>{user.city}</span>
                    </div>
                    <div className='client-account'>
                        <p>Estado</p>
                        <span>{user.state}</span>
                    </div>

                    <div className='client-account'>
                        <p>Bairro</p>
                        <span>{user.neighborhood}</span>
                    </div>
                    <div className='client-account'>
                        <p>Rua</p>
                        <span>{user.street}, {user.street_number}</span>
                    </div>
                    <div className='client-account'>
                        <p>CEP</p>
                        <span>{user.cep}</span>
                    </div>
                </div>

                <div className='custom-button' style={{marginTop: '3rem'}}>
                    <Link to='profile/edit'>
                        <Button format='profile' type='submit'>Editar perfil</Button>
                    </Link>
                </div>
            </ProfileCard>  

            <LogoutCard>
                <div className='logout-btn'>
                    <Button
                        format='logout'
                        onClick={logout}
                    > Sair de todos os dispositivos logados </Button>
                </div>
            </LogoutCard>
        </Container>
        </>
    )
}

export default RetailerProfile