import styled from 'styled-components'

export const Container = styled.section`
color: #3A3B3C;
  table{
    width: 100%;
    border-collapse: collapse;
  }

  td,th{
    border: 1px solid #999;
    padding: 0.5rem;
    text-align: left;
  }

  thead{
    font-weight: bold;
    tr{
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  tbody{
    tr:nth-child(odd){
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  p{
   font-size: 1.5rem;
   font-weight: bold;
 }
`;