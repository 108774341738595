import {
    ModalBackground, ModalContainer, Title, CloseBtn,
    DivColor, Row, SmCol, MdCol, LgCol 
} from "./styles";
import Button from "../../../../components/Button";
import {HiPrinter} from 'react-icons/hi';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Voucher from '../../../../components/Voucher'
import Logo from '../../../../assets/vdf_logo-black.png'
import { useUserContext } from "../../../../components/contexts/useUserContext";

const RequestModal = (
    { closeModal, name, cpf, ddd, phone, destination, room, adults,
     children, hotel, startTime, endTime, status, neighborhood,
     street, streetNumber, cep, roomCapacity, roomDescription,
     map, bookingNumber, bookingDate, email, observations, info}
) => {

    const { user } = useUserContext()
     
    const formatDate = (dateInput) => {
        let d = new Date(dateInput)
        let ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('pt-br', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
        return(`${da}/${mo}/${ye}`);
    }

    const daysDiff = (startTime, endTime) => {
        let startDate = new Date(startTime);
        let endDate = new Date(endTime);
        var msDiff =  endDate.getTime() - startDate.getTime();    //Future date - current date
        return Math.floor(msDiff / (1000 * 60 * 60 * 24)) + 1;
    }

    return (
        <DivColor>
            <ModalBackground>
                <ModalContainer>
                    <CloseBtn onClick={() => closeModal(false)}>X</CloseBtn>
                    {user.isAdmin ? 
                        <Title>Solicitação de Reserva em Hotel AMERIS</Title>
                    :
                        <Title>Sua solicitação de Reserva em Hotel AMERIS</Title>
                    }

                    <Row>
                        <SmCol>
                            <h5>Nome</h5>
                            <p>{name}</p>
                        </SmCol>
                        <SmCol>
                            <h5>CPF</h5>
                            <p>{cpf}</p>
                        </SmCol>
                        <SmCol>
                            <h5>Telefone</h5>
                            <p>{`(${ddd}) ${phone}`}</p>
                        </SmCol>
                    </Row>

                    <hr/>

                    <Row style={{marginBottom: "2rem"}}>
                        <SmCol className="responsive-col">
                            <div>
                                <h5>Destino</h5>
                                <p>{destination}</p>
                            </div>
                            <div>
                                <h5>Hotel</h5>
                                <p>{hotel}</p>
                                <p>{neighborhood}</p>
                                <p>{`${street} - ${streetNumber}`}</p>
                                <p>{cep}</p>
                            </div>
                            <div>
                                <h5>Data de Estadia</h5>
                                <p>{`${formatDate(startTime)} - ${formatDate(endTime)}`}</p>
                                <p>{daysDiff(startTime, endTime)} diárias</p>
                            </div>
                        </SmCol>
                        <LgCol>
                            <MdCol>
                                <h5>Quarto</h5>
                                <p>{room}</p>
                                <p>{`- Acomoda ${roomCapacity} pessoas`}</p>
                                <p>{`- ${roomDescription}`}</p>
                            </MdCol>
                            <MdCol>
                                <h5>Número de Hóspedes</h5>
                                <p>{adults} adultos e {children} crianças</p>
                            </MdCol>
                            <div className="wrapper">
                                <div>
                                    <h2>Status: {status}</h2>
                                    {status === 'Aprovado' &&
                                    <Button format='home'>
                                        <PDFDownloadLink className="download-link" style={{color: 'white'}} document={<Voucher 
                                            logo = {Logo} 
                                            nome_hotel = {hotel}
                                            cep_hotel = {cep}
                                            rua_hotel = {street}
                                            numero_hotel = {streetNumber}
                                            bairro_hotel = {neighborhood}
                                            mapa_hotel = {map} 
                                            numero_reserva = {bookingNumber} 
                                            nome_hospede = {name} 
                                            numero_adulto = {adults} 
                                            numero_crianca = {children} 
                                            data_de_chegada = {formatDate(startTime)} 
                                            data_de_partida = {formatDate(endTime)} 
                                            tipo_de_quarto = {room} 
                                            data_da_reserva = {formatDate(bookingDate)} 
                                            observacoes = {observations} 
                                            informacoes_gerais = {info} 
                                            telefone = {`(${ddd}) ${phone}`} 
                                            email = {email} 
                                        />} fileName="voucher.pdf">
                                            {({ loading }) =>
                                            loading ? 'Carregando documento...' : 
                                                <>
                                                    <HiPrinter/> Imprimir Voucher da Reserva
                                                </>
                                            }
                                        </PDFDownloadLink>
                                        </Button>
                                    }
                                </div>
                            </div>
                        </LgCol>
                    </Row>
                </ModalContainer>
            </ModalBackground>
        </DivColor>
    );
};

export default RequestModal;