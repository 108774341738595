import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    color: #3A3B3C;

    h1 {
        color:#3A3B3C;
        font-size:2.813rem;
        margin: 2rem 0 0 0;
        text-align: center;
    }

    h2 {
        color:#3A3B3C;
        font-size:1.5rem;
        font-weight: 400;
        margin: 0 0 2rem 0;
        text-align: center;
    }

    .fluxo {
        display: flex;
        text-align: center;
        margin: auto;

        h5 {
            font-size: 1.5rem;
            margin: 0;
            font-weight: 600;
            span {
                background: -webkit-linear-gradient(270deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        @media screen and (max-width: 900px){
            h5 {
                font-size: 1.3rem;
            }
        }

        @media screen and (max-width: 740px){
            h5 {
                font-size: 1.1rem;
            }
        }

        @media screen and (max-width: 610px){
            h5 {
                font-size: 1rem;
            }
        }

        @media screen and (max-width: 550px){
            h5 {
                font-size: 0.8rem;
            }
        }
        @media screen and (max-width: 380px){
            h5 {
                font-size: 0.65rem;
            }
        }
    }

    form {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        align-items: center;
    }
    .sign-up-form {
        min-width: 80vw;
        margin: auto;
    }
    .form-1 {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .sub-form-right {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        label {
            margin-right: 3rem;
        }
    }
    .sub-form-left {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        label {
            margin-right: 3rem;
        }
        .title-1 {
            display: flex;
            padding-right: 3.3rem;
            margin: 0;
        }
        .title-2 {
            padding-top: 2rem;
            display: flex;
            padding-right: 1rem;
            margin: 0;
        }

        .rg, .birth { flex: 1 }
    }
    .select_input {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        .select {
            line-height: 33px;
            padding: 0.9rem 0rem;
            padding-right: 4.1%;
            width: 466px;
        }
    }
    .select_input_right {
        width: 50%;
        margin-left: 10px;
        .select {
            line-height: 32px;
            padding: 0.9rem 0rem;
            padding-left: 1%;
            width: 137px;
        }
    }
    .checkbox {
        width: 159px;
        padding:  1.3rem 0rem;
    }
    .div_button {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 3rem;
        padding-right: 2.3rem;
        margin-left: 1rem;
        .button {
            width: 300px;
        }
    }
    
    .signup-div{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10vh;
        .signup-button {
            max-width: 35rem;
        }
    }

    .div-recaptcha {
        display: flex;
        margin: 0 auto;
        padding-top: 2rem;
    }

    .choose-payment {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }

    .boleto{
        display:flex;
        flex-direction: column;
        width: 52%;
        border: 1px;
        border-style: hidden hidden solid hidden;
        padding-bottom: 2rem;
        color: #3A3B3C;

        h5{
            text-align: justify;
            font-size:1.75rem;
            font-weight: 600;
            
        }
        h2{
            text-align: justify;
            margin-top: -2rem;
          
            font-weight: 400;    
            font-size:1.125rem;
            width: 100%;
        }
        Button{
            border-radius: 5px;
            font-size:1.125rem;
            color:#FFF;
            width: 21rem;
            height:2.8rem;
            margin: 0rem !important;
            background-color:#007AFF;
            display:flex;
            justify-content: center;
            align-items: center;
            text-decoration: none !important;

        }

        
    }

    
    .cartaoall{
        display:flex;
        flex-direction: column;
        width: 52%;
        border: 1px;
        border-style: hidden hidden solid hidden;
        padding-bottom: 2rem;
        color: #3A3B3C;
        
        .Cartao{
            display:flex;
            flex-direction: column;
            width: 100%;
            
            h3{
        
                font-size:1.75rem;
                font-weight: 600;
            }
            h4{
                margin-top: -1rem;
                font-weight: 400;
            
                font-size:1.125rem;
               
            }
            Button{
                border-radius: 5px;
                font-size:1.125rem;
                color:#FFF;
                width:21rem;
                height: 3rem;
                margin: 0rem !important;
                background-color:#007AFF;
                display:flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;

            }
            
        }

        .cardpics{
            margin-top: 3rem;
            
        
            svg {
                height: 4.2rem;
                width: 40%;
            
            }
        }
    }

    @media screen and (max-width: 1400px){
        .select_input { .select {
                padding-right: 0.6rem;
        } }
    }
    @media screen and (max-width: 1300px){
        .select_input { .select {
                padding-right: 0.5%;
        } }
    }

    @media screen and (max-width: 1300px){
        .select_input { .select {
                padding-right: 0%;
        } }
    }

    @media screen and (max-width: 1000px){
        .div_button {
            margin-left: 2.3rem;
        }
    }

    @media screen and (max-width: 900px){
        h1 {
            font-size: 2.2rem;
        }
        .sub-form-right {
            width:100%;
            display: flex;
            justify-content: center;
            padding-right: 0%;
        }
        .sub-form-left {
            width:100%;
            display: flex;
            justify-content: center;
            padding: 0;
            padding-right: 0%;
        }
        .select_input {
            padding: 0.4rem 0rem 0.4rem 4.1rem;
            width: 100%;
            padding-right: 0;
            justify-content: center;
            padding-right: 0%;
        }
        .select_input_right {
            padding-right: 0%;
            width: 150px;
        }

        .div_button {
            .button {
                width: 250px;
            }
        }
    }

    @media screen and (max-width: 850px){
        .select_input_right {
            padding-left: 0rem;
        }
    }
    @media screen and (max-width: 610px){
        display: flex;
        justify-content: center;
        margin: 0;
        h1 {
            font-size: 1.8rem;
        }
        h2 {
            font-size: 1.2rem;
        }
        .sub-form-right {
            width:100vw;
            margin-left: 12%;
            display: flex;
            flex-direction: column;
        }
        .sub-form-left, .select_input_right {
            width:100vw;
            margin-left: 12%;
            display: flex;
            flex-direction: column;
        }
        .select_input {
            justify-content: start;
            width:76.5%;
            margin-left: 10%;
            padding: 0.4rem 0rem 0rem 0.65rem;
        }
        .select_input_right { 
            width: 100%;
            margin-left: 1%;
            .select {
                width: 84%;
        } }
        .checkbox {
            padding:  1.3rem 4%;
        }
        .div_button {
            justify-content: start;
            padding: 0;
            margin-right: 10%;
            margin-left: 10%;
            .button {
                width: 100%;
            }
        }

        .div-recaptcha {
            padding-bottom: 1rem;
        }
    }

    @media screen and (max-width: 500px){
        .sub-form-right {
            margin-left: 10%;
        }
        .sub-form-left {
            margin-left: 10%;
        }
        .select_input {
            margin-left: 10%;
            width: 79.5%;
        }
        .select_input_right { .select {
            margin-left: 1%;
            width: 84%;
        } }
        .div_button {
            margin-left: 10%;
        }
        
    }
    @media screen and (max-width: 400px){
        .sub-form-right {
            margin-left: 8%;
        }
        .sub-form-left {
            margin-left: 8%;
        }
        .select_input {
            margin-left: 8%;
            width: 82%;
        }
        .select_input_right { .select {
            margin-left: 1%;
            width: 84%;
        } }
        .div_button {
            margin-left: 8%;
            margin-right: 8%;
        }
    }
    @media screen and (max-width: 350px){
        .sub-form-right {
            margin-left: 6%;
        }
        .sub-form-left {
            margin-left: 6%;
        }
        .select_input {
            margin-left: 6%;
            width: 85%;
        }
        .select_input_right { .select {
            margin-left: 2%;
            width: 85%;
        } }
        .div_button {
            margin-left: 6%;
            margin-right: 6%;
        }
    }
    @media screen and (max-width: 300px){
        .select_input {
            width: 88%;
        } 

        .select_input_right { .select {
            width: 85%;
        } }
    }
`