import { Container, Screen1, Screen2, Screen3, Tag } from './styled';
import ArrowBack from '../../../components/ArrowBack'
import { Calendar } from '../../../components/Calendar'
import { useState, useEffect } from 'react';
import StyledSelect from '../../../components/Select';
import Select from 'react-select'
import Input from "../../../components/Input/InputSignUp";
import MaskInput from "../../../components/Input/MaskInput";
import { FaPhone } from "react-icons/fa";
import Button from '../../../components/Button';
import { addDays } from 'date-fns';
import RoomCard from '../../../components/RoomCard';
import { useParams } from 'react-router-dom';
import { api } from '../../../services/api';
import { useUserContext } from "../../../components/contexts/useUserContext";
import CarouselImage from '../../../components/CarouselImage';
import Modal from "../../../components/Modal";
import ModalInativo from "../../../components/ModalInativo"
import { useHistory } from "react-router";
import ModalTermos from "./Modal"
import IconTag from "../../../components/IconTag"
import {alert} from "../../../components/Alert"

const Destination4 = () => {
    const {id} = useParams();
    const placeholder = "https://patriciaelias.com.br/wp-content/uploads/2021/01/placeholder.png"
    const { user } = useUserContext();
    const history = useHistory();

    const [name, setName] = useState("");
    const [cpf, setCpf] = useState("");
    const [ddd, setDdd] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [rg, setRg] = useState("");
    const [cep, setCep] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [street, setStreet] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [birthdate, setBirthdate] = useState("");

    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(0);
    const [adults, setAdults] = useState({value: 1, label: "1 adulto"});
    const [kids, setKids] = useState({value: 0, label: `Nenhuma Criança (até 7 anos)`});
    const [adultsOptions, setAdultsOptions] = useState([]);
    const [kidsOptions, setKidsOptions] = useState([]);
    const [bookerIsUser, setBookerIsUser] = useState(false);
    const [user_package, setPackage] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openModalTermos, setOpenModalTermos] = useState(false);
    const [openModalInativo, setOpenModalInativo] = useState(false);
    const [optionStates, setOptionStates] = useState([]);
    
    const [states, setStates] = useState([]);

    const optionsClassifications = [
        "★",
        "★★",
        "★★★",
        "★★★★",
        "★★★★★"
      ]

    const [hotel, setHotel] = useState({
        cep: null, street: null, street_number: null, 
        neighborhood: null, images_url: [], classification: null,
        tags: [], name: null, category: {name: null}, destination: {name: null},
        description: null, room_types: [], link_maps: null
    });

    const [datas, setDatas] = useState([{
        startDate: new Date(),
        endDate: addDays(new Date(), 1),
        key: 'selection'
    }]);

    const setDateRange = (datas) => {
        datas.forEach((dates) => {
            let start = dates.startDate;
            let end = dates.endDate;
            if(start != null && end != null){
                let diffTime = Math.abs(end - start);
                let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                if(diffDays > 7) {
                    let newStart = start
                    if(newStart > end){
                        newStart = end
                    }
                    alert("Você pode selecionar no máximo 7 diárias", "warn")
                    setDatas([{
                        startDate: newStart,
                        endDate: newStart,
                        key: 'selection'
                    }]) 
                }else{
                    setDatas(datas)
                }
            }else{
                setDatas(datas)
            }
            return null
        })
        return null
    }

    const formatDate = (dateInput) => {
        let d = new Date(dateInput)
        let ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('pt-br', { month: 'long' }).format(d);
        let da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
        return(`${da} de ${mo} de ${ye}`);
    }

    const daysDiff = (startDate, endDate) => {
        var msDiff =  Math.abs(endDate.getTime() - startDate.getTime());    //Future date - current date
        return Math.ceil(msDiff / (1000 * 60 * 60 * 24));
    }

    useEffect(() => {
        api.get('states').then((response) => {
            setStates(response.data);
        })
        return null
    },[])

    useEffect(() => {
        setOptionStates(states.map((state) => {
            return { value: state.abbreviation, label: state.name }
        }))
    }, [states, state])

    useEffect(() => {
        if(user && user.type === 'client' && user.is_active === false){
            history.push('/')
        }
        return null
    }, [user, history]);

    useEffect(() => {
        api.get(`/hotels/show/${id}`)
        .then(response => {
            setHotel(response.data);
            let max = 1;
            response.data.room_types?.forEach((roomType) => {
                if(roomType.capacity > max)
                    max = roomType.capacity;
            })
            let adultsCapacity = [{value: 1, label: "1 adulto"}];
            let kidsCapacity = [{value: 0, label: 'Nenhuma Criança (até 7 anos)'}, {value: 1, label: "1 criança (até 7 anos)"}];
            if (max > 1)
                adultsCapacity.push({value: 2, label: '2 adultos'});
            setAdultsOptions(adultsCapacity);
            setKidsOptions(kidsCapacity);
        })
        return null
    }, [id])

    useEffect(() => {
        if (bookerIsUser){
            setCpf(user.cpf);
            setName(user.name);
            setDdd(user.ddd);
            setNumber(user.phone);
            setEmail(user.email);
            setRg(user.rg);
            setCep(user.cep);
            setCity(user.city);
            setState(user.state);
            setStreet(user.street);
            setStreetNumber(user.street_number);
            setNeighborhood(user.neighborhood);
            setBirthdate(user.birthdate)
        }
        else {
            setCpf('');
            setName('');
            setDdd('');
            setNumber('');
            setEmail('');
            setRg('');
            setCep('');
            setCity('');
            setState('');
            setStreet('');
            setStreetNumber('');
            setNeighborhood('');
            setBirthdate('')
        }
        return null
    }, [bookerIsUser, user])

    const handleBookingSubmit = async () => {
        if(user && user.type === 'client'){
            api.get(`clients/active_package/${user.id}`)
            .then((response) => {
                setPackage(response.data)
                if (response.data.is_active === true){
                    if(bookerIsUser === false){
                        setOpenModalTermos(true);
                    } else {
                        setOpenModal(true);
                    }
                } else {
                    setOpenModalInativo(true);
                }
            })
        }
        return null
    }

    const checkCapacity = (value) => {
        if (value.capacity >= adults.value)
            return value;
        return null
    }

    window.addEventListener('click', (e) => {   
        if (!document.getElementById('calendar')?.contains(e.target))
          setShowCalendar(false);
        return null
    });

    return (
        <>
        <Container>
            <ArrowBack />
            <Screen1>
                <div>
                    <div className="images">
                        <CarouselImage request="hotels" id={id} placeholder={placeholder}/>
                    </div>
                    <h3>Detalhes da Hospedagem</h3>
                    <p>
                        <b>CEP: </b>
                        {hotel.cep}
                    </p>
                    <p>
                        <b>Rua: </b>
                        {hotel.street}
                    </p>
                    <p>
                        <b>Número: </b>
                        {hotel.street_number}
                    </p>
                    <p>
                        <b>Bairro: </b>
                        {hotel.neighborhood}
                    </p>
                    
                    <p>
                        <b>Classificação: </b>
                        {hotel.classification? optionsClassifications[hotel.classification - 1] : "Este hotel ainda não foi avaliado."}
                    </p>
                    <div className="tags">
                        {hotel.tags.map((tag, index) => {
                            return (
                                <Tag key={index}>
                                    <IconTag iconName={tag.icon_name}/>
                                    {tag.name}
                                </Tag>
                            )
                        })}
                    </div>
                </div>
                <div>
                    <h2>{hotel.name}</h2>
                    <h6>{hotel.category?.name}</h6>
                    <p>{hotel.description}</p>
                </div>
            </Screen1>
            <Screen2>
                <h3>Faça sua solicitação de reserva <br/> em {hotel.name}</h3>
                <div className="select-options">
                    <div>
                        <h5>Data de Estadia</h5>
                        <div className="select-date" id="calendar">
                            <div onClick={() => setShowCalendar(!showCalendar)}>
                                <StyledSelect isSearchable={false} isDisabled
                                    placeholder={daysDiff(datas[0].startDate, datas[0].endDate) > 1 ?
                                        `${formatDate(datas[0].startDate)} - ${formatDate(datas[0].endDate)}` :
                                        formatDate(datas[0].startDate)}
                                />
                            </div>
                            {showCalendar && <Calendar months={2} className="my-calendar"
                                direction="horizontal" state={datas} setState={setDateRange} minDate={new Date()}
                            />}
                        </div>
                    </div>
                    <div>
                        <h5>Número de Hóspedes</h5>
                        <div className="select-inputs">
                            <StyledSelect placeholder="Adultos" isClearable={false} isSearchable={false}
                                options={adultsOptions} value={adults} onChange={value => setAdults(value)}
                            />
                            <StyledSelect placeholder="Crianças (até 7 anos)" isClearable={false} isSearchable={false}
                                options={kidsOptions} value={kids} onChange={value => setKids(value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="room-cards">
                    {hotel.room_types.filter(checkCapacity).map((roomType, index) => {
                        return <RoomCard key={index} selected={selectedRoom === roomType.id ? true : false}
                            setSelectedRoom={setSelectedRoom} id={roomType.id} name={roomType.name} 
                            capacity={roomType.capacity} description={roomType.description}
                            images={roomType.images_url}
                        />
                    })}
                </div>
                <div>
                    <input type='checkbox' className='checkbox' id='checkbox' value={bookerIsUser} onChange={() => setBookerIsUser(!bookerIsUser)}/>
                    <label for='checkbox' className='label'>Reservar no Nome de {user?.name}</label>
                </div>
                {bookerIsUser === false?
                <div className="booker-data">   
                    <h5>Digite os dados do portador da Reserva</h5>
                    <form>
                        <div className="input-line">
                            <Input
                                placeholder="Nome Completo"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                format='full_name'
                                required
                                readOnly={bookerIsUser}
                            />
                                <Input
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    format='full_name'
                                    required
                                    readOnly={bookerIsUser}
                                />
                        </div>
                        <div className="input-line">
                            <MaskInput
                                mask="999.999.999-99"
                                value={cpf}
                                onChange={(e) => setCpf(e.target.value)}
                                required
                                placeholder="CPF"
                                readOnly={bookerIsUser}
                            />
                            <Input
                                placeholder="Rg"
                                value={rg}
                                onChange={(e) => setRg(e.target.value)}
                                format='number'
                                required
                                readOnly={bookerIsUser}
                            />
                        </div>
                        <div className="input-line">
                            <Input
                                placeholder="Data de Nascimento"
                                value={birthdate}
                                onChange={(e) => {setBirthdate(e.target.value)}}
                                format="birthdate"
                                required
                                readOnly={bookerIsUser}
                                type="date"
                            />
                            <MaskInput
                                mask="99999-999"
                                value={cep}
                                onChange={(e) => setCep(e.target.value)}
                                placeholder="Cep"
                                format="cep"
                                required
                                readOnly={bookerIsUser}
                            />
                        </div>
                        <div className="input-line">
                            <div className='select'>
                                <Select
                                    name='uf'
                                    options={optionStates} isSearchable placeholder="Estado"
                                    onChange={(value) => {setState(value?.value)}}
                                    value={state && optionStates.filter((option) => {return option.value === state})[0]}
                                />
                            </div>
                            <Input
                                placeholder="Cidade"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                format='city'
                                required
                                readOnly={bookerIsUser}
                            />
                        </div>
                        <div className="input-line">
                            <Input
                                placeholder="Bairro"
                                value={neighborhood}
                                onChange={(e) => setNeighborhood(e.target.value)}
                                format='neighborhood'
                                required
                                readOnly={bookerIsUser}
                            />
                            <Input
                                placeholder="Rua"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                format='street'
                                required
                                readOnly={bookerIsUser}
                            />
                            <Input
                                placeholder="Número"
                                value={streetNumber}
                                onChange={(e) => setStreetNumber(e.target.value)}
                                format='number'
                                required
                                readOnly={bookerIsUser}
                                className="number"
                            />
                        </div>
                        <div className="phone-inputs input-line">
                            <MaskInput
                                mask='(99)'
                                placeholder='DDD'
                                name='ddd'
                                value={ddd} onChange={(e)=>setDdd(e.target.value)}
                                format='ddd'
                                required
                                readOnly={bookerIsUser}
                            />
                            <MaskInput
                                mask="99999-9999"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                placeholder="Telefone"
                                Icon={FaPhone}
                                format="phone"
                                required
                                readOnly={bookerIsUser}
                            />
                        </div>
                    </form>
                </div>
                : '' }
                <div className="confirmation">
                    <h4>
                        Estadia total
                        <span>{daysDiff(datas[0].startDate, datas[0].endDate)} diárias</span>
                    </h4>
                        <Button 
                            format="home"
                            className="openModalBtn"
                            onClick={() => {
                                handleBookingSubmit();
                            }}
                        >Quero Ir de Férias!</Button>
                </div>
            </Screen2>
            <Screen3>
                <h3>Como chegar</h3>
                <div dangerouslySetInnerHTML={{__html: hotel?.link_maps}}></div>
            </Screen3>
        </Container>
        {openModal && <Modal closeModal={setOpenModal}
                        name={name} cpf={cpf} rg={rg} email={email} phone={number} 
                        ddd={ddd} cep={cep} state={state} city={city} number={streetNumber}
                        neighborhood={neighborhood} street={street} birthdate={birthdate} hotel={hotel}
                        adults={adults} kids={kids} dates={datas[0]} room_id={selectedRoom} user_id={user.id}
                        diarias={daysDiff(datas[0].startDate, datas[0].endDate)} user_package={user_package}/>}

        {openModalInativo && <ModalInativo closeModal={setOpenModal}/>}
        {openModalTermos && <ModalTermos closeModal={setOpenModalTermos} nextModal={setOpenModal} />}
    </>
    );
}

export default Destination4;