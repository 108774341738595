import React from 'react'
import CardHowItWork from '../../components/CardHowItWork'
import ArrowBack from '../../components/ArrowBack'

import { ContainerWrapper, ContainerCards, ButtonContainer } from './styled'
import { Link } from 'react-router-dom';
import Button from "../../components/Button";
import { useUserContext } from '../../components/contexts/useUserContext'

import img1 from '../../assets/ImagesTutorial/image-1.png'
import img2 from '../../assets/ImagesTutorial/image-2.png'
import img3 from '../../assets/ImagesTutorial/image-3.png'
import img4 from '../../assets/ImagesTutorial/image-4.png'
import img5 from '../../assets/ImagesTutorial/image-5.png'
import img6 from '../../assets/ImagesTutorial/image-6.png'
import lamp from '../../assets/lamp.svg'

const PageHowItWork = () => {

    const { user } = useUserContext();

    const returnElements = [
        {
            id: 1,
            title: "Solicite sua conta e login",
            phrases: ["Preencha seus dados pessoais e selecione seu pagamento", "Solicite e aguarde a aprovação de seu cadastro "],
            image: img1, 
            color: "#FCC046"
        },

        {
            id: 2,
            title: "Efetue seu pagamento em 24x",
            phrases: ["Ao receber sua aprovação por e-mail, faça seu login no site", "Encontre seus boletos de pagamento no seu perfil"],
            image: img2,
            color: "#F09E40"
        },

        {
            id: 3,
            title: "Solicite o destino desejado",
            phrases: ["Solicite o seu destino favorito entre todas as nossas opções", "Aguarde o e-mail com as opções de Hotéis no destino escolhido"],
            image: img3,
            color: "#E47D3A"
        },

        {
            id: 4,
            title: "Realize sua reserva",
            phrases: ["Encontre seu hotel ideal",  "Faça sua solicitação de reserva"],
            image: img4,
            color: "#D75B33"
        },
        {
            id: 5,
            title: "Acompanhe sua solicitação",
            phrases: ["Acompanhe a sua solicitação pelo seu perfil", "Receba a confirmação da reserva por e-mail"],
            image: img5,
            color: "#CB3A2D"
        },
        {
            id: 6,
            title: "Vai de Férias!",
            phrases: ["Sua hospedagem no seu hotel está garantida", "Relaxe, tire fotos e tenha as férias dos seus sonhos"],
            image: img6,
            color: "#BF1827"
        }
    ]

    return (
        <>
            <ArrowBack/>
            <ContainerWrapper>
                <div className="header-style">
                    <h1><img src={lamp} alt="Icone de lâmpada"/>Como adquirir suas férias?</h1>
                </div>
                    <ContainerCards>
                        {returnElements?.map((element) => (
                            <CardHowItWork
                                key={element.id}
                                id={element.id}
                                title={element.title}
                                description={element.phrases}
                                image={element.image}
                                titleColor={element.color}
                            />
                        ))}
                    </ContainerCards>
                    <ButtonContainer>
                        <Link to={user ? "/vacation" : "/login"}>
                            <Button format="submit">Comece a planejar suas férias agora</Button>
                        </Link>
                            <h4>7</h4>
                    </ButtonContainer>
            </ContainerWrapper>
        </>
    )
}

export default PageHowItWork
