import styled from "styled-components";

export const Container = styled.table`
    width: 90%;
    margin: auto;
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
   
    

    caption {
        font-size: 3.125rem;
        font-weight: 700;
        margin-bottom: 2rem;
        label{
            margin-left: 1rem;
            font-size: 2rem;
            font-weight: 500;
        }
    }

    th {
        color: #53535F;
        padding-bottom: 0.5rem;
    }

    tr {
        border-bottom: 1px solid #CBCBCB;
    }

    @media screen and (max-width: 1024px){
        width: 90%;

        caption{
            font-size: 1.8rem;
        }

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            border-bottom: 3px solid #CBCBCB;
            display: block;
            margin-bottom: 0.625rem;
        }

        td {
            width: 100%;
            padding: 0.5rem 0;
            border-bottom: 1px solid #CBCBCB;
            display: block;
            font-size: 0.8rem;
            text-align: right;
        }

        td::before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
        }
        
        td:last-child {
            border-bottom: 0;
        }
    }

    @media screen and (max-width: 576px) {
        caption {
            font-size: 1.6rem;
        }
    }
`