import { useUserContext } from "../../components/contexts/useUserContext"
import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {Container, ProfileCard} from "./style"
import UserPlaceholder from '../../assets/user-placeholder.png'
import Button from '../../components/Button'
import { api } from "../../services/api"
import { photo } from "../../../src/services/photo"
import MaskInput from "../../components/Input/MaskInput"
import {alert} from "../../components/Alert"
import InputWithoutIcon from "../../components/Input/InputWithoutIcon"
import Select from 'react-select';

function ClientUpdate() {
    const history = useHistory()
    const {user, updateClient, showClient} = useUserContext()
    const [image, setImage] = useState([])
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [city, setCity] = useState(user.city)
    const [cep, setCep] = useState(user.cep)
    const [cepFailure, setCepFailure] = useState(false)
    const [states, setStates] = useState([]);
    const [optionStates, setOptionStates] = useState([]);
    const [state, setState] = useState(user.state)
    const [street, setStreet] = useState(user.street)
    const [neighborhood, setNeighborhood] = useState(user.neighborhood)
    const [street_number, setStreet_number] = useState(user.street_number)
    const [ddd, setDdd] = useState(user.ddd)
    const [phone, setPhone] = useState(user.phone)

    useEffect(() => {
        api.get('states').then((response) => {
            setStates(response.data);
        })
    },[])

    useEffect(() => {
        setOptionStates(states.map((state) => {
            return { value: state.abbreviation, label: state.name }
        }))
    }, [states, state])

    const changeImage = async (e) => {
        e.preventDefault()
        try{
            const formData = new FormData ()
                formData.append('image[]', image[0])
        

            const response = await api.post(`/clients/add_image/${user.id}`, formData)
            
            if(response.data){
                alert('Foto adicionada', 'success');
                setImage(response.data)
                showClient()
                
            }
            
        }catch(error){
            alert('Erro, tente novamente', 'error')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (city === ''){
            alert('Insira a cidade!', 'warn')
            return
        }

        if (cep === ''){
            alert('Insira o cep!', 'warn')
            return
        }

        if (cepFailure) {
            alert('Cep inválido!', 'warn')
            return
        }
        if (state === ''){
            alert('Insira o estado!', 'warn')
            return
        }

        if (street === ''){
            alert('Insira a rua!', 'warn')
            return
        }

        if (neighborhood === ''){
            alert('Insira o bairro!', 'warn')
            return
        }

        if (street_number === ''){
            alert('Insira o número da rua!', 'warn')
            return
        }
        
        await updateClient({
            city,
            cep,
            state,
            street,
            neighborhood,
            street_number,
            ddd: ddd.replace(/\D/g,''),
            phone
        })
        // history.goBack()

    }

    const handlePasswordChange = async (e) => {
        e.preventDefault()
        if (password.length < 6){
            alert('Senha muito curta', 'warn')
            return
        }

        if (password !== passwordConfirmation){
            alert('Confirmação de senha não bate com a senha', 'warn')
            return
        }

        try{
            await api.patch(`clients/password_edit/${user.id}`, {
                password,
                password_confirmation: passwordConfirmation,
                current_password: currentPassword
            })
            alert("Senha modificada com sucesso.", 'success')
            history.push('/profile')
        }
        catch(e){
            alert("Erro, tente novamente", 'error')
        }
    }

    const onBlurCep = (event) => {
        const { value } = event.target;
        const cep = value?.replace(/[^0-9]/g, "");

        if (cep?.length !== 8) {
            setCepFailure(true)
            return;
        }

        fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json()) // Retorna o json da api viacep
        .then((data) => {
            if (data.erro)
                setCepFailure(true);
            else {
                setStreet(data.logradouro)
                setCity(data.localidade)
                setState(data.uf)
                setDdd(data.ddd)
                setNeighborhood(data.bairro)
                setCepFailure(false)
            }
        });
    }


    return (
        <>
        <Container>
                <div className='title'>
                <h1>Editar perfil</h1>
                <h2>Olá, <span>{user? user.name:''}!</span> </h2>
                </div>
            
            <ProfileCard>
                <form onSubmit={changeImage}>
                    <div className='client-info'>
                        <div className='profile-pic'>
                            <img alt="Foto de Perfil" src={user.client_image_url? `${photo.defaults.baseURL +user.client_image_url}`: UserPlaceholder} />
                            <input
                                placeholder='Foto' type='file' name='file' id='file'
                                onChange={(value) => setImage(value.target.files)}
                            />
                        </div>
                    </div>
                    <div className='custom-button'>
                    <Button format='profile' type='submit' className='button'>Confirmar nova foto de perfil</Button>

                    </div>
                </form>
                <form onSubmit={handleSubmit}>

                <div className='client-info'>
                    <InputWithoutIcon
                        label='Cidade'
                        type='text'
                        value={city}
                        onChange={value => setCity(value.target.value)}
                    />
                </div>

                <div className='client-info'>
                    <label>Estado</label>
                    <div className="select">
                        <Select
                            name='uf'
                            options={optionStates} isSearchable placeholder="Estado"
                            onChange={(value) => {setState(value?.value)}}
                            value={state && optionStates.filter((option) => {return option.value === state})[0]}
                            failMessage="O campo Estado não pode estar vazio"
                        />
                    </div>
                </div>

                <div className='client-info'>
                    <MaskInput
                        mask='99999-999'
                        placeholder='CEP'
                        label='CEP'
                        name='cep'
                        value={cep}
                        onChange={value => {setCep(value.target.value); setCepFailure(false)}}
                        onBlur={onBlurCep}
                        format='cep-sm3'
                        required
                    />
                </div>

                <div className='client-info'>
                    <InputWithoutIcon
                        label='Rua'
                        type='text'
                        value={street}
                        onChange={value => setStreet(value.target.value)}
                    />
                </div>

                <div className='client-info'>
                    <InputWithoutIcon
                        label='Bairro'
                        type='text'
                        value={neighborhood}
                        onChange={value => setNeighborhood(value.target.value)}
                    />
                </div>

                <div className='client-info'>
                    <InputWithoutIcon
                        label='Número da rua'
                        type='text'
                        value={street_number}
                        onChange={value => setStreet_number(value.target.value)}
                    />
                </div>

                <div className='client-info'>

                    <label className="label-client-update">Telefone</label>
                    <div className='client-info-phone'>
                        <MaskInput
                            mask='(99)'
                            placeholder='DDD'
                            name='ddd'
                            value={ddd}
                            onChange={value => setDdd(value.target.value)}
                            format='ddd'
                            required
                        />
                        <MaskInput
                            mask="99999-9999"
                            value={phone}
                            onChange={value => setPhone(value.target.value)}
                            placeholder="Telefone"
                            format="phone"
                            required
                        />
                    </div>
                </div>

                <div className='custom-button'>
                    <Button type='submit' format='profile'>Confirmar mudanças</Button>
                </div>

                </form>
                
                <form onSubmit={handlePasswordChange}>
                    <div className='client-info'>
                        <InputWithoutIcon
                            label='Senha atual'
                            type='password'
                            value={currentPassword}
                            onChange={value => setCurrentPassword(value.target.value)}
                        />
                    </div>

                    <div className='client-info'>
                        <InputWithoutIcon
                            label='Nova Senha'
                            type='password'
                            value={password}
                            onChange={value => setPassword(value.target.value)}
                        />
                    </div>

                    <div className='client-info'>
                        <InputWithoutIcon
                            label='Confirmar Nova Senha'
                            type='password'
                            value={passwordConfirmation}
                            onChange={value => setPasswordConfirmation(value.target.value)}
                        />
                    </div>

                    <div className='custom-button'>
                        <Button type='submit' format='profile'>Alterar Senha</Button>
                    </div>
                </form>

                <div className='custom-button'>
                    <Button format='logout' onClick={() => history.goBack()}>Voltar</Button>
                </div>



            </ProfileCard>  
        </Container>

        </>
    )
}

export default ClientUpdate