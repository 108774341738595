import styled from "styled-components";

export const Container = styled.div`
    background: #FFF;
    padding-bottom: 8rem;
    font-family: 'Poppins', sans-serif;
    color: #3A3B3C;

    .title{
        border-radius: 0.625rem;
        padding: 1rem 1rem 1rem;
        max-width: 50rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        color: #3A3B3C;

    }
    @media screen and (max-width: 1150px) {
        .title{
            padding-left: 0rem;
            margin: auto;
        }
    }

    @media screen and (max-width: 1150px) {
        padding: 1rem;
    }

`
export const ProfileCard = styled.div`
    border: 1px solid #E2E4E5;
    border-radius: 0.625rem;
    padding: 1rem 3rem 1rem;
    max-width: 45rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #FCC046;
    
    .select {
        line-height: 32px;
        padding: 0.4rem 0.625rem;
    }

    .client-info {
        margin: 2rem 0;
        position: relative;

        label{
            color: #000;
            position: absolute;
            pointer-events: none;
            top: -1rem;
            left: 0rem;
            transition: .2s ease;
            font-family: 'Poppins', sans-serif;
            opacity: 0.9;
            font-size: .8rem;
            font-weight: 600;
        }

        img{
            border-radius: 50%;
            width: 12.5rem;
            height: 12.5rem;
            object-fit: cover;
        }

        p{
            font-size: 12px;
            color: #AAAAAA;
            margin-bottom: 0;
        }
    }

    .label-client-update {
        color: #000;
        font-size: .8rem;
        font-weight: 600;
    }

    .client-info-phone {
        display: flex;
    }

    .custom-button{
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none; 
    }

    @media screen and (max-width: 1150px) {
          
    }
`