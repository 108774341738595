import styled from "styled-components"

export const customStyles = {
    menu: base => ({
        ...base,
        fontFamily: 'Poppins',
        fontSize: '1rem',
        width: '100%',
        margin: 0,
        boxShadow: 'none',
        border: '1px solid #E2E4E5',
        borderRadius: 0,
    }),

    control: () => ({
        display: 'flex',
        width: '100%',
        border: 0,
        margin: '0.625rem 0',
        boxShadow: "none",
        borderBottom: '1px solid #E2E4E5',
        fontFamily: 'Poppins',
        fontSize: '1rem',
        fontWeight: '400',
        cursor: 'text',
    }),

    clearIndicator: base => ({
        ...base,
        cursor: 'pointer'
    }),

    placeholder: () => ({
        color: '#CBCBCB'
    }),

    dropdownIndicator: () => ({
        cursor: 'pointer',
    })
}

export const Container = styled.div`
    position: relative;
    margin: 2rem 0;
    padding: 0.625rem;

    label {
        position: absolute;
        pointer-events: none;
        top: 0;
        left: -.5rem;
        opacity: 1;
        font-size: .8rem;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }
`