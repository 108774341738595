import styled from 'styled-components';

export const ContainerWrapper = styled.section`
        width: 100%;
        height: 100%;
        max-width: 1400px;
        margin: 0 auto;
        color: #3A3B3C;

        .header-style {
            padding: 0 20px;

            h1 {
                font-size: 50px;
                font-weight: bold;
                font-family: Poppins;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                gap: 1rem;

                img {height: 4.5rem}
            }
        }

        @media(max-width: 768px) {
            .header-style {            
                h1 {
                    font-size: 2rem;

                    img {height: 3rem}
                }

                p {text-align: justify}
            }
        }

        @media(max-width: 411px) {
            .header-style {

                h1 {
                    font-size: 1.5rem;

                    img {height: 2.5rem}
                }
                
                p {
                    font-size: 16px;
                }
            }
        }

    .sub-title {
        padding-bottom: 1.2rem;
        border-bottom: 1px solid #3a3b3c;
    }

    .text-container {
        padding: 0 20px;
        margin-bottom: 5rem;

        h1 {
            font-size: 2rem;
            font-weight: bold;
            font-family: Poppins;
            margin-bottom: 0;
            color: #3a3b3c;
        }
    
        p {
            color: #3a3b3c;
            font-family: Poppins;
            font-weight: 400;
            font-size: 1.25em;
            text-align: justify;
            text-justify: inter-word;
        }

    }

    @media(max-width: 411px) {
        .header-style {
            
            p {
                color: #3a3b3c;
                font-size: 16px;
            }
        }
    }

`;

export const ButtonContainer = styled.div`
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;
`;
