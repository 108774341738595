import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    
    .div-style-arrow {
        padding: 3rem;
        margin-bottom: 0;
        
        .div-wrapper-arrow {
            display: flex;
            gap: 1rem;
            cursor: pointer;
            align-items: center;
            justify-content: flex-start;
            width: fit-content;
            color: #E04013;
            ${(props)=>{
                if (props.white){
                    return('color: #FFF;')
                }
            }}
            text-decoration: none;
    
            svg {
                font-size: 2rem;
            }
            
            span {
                color: #E04013;
                ${(props)=>{
                    if (props.white){
                        return('color: #FFF;')
                    }
                }}
                font-size: 1.3rem;
                font-family: Roboto;
                font-weight: 400;        
            }
        }
        .div-wrapper-arrow:hover {
            color: #FCC046;
            span {
                color: #FCC046;
            }
        }
    }

    @media(max-width: 700px){
        .div-style-arrow {
            padding: 25px 20px;
            svg{
                font-size: 1.5rem !important;
            }
            span {
                display: none;
            }
        }
    }

`;
