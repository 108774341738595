import { useState, useEffect } from 'react'
import { Container } from "./styles"
import { api } from "../../../../services/api"
import { useLocation, useParams } from "react-router-dom"
import ArrowBack from '../../../../components/ArrowBack'
import { Page } from "../../../AdminDashboard/styles"

const ShowClient = () => {

    const location = useLocation()
    const {id} = useParams()

	const [client, setClient] = useState(location.state?.client || {})

    useEffect(() => {
		api.get(`clients/show/${id}`)
		.then((response) => {
		setClient(response.data)	
		})
    }, [id, location])

	const formatDate = (dateInput) => {
		if(dateInput){
			let d = new Date(dateInput)
			let ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
			let mo = new Intl.DateTimeFormat('pt-br', { month: 'long' }).format(d);
			let da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
			return(`${da} de ${mo} de ${ye}`);
		}
		return '';
	}

    return (
		<Page>
			<Container>
				<ArrowBack />
				<h2>{client?.name}</h2>
				<div className="info">
					<h4>Informações Pessoais</h4>
					<p><span>Email: </span>{client?.email}</p>
					<p><span>CPF: </span>{client?.cpf}</p>
					<p><span>RG: </span>{client?.rg}</p>
					<p><span>Telefone: </span>({client?.ddd}) {client?.phone}</p>
					<p><span>Data de Nascimento: </span>{formatDate(client?.birthdate)}</p>
					<h4>Endereço</h4>
					<p><span>Cidade: </span>{client?.city} - {client?.state}</p>
					<p><span>CEP: </span>{client?.cep}</p>
					<p><span>Rua: </span>{client?.street} - {client?.street_number}</p>
					<p><span>Bairro: </span>{client?.neighborhood}</p>
				</div>
			</Container>
		</Page>
    )
}

export default ShowClient
