import { createContext, useContext, useState, useEffect} from "react";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line
import Cookies, { set } from "js-cookie";
import {alert} from '../Alert'

const UserContext = createContext();

const UserProvider = ({children}) => {


    const history = useHistory();
    const [user, setUser] = useState(null)

    var CryptoJS = require("crypto-js");

    useEffect(() => {
        const handleCookies = async () => {
            const userCookie = await Cookies.get('user');
            Cookies.raw = true
            if(userCookie !== undefined) {
                var bytes = CryptoJS.AES.decrypt(userCookie, "StrucTHotel");
                const retrievedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                setUser(retrievedUser);
                
                let tmp = retrievedUser.type.charAt(0).toUpperCase() + retrievedUser.type.slice(1);
    
                api.defaults.headers.common[`X-${tmp}-Token`] = retrievedUser.authentication_token
                api.defaults.headers.common[`X-${tmp}-Email`] = retrievedUser.email
            }
            return userCookie
        };

        handleCookies();

    }, [CryptoJS])

    const updateClient = async ({name, email, city, cep, state, street, neighborhood, street_number, ddd, phone, password})  => {
        try{
            const response = await api.patch(`clients/update/${user.id}`, {
                name,
                email,
                city,
                cep,
                state,
                street,
                neighborhood,
                street_number,
                ddd,
                phone
            })
            if(response.data){
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'client'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1})

                alert('Dados alterados com sucesso!', 'success')
                setUser({...response.data, type: 'client'})
                history.push("/profile")
            }

        }catch(e){
            alert("Erro, tente novamente", 'error')
        }
    }

    const updateSolicitation = async ({destination_id})  => {
        try{
            const response = await api.patch(`clients/update/${user.id}`, {
                solicitacao_destino: `${destination_id}`
            })
            if(response.data){
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'client'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1})

                setUser({...response.data, type: 'client'})
                history.go(0)
            }

        }catch(e){
            alert("Erro, tente novamente", 'error')
        }
    }

    const cancelSolicitation = async ()  => {
        try{
            const response = await api.get('clients/unset_solicitacao/'+user.id+'/1')
            if(response.data){
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'client'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1})

                setUser({...response.data, type: 'client'})
                history.go(0)
            }

        }catch(e){
            alert("Erro, tente novamente", 'error')
        }
    }

    const showClient = async () => {
        if(user && user.user_type === "client"){
            try{
                const response = await api.get(`/clients/show/${user.id}`)
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'client'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1})
                setUser({...response.data, type: 'client'})
                api.defaults.headers.common[`X-Client-Token`] = response.data.authentication_token
                api.defaults.headers.common[`X-Client-Email`] = response.data.email

            } catch(e){
                console.error(e)
            }
        }
    }

    const refreshClient = async () => {
        if(user && user.user_type === "client"){
            try{
                const response = await api.get(`/clients/show/${user.id}`)
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'client'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1})
                setUser({...response.data, type: 'client'})
                api.defaults.headers.common[`X-Client-Token`] = response.data.authentication_token
                api.defaults.headers.common[`X-Client-Email`] = response.data.email

            } catch(e){
                console.error(e)
            }
        }
    }

    const updateAdmin = async (data) => {
        if(user && user.type === 'admin'){
            try{
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...data, type: 'admin'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1})
                setUser({...data, type: 'admin'})
                api.defaults.headers.common[`X-Client-Token`] = data.authentication_token
                api.defaults.headers.common[`X-Client-Email`] = data.email

            } catch(e){
                console.error(e)
            }
        }
    }

    const updateRetailer = async ({city, cep, state, street, neighborhood, 
        street_number, ddd, phone, person_type, bank, account_type, branch_number, branch_check_digit,
        account_number, account_check_digit, icms_contribution, 
        national_simple, state_registration, municipal_registration})  => {
        try{
            const response = await api.patch(`retailers/update/${user.id}`, {
                city,
                cep,
                state,
                street,
                neighborhood,
                street_number,
                ddd,
                phone,
                person_type,
                bank,
                account_type,
                branch_number,
                branch_check_digit,
                account_number,
                account_check_digit,
                icms_contribution,
                national_simple,
                state_registration,
                municipal_registration
            })
            if(response.data){
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'retailer'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1})
                alert('Dados alterados com sucesso!', 'success')

                setUser({...response.data, type: 'retailer'})
                history.push('/profile');
            }

        }catch(e){
            alert("Erro, tente novamente", 'error')
        }
    }

    const showRetailer = async () => {
        try{
            const response = await api.get(`/retailers/show/${user.id}`)
            var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'retailer'}), "StrucTHotel").toString();
            Cookies.set('user', encrypted, {expires: 1})
            setUser({...response.data, type: 'retailer'})
            api.defaults.headers.common[`X-Client-Token`] = response.data.authentication_token
            api.defaults.headers.common[`X-Client-Email`] = response.data.authentication_token

        } catch(e){
            console.error(e)
        }
    }
    

    const logout = async () => {
        if (window.confirm("Você realmente deseja sair de sua conta?")){
            try{
                const response = await api.post(`${user.type}s/logout`)
                if (response){
                }
            }catch(e){
                alert("Erro, tente novamente", 'error')
            }finally{
                Cookies.remove('user')
                history.push('/')
                history.go(0)
            }
        }
    }

    const sign_out = async () => {
        if (window.confirm("Você realmente deseja sair de sua conta?")){
            Cookies.remove('user')
            history.push('/')
            history.go(0)
        }
    }

    const login_cliente = async ({email, password}) => {
        try{
            const response = await api.post('clients/login', {
                email,
                password
            });
            if(response.data){
                setUser({...response.data, type: 'client'})
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'client'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1});
                history.push('/')
                api.defaults.headers.common['X-Client-Email'] = response.data.email
                api.defaults.headers.common['X-Client-Token'] = response.data.authentication_token
            }
        }catch(e){
            alert(e.response.data.message? e.response.data.message : 'Algum erro ocorreu. Por favor, tente novamente.', e.response.data.type? e.response.data.type : 'error')
        }
    }

    const login_retailer = async ({email, password}) => {
        try{
            const response = await api.post('retailers/login', {
                email,
                password
            });
            if(response.data){
                setUser({...response.data, type: 'retailer'})
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'retailer'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1});
                history.push('/')
                api.defaults.headers.common['X-Retailer-Token'] = response.data.authentication_token
                api.defaults.headers.common['X-Retailer-Email'] = response.data.email
            }
        }catch(e){
            alert('Usuário não autorizado', 'error')
        }
    }

    const login_admin = async ({email, password}) => {
        try{
            const response = await api.post('admins/login', {
                email,
                password
            });
            if(response.data){
                setUser({...response.data, type: 'admin'})
                var encrypted = CryptoJS.AES.encrypt(JSON.stringify({...response.data, type: 'admin'}), "StrucTHotel").toString();
                Cookies.set('user', encrypted, {expires: 1});
                history.push('/')
                api.defaults.headers.common['X-Admin-Token'] = response.data.authentication_token
                api.defaults.headers.common['X-Admin-Email'] = response.data.email
            }
        }catch(e){
            alert('Usuário não autorizado', 'error')
        }
    }


    return (
        <UserContext.Provider value={{user, login_retailer, login_admin, login: login_cliente, logout, updateClient, setUser, refreshClient, showClient, updateSolicitation, cancelSolicitation, sign_out, updateRetailer, showRetailer, updateAdmin}}>
            {children}
        </UserContext.Provider>
    )
}

export const useUserContext = () => {
    const context = useContext(UserContext);

    return context;
}

export default UserProvider;
