import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .image-container {
        max-width: 35%;
        width: 100%;
        max-height: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .forms-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        .subforms-container {
            display: flex;
            flex-direction: column;
            width: 40rem;
            height: 25rem;
            justify-content: center;
            align-items: center;
        }

        form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 60%;

            label {
                height: 3rem;
                margin-top: 1rem;
            }

            .forgot-link {
                color: #242731;
                filter: opacity(80%);
                margin: 15px 0;
                font-family: Poppins;
                font-size: 1rem;
            }

            span {
                color: #242731;
                filter: opacity(80%);
                margin: 15px 0;
                font-family: Poppins;
                font-size: 1rem;
            }

            Button {
                width: 30rem;
            }
        }

        h3 {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 3.125rem;
            text-align: center;

            display: flex;

            align-items: center;
            color: #000000;
        }

        hr{
        margin: 2rem;
        border-color:#aaa;
        box-sizing:border-box;
        width:65%;  
        }
    }

    @media(max-width: 640px) {
        flex-direction: column;
        font-size: 62.5%;

        h3 {
            text-align: center;
        }
        
        .image-container {
            display: none;
        }
        
        .forms-container {
            max-height: 100%;
            height: 70%;
            max-width: 100%;
            width: 100%;
            
            form {
                width: 95%;
                
                Button {
                    width: 15rem;
                }
            }

            .subforms-container{
                max-width: 100vw;
                width: 100vw;
            }
        }
    }
    @media(max-height: 703px) {
        height: 100%;
    }
`;