import styled from "styled-components"

export const Container = styled.nav`
  background-color: #e04013;
    width: 250px;
    height: 100vh;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    box-sizing: border-box;
    padding: 0;

  span{
     margin: 0 !important;
  }
  ${props=>{
    if (props.sidebar){
      return `right: 0; transition: 350ms;`
    }
  }}

  .nav-menu-items {
    width: 250px;
    max-width: 250px;
    *{
      max-width: 250px;
    }
  }

  .navbar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .menu-bars {
    margin-right: 2rem;
    margin-top: 1rem;
    font-size: 2rem;
    background: none;
    color: #f9f6f7;
    cursor: pointer;
    background: green;
    height: 80px;
    width: auto;
    
  }

  @media (min-width: 1000px) and (orientation: landscape) {
    .menu-bars {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    .header-login {
      display: none;
    }
    .menu-bars {
      display: flex;
    }
  }

  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 4px 16px;
    list-style: none;
    min-height: 45px;
    margin-left: -1.5rem;
  }

  .nav-text a {
    text-decoration: none;
    width: 95%;
    min-height: 45px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    font-style: normal;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    font-family: Poppins;
  }

  .nav-text a span {
    font-size: 1rem;
    text-decoration: none;
  }
  .nav-text:hover {
    transition-timing-function: ease;
    transition-duration: 0.6s;
    background-color: #E47D3A;
  }

  .nav-text a:hover {
    /* color: #FCC046;
        transition-timing-function: ease;
        transition-duration: 0.6s; */
  }

  .drop {
    transition: opacity 1.5s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
    margin-left: 2rem;
  }

  .drop.drop-active {
    opacity: 1;
    height: auto;
  }

  .header-login {
    color: #f9f6f7;
    font-size: 1.8rem;
    margin-right: 2vw;
    cursor: pointer;
    font-weight: 900;
    height: 100%;
    display: table;
    border-style: none none solid none;
    border-color: transparent;

    span {
      height: 100%;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      display: table-cell;
      vertical-align: middle;
    }

    button {
      border: none;
      background-color: transparent;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      margin: 5px;

      border-radius: 5px;
      color: #fff;
    }

    button:hover {
      cursor: pointer;
    }
  }

`