import { Background, ModalWrapper } from './styles';

const BlankModal = ({ showModal, children }) => {
  return (
      <>
        {showModal ? (
          <Background>
            <ModalWrapper>
              {children}
            </ModalWrapper>
          </Background>
        ) : null}
      </>
  )
}

export default BlankModal;