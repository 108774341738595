import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Container } from './styles';
import { api } from '../../../services/api'
import TextArea from '../../../components/TextArea';
import Form from '../../../components/Forms';
import {alert} from '../../../components/Alert';
import { Page } from '../../AdminDashboard/styles';

const CategoriesUpdate = () =>{

    let{id} = useParams()

    const[name, setName] = useState('')
    const[description, setDescription] = useState('')
    const[failNameMessage, setFailNameMessage] = useState('')
    const[failedName, setFailedName] = useState(false)
    const[failDescriptionMessage, setFailDescriptionMessage] = useState('')
    const[failedDescription, setFailedDescription] = useState(false)

    const history = useHistory()

    const loadCategory = async () => {
        const response = await api.get(`/categories/show/${id}`)
        setName(response.data.name)
        setDescription(response.data.description)
    }

    useEffect(() => {
        loadCategory()
    // eslint-disable-next-line
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(name === ''){
            setFailNameMessage('Nome deve estar preenchido.')
            setFailedName(true)
        }else if(name.length < 3){
            setFailNameMessage('Nome deve ter mais de 3 caracteres')
            setFailedName(true)
        }if(description === ''){
            setFailDescriptionMessage('Descrição deve estar preenchida.')
            setFailedDescription(true)
        }else if(description.length < 5){
            setFailDescriptionMessage('Descrição deve ter mais de 3 caracteres')
            setFailedDescription(true)
        }

        try{
            const response = await api.patch(`/categories/update/${id}`, {
                category: {
                    name,
                    description
                }
            })
            if(response.data){
                alert('Categoria atualizada.', 'success')
                history.push('/categories')
            }
        }catch(error){
            alert('Erro, tente novamente.', 'error')
        }

    }

    return(
        <Page>
            <Container>
                <h1>Editar Categoria {name}</h1>
                <Form 
                    onSubmit={handleSubmit}
                    title='Categoria'
                    text='Modifique os campos para editar sua categoria.'
                    button={
                        <>
                            <Button format='submit' type='submit'>Atualizar Categoria</Button>
                            <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
                        </>
                    }
                >
                    <Input
                        label='Nome'
                        value={name}
                        onChange={(value) => setName(value.target.value)}
                        failed={failedName}
                        failMessage={failNameMessage}
                    />
                    <TextArea
                        label='Descrição'
                        value={description}
                        onChange={(value) => setDescription(value.target.value)}
                        failed={failedDescription}
                        failMessage={failDescriptionMessage}
                    />
                </Form>
            </Container>
        </Page>
    )

}

export default CategoriesUpdate