import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    color: #3A3B3C;
    h1 {
        color:#3A3B3C;
        font-size:2.813rem;
        margin: 2rem 0 2rem 0;
        text-align: center;
    }
    h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
    }
    .fluxo {
        display: flex;
        text-align: center;
        margin: auto;
        h5 {
            font-size: 1.5rem;
            margin: 0;
            font-weight: 600;
            span {
                background: -webkit-linear-gradient(270deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
    form {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        align-items: center;
    }
    .sign-up-form {
        min-width: 80vw;
        margin: auto;
    }
    .form-1 {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .sub-form {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        label {
            margin-right: 3rem;
        }
    }
    .sub-form-left {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        
        label {
            margin-right: 3rem;
        }
        .title-1 {
            display: flex;
            padding-right: 3.3rem;
            margin: 0;
        }
        .title-2 {
            padding-top: 2rem;
            display: flex;
            padding-right: 1rem;
            margin: 0;
        }
        .rg, .birth { flex: 1 }
    }
    .select_input {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        .select {
            line-height: 33px;
            padding: 0.9rem 0rem;
            margin-right: 0.625rem;
            width: 418px;
        }
    }
    .select_input_right {
        width: 50%;
        display: flex;
        justify-content: start;
        .select {
            line-height: 33px;
            padding: 0.9rem 0rem;
            margin-left: 0.625rem;
            width: 418px;
        }
    }
    .select_input_2 {
        margin-left: 20px;
        margin-right: 0.5rem;
        .select {
            line-height: 32px;
            padding: 0.9rem 0 0 0;
            padding-left: 1%;
            width: 264px;
        }
    }
    .select_input_right_2 {
        margin-left: 10px;
        .select {
            line-height: 32px;
            padding: 0.9rem 0rem;
            padding-left: 1%;
            width: 140px;
        }
    }

    .div-recaptcha {
        display: flex;
        margin: 0 auto;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .checkbox {
        width: 159px;
        padding: 1rem 0 0 0;
    }
    .div_button {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 3rem;
        padding-right: 2.3rem;
        margin-left: 1rem;
        .button {
            width: 300px;
        }
    }
    
    .signup-div{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10vh;
        .signup-button {
            max-width: 35rem;
        }
    }
    .div-recaptcha {
        display: flex;
        margin: 0 auto;
        padding-top: 2rem;
    }
    .choose-payment {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }
    .boleto{
        display:flex;
        flex-direction: column;
        width: 52%;
        border: 1px;
        border-style: hidden hidden solid hidden;
        padding-bottom: 2rem;
        color: #3A3B3C;
        h5{
            text-align: justify;
            font-size:1.75rem;
            font-weight: 600;
            
        }
        h2{
            text-align: justify;
            margin-top: -2rem;
          
            font-weight: 400;    
            font-size:1.125rem;
            width: 100%;
        }
        Button{
            border-radius: 5px;
            font-size:1.125rem;
            color:#FFF;
            width: 21rem;
            height:2.8rem;
            margin: 0rem !important;
            background-color:#007AFF;
            display:flex;
            justify-content: center;
            align-items: center;
            text-decoration: none !important;
        }
        
    }
    
    .cartaoall{
        display:flex;
        flex-direction: column;
        width: 52%;
        border: 1px;
        border-style: hidden hidden solid hidden;
        padding-bottom: 2rem;
        color: #3A3B3C;
        
        .Cartao{
            display:flex;
            flex-direction: column;
            width: 100%;
            
            h3{
        
                font-size:1.75rem;
                font-weight: 600;
            }
            h4{
                margin-top: -1rem;
                font-weight: 400;
            
                font-size:1.125rem;
               
            }
            Button{
                border-radius: 5px;
                font-size:1.125rem;
                color:#FFF;
                width:21rem;
                height: 3rem;
                margin: 0rem !important;
                background-color:#007AFF;
                display:flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
            }
            
        }
        .cardpics{
            margin-top: 3rem;
            
        
            svg {
                height: 4.2rem;
                width: 40%;
            
            }
        }
    }
    @media screen and (max-width: 1400px){
        .select_input { .select {
                padding-right: 0.6rem;
        } }
        .select_input_right { .select {
                padding-left: 1.5%;
        } }
    }
    @media screen and (max-width: 1300px){
        .select_input { .select {
                padding-right: 0.5%;
        } }
        .select_input_right { .select {
                padding-left: 1.7%;
        } }
    }
    @media screen and (max-width: 1300px){
        .select_input { .select {
                padding-right: 0%;
        } }
    }
    @media screen and (max-width: 1010px){
        h1 {
            font-size: 2.2rem;
        }
        .sub-form {
            width:100%;
            display: flex;
            justify-content: center;
        }
        .sub-form-left {
            width:100%;
            display: flex;
            justify-content: center;
        }
        .select_input {
            width: 100%;
            justify-content: center;
            .select {
                margin: 0;
            }
        }
        .select_input_right {
            width: 100%;
            justify-content: center;
            .select {
                margin: 0;
                padding-left: 0;
            }
        }
        .select_input_right_2 {
            width: 135px;
            display: flex;
            margin: 0;
            justify-content: flex-end;
        }
        .select_input_2 { .select {
            width: 261px;
            margin: 0;
        } }
    }
    @media screen and (max-width: 610px){
        display: flex;
        justify-content: center;
        margin: 0;
        h1 {
            font-size: 1.8rem;
        }
        h2 {
            font-size: 1.3rem;
        }
        .sub-form {
            width:90%;
            margin-left: 10%;
            display: flex;
            flex-direction: column;
        }
        .sub-form-left, .select_input_right_2 {
            width:90%;
            margin-left: 10%;
            display: flex;
            flex-direction: column;
        }
        .select_input {
            justify-content: start;
            width:90%;
            margin-left: 10%;
            padding: 0.4rem 0rem 0rem 0.65rem;
            .select {
                width:85.3%;
            }
        }
        .select_input_right {
            justify-content: start;
            width:90%;
            margin-left: 10%;
            padding-left: 0;
            /* padding: 0.4rem 0rem 0.4rem 0.65rem; */
            padding-left: 0.65rem;
            .select {
                width:85.3%;
            }
        }
        .select_input_right_2 { 
            width:90%;
            margin: 0;
            padding-left: 0.35rem;
            .select {
                width:92.5%;
        } }
        .select_input_2 { 
            display: flex;
            justify-content: start;
            margin-left: 1%;
            .select {
                width: 98.5%;
        } }
        .checkbox {
            padding:  1.3rem 4%;
        }
        .div_button {
            justify-content: center;
            padding: 0;
            margin-right: 10%;
            margin-left: 10%;
            .button {
                width: 95%;
            }
        }
        .div-recaptcha {
            padding-bottom: 1rem;
        }
    }
    @media screen and (max-width: 500px){
        .sub-form {
            margin-left: 8%;
        }
        .sub-form-left {
            margin-left: 8%;
        }
        .select_input {
            margin-left: 8%;
            width: 88.5%;
        }
        .select_input_right {
            margin-left: 8%;
            width: 88.5%;
        }
        .select_input_2 { 
            margin-left: 8%;
        }
        .select_input_right_2 {
            .select {
                width: 93.5%;
            }
        }
        .div_button {
            margin-left: 8%;
        }
        
    }
    @media screen and (max-width: 400px){
        .sub-form {
            margin-left: 6%;
        }
        .sub-form-left {
            margin-left: 6%;
        }
        .select_input {
            margin-left: 6%;
            width: 89.8%;
        }
        .select_input_right {
            margin-left: 6%;
            width: 89.8%;
        }
        .select_input_right_2 {
            .select {
                margin-left: 0.1rem;
                width: 94.5%;
            }
        }
        .div_button {
            margin-left: 6%;
            margin-right: 6%;
        }
    }
    @media screen and (max-width: 350px){
        .sub-form {
            margin-left: 4%;
        }
        .sub-form-left {
            margin-left: 4%;
        }
        .select_input {
            margin-left: 4%;
            width: 90.5%;
        }
        .select_input_right {
            margin-left: 4%;
            width: 90.5%;
        }
        .select_input_right_2 { .select {
            margin-left: 0.1rem;
            width: 95.5%;
        } }
        .div_button {
            margin-left: 4%;
            margin-right: 4%;
        }
    }
    @media screen and (max-width: 300px){
        .select_input {
            width: 92%;
        } 
        .select_input_right {
            width: 92%;
        }
        .select_input_right_2 { .select {
            width: 96.8%;
        } }
    }
`