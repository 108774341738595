import { useUserContext } from "../../components/contexts/useUserContext"
import { Container, ProfileCard, BookingCard, LogoutCard } from "./styles"
import UserPlaceholder from '../../assets/user-placeholder.png'
import Button from '../../components/Button'
import { Link } from "react-router-dom"
import { photo } from "../../../src/services/photo"
import pdf from '../../assets/Terms/Regimento Interno Vai de Ferias - 29032022.pdf'

function ClientProfile() {
    const { user, logout } = useUserContext()

    const formatDate = (dateInput) => {
        let year = dateInput.slice(0,4)
        let month = dateInput.slice(5,7)
        let day = dateInput.slice(8,10)
        return(`${day}/${month}/${year}`);
    }
    
    return (
        <>        
        <Container>
            <div className='title'>
            <h1>Meu perfil</h1>
            <h2>Olá, <span>{user? user.name:''}!</span> </h2>
            </div>
            <ProfileCard>
                <div className='client-info'>
                    <div className='profile-pic'>
                        <img alt="Foto de Perfil" src={user.client_image_url? `${photo.defaults.baseURL + user.client_image_url}`: UserPlaceholder} />
                    </div>
                </div>

                <p className='sub-title'>Informações Pessoais</p>
                <div className="account">
                    <div className='client-account'>
                        <p>Nome</p>
                        <span>{user? user.name:''}</span>
                    </div>
                    <div className='client-email'>
                        <p>E-mail</p>
                        <span>{user? user.email:''}</span>
                    </div>
                    <div className='client-account'>
                        <p>CPF</p>
                        <span>{user.cpf}</span>
                    </div>
                    <div className='client-account'>
                        <p>RG</p>
                        <span>{user.rg}</span>
                    </div>
                    <div className='client-account'>
                        <p>Data de Nascimento</p>
                        <span>{formatDate(user.birthdate)}</span>
                    </div>
                    <div className='client-account'>
                        <p>Telefone</p>
                        <span>({user.ddd}) {user.phone}</span>
                    </div>
                </div>

                <p className='sub-title'>Endereço</p>
                <div className="account">
                    <div className='client-account'>
                        <p>Cidade</p>
                        <span>{user.city}</span>
                    </div>
                    <div className='client-account'>
                        <p>Estado</p>
                        <span>{user.state}</span>
                    </div>

                    <div className='client-account'>
                        <p>Bairro</p>
                        <span>{user.neighborhood}</span>
                    </div>
                    <div className='client-account'>
                        <p>Rua</p>
                        <span>{user.street}, {user.street_number}</span>
                    </div>
                    <div className='client-account'>
                        <p>CEP</p>
                        <span>{user.cep}</span>
                    </div>
                </div>

                <div className='custom-button' style={{marginTop: '2rem'}}>
                    <Link to='profile/edit'>
                        <Button format='profile' type='submit'>Editar perfil</Button>
                    </Link>
                </div>
            </ProfileCard>  

            <BookingCard>
                <div className='client-history'>
                    <Link to="/user/my-requests">
                        <Button format='profile'>Ver meus pacotes e reservas</Button>
                    </Link>
                </div>
            </BookingCard>

            <LogoutCard>
                <div className='client-history'>
                    <a href={pdf} download="REGIMENTO-INTERNO_VAI-DE-FERIAS">
                        <Button format='profile'>Download do Regimento Interno</Button>
                    </a>
                </div>
                <div className='logout-btn'>
                    <Button
                        format='logout'
                        onClick={logout}
                    > Sair de todos os dispositivos logados </Button>
                </div>
            </LogoutCard>
        </Container>
        </>
    )
}

export default ClientProfile