import ArrowBack from '../../../components/ArrowBack'
import { Container, Page } from './styles';
import Button from '../../../components/Button';
import { useState, useEffect } from 'react';
import { api } from '../../../services/api';
import { useUserContext } from '../../../components/contexts/useUserContext';
import { Link } from 'react-router-dom';
import RequestModal from "./RequestModal";

const MyRequests = () => {

    const { user } = useUserContext();
    const [showRequests, setShowRequests] = useState(true);
    const [userBookings, setUserBookings] = useState([]);
    const [userPackages, setUserPackages] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    // Modal values
    const [name, setName] = useState('');
    const [cpf, setCpf] = useState('');
    const [ddd, setDdd] = useState('');
    const [phone, setPhone] = useState('');
    const [destination, setDestination] = useState('');
    const [room, setRoom] = useState('');
    const [adults, setAdults] = useState(0);
    const [children, setChildren] = useState(0);
    const [hotel, setHotel] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [status, setStatus] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [cep, setCep] = useState('');
    const [roomCapacity, setRoomCapacity] = useState(0);
    const [roomDescription, setRoomDescription] = useState('');
    const [mapsLink, setMapsLink] = useState('');
    const [bookingNumber, setBookingNumber] = useState('');
    const [bookingDate, setBookingDate] = useState('');
    const [email, setEmail] = useState('');
    const [observations, setObservations] = useState('');
    const [generalInfo, setGeneralInfo] = useState('');

    const handleOpenModal = (
            name, cpf, ddd, phone, destination, room,
            adults, children, hotel, startTime, endTime,
            status, neighborhood, street, streetNumber,
            cep, capacity, description, mapsLink, email,
            bookingNumber, bookingDate, observations,
            generalInfo 
    ) => {
        setOpenModal(true);
        setName(name);
        setCpf(cpf);
        setDdd(ddd);
        setPhone(phone);
        setDestination(destination);
        setRoom(room);
        setAdults(adults);
        setChildren(children);
        setHotel(hotel);
        setStartTime(startTime);
        setEndTime(endTime);
        setStatus(status);
        setNeighborhood(neighborhood);
        setStreet(street);
        setStreetNumber(streetNumber);
        setCep(cep);
        setRoomCapacity(capacity);
        setRoomDescription(description);
        setMapsLink(mapsLink);
        setEmail(email);
        setBookingNumber(bookingNumber);
        setBookingDate(bookingDate);
        setObservations(observations);
        setGeneralInfo(generalInfo);
    }

    useEffect(() => {
        if (user){
            api.get(`/bookings/client_index/${user.id}`)
            .then(response => setUserBookings(response.data));
            api.get(`/packages/client_index/${user.id}`)
            .then(response => setUserPackages(response.data));
        }
    }, [user])

    const formatDate = (dateInput) => {
        let d = new Date(dateInput)
        let ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('pt-br', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
        return(`${da}/${mo}/${ye}`);
    }

    return (
        <Page>
            {openModal && <RequestModal
                closeModal={setOpenModal} name={name} cpf={cpf} ddd={ddd} phone={phone}
                destination={destination} room={room} adults={adults} children={children}
                hotel={hotel} startTime={startTime} endTime={endTime} status={status}
                neighborhood={neighborhood} street={street} streetNumber={streetNumber}
                cep={cep} roomCapacity={roomCapacity} roomDescription={roomDescription}
                map={mapsLink} bookingNumber={bookingNumber} bookingDate={bookingDate}
                email={email} observations={observations} info={generalInfo}
            />}
            <ArrowBack/>
            <Container>
                <h1>Suas Solicitações de Reservas e Pacotes</h1>
                <div>
                    <div className="tabs">
                        <h4 className={showRequests ? 'active' : ''} onClick={() => setShowRequests(true)}>Solicitações de Reserva</h4>
                        <h4 className={!showRequests ? 'active' : ''} onClick={() => setShowRequests(false)}>Meus Pacotes</h4>
                    </div>
                    {showRequests &&
                        <div>
                            <div className="row">
                                <h6 className="sm-col">Hotel</h6>
                                <h6 className="md-col">Periodo de Estadia</h6>
                                <h6 className="sm-col">Status da Reserva</h6>
                                <h6 className="sm-col">Data de Confirmação</h6>
                                <span className="md-col"/>
                            </div>
                            <ul>
                                {userBookings.map((booking, index) => {
                                    return(
                                        <li key={index}>
                                            <span className="sm-col">
                                                <h6 className="responsive-header">Hotel:</h6>
                                                <p>{booking.hotel?.name}</p>
                                            </span>
                                            <span className="md-col">
                                                <h6 className="responsive-header">Período de Estadia:</h6>
                                                <p>{`${formatDate(booking.start_time)} - ${formatDate(booking.end_time)}`}</p>
                                            </span>
                                            <span className="sm-col">
                                                <h6 className="responsive-header">Status da Reserva:</h6>
                                                <p className={booking.status}>{booking.status}</p>
                                            </span>
                                            <span className="sm-col">
                                                <h6 className="responsive-header">Data de Confirmação:</h6>
                                                <p>
                                                    {booking.status === 'Aprovado' || booking.status === 'Confirmado' ?
                                                    formatDate(booking.updated_at) : 
                                                    '-'}
                                                </p>
                                            </span>
                                            <span className="md-col">
                                                <Button onClick={() => handleOpenModal(
                                                        booking.name, booking.cpf, booking.ddd, booking.telephone,
                                                        booking.destination, booking.room_type?.name, booking.adults,
                                                        booking.children, booking.hotel?.name, booking.start_time,
                                                        booking.end_time, booking.status,
                                                        booking.hotel?.neighborhood, booking.hotel?.street,
                                                        booking.hotel?.street_number, booking.hotel?.cep,
                                                        booking.room_type?.capacity, booking.room_type?.description,
                                                        booking.hotel?.link_maps, booking.client?.email, booking.id,
                                                        booking.created_at, 'proibido animais', booking.hotel?.informations
                                                    )} format="submit">
                                                    Ver Mais
                                                </Button>
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    }
                    {!showRequests &&
                        <div>
                            <div className="row">
                                <h6 className="sm-col">Número do Pacote</h6>
                                <h6 className="lg-col">
                                    Pacote <br/> 
                                    Método de pagamento
                                </h6>
                                <h6 className="md-col">Status do Pacote</h6>
                                <h6 className="md-col">Diárias restantes</h6>
                                <span className="md-col"/>
                            </div>
                            <ul>
                                {userPackages.map((userPackage, index) => {
                                    return(
                                        <li key={index}>
                                            <span className="sm-col">
                                                <h6 className="responsive-header">Número do Pacote:</h6>
                                                <p>{userPackage.id}</p>
                                            </span>
                                            <span className="lg-col">
                                                <h6 className="responsive-header">
                                                    Pacote <br/> 
                                                    Método de pagamento:
                                                </h6>
                                                <p>{userPackage.package_plan?.name}</p>
                                            </span>
                                            <span className="sm-col">
                                                <h6 className="responsive-header">Status do Pacote:</h6>
                                                <p className={userPackage.is_active ? 'ativo' : 'inativo'}>
                                                    {userPackage.is_active ? 'Ativo' : 'Inativo'}
                                                </p>
                                            </span>
                                            <span className="md-col">
                                                <h6 className="responsive-header">Diárias restantes:</h6> 
                                                <p>
                                                    {`${userPackage.available_vacation_days || 0} diária${userPackage.available_vacation_days !== 1 ? 's' : ''}`}
                                                </p>
                                            </span>
                                            <span className="md-col">
                                                <Button format="submit">
                                                    <Link to={`/user/my-requests/package-info/${userPackage.id}`}>
                                                        Ver Mais
                                                    </Link>
                                                </Button>
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    }
                </div>
            </Container>
        </Page>
    )
}

export default MyRequests;