import styled from "styled-components"

export const Container = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  
  .image {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .rec-carousel-wrapper {
    position: relative;
    justify-content: flex-end;

    .rec-pagination {
      position: absolute;
      bottom: 1rem;
      gap: .75rem;

      .rec-dot {
        box-shadow: none;
        box-sizing: content-box;
        border: 3px solid #fff;

        @media screen and (max-width: 720px){
          border: 2px solid #fff;
        }
      }

      .rec-dot_active {
        background-color: #fff;
      }
    }
  }
  
  button { // Arrow do Carousel
    color: black;
  }

  .rec-arrow{
    position: absolute;
    box-shadow: none;
    background-color: transparent;
    color: white;
    z-index: 1;
    align-self: center;
    border: none;

    &:hover, &:focus {
      background: transparent !important;
      box-shadow: none;
      color: #3A3B3C;
    }
  }

  .rec-arrow-right {right: 0}

  .rec-arrow-left {left: 0}

  @media (max-width: 1000px) {
    margin: 0rem;


  }
`