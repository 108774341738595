import styled from 'styled-components';

export const ContainerWrapper = styled.section`
    width: 100%;
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;
    color: #3A3B3C;
    
    .header-style {
        padding: 0 20px;

        h1 {
            font-size: 50px;
            font-weight: bold;
            font-family: Poppins;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            gap: 1rem;

            img {height: 4.5rem}
        }
    }

    @media(max-width: 768px) {
        .header-style {            
            h1 {
                font-size: 2rem;

                img {height: 3rem}
            }

            p {text-align: justify}
        }
    }

    @media(max-width: 411px) {
        .header-style {

            h1 {
                font-size: 1.5rem;

                img {height: 2.5rem}
            }
            
            p {
                font-size: 16px;
            }
        }
    }

`;

export const ContainerCards = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    justify-content: center;
    column-gap: 3rem;
    row-gap: 1rem;
    padding: 4rem 3rem 1rem;

    @media(max-width: 411px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        padding: 1rem;
    }
`;

export const ButtonContainer = styled.div`
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
    Button {
        padding-top: .5rem;
        padding-bottom: .5rem;

        @media screen and (max-width: 480px){
            font-size: 1rem;
            font-weight: 500;
        }
    }

    h4{
        font-size: 2rem;
        font-weight: 500;
        margin: 0 0 0 2rem;
        color: #BF1827;
        font-family: 'Poppins', sans-serif;
    }


    @media screen and (max-width: 1080px) {
        margin: .25rem;

        Button{width: auto}

        h4 {margin-left: 0}
    }
`;