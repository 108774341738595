// Arquivo do CRUD de bookings
import { useState, useEffect } from 'react'
import { Container } from "./styles"
import { api } from "../../../services/api"
import { useUserContext } from "../../../components/contexts/useUserContext";

const MyBookings = () => {
  const { user } = useUserContext();

  const [bookings, setBookings] = useState([]);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    api.get('bookings/client_index/'+user.id)
    .then((response) => {setBookings(response.data)})

    api.get('room_types/index/')
    .then((response) => {setRooms(response.data)})
  },[user])

  const formatDate = (dateInput) => {
    let d = new Date(dateInput)
    let ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('pt-br', { month: 'long' }).format(d);
    let da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
    return(`${da} de ${mo} de ${ye}`);
  }

    return (
        <Container>
          <p>Reservas de {user.name}</p>
          
          <table>
            <thead>
              <tr>
                <td>Status</td>
                <td>Ínicio</td>
                <td>Fim</td>
                <td>Quarto</td>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, _) => {
                return(
                <tr key={booking.id}>
                  <td>{booking.status}</td>
                  <td>{formatDate(booking.start_time)}</td>
                  <td>{formatDate(booking.end_time)}</td>
                  <td>
                    {
                      rooms.map((room, index) => {
                        if (room.id === booking.room_type_id)
                          return(room.name)
                        else
                          return ""
                     })
                    }
                  </td>
                </tr>
              )})}
            </tbody>
           
          </table>
        </Container>
    )
}

export default MyBookings
