import styled, { css } from "styled-components";

export const Container = styled.div`
    span {
        color: red;
    }
`

export const InputContainer = styled.div`
    position: relative;
    margin: 2rem 0;
    font-family: 'Poppins', 'sans-serif';
    font-weight: 400;
    padding: 0.625rem;

    label {
        position: absolute;
        pointer-events: none;
        top: 1.8rem;
        left: 1rem;
        transition: .2s ease;
        color: #CBCBCB;
    }

    ${props => (props.isFocus) &&
        css`
            input {
                outline: none !important;
                border-bottom: 1px solid #007AFF !important;
                border-color: #007AFF !important;
            }
        `
    }

    ${props => (props.isFilled) &&
        css`
            input {
                outline: none !important;
                border-bottom: 1px solid #000 !important;
                border-color: #000 !important;
            }
        `
    }


    ${props => (props.failed) &&
        css`
            input {
                border-color: red !important;
                color: red !important;
            }
        `
    }

    ${props => (props.lessMargin) && 
        css`
            margin: 0rem;
        `
    }
    
    input {
        border: none;
        border-bottom: 1px solid #E2E4E5;
        border-radius: 0;
        margin-top: 1.5rem;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 1rem;

        :focus ~ label,
        :not(:focus):valid ~ label{
            top: 0;
            left: -.5rem;
            opacity: 1;
            font-size: .8rem;
            font-weight: 600;
        }

        :focus ~ label {
            color: #007AFF;
        }

        :not(:focus):valid ~ label {
            color: black;
        }
    }
`