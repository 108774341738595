import { useState, useEffect } from 'react'
import { 
    InnerContainer, 
    LeftSection, 
    OutterContainer, 
    RightSection, 
    Info 
} from "./styles"
import Button from "../../../components/Button"
import {alert} from "../../../components/Alert"
import { api } from "../../../services/api"
import { useLocation, useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import Carousel from 'react-elastic-carousel'
import { useUserContext } from "../../../components/contexts/useUserContext"
import { photo } from "../../../../src/services/photo"
import ArrowBack from '../../../components/ArrowBack'
import Tag from '../../../components/Tag'

const ShowHotel = () => {
    const { user } = useUserContext();
    const location = useLocation()
    const {id} = useParams()
    const [adminPermissions, setAdminPermissions] = useState([]);
	const [hotel, setHotel] = useState(location.state?.hotel || {})
	const [images, setImages] = useState([])

	const history = useHistory()

    useEffect(() => {
		api.get(`hotels/show/${id}`)
		.then((response) => {
		setHotel(response.data)	
		})
      }, [id, location])

	useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          let permissions = []
          response.data.forEach((permission) => {
            if (user.permissions.indexOf(permission.id) !== -1)
              permissions.push(permission.name)
          })
          setAdminPermissions(permissions)
        })
    }, [user])

    const handleSubmit = async (e) => {
      	e.preventDefault()
      	try{
          	const formData = new FormData ()

          	for(let i = 0; i < images.length; i++){
              	formData.append('images[]', images[i])
          }

          	const response = await api.post(`/hotels/add_images/${id}`, formData)

          	if(response.data){
              	alert('Fotos adicionadas', 'success');
				setHotel(response.data)
          	}
      	}catch(error){
          	alert('Erro, tente novamente.','error')
      }
    }

	const deleteImages = async () => {
        try{
            if(window.confirm('Você tem certeza?')){
                await api.delete(`/hotels/delete_images/${id}`)
                history.push('/hotels')
            }
        }catch(e){
            alert('Erro, tente novamente', 'error')
        }
    }

    return (
        <OutterContainer>
            <ArrowBack />
            <h1> Hotel - {hotel?.name} </h1>
    	    <InnerContainer>
                <LeftSection>
                    <div className='upperPart'>
                        <Carousel className="image-container">
                            {hotel.images_url?.map((image, index) => {return(
                                <img src={`${photo.defaults.baseURL + image}`} alt="Hotel" key={index}/>
                            )})}
                        </Carousel>
                        <div className='tags'>
                            {hotel?.tags?.map(tag => <Tag name={tag.name} iconName={tag.icon_name} key={tag.id} />)}
                        </div>
                    </div>
                    <div className="actions">
                        { adminPermissions.indexOf('modify_hotels') !== -1 &&  
                        <> 
                            <form onSubmit={handleSubmit}>
                                {images.length === 0 ? <label for='file'>Selecione fotos</label> : <label for='file'>{images.length} foto(s) selecionadas</label>}
                                <input
                                    placeholder='Fotos' type='file'	multiple name='file' id='file'
                                    onChange={(value) => setImages(value.target.files)}
                                />
                                <Button format='submit' type='submit' className='button'>Adicionar fotos</Button>
                            </form>
                            <div className='links'>
                                <Button format='submit' className='submit' onClick={deleteImages} type='button'>Deletar TODAS as Fotos</Button>
                                <Button format='submit' className='submit' type='button' onClick={() => history.push(`/hotels/update/${hotel?.id}`)}>Editar</Button>
                                <Button format='submit' className='submit' type='button' onClick={() => history.push(`/rooms/index/${hotel?.name}`)}>Ver Tipos de Quartos</Button>
                            </div>
                        </>
                        }
                    </div>
                </LeftSection>
                <RightSection>
                    <Info>
                        <h6>Descrição</h6>
                        <p>
                            {hotel?.description}
                        </p>
                    </Info>
                    <Info>
                        <h6>Endereço</h6>
                        <p>
                            {hotel?.street}, {hotel?.street_number}, {hotel?.neighborhood} ({hotel?.cep})
                        </p>
                    </Info>
                    <Info>
                        <h6>Mapa</h6>
                        <div dangerouslySetInnerHTML={{__html: hotel?.link_maps}}></div>
                    </Info>
                    <Info>
                        <h6>Informações para o Voucher:</h6>
                        <p>
                            {hotel?.information}
                        </p>
                    </Info>
                </RightSection>
            </InnerContainer>
        </OutterContainer>
    )
}

export default ShowHotel