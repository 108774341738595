import axios from "axios"
import ArrowBack from "../../../../components/ArrowBack"
import { Container } from "./styles.js"
import { useParams, useHistory } from "react-router-dom"
import { useState, useEffect } from 'react'
import { api } from "../../../../services/api"
import Button from "../../../../components/Button"
import { alert } from "../../../../components/Alert"
import { Page } from "../../../AdminDashboard/styles"


const PaymentCreditCard = () => {

    const { id } = useParams()
    const history = useHistory()

    // eslint-disable-next-line
    const [pageNumber, setPageNumber] = useState(1)

    // Dados enviado para o pagar.me
    // eslint-disable-next-line
    const [holder_name, setHolder_name] = useState('');
    // eslint-disable-next-line
    const [number, setNumber] = useState('');
    // eslint-disable-next-line
    const [exp_month, setExp_month] = useState('');
    // eslint-disable-next-line
    const [exp_year, setExp_year] = useState('');
    // eslint-disable-next-line
    const [cvv, setCvv] = useState('');

    const payment_method = 'credit_card'
    const [client, setClient] = useState({})
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        api.get(`clients/show/${id}`)
            .then((response) => {
                setClient(response.data)
            })
    }, [id])

    const handleSubmit = async () => {
        setDisableButton(true);
        try {
            const response = await axios.post("https://api.pagar.me/core/v5/tokens?appId=pk_test_DVBM2VmikrIOlZY7", {
                type: 'card',
                card: {
                    number,
                    holder_name,
                    exp_month,
                    exp_year,
                    cvv,
                }
            });
            if (response.data) {
                try {
                    const pacotes = await api.post("packages/create", {
                        payment_method,
                        card_hash: response.data.id,
                        package: {
                            client_id: client.id,
                            package_plan_id: 1,
                            available_vacation_days: 7,
                            is_active: true
                        }
                    });
                    if (pacotes.data) {
                        alert(`O Pacote do cliente #${client.id} foi aprovado!`, 'success');
                        history.push("/");
                    }
                } catch (error) {
                    alert('Erro no processamento do pacote. Tente novamente', 'error');
                }
            }
        } catch (error) {
            alert('Erro na integração de pagamentos. Tente novamente', 'error');
        }
    }

    return (
        <Page>
            <Container>
                <ArrowBack />
                <h2>Confirme o Pedido do cliente</h2>
                <div className='info'>
                    <p><span>Nome:</span> {client.name}</p>
                    <p><span>CPF:</span> {client.cpf}</p>
                    <p><span>Email:</span> {client.email}</p>
                    <p><span>Valor:</span> 24 x R$99,00</p>
                    <p><span>Método de Pagamento: </span>Cartão de Crédito</p>
                    <p><span>Número do cartão:</span> **** **** **** {number.slice(-4)}</p>
                    <p><span>Nome no cartão:</span> {holder_name}</p>
                    <p><span>Validade do cartão:</span> {exp_month}/{exp_year}</p>
                </div>
                <Button type='submit' format='submit' cursor={disableButton && "wait"} onClick={() => !disableButton && handleSubmit()}>Confirmar Pedido</Button>
            </Container>
        </Page>
    )
}

export default PaymentCreditCard