import InputMask from 'react-input-mask'
import { useState } from "react"
import { Container, InputContainer } from "../InputSignUp/styles"

const MaskInput = ({ Icon, format, failed, failMessage, label, ...props}) => {

    const [isFocus, setIsFocus] = useState(false)
    const [isFilled, setIsFilled] = useState(false)

    const handleOnFocus = () => {
        setIsFilled(false)
        setIsFocus(true)
    }

    const handleOnBlur = (event) => {
        setIsFocus(false)
        setIsFilled(!!event.target.value)
    }

    return (
        <Container>
            <InputContainer isFocus={isFocus} isFilled={isFilled} failed={failed} format={format}>
                <InputMask
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    {...props}
                    required
                />
                <label>{label} {(Icon) ? (<Icon size={18} />) : (<></>)}</label>
            </InputContainer>
            {failed && <span>{failMessage}</span>}
        </Container>
    )
}

export default MaskInput