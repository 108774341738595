import { useState } from "react";
import Button from '../../../../components/Button';
import {alert} from '../../../../components/Alert';
import logo from '../../../../assets/vdf_logo-black.png'
import {
    ModalBackground,
    ModalContainer,
    Title,
    Check,
    CloseBtn,
    DivColor,
    TextModal,
    SubTitle,
    SubText,
    LogoImg,
    DivImage
  } from "./styled";

const ModalPJ = ({ closeModal, handleSubmit }) => {

    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        setChecked(!checked)  
    }

    const verifyCheckboxIsChecked = () => {
        if (checked) {
            handleSubmit()
        } else {
            alert("Para continuar, aceite os contrato de prestação de serviços", 'warn')
        }
    }
    
    return (
        <DivColor>
            <ModalBackground>
                <ModalContainer>
                    <CloseBtn onClick={() => closeModal(false)}>X</CloseBtn>
                    <DivImage className="landing-logo">
                        <LogoImg className="homepage-logo" src={logo} alt="logomarca da página" />
                    </DivImage>
                    <Title>Termos de uso</Title>
                    <SubText>CONTRATO DE PARCERIA PARA REVENDA DE CLUBE DE FÉRIAS</SubText>
                    <TextModal>
                    <b>AMERIS – REDE DE HOTÉIS INDEPENDENTES LTDA.</b>, pessoa jurídica de direito privado,
                    sediada no ST SHIN CA 01 Lote A Bloco A Sala 352 – Brasília – DF, CEP: 71.503-501,
                    inscrita no CNPJ sob n° 30.249.497/0001-47, representada na forma do seu contrato
                    social, adiante designada somente como “<b>CONTRATANTE</b>”, enquanto administradora do
                    Clube “<b>VAI DE FÉRIAS</b>”, adiante designado somente como “<b>CLUBE</b>”,
                    e, <b>REVENDEDOR</b> que confirmou o aceite ao presente contrato.
                    </TextModal>
                    <TextModal>
                    Pelo presente instrumento particular, e na melhor forma de direito, as partes a seguir qualificadas:
                    </TextModal>
                    <SubTitle>1. DO OBJETO</SubTitle>
                    <TextModal>
                    <b>1.1. </b>O presente Contrato tem por objeto estabelecer as condições pelas quais o <b>REVENDEDOR</b> promoverá a venda dos planos de hospedagem do clube “VAI DE FÉRIAS” administrado pela <b>CONTRATANTE</b>.    
                    </TextModal>
                    <TextModal>
                    <b>1.2. </b>O presente contrato será firmado através da inserção dos dados do <b>REVENDEDOR</b> no site <a href="https://www.vaideferias.com.br" target="_blank" rel="noreferrer">www.vaideferias.com.br</a>, com a devida ciência de todos os termos aqui dispostos.
                    </TextModal>
                    <SubTitle>2. DAS CONDIÇÕES GERAIS</SubTitle>
                    <TextModal>
                    <b>2.1. </b>O <b>REVENDEDOR</b> está autorizado a realizar os Planos de Hospedagem disponibilizados pela <b>CONTRATANTE</b> mediante uso de marcas e identificações desta.
                    </TextModal>
                    <TextModal>
                    <b>2.2. </b>Para cada cliente que contratar os serviços objeto deste contrato, o <b>REVENDEDOR</b> deverá celebrar um contrato, conforme modelo fornecido pela <b>CONTRATANTE</b>, contendo todas as condições estabelecidas.
                    </TextModal>
                    <TextModal>
                    <b>2.3. </b>O <b>REVENDEDOR</b> no ato da comercialização deverá dar ciência ao CLIENTE dos termos do Regimento Interno do Clube “Vai de Férias” disponibilizado no site da <b>CONTRATANTE</b> (www.vaideferias.com.br).
                    </TextModal>
                    <TextModal>
                    <b>2.4. </b>As especificações e características dos serviços prestados aos clientes do revendedor podem ser modificadas de forma unilateral pela <b>CONTRATANTE</b>, conforme as regras presentes no Regimento Interno do Clube “Vai de Férias”.
                    </TextModal>
                    <TextModal>
                    <b>2.5. </b>A cada novo contrato celebrado, o <b>REVENDEDOR</b> deverá enviar as especificações para a prestação dos serviços, contendo todas as informações sobre <b>CLIENTE</b>, conforme modelo fornecido pela <b>CONTRATANTE</b>.
                    </TextModal>
                    <TextModal>
                    <b>2.6. </b>A <b>CONTRATANTE</b> não se responsabiliza pelo cancelamento generalizado do serviço prestado, assim considerado a prestação dos serviços aos clientes da <b>CONTRATANTE</b> e do <b>REVENDEDOR</b>, por indisponibilidade técnica, decisão empresarial ou qualquer outro motivo, permanecendo válidas as condições contratadas vigentes para os demais serviços não atingidos pelo ora referido cancelamento.
                    </TextModal>
                    <TextModal>
                    <b>2.7. </b>A responsabilidade da <b>CONTRATANTE</b> perante terceiros está restrita ao que envolve o funcionamento do <b>CLUBE</b>, constituindo responsabilidade do <b>REVENDEDOR</b> responder por eventuais reclamações dos clientes perante qualquer Juízo ou instância judicial, bem como procedimentos administrativos em qualquer órgão público decorrentes. bem como, em face de terceiros em geral no que tange aos serviços regulados neste instrumento.
                    </TextModal>
                    <TextModal>
                    <b>2.8. </b>Sem prejuízo da cláusula 2.7 acima, de qualquer forma, a responsabilidade da <b>CONTRATANTE</b> neste contrato está restrita aos danos diretos, excluindo-se lucros cessantes, danos emergentes, danos de imagem, expectativas de direitos e outros.
                    </TextModal>
                    <TextModal>
                    <b>2.9. </b>A tabela de preços utilizada pelo <b>REVENDEDOR</b> será fornecida pela <b>CONTRATANTE</b>, e eventuais descontos só poderão ser concedidos com a devida autorização, por escrito, da <b>CONTRATANTE</b>.
                    </TextModal>
                    <SubTitle>3. DA POLÍTICA DE NÃO CONCORRÊNCIA</SubTitle>
                    <TextModal>
                    <b>3.1. </b>O <b>REVENDEDOR</b> não poderá iniciar ou manter relações comerciais que envolvam o mesmo serviço, com prospects e clientes que estejam sendo atendidos pela <b>CONTRATANTE</b> ou por outros revendedores.
                    </TextModal>
                    <TextModal>
                    <b>3.2. </b>A <b>CONTRATANTE</b> garante através deste instrumento que todas as negociações com clientes ou prospects conduzidas pelo <b>REVENDEDOR</b> serão preservadas com exclusividade a este.
                    </TextModal>
                    <TextModal>
                    <b>3.3. </b>O <b>REVENDEDOR</b> não poderá atuar como fornecedor de serviços similares, destinados ao mesmo fim, fornecido por empresas concorrentes, durante toda a vigência deste Contrato.
                    </TextModal>
                    <SubTitle>4. DO ATENDIMENTO AOS CLIENTES</SubTitle>
                    <TextModal>
                    <b>4.1. </b>É de responsabilidade do <b>REVENDEDOR</b> garantir o fornecimento das corretas informações aos <b>CLIENTES</b>, resolução de dúvidas e acompanhamento durante todo o processo de venda.
                    </TextModal>
                    <TextModal>
                    <b>4.2. </b>Todos os contatos serão realizados apenas entre a <b>CONTRATANTE</b> e o <b>REVENDEDOR</b>, especificadamente a qualquer solicitação esclarecimento adicionais que sejam solicitados pelos <b>CLIENTES</b>.
                    </TextModal>
                    <SubTitle>5. DA FORMA DE REMUNERAÇÃO</SubTitle>
                    <TextModal>
                    <b>5.1. </b>Em razão da parceria ora firmada, pagará a <b>CONTRATANTE</b> ao <b>REVENDEDOR</b>, como remuneração pela venda realizada, o percentual de 5% (cinco por cento) do valor do Plano de Hospedagem vendido.
                    </TextModal>
                    <TextModal>
                    <b>5.1.1. </b>Será considerado para a remuneração supracitada os contratos devidamente assinados entre a <b>CONTRATANTE</b> e os clientes captados pela <b>REVENDEDOR</b>, bem como, o pagamento somente será realizado após a compensação bancária do primeiro boleto do pacote vendido.
                    </TextModal>
                    <TextModal>
                    <b>5.2. </b>O Planos de Hospedagem vendidos pelo <b>REVENDEDOR</b> serão atribuídas à conta deste no aplicativo do <b>CLUBE</b> através do QR Code remete a indicação realizada pelo revendedor.
                    </TextModal>
                    <TextModal>
                    <b>5.3. </b>O valor devido ao <b>REVENDEDOR</b> será depositado em 05 (cinco) úteis após o cumprimento dos requisitos da Cláusula 5.1.1, em conta bancária de titularidade do <b>REVENDEDOR</b>.
                    </TextModal>
                    <TextModal>
                    <b>5.3.1. </b>Em nenhuma hipótese a remuneração devida ao <b>REVENDEDOR</b> será depositada em contas de terceiros.
                    </TextModal>
                    <TextModal>
                    <b>5.4. </b>Se houver o atraso no pagamento por mais de 60 (sessenta) dias corridos após a data de vencimento, poderá o <b>REVENDEDOR</b> suspender os serviços até o efetivo pagamento, bem como poderá rescindir o presente instrumento.
                    </TextModal>
                    <TextModal>
                    <b>5.5. </b>Todos os pagamentos do presente contrato devem ser feitos com a devida apresentação de Nota Fiscal.
                    </TextModal>
                    <TextModal>
                    <b>5.6. </b>Todos os pagamentos deverão ser realizados após as devidas deduções de impostos presentes ou futuros, incluindo, mas não se limitando, o ISS, PIS, COFINS, impostos locais ou outros encargos (coletivamente "Imposto Local") e encargos previdenciários.
                    </TextModal>
                    <TextModal>
                    <b>5.7. </b>O <b>REVENDEDOR</b> poderá optar, ao invés do recebimento em depósito bancário, pela troca em um dos Planos de Hospedagem disponibilizados pela <b>CONTRATANTE</b>.
                    </TextModal>
                    <TextModal>
                    <b>5.7.1. </b>O <b>REVENDEDOR</b> ao optar pelo recebimento da remuneração na forma de Plano de Hospedagem, deverá se submeter a todas as regras e condições do Regimento Interno do clube “Vai de Férias”.
                    </TextModal>
                    <TextModal>
                    <b>5.8. </b>Para que seja possível a troca por um dos Planos de Hospedagem fornecidos pela <b>CONTRATANTE</b> o <b>REVENDEDOR</b> deverá ter realizado a venda de no mínimo 10 (dez) Planos de Hospedagem a <b>CLIENTES</b>.
                    </TextModal>
                    <SubTitle>6. DA VIGÊNCIA</SubTitle>
                    <TextModal>
                    <b>6.1. </b>Este Contrato entra em vigor na data de confirmação do aceito no site <a href="https://www.vaideferias.com.br" target="_blank" rel="noreferrer">www.vaideferias.com.br</a> e vigerá por prazo indeterminado, facultando-se às partes rescindi-lo de pleno direito, sem ônus a qualquer tempo, mediante comunicação à outra parte.
                    </TextModal>
                    <SubTitle>7. DA CONFIDENCIALIDADE</SubTitle>
                    <TextModal>
                    <b>7.1. </b>As partes convencionam que toda e qualquer informação transferida entre elas em decorrência deste contrato, será considerada confidencial e privilegiada, e não será divulgada a terceiros sem o expresso consentimento de ambas.
                    </TextModal>
                    <TextModal>
                    <b>7.2. </b>O <b>REVENDEDOR</b> se compromete a obter termo de confidencialidade de seus funcionários diretamente relacionados à execução dos serviços contratados pelos seus clientes.
                    </TextModal>
                    <SubTitle>8. CONDIÇÕES GERAIS</SubTitle>
                    <TextModal>
                    <b>8.1. </b>O presente Contrato não estabelece entre as Partes, qualquer vínculo de imagem, natureza societária, associativa ou empregatícia, e nem estabelece relação de representação comercial, ficando restritas as obrigações e direitos ao objeto do presente contrato.
                    </TextModal>
                    <TextModal>
                    <b>8.2. </b>Em razão das peculiaridades do presente instrumento, não se formará vínculo de emprego entre a <b>CONTRATANTE</b> e os empregados, prepostos, representantes, sócios ou diretores do <b>REVENDEDOR</b>, restando a esta a total responsabilidade pelo pagamento de salários e demais verbas trabalhistas aos seus empregados, prepostos, representantes, diretores e demais envolvidos na sua operação, bem como pelo recolhimento das contribuições previdenciárias, fiscais e fundiárias sempre que devidas, atuando em absoluto estado de autonomia e sem qualquer subordinação laboral à <b>CONTRATANTE</b>.
                    </TextModal>
                    <TextModal>
                    <b>8.3. </b>Nenhum atraso, omissão ou falha no exercício de qualquer direito ou faculdade prevista na lei ou neste Contrato, será tido como novação ou renúncia a tal direito ou faculdade, nem como aquiescência ao evento que lhe tenha dado origem. A renúncia de qualquer das partes a direito ou faculdade conferida neste Contrato deverá ser manifestada sempre por escrito.
                    </TextModal>
                    <TextModal>
                    <b>8.4. </b>Não será admitida qualquer alteração, modificação ou acréscimo ao presente Contrato, salvo mediante avença escrita assinada pelas partes.
                    </TextModal>
                    <TextModal>
                    <b>8.5. </b>Fica eleito o Foro de Brasília - DF, como competente para dirimir todas e quaisquer questões oriundas da interpretação do presente Contrato, com renúncia de qualquer outro, por privilegiado que seja.
                    </TextModal>
                    <Check>
                        <label>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={handleChange}
                            />
                            <span>Confirmo a aceitação do contrato de Revendedor(a), estando ciente de todos os seus termos e condições.</span>
                        </label>
                    </Check>
                    <Button format="submit" onClick={() => verifyCheckboxIsChecked()}>OK</Button>
                    
                </ModalContainer>
            </ModalBackground>
        </DivColor>
    );
};

export default ModalPJ;