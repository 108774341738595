import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useUserContext } from "../components/contexts/useUserContext";

import {Navbar} from '../components/Navbar'

import HomePage from '../pages/HomePage'

import Faq from '../pages/FAQ'

import MyBookings from '../pages/User/MyBookings'
import MyRequests from '../pages/User/MyRequests';
import PackageInfo from '../pages/User/MyRequests/PackageInfo';

import LoginPageC from '../pages/LoginPage/clients'
import LoginPageR from '../pages/LoginPage/retailers'
import LoginPageA from '../pages/LoginPage/admin'
import SignUpPage from '../pages/SignUpPage'

import Destination1 from '../pages/SelectDestinations/Destination1'
import Destination2 from '../pages/SelectDestinations/Destination2'
import Destination3 from '../pages/SelectDestinations/Destination3'
import Destination4 from '../pages/SelectDestinations/Destination4'

import RetailerCreate from '../pages/Retailers/Create';

import Contacts from '../pages/Contacts';
import ClientProfile from '../pages/ClientProfile';
import ClientUpdate from '../pages/ClientUpdate';

import RetailerProfile from '../pages/RetailersDashboard/RetailerProfile';
import AdminProfile from '../pages/AdminDashboard/AdminProfile';

import PaymentPage from '../pages/PaymentMethods'

import PageHowItWork from '../pages/HowItWork';
import BeARetailer from '../pages/BeARetailer';

import VoucherPage from '../pages/Voucher';

import RetailerUpdate from '../pages/RetailersDashboard/RetailerUpdate';
import AdminEdit from '../pages/AdminDashboard/AdminEdit';

import PasswordRecovery from '../pages/PasswordRecovery';
import PasswordNew from '../pages/PasswordNew';

const Routes = () => {
    const { user } = useUserContext()
    
    return (
        <Switch>

            <Route exact path='/' component={HomePage}/>

            <React.Fragment>
                <Navbar/>
                <Route exact path='/sign_up/:id?'>
                    {user? <Redirect to='/' /> : <SignUpPage></SignUpPage>}
                </Route>
                
                <Route exact path='/retailer/sign_up/:id?'>
                    {user? <Redirect to='/' /> : <RetailerCreate></RetailerCreate>}
                </Route>
                
                <Route exact path='/contacts' component={Contacts}/>

                <Route exact path = '/payment' component={PaymentPage}/> 

                <Route exact path='/guide' component={PageHowItWork}/>

                <Route exact path='/retailer/guide' component={BeARetailer}/>

                <Route exact path='/FAQ' component={Faq}/>

                <Route exact path='/voucher/:info?' component={VoucherPage}/>

                <Route exact path='/vacation' component={Destination1}/>
                <Route exact path='/vacation/destination/:categoryId' component={Destination2}/>

                {user && user.type === 'client' ? 
                <>
                    <Route exact path= '/profile' component = {ClientProfile}/>
                    <Route exact path= '/profile/edit' component = {ClientUpdate}/>
                </>
                : user && user.type === 'retailer' ? 
                <>
                    <Route exact path= '/profile' component = {RetailerProfile}/>
                    <Route exact path='/profile/edit' component={RetailerUpdate}/>
                </>
                : user && user.type === 'admin' ? 
                <>
                    <Route exact path= '/profile' component = {AdminProfile}/> 
                    <Route exact path='/profile/edit' component={AdminEdit} />
                </>
                : ''}
                
                { user
                    ?
                    <>
                        <Route exact path='/vacation/city/:categoryId/:destinationId' component={Destination3}/>
                        <Route exact path='/vacation/hotel/:id' component={Destination4}/>

                        <Route exact path='/user/my-bookings' component={MyBookings}/>
                        <Route exact path='/user/my-requests' component={MyRequests}/>
                        <Route exact path='/user/my-requests/package-info/:id' component={PackageInfo}/>
                    </>
                    :
                    <>
                        <Route exact path='/login' component={LoginPageC}/>
                        <Route exact path='/login/retailer' component={LoginPageR}/>
                        <Route exact path='/login/admin' component={LoginPageA}/>

                        <Route exact path="/recovery" component={PasswordRecovery}/>
                        <Route exact path="/recovery/:email/:token?" component={PasswordNew}/>
                        <Route exact path="/retailer/recovery" component={PasswordRecovery}/>
                        <Route exact path="/retailer/recovery/:email/:token?" component={PasswordNew}/>
                        <Route exact path="/admin/recovery" component={PasswordRecovery}/>
                        <Route exact path="/admin/recovery/:email/:token?" component={PasswordNew}/>
                    </>
                    
                }
            </React.Fragment>
        </Switch>
    )
}

export default Routes
