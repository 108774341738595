import { Container, Grid1, Grid2, RedGrid, OrangeGrid, Info } from "./styles"
import Button from '../../components/Button'
import { CarouselGrid } from '../../components/Carousel/Grid'
import Beneficios3 from '../../assets/photo-buy.jpeg'
import { useUserContext } from "../../components/contexts/useUserContext"
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import OrderConfirmation from "../../components/OrderConfirmation"
import { NavbarHome } from "../../components/NavbarHome"
import { Navbar } from "../../components/Navbar"
import logo from "../../assets/logo.png";
import landing_photo from "../../assets/landing-photo.png";
import ameris from "../../assets/ameris-white.png";
import {ReactComponent as RightArrows} from '../../assets/right-arrows.svg';
import { useState, useEffect } from "react"
import {alert} from "../../components/Alert"

const HomePage = () => {

    const { success } = useParams()
    const [displayNav, setDisplayNav] = useState('none');
    const [toggle, setToggle] = useState(true);
    const [sideBarActive, setSideBarActive] = useState(false)
    const { user } = useUserContext();
    const history = useHistory();
    const location = useLocation();

    const homeSideBarState = {
        state: sideBarActive,
        setState: setSideBarActive,
    }

    const goToBuyPage = () => {
        history.push('/sign_up')
        history.go(0)
    }

    useEffect(() => {
        let hash = location.hash
        if (hash !== '') {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) element.scrollIntoView()
        }
    }, [location]);

    useEffect(() => {
        const intervalId = setTimeout(() => {
            setToggle((toggle) => !toggle)
        }, 5000)

        return () => clearInterval(intervalId);
    }, [toggle]);

    useEffect(() => {
        if(user && user.type === 'client' && user.is_active === false){
            alert("Sua conta se encontra inativa. Algumas funcionalidades do sistema estão restritas.", 'warn')
        }
    }, [user]);
    
    const getPhrase = (toggle) => {
        return (
            toggle ?
            <h2 className="toggle-text-1">
                Adquira nosso pacote com 
                <span>7 diárias</span>
                e Vai de Férias!
            </h2>
            :
            <h2 className="toggle-text-2">
                Hotéis e pousadas que cabem no seu
                <span>bolso</span>
                !
            </h2>
        )
    }

    window.addEventListener('scroll', () => {
        if(window.scrollY > window.innerHeight) {
            setDisplayNav('flex');
        }
        else {
            setDisplayNav('none');
        }
    });



    return (
        <Container >
            <NavbarHome className='nav-home' sidebarState={homeSideBarState}/>
            {success === '1' ?
            <OrderConfirmation confirmation_number='1'/>
            :
            success === '2' ?
            <OrderConfirmation confirmation_number='2'/>
            :
            ''
            }
            <Grid1>
                <div className="landing-logo">
                    <img className="homepage-logo" onClick={() => goToBuyPage()} src={logo} alt="logomarca da página" />
                    <Link to="/guide">
                        <Button format="return">CONHEÇA NOSSO PROGRAMA DE FÉRIAS</Button>
                    </Link>
                </div>
                <div className="landing-text">
                    <RightArrows fill="#9E1900" />
                    {getPhrase(toggle)}
                </div>
                <img className="photo" src={landing_photo} alt="Mulher feliz com mala de viagem"/>
            </Grid1>

            <Grid2 displayNav={displayNav}>
                <Navbar className='nav-geral' sidebarState={homeSideBarState}/>
                <h2>Descubra o melhor destino para suas férias</h2>
                <CarouselGrid />
                <div className='justify-center'>
                    <Link to='/vacation'>
                        <Button className="Home-btn" format='home'>Veja mais destinos</Button>
                    </ Link>
                </div>
            </Grid2>
            
            <RedGrid id="Quem-Somos">
                <div className="title">
                    <h2>Quem Somos</h2>
                    <img src={ameris} alt="Logo da Ameris" />
                </div>
                <span className="WhoAreWe">Um Programa de Férias que cabe no seu bolso e realiza seu sonho de fazer aquela viagem inesquecível. <br /><br />
                        São 7 dias de hospedagem nos melhores destinos do Brasil.<br /><br />
                        Idealizado pela Ameris Hotéis, uma rede de Hotéis Independentes fundada em 2018, estamos na 3ª posição no ranking das maiores cadeias hoteleiras do Brasil, segundo o “Relatório Hotelaria em Números 2021”, da JLL. 
                </span>
            </RedGrid>

            <Grid2>
                <h2> 7 motivos para apertar o <Link className="play-link" to="/sign_up">play</Link> do Vai de Férias:</h2>
                <ul className="beneficios">
                    <li key="days" className="diarias"><p>  7 diárias nos melhores destinos do Brasil</p></li>
                    <li key="payment" className="pagamento"><p>  Pagamento em 24x sem juros</p></li>
                    <li key="instalments" className="parcelamento"><p>  Parcelamento no boleto</p></li>
                    <li key="wait" className="carencia"><p>  Carência de apenas 30 dias</p></li>
                    <li key="hotel" className="hospedagem"><p>  Até dois anos para reservar sua hospedagem</p></li>
                    <li key="coffee" className="cafe"><p>  Café da manhã incluso</p></li>
                    <li key="intitute" className="mcdonald"><p>  O programa apoia o instituto Ronald McDonald</p></li>
                </ul>
            </Grid2>

            <OrangeGrid>
                <h2>Como Adquirir Suas Férias</h2>
                <div className='info'>
                    <img src={Beneficios3} alt='como fazer parte' />
                    <div className='text'>
                        <p>Para dar um play em suas Férias é muito simples, adquira agora o pacote e desfrute de momentos inesquecíveis</p>
                        <Link to="/sign_up">
                            <Button className="Home-btn" format='home'>Vamos!</Button>
                        </Link>
                    </div>
                </div>
            </OrangeGrid>
        
            <Info>
                <div className="title">
                    <h2 style={{paddingTop: 50}}>Seja um Revendedor</h2>
                </div>
                <span>Seja um revendedor do programa Vai de Férias, indicando para familiares e amigos, e ganhe uma renda extra ou até mesmo um pacote do nosso programa.</span>
                <Link to='/retailer/sign_up'>
                    <div>
                    <Button format='home' style={{width: '9rem'}}>Cadastre-se</Button>
                    </div>
                </ Link>
            </Info>
        </Container>
    )
}

export default HomePage
