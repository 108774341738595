import styled from 'styled-components';

export const Page = styled.section`
    min-height: calc(100vh - 4.5rem); // Navbar size
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    color: #3A3B3C;
    width: 100%;
    overflow-x: scroll;

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    @media screen and (max-width: 992px){
        margin-left: unset;
        padding: 4rem 0;
    }
`;

export const Container = styled.div`
    width: 90%;
    margin: auto;
    color: #3A3B3C;

    h1 {
        font-size: 3.125rem;
        font-weight: 700;
        margin: 0 0 2rem;
    }

    h5 {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
    }
   
    .filter {
        display: flex;
        width: 100%;

        > div {
            width: 30rem;
            padding: 0;
            margin: 1.5rem 0;
        }
    } 

    .cards {
       display: flex;
       flex-wrap: wrap;
       justify-content: center;
       padding: 1rem 0;
       gap: 1rem;

        > div{
            width: 30%;
            min-width: 20rem;
            min-height: 12rem;
        }
    }

    @media screen and (max-width: 1440px){
        .cards {
            justify-content: space-around;
            
            > div { width: 45% }
        }
    }

    @media screen and (max-width: 1080px){
        .cards {
            justify-content: center;
            
            > div { width: 100% }
        }
    }

    /* @media screen and (max-width: 992px) {
        .filter { justify-content: center }

        h1 { text-align: center }
    } */

    @media screen and (max-width: 576px){
        h1 {
            font-size: 1.6rem;
            text-align: center;
        }

        .cards > div {
            min-width: unset;
            min-height: unset;
        }
    }
`;
