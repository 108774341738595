import React from "react"

import { ContainerWrapper, ButtonContainer } from './styles'
import Button from "../../components/Button";
import { Link } from 'react-router-dom';


function BeARetailer() {

    return(
        <>
            <ContainerWrapper>
                <div className="header-style">
                    <h1>Seja um Revendedor</h1>
                    <p className="sub-title">Seja um Revendedor <b>VAI DE FÉRIAS</b>, pacotes de hospedagens que cabem no bolso.
                    <br></br>
                    Não é necessário nenhum investimento. É só se cadastrar!!!!</p>
                </div>
                <div className="text-container">
                    <p>Ser um revendedor do <b>PROGRAMA VAI DE FÉRIAS</b> é conquistar uma nova renda ao seu plano financeiro.</p>
                    <p>A cada pacote vendido você garante <b>5%</b> do valor da venda. Quanto mais vender mais você vai lucrar.</p>
                    <p>Para facilitar suas vendas você receberá um <b>QRCODE</b> exclusivo, que poderá ser encaminhado aos amigos e familiares. A sua comissão está garantida a cada venda concretizada a partir de seu <b>QRCODE</b>.</p>
                    <p>O cadastro é fácil e rápido!</p>
                    <h1>Passo 1:</h1>
                    <p>Clique em Quero ser um Revendedor, faça o seu cadastro, leia o regulamento e dê o aceite.</p>
                    <h1>Passo 2:</h1>
                    <p>Aprovado o cadastro você receberá uma mensagem do nosso time Vai de Férias. Na área do Revendedor com seu login e senha, é possível acessar sua conta no Programa, encaminhar seu <b>QRCODE</b> e acompanhar as suas comissões.</p>
                    <p>Agora é só dar o <b Style="color: #BF1827">Play</b> em seu cadastro e começar a vender!!!!</p>
                </div>
            </ContainerWrapper>
            <ButtonContainer>
                <Link to='/retailer/sign_up'>
                    <Button format="submit">Vire um revendedor agora</Button>
                </Link>
            </ButtonContainer>
        </>
    )
}

export default BeARetailer