import styled from "styled-components";

export const TagContainer = styled.div`
    background: #353839;
    color: #fff;
    display: flex;
    width: 10rem;
    margin: 0.2rem;
    padding: .5rem 0.2rem;
    border-radius: 5px;
    align-items: center;

    span {
        width: calc(100% - 2.5rem);
        margin-left: 0.5rem;
        text-align: center;
    }

    svg {
        font-size: 1rem;
    }
`