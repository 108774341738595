import { Container, FloatButton, Notification } from './styles'
import { FaHome, FaHotel, FaThList } from 'react-icons/fa'
import { RiMapPin2Fill, RiSideBarFill, RiCloseFill, RiDoorOpenFill, RiFileDownloadFill, RiFileDownloadLine } from 'react-icons/ri'
import { BsTagFill } from 'react-icons/bs'
import { Link } from 'react-router-dom';
import { useUserContext } from "../contexts/useUserContext";
import { useState, useEffect } from 'react'
import { api } from '../../services/api';

import ClientPDF from '../../assets/Terms/Regimento Interno Vai de Ferias - 29032022.pdf'
import PjPDF from '../../assets/Terms/Contrato Revendedor PJ - Vai de Ferias - 17032022.pdf'
import PfPDF from '../../assets/Terms/Contrato Revendedor PF - Vai de Ferias - 17032022.pdf'
import CessionPDF from '../../assets/Terms/Termo de Cessão de Diárias - 15032022.pdf'

export const Sidebar = () => {
  const { user } = useUserContext();
  const [sidebar, setSidebar] = useState(true);
  const [adminPermissions, setAdminPermissions] = useState([]);
  const [totalSignUpSolicitations, setTotalSignUpSolicitations] = useState([]);
  const [totalDestinationSolicitations, setTotalDestinationSolicitations] = useState([]);
  const [totalBookingSolicitations, setTotalBookingSolicitations] = useState([]);

  const showSidebar = () => {
    setSidebar(!sidebar)
  }

  useEffect(() => {
    api.get('/permissions/index')
    .then(response => {
      let permissions = []
      response.data.forEach((permission) => {
        if (user.permissions.indexOf(permission.id) !== -1)
          permissions.push(permission.name)
      })
      setAdminPermissions(permissions)
    })

    api.get('clients/total_records/', { params: { pending_approval: true } })
        .then((response) => {
          setTotalSignUpSolicitations(response.data)
    })

    api.get('clients/total_solicitacoes/')
        .then((response) => {
          setTotalDestinationSolicitations(response.data)
    })

    api.get('bookings/total_pending/')
        .then((response) => {
          setTotalBookingSolicitations(response.data)
    })
  }, [user])

  return (
    <>
    <FloatButton onClick={showSidebar}>
      <RiSideBarFill className='icon'/>
    </FloatButton>
    <Container active={sidebar}>
      <div className='sidebar'>
        <RiCloseFill className='icon close' onClick={showSidebar}/>
        <div className='top-list'>
          <span>Usuário Logado:</span>
          <span>{user?.name}</span>
          <Link to='/dashboard' className="text-link">
          <div className='title'>
            <FaHome className='icon'/>
            <span>Página Inicial</span>
          </div>
          </Link>
        </div>
        <div className='midle-list'>
          <ul>
            <Link to='/destinations' className="text-link">
              <li><RiMapPin2Fill className='icon'/><span>Destinos</span></li>
            </Link>
            <Link to='/hotels' className="text-link">
              <li><FaHotel className='icon'/><span>Hotéis</span></li>
            </Link>
            <Link to='/tags' className="text-link">
              <li><BsTagFill className='icon'/><span>Tags</span></li>
            </Link>
            <Link to='/categories' className="text-link">
              <li><FaThList className='icon'/><span>Categorias</span></li>
            </Link>
            <Link to='/rooms' className="text-link">
              <li><RiDoorOpenFill className='icon'/><span>Tipos de Quarto</span></li>
            </Link>
          </ul>
        </div>
        <div className='bottom-list'>
          <ul>
            { adminPermissions.indexOf('modify_access_clients') !== -1 &&
              <>
              { totalSignUpSolicitations > 0 ? 
              <Notification><span>{totalSignUpSolicitations}</span></Notification>
              :
              ''
              } 
              <Link to='/manager/clients/approval' className="text-link">
                <li><span>Solicitações de Cadastro</span></li>
              </Link>
              </>
            }
            { adminPermissions.indexOf('index_clients') !== -1 &&
              <>
                { totalDestinationSolicitations > 0 ? 
                  <Notification><span>{totalDestinationSolicitations}</span></Notification>
                :
                ''
                }
                <Link to='/manager/clients_solicitations' className="text-link">
                  <li><span>Solicitações de Destinos</span></li>
                </Link>
              </>
            }
            { adminPermissions.indexOf('index_retailers') !== -1 &&
              <>
                { totalBookingSolicitations > 0 ? 
                  <Notification><span>{totalBookingSolicitations}</span></Notification>
                  :
                  ''
                }
                <Link to='/manager/booking_requests' className="text-link">
                  <li><span>Solicitações de Reservas</span></li>
                </Link>
              </>
            }
            <Link to='/bookings' className="text-link">
              <li><span>Reservas</span></li>
            </Link>
            { adminPermissions.indexOf('index_clients') !== -1 &&
                <Link to='/manager/clients' className="text-link">
                  <li><span>Usuários</span></li>
                </Link>
            }
            { adminPermissions.indexOf('index_retailers') !== -1 &&
              <Link to='/manager/retailers' className="text-link">
                <li><span>Revendedores</span></li>
              </Link>
            }
            { adminPermissions.indexOf('index_retailers') !== -1 &&
              <Link to='/manager/commission_payments' className="text-link">
                <li><span>Comissão dos Revendedores</span></li>
              </Link>
            }
            { adminPermissions.indexOf('modify_permissions') !== -1 &&
              <Link to='/manager/admins' className="text-link">
                <li><span>Administradores</span></li>
              </Link>
            }
            <Link to='/dashboard/payment' className="text-link">
              <li><span>Opções de pagamento</span></li>
            </Link>
          </ul>
        </div>
        <div className='terms-list'>
          <ul>
            <a href={PfPDF} download="CONTRATO_REVENDEDOR_PF">
              <li><RiFileDownloadFill className='icon'/><span>Contrato Revendedor PF</span></li>
            </a>
            <a href={PjPDF} download="CONTRATO_REVENDEDOR_PJ">
            <li><RiFileDownloadFill className='icon'/><span>Contrato Revendedor PJ</span></li>
            </a>
            <a href={ClientPDF} download="REGIMENTO-INTERNO_VAI-DE-FERIAS">
              <li><RiFileDownloadLine className='icon'/><span>Termos do Cliente</span></li>
            </a>
            <a href={CessionPDF} download="TERMO_CESSAO_DIARIAS">
              <li><RiFileDownloadLine className='icon'/><span>Termo Cessão de Diárias</span></li>
            </a>
          </ul>
        </div>
      </div>
    </Container>
    </>
  )
}

export default Sidebar