import React from 'react'
import { useHistory } from 'react-router-dom'

import { Container } from './styled'
import { HiArrowNarrowLeft } from 'react-icons/hi'

const ArrowBack = ({white, signUp, pageNumber, setPageNumber, ...props}) => {

    const history = useHistory()

    const handleClick = () => {
        
        if (signUp) {
            if (pageNumber === 1) {
                history.goBack();
                return
            }
            setPageNumber(pageNumber - 1)
            return
        }
        history.goBack();
    }

    return (
        <Container white={white === true} {...props}>
            <div className="div-style-arrow">
                <div className="div-wrapper-arrow" onClick={handleClick} to="">
                    <HiArrowNarrowLeft />
                    <span>Voltar</span>
                </div>
            </div>
        </Container>
    )
}

export default ArrowBack
