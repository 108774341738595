import styled from "styled-components";

export const Container = styled.div`
    background-color: #fff;
    border-radius: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    max-height: 90vh;
    max-width: 90vw;
    font-family: 'Poppins', sans-serif;
    Button {
        width: 90%;
    }

    @media screen and (max-width: 1024px){
        font-size: 1rem;
        h1 {
            padding: 1rem;
        }
    }
`