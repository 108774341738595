import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
    text-align: center;
    h2 {
        font-size: 500%;
        margin-bottom: 0;
        color: #3A3B3C;
        font-weight: 600;
    }
    img {
        height: 31.5rem;
        object-fit: cover;
    }
    .images {
        margin: 0 !important;
        width: 100%;
        height: 60vh;

        .image {height: 60vh}
    }
    p {
        font-size: 1.37rem;
        margin: 4rem 0rem;
        opacity: 0.9;
        width: 66rem;
        text-align: justify;
    }
    .center {
        display: flex;
        justify-content: center;
    }
    .grid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    @media screen and (max-width: 1076px){
        p {
            width: 44rem;
        }
        h2{
            font-size: 3rem;
            text-align:center;
            margin-left: 10%;
      }
    }
    @media screen and (max-width: 723px){
        p {
            width: 21rem;
        }
        h2{
            font-size: 2.5rem;
            text-align:center;
            margin-left: 5%;
        }
    }
`;

