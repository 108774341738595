import ArrowBack from '../../../components/ArrowBack'
import { Container, Page } from './styles';
import Button from '../../../components/Button';
import Title from '../../../components/Title'
import { useState, useEffect } from 'react';
import {HiPrinter} from 'react-icons/hi';
import { useUserContext } from '../../../components/contexts/useUserContext'
import { api } from '../../../services/api';
import { photo } from '../../../services/photo';

const RetailerCommission = () => {
    const { user } = useUserContext()
    const [commissionPayments, setCommissionPayments] = useState([{created_at: null, payment_value_in_cents: 0, payment_date: null}]);
    
    useEffect(() => {
        api.get(`/commission_payments/retailer_index/${user.id}`)
        .then(response => {
            setCommissionPayments(response.data)
        });
    }, [user])

    const formatDate = (dateInput) => {
        if(dateInput){
        let options = {year: 'numeric', month: '2-digit', day: '2-digit'};

        let d = new Date(dateInput + "T12:00:00-03:00")
        return Intl.DateTimeFormat('pt-br', options).format(d)
        }
    }

    const getCommissionValue = (commissionInCents) => {
        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return formatter.format(commissionInCents/100);
      }

    return (
        <Page>
            <ArrowBack/>
            <Container>
                <Title>Seus Recibos de Comissões</Title>
                <div>
                    <div className="tabs">
                        <h4 className={'active'}>Comissões</h4>
                    </div>
                        <div className="my-payments">
                            <div className="row">
                                <h6 className="sm-col">N° da Comissão</h6>
                                <h6 className="sm-col">Valor</h6>
                                <h6 className="md-col">Data do Pagamento</h6>
                                <h6 className="md-col">Download de Comprovante</h6>
                            </div>
                            <ul>
                                {commissionPayments.map((commissionPayment, key) => {return (
                                    <li key={key}>
                                        <span className="sm-col">
                                            <h6 className="responsive-header">Número do Pagamento:</h6>
                                            <p>{commissionPayment.id}</p>
                                        </span>
                                        <span className="sm-col">
                                            <h6 className="responsive-header">Valor do Pagamento:</h6>
                                            <p>{getCommissionValue(commissionPayment.payment_value_in_cents)}</p>
                                        </span>
                                        <span className="md-col">
                                            <h6 className="responsive-header">
                                                Data do Pagamento:
                                            </h6>
                                            <p>{formatDate(commissionPayment.payment_date)}</p>
                                        </span>
                                        <span className="md-col download-button">
                                                <Button onClick={()=>window.open(photo.defaults.baseURL+commissionPayment.payment_receipt_url, '_blank').focus()} format="submit"><HiPrinter/>Baixar Comprovante</Button>
                                        </span>
                                    </li>
                                )})}
                            </ul>
                        </div>
                </div>
            </Container>
        </Page>
    )
}

export default RetailerCommission;