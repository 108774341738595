import styled from "styled-components";

export const Container = styled.label`
    border: #9E9EA7 solid 2px;
    width: 70%;
    border-radius: 5px;
    margin: 2px auto;
    font-family: 'Poppins';
    display: flex;
    justify-content: center;
    align-items: center;

    input {
        border: none;
        outline: none;
        width: 90%;
        font-size: 0.9rem;
        font-family: inherit;
        color: #9E9EA7;
        padding: .5rem .8rem;
    }

    svg {
        color: #9E9EA7;
        font-size: 1.2rem;
        padding: .2rem;
    }
`;