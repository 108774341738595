import { useState, useEffect } from 'react'
import { Container } from './styles'
import { api } from '../../../../services/api'
import { Link } from 'react-router-dom'
import Button from '../../../../components/Button'
import { useUserContext } from '../../../../components/contexts/useUserContext'
import Pagination from '../../../../components/Pagination';
import { Page } from "../../../AdminDashboard/styles"

const RetailerManager = () => {
    const { user } = useUserContext()
    const [retailer, setRetailer] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0)
    const [showPermission, setShowPermission] = useState(false)
    const [showPermission2, setShowPermission2] = useState(false)
    const [showPermission3, setShowPermission3] = useState(false)

    const retailer_regain = (r) => {
      api.patch(`retailers/regain/${r.id}`)
      alert('O acesso do revendedor ao site foi autorizado!', 'success')
      r.is_active = true
      setRetailer(
        retailer.map((item) => {
          return item.id === r.id? r: item;
        })
      )
    }
    const retailer_revoke = (r) => {
      api.patch(`retailers/restrict/${r.id}`)
      alert('O acesso do revendedor ao site foi revogado!', 'success')
      r.is_active = false
      setRetailer(
        retailer.map((item) => {
          return item.id === r.id? r: item;
        })
      )
    }
    useEffect(() => {
      api.get('/permissions/index')
      .then(response => {
        response.data.forEach((permission) => {
          if (permission.name === 'show_retailers' && user.permissions.indexOf(permission.id) !== -1)
            setShowPermission(true)
        })
      })
    }, [user])

    useEffect(() => {
      api.get('/permissions/index')
      .then(response => {
        response.data.forEach((permission) => {
          if (permission.name === 'modify_access_retailers' &&  user.permissions.indexOf(permission.id) !== -1)
            setShowPermission2(true)
        })
      })
    }, [user])

    useEffect(() => {
      api.get('/permissions/index')
      .then(response => {
        response.data.forEach((permission) => {
          if (permission.name === 'modify_commission_payments' &&  user.permissions.indexOf(permission.id) !== -1)
            setShowPermission3(true)
        })
      })
    }, [user])

    useEffect(() => {
        window.scrollTo(0, 0)
        let offset = (currentPage - 1)*10
        api.get('retailers/index/'+offset)
        .then((response) => {
            setRetailer(response.data)
        })
        api.get('retailers/total_records/')
        .then((response) => {
          setTotalItems(response.data)
        })
      },[currentPage, user])

    return (
      <Page>
        <Container>
        <h1>Revendedores</h1>
        <table>
            <thead>
            <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Opções</th>
                <th>Gerenciar Acesso</th>
            </tr>
            </thead>
            <tbody>
            {retailer.map((retailer, index) => { return(
                <tr key={retailer.id}>
                <td>{retailer.name}</td>
                <td>{retailer.email}</td>
                <td className="buttons" style={{minWidth: "35%"}}>
                    { showPermission &&                     
                        <Link to={{ pathname: `retailers/show/${retailer.id}`, state: { retailer }}}>
                            <Button className="info" format="show">Ver</Button>
                        </Link>
                    }
                    { showPermission3 &&                     
                        <Link to={{ pathname: `commission_payments/show/${retailer.id}`, state: { retailer }}}>
                            <Button className="info" format="update">Comissão</Button>
                        </Link>
                    }
                </td>
                
                {retailer.is_active === false ? 
                <td onClick={ () => {retailer_regain(retailer)}}  className="buttons" style ={{minWidth: "35%"}}>
                 { showPermission2 &&   
                        <Button className="info" format="update">Permitir</Button>
                    
                }
                </td>
                :
                <td onClick={ () => {retailer_revoke(retailer)}}  className="buttons" style ={{minWidth: "35%"}}>
                 { showPermission2 &&   
                        <Button className="info" format="delete">Revogar</Button>
                    
                }
                </td>
                }
                </tr>
            )})}
            </tbody>
        </table>
        < div className = 'pagination-box'>
        <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalItems}
            pageSize={10}
            onPageChange={page => setCurrentPage(page)}
            />
        </ div>
        </Container>
      </Page>
    )
}

export default RetailerManager