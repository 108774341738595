import styled from 'styled-components';
import bg from '../../../assets/Destinations/bgDestination1.jpeg'

export const Background = styled.div`
    background-image: url(${bg});
    background-size: cover;
    background-position: bottom;
    min-height: 100vh;
`

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #3A3B3C;
    position: relative;
    z-index: 1;

    h2 {
        font-size: 3.12rem;
        margin-bottom: 0;
        color: #3A3B3C;
        font-weight: 600;
    }
    p{
        font-size: 1.37rem;
        opacity: 0.9;
    }
    .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        a {
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline #FCC046;
        }
    }
`;

export const Item = styled.div`
    width: 16.75rem;
    height: 31.8rem;
    margin: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    
    h2 {
        font-size: 3.12rem;
        color: #3A3B3C;
        margin: 0;
    }
    img {
        height: 2rem;
    }

    background-color:rgba(255, 255, 255, 0.7);
    position: relative;
    background-origin: border-box;
    border: solid 5px;
    border-image: linear-gradient(103.6deg, #FCC046 -13.96%, #CC2A55 58.95%, #BF1827 134.95%) 50;
    border-radius: 10px;
`;