import { useState } from "react";
import Button from '../../../../components/Button';
import {alert} from '../../../../components/Alert';
import {
    ModalBackground,
    ModalContainer,
    Title,
    Check,
    CloseBtn,
    DivColor,
    TextModal
  } from "./styled";

const ModalTermos = ({ closeModal, nextModal }) => {

    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        setChecked(!checked)  
    }

    const verifyCheckboxIsChecked = () => {
        if (checked) {
            closeModal(false)
            nextModal(true)
        } else {
            alert("Para continuar, aceite o termo de cessão de diárias", 'warn')
        }
    }
    
    return (
        <DivColor>
            <ModalBackground>
                <ModalContainer>
                    <CloseBtn onClick={() => closeModal(false)}>X</CloseBtn>
                    <Title>TERMO DE CESSÃO DE DIÁRIAS</Title>
                    <TextModal>
                    Confirmo a cessão de todos direitos e obrigações decorrentes da adesão ao Plano de
Hospedagem do programa “Vai de Férias”, conforme dados inseridos nesta oportunidade,
estando ciente de todos os termos e condições da cessão constante do Regulamento do
clube, nada mais havendo que reclamar no futuro.
                    </TextModal>
                    <Check>
                        <label>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={handleChange}
                            />
                            <span>Confirmo a aceitação do termo de Cessão de diárias, estando ciente de todos os seus termos e condições.</span>
                        </label>
                    </Check>
                    <Button format="submit" onClick={() => verifyCheckboxIsChecked()}>OK</Button>
                    
                </ModalContainer>
            </ModalBackground>
        </DivColor>
    );
};

export default ModalTermos;