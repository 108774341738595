import { useState, useEffect } from 'react'
import Button from "../../../components/Button"
import {alert} from "../../../components/Alert"
import { api } from "../../../services/api"
import { useLocation, useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import Carousel from 'react-elastic-carousel'
import { useUserContext } from "../../../components/contexts/useUserContext"
import { photo } from "../../../services/photo"
import { LeftSection, OutterContainer, RightSection, InnerContainer, Info } from '../../Hotels/showHotel/styles'
import ArrowBack from '../../../components/ArrowBack'

const ShowDestination = () => {
    const { user } = useUserContext();
    const location = useLocation()
    const {id} = useParams()
    const [adminPermissions, setAdminPermissions] = useState([]);
	const [destination, setDestination] = useState(location.state?.destination || {})
	const [images, setImages] = useState([])

	const history = useHistory()

    useEffect(() => {
		api.get(`destinations/show/${id}`)
		.then((response) => {
		setDestination(response.data)	
		})
      }, [id, location])

	useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          let permissions = []
          response.data.forEach((permission) => {
            if (user.permissions.indexOf(permission.id) !== -1)
              permissions.push(permission.name)
          })
          setAdminPermissions(permissions)
        })
    }, [user])

    const handleSubmit = async (e) => {
      	e.preventDefault()
      	try{
          	const formData = new FormData ()

          	for(let i = 0; i < images.length; i++){
              	formData.append('images[]', images[i])
          }

          	const response = await api.post(`/destinations/add_images/${id}`, formData)

          	if(response.data){
              	alert('Fotos adicionadas', 'success');
				setDestination(response.data)
          	}
      	}catch(error){
          	alert('Erro, tente novamente','error')
      }
    }

	const deleteImages = async () => {
        try{
            if(window.confirm('Você tem certeza?')){
                await api.delete(`/destinations/delete_images/${id}`)
                history.push('/destinations')
            }
        }catch(e){
            alert('Erro, tente novamente', 'error')
        }
    }

    return (
        <OutterContainer>
            <ArrowBack />
            <h1>Destino - {destination?.name}, {destination?.state?.abbreviation}</h1>
            <InnerContainer>
                <LeftSection>
                <Carousel className="image-container">
                        {destination.images_url?.map((image, index) => {return(
                            <img src={`${photo.defaults.baseURL + image}`} alt="Destino" key={index}/>
                        )})}
                    </Carousel>
                    <div className='actions'>
                    { adminPermissions.indexOf('modify_destinations') !== -1 &&   
                        <>
                        <form onSubmit={handleSubmit}>
                            {images.length === 0 ? <label for='file'>Selecione fotos</label> : <label for='file'>{images.length} foto(s) selecionadas</label>}
                            <input
                                placeholder='Fotos' type='file'	multiple name='file' id='file'
                                onChange={(value) => setImages(value.target.files)}
                            />
                            <Button format='submit' type='submit' className='button'>Adicionar fotos</Button>
                        </form>
                        <div className='links'>
                            <Button format='submit' className='delete' onClick={deleteImages} type='button'>Deletar TODAS as Fotos</Button>
                            <Button format='submit' className='edit' type='button' onClick={() => history.push(`/destinations/edit/${destination?.id}`)}>Editar</Button>
                            <Button format='submit' className='edit' type='button' onClick={() => history.push(`/hotels/index/${destination?.name}`)}>Ver Hotéis</Button>
                        </div>
                        </>
                    }
                    </div>
                </LeftSection>
                <RightSection>
                    <Info>
                        <h6>
                            Descrição
                        </h6>
                        <p>
                            {destination?.description}
                        </p>
                    </Info>
                </RightSection>
            </InnerContainer>
        </OutterContainer>
    )
}

export default ShowDestination
