import { useState, useEffect } from 'react'
import { Container } from "./styles"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import { api } from "../../../services/api"
import { useHistory } from "react-router-dom"
import Form from '../../../components/Forms'
import {alert} from '../../../components/Alert'
import { Page } from '../../AdminDashboard/styles'
import { useUserContext } from "../../../components/contexts/useUserContext"

const AdminInvite = () => {
  const history = useHistory();
  const { user } = useUserContext();
  const [showPermission, setShowPermission] = useState(false)

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const inviteAdmin = async (e) => {
    e.preventDefault();
    // TODO
    await api.post('admins/create', {
        name: name,
        email: email
    }).then((response) => {
      console.log(response)
      response.status===201 ? alert("O convite foi enviado com sucesso.", 'success') : alert("erro, tente novamente", 'error')
      history.push('/')
    }).catch((error) => {
      alert("Não foi possível enviar o convite. Tente novamente", 'error')
    })
  }

  useEffect(() => {
    api.get('/permissions/index')
    .then(response => {
      response.data.forEach((permission) => {
        if (permission.name === 'invite_admins' &&  user.permissions.indexOf(permission.id) !== -1)
          setShowPermission(true)
      })
    })
}, [user])

    return (
      <Page>
        <Container>
          <h1><span>Convidar Administrador</span></h1>
          <span>Convide alguém para fazer parte da equipe de administradores do site.</span>
          <Form
            onSubmit={inviteAdmin}
            title='Nome e Email do Administrador'
            text='Este também será o email usado para fazer login no site.'
            button={
              <>
                {
                    showPermission &&
                    <Button format='submit' type='submit'>Enviar Convite</Button>
                }
                  <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
              </>
            }
          >
            <Input label='Name' value={name} onChange={(e)=>setName(e.target.value)}></Input>
            <Input label='Email' value={email} onChange={(e)=>setEmail(e.target.value)}></Input>
          </Form>
        </Container>
      </Page>
    )
}

export default AdminInvite
