import { useState, useEffect } from 'react'
import { Container } from './styles'
import Button from '../../../../components/Button'
import { api } from '../../../../services/api' 
import { Link } from 'react-router-dom'
import { useUserContext } from '../../../../components/contexts/useUserContext'
import Pagination from '../../../../components/Pagination';
import { Page } from "../../../AdminDashboard/styles"

const ClientSolicitations = () => {
    const { user } = useUserContext()
    const [clients, setClients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [showPermission, setShowPermission] = useState(false)
    const [destinations, setDestinations] = useState([]);

    useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          response.data.forEach((permission) => {
            if (permission.name === 'show_clients' && user.permissions.indexOf(permission.id) !== -1)
              setShowPermission(true)
          })
        })
      }, [user])

    useEffect(() => {
        window.scrollTo(0, 0)
        let offset = (currentPage - 1)*10
        api.get('clients/index_solicitacoes/'+offset)
        .then((response) => {
            setClients(response.data)
        })
        api.get('clients/total_solicitacoes/')
        .then((response) => {
          setTotalItems(response.data)
        })
      },[currentPage, user])

    useEffect(() => {
      api.get('destinations/index')
        .then((response) => {
            setDestinations(response.data)
        })
    }, [])

    return (
      <Page>
        <Container>
        <h1>Solicitações de Destinos</h1>
        <table>
            <thead>
            <tr>
                <th>Nome do Cliente</th>
                <th>Destino Solicitado</th>
                <th>Opções</th>
            </tr>
            </thead>
            <tbody>
            {clients.map((client, index) => {
              var destino = ''
              for(var i =0; i < destinations.length; i++){
                if (destinations[i].id === parseInt(client.solicitacao_destino, 10)){
                  destino = destinations[i].name;
                  break;
                }
              }
              return(
                <tr key={client.id}>
                <td>{client.name}</td>
                <td>{destino}</td>
                <td className="buttons" style={{minWidth: "35%"}}>
                { showPermission &&  
                  <>   <Link to={`/manager/select_hotels/${client.id}`}>
                        <Button className='info' format="accept">Selecionar Hoteis</Button>
                      </Link>
                  </>
                       
                }
                </td>
                </tr>
            )})}
            </tbody>
        </table>
        < div className = 'pagination-box'>
            <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalItems}
            pageSize={10}
            onPageChange={page => setCurrentPage(page)}
            />
        </ div>
        </Container>
      </Page>
    )
}

export default ClientSolicitations