import { Container } from "./styles"

export const CarouselItem = ({city, state, country, image}) => {

    return(
        <Container >
            <div className='image'>
                <img src={image} alt={city} />
                <div className='text'>
                    <h3>{city}</h3>
                    <span>{state}, {country}</span>
                </div>
            </div>
        </Container>
    );
}