import { useUserContext } from "../../../components/contexts/useUserContext"
import { Container, ProfileCard, LogoutCard } from "../../ClientProfile/styles.js"
import UserPlaceholder from '../../../assets/user-placeholder.png'
import Button from '../../../components/Button'
import { Link } from "react-router-dom"
import { photo } from "../../../../src/services/photo"

function AdminProfile() {
    const { user, logout } = useUserContext()

    return (
        <>        
        <Container>
            <div className='title'>
                <h1>Meu perfil de Administrador</h1>
                <h2>Olá, <span>{user? user.name:''}!</span> </h2>
            </div>
            <ProfileCard>
                <div className='client-info'>
                    <div className='profile-pic'>
                        <img alt="Foto de Perfil" src={user.admin_image_url? `${photo.defaults.baseURL + user.admin_image_url}`: UserPlaceholder} />
                    </div>
                </div>

                <p className='sub-title'>Informações Pessoais</p>
                <div className="account">
                    <div className='client-account'>
                        <p>Nome</p>
                        <span>{user.name}</span>
                    </div>
                    <div className='client-account'>
                        <p>E-mail</p>
                        <span>{user.email}</span>
                    </div>
                </div>

                <div className='custom-button' style={{marginTop: '3rem'}}>
                    <Link to='/profile/edit'>
                        <Button format='profile' type='submit'>Editar perfil</Button>
                    </Link>
                </div>
            </ProfileCard>  

            <LogoutCard>
                <div className='logout-btn'>
                    <Button
                        format='logout'
                        onClick={logout}
                    > Sair de todos os dispositivos logados </Button>
                </div>
            </LogoutCard>
        </Container>
        </>
    )
}

export default AdminProfile