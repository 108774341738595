import { useState, useEffect } from 'react'
import { Container } from "./styles"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import { api } from "../../../services/api"
import { useHistory, useParams } from "react-router-dom"
import TextArea from '../../../components/TextArea'
import StyledSelect from '../../../components/Select'
import Form from '../../../components/Forms'
import {alert} from '../../../components/Alert'
import { Page } from '../../AdminDashboard/styles.js'
import CepAdmin from '../../../components/Input/InputCepAdmin'

const EditHotel = () => {
  const history = useHistory()
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [linkMaps, setLinkMaps] = useState('');
  const [categoryID, setCategoryID] = useState();
  const [categories, setCategories] = useState([]);
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [destinationID, setDestinationID] = useState();
  const [destinations, setDestinations] = useState([]);
  const [optionsDestinations, setOptionsDestinations] = useState([]);
  const [informations, setInformations] = useState('');
  const [classification, setClassification] = useState();
  const [tags, setTags] = useState([])
  const [tagIds, setTagIds] = useState([])

  const [neighborhood, setNeighborhood] = useState("");
  const [neighborhoodFailure, setNeighborhoodFailure] = useState(false);
  const [street, setStreet] = useState('');
  const [streetFailure, setStreetFailure] = useState(false);
  const [street_number, setStreet_number] = useState('');
  const [streetNumberFailure, setStreetNumberFailure] = useState(false);
  const [cep, setCep] = useState("");
  const [cepFailure, setCepFailure] = useState(false);

  const {id} = useParams()

  const optionsClassifications = [
    {value: 1, label: "★"},
    {value: 2, label: "★★"},
    {value: 3, label: "★★★"},
    {value: 4, label: "★★★★"},
    {value: 5, label: "★★★★★"}
  ]


  useEffect(() => {
    const classificationsLabels = [
      "★",
      "★★",
      "★★★",
      "★★★★",
      "★★★★★"
    ]
    api.get('categories/index').then((response) => {
        setCategories(response.data);
    })
    api.get('destinations/index').then((response) => {
      setDestinations(response.data);
    })
    api.get(`hotels/show/${id}`).then((response) => {
      setName(response.data.name);
      setDescription(response.data.description);
      setInformations(response.data.informations);
      setClassification({ value: response.data.classification, label: classificationsLabels[response.data.classification] });
      setNeighborhood(response.data.neighborhood);
      setStreet(response.data.street);
      setStreet_number(response.data.street_number);
      setCep(response.data.cep);
      setLinkMaps(response.data.link_maps);
      setCategoryID({ value: response.data.category.id, label: response.data.category.name })
      setDestinationID({ value: response.data.destination.id, label: response.data.destination.name })
      setTagIds(response.data.tags.map(i => { return {value: i.id, label: i.name} }))
    })
  },[id])

  useEffect(() => {
    setOptionsCategories(categories.map((category) => {
      return { value: category.id, label: category.name }
    }))
  }, [categories])

  useEffect(() => {
    setOptionsDestinations(destinations.map((destination) => {
      return { value: destination.id, label: destination.name }
    }))
  }, [destinations])

  const loadTags = async () => {
    const response = await api.get('/tags/index')
    setTags(response.data.map(tag => {return(
        {label: tag.name, value: tag.id} 
    )}))
  }
  useEffect(() => {
    loadTags()
  }, [])

  const editHotel = async (e) => {
    e.preventDefault();
    if(checkValues()){
      await api.put(`hotels/update/${id}`, {
        hotel: {
          name: name,
          description: description,
          classification: classification.value,
          street,
          street_number,
          cep,
          neighborhood,
          link_maps: linkMaps,
          category_id: categoryID?.value,
          destination_id: destinationID?.value,
          informations,
          tag_ids: tagIds.map(i => { return i.value })
        }
      }).then((response) => {
        response.status===200 ? alert("Atualizado com sucesso!", 'success') : alert("Ocorreu um erro. Por favor, tente novamente.", 'error')
        history.push('/hotels')
      }).catch((error) => {
        alert("Ocorreu um erro. Por favor, tente novamente.", 'error')
      })
    }
  }

  function onBlurCep(event) {
    const { value } = event.target;
    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json()) // Retorna o json da api viacep
      .then((data) => {
        setStreet(data.logradouro)
        setNeighborhood(data.bairro)
      });
  }

  const checkValues = () =>{
    if(name.length <3){
      alert("erro, nome deve ter ao menos 3 caracteres", 'warn')
      return false
    } else if (description.length < 10){
      alert("erro, descrição deve ter ao menos 10 caracteres", 'warn')
      return false
    }else if (!destinationID){
      alert("erro, selecione o destino", 'warn')
      return false
    }else if (!categoryID){
      alert("erro, selecione a categoria", 'warn')
      return false
    }else if (!classification){
      alert("erro, selecione a classificação")
      return false
    } else if (cep === '') {
      setCepFailure(true);
      return false;
    }else if (street === '') {
      setStreetFailure(true);
      return false;
    }else if (street_number === '') {
      setStreetNumberFailure(true);
      return false;
    } else if (neighborhood === '') {
      setNeighborhoodFailure(true);
      return false;
    } else {
      return true
    }
  }


    return (
      <Page>
        <Container>
            <h1>Editar Hotel</h1>
            <Form
            onSubmit={editHotel}
            title='Hotel'
            text='Preencha os campos para editar o hotel.'
            button={
              <>
                  <Button format='submit' type='submit'>Editar Hotel</Button>
                  <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
              </>
            }
          >
            <Input label='Nome' value={name} onChange={(e)=>setName(e.target.value)}></Input>
            <TextArea label='Descrição' value={description} onChange={(e)=>setDescription(e.target.value)}/>
            <StyledSelect
              options={optionsClassifications}
              label='Classificação'
              placeholder='Selecionar...'
              value={classification}
              onChange={classification => setClassification(classification)}
            />
            <CepAdmin
              mask="99999-999"
              onBlur={onBlurCep}
              value={cep}
              onChange={(e) => {setCep(e.target.value); setCepFailure(false)}}
              failed={cepFailure}
              failMessage="O campo CEP não pode estar vazio"
              required
              label="CEP"
              format="cepAdmin"
            />
            <Input
              label='Bairro'
              value={neighborhood} onChange={(e) => {setNeighborhood(e.target.value); setNeighborhoodFailure(false)}}
              failed={neighborhoodFailure}
              failMessage="O campo Bairro não pode estar vazio"
              required
              format="neighborhood"
            />
            <Input
              label='Rua'
              name='logradouro'
              value={street} onChange={(e) => {setStreet(e.target.value); setStreetFailure(false)}}
              failed={streetFailure}
              failMessage="O campo Rua não pode estar vazio"
              format='street'
              required
            />
            <Input
              label='Número'
              name='numero'
              value={street_number} onChange={(e) => {setStreet_number(e.target.value); setStreetNumberFailure(false)}}
              failed={streetNumberFailure}
              failMessage="O campo Número não pode estar vazio"
              format='number'
            />
            
            <Input label='Link do Mapa' value={linkMaps} onChange={(e)=>setLinkMaps(e.target.value)}></Input>
            <StyledSelect
                    options={optionsCategories}
                    label='Categoria'
                    placeholder='Selecionar...'
                    value={categoryID}
                    onChange={category => setCategoryID(category)}
            />
            <StyledSelect
                    options={optionsDestinations}
                    label='Destino'
                    placeholder='Selecionar...'
                    value={destinationID}
                    onChange={destination => setDestinationID(destination)}
            />
            <StyledSelect
                    label='Tags'
                    isMulti
                    placeholder='Selecionar...'
                    options={tags}
                    value={tagIds}
                    onChange={tag => setTagIds(tag)}
            />
            <TextArea label='Informações do Voucher' value={informations} onChange={(e)=>setInformations(e.target.value)}/>
          </Form>
        </Container>
      </Page>
    )
}

export default EditHotel