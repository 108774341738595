import {
    ModalBackground,
    ModalContainer,
    Title,
    WrapSides,
    RightSide,
    ModalButton,
    CloseBtn,
    DivColor
  } from "./styles";

  import { Link } from 'react-router-dom';
  
  const Modal = ({closeModal}) => {
    return (
      <DivColor>
      <ModalBackground>
        <ModalContainer>
          <CloseBtn onClick={() => closeModal(false)}>X</CloseBtn>
          <Title>
            Entre em sua conta Vai de Férias para explorar hotéis incríveis
          </Title>
          <WrapSides>
            <RightSide>
              <ModalButton>
                <Link to="/login" onClick={() => closeModal(false)}>
                    <h1>Entrar Na Minha Conta</h1>
                </Link>
              </ModalButton>
            </RightSide>
          </WrapSides>
          <Title>
            Ainda não adquiriu o seu pacote?
          </Title>
          <WrapSides>
            <RightSide>
              <ModalButton>
                <Link to="/sign_up" onClick={() => closeModal(false)}>
                    <a href="/#">Comprar Agora</a>
                </Link>
              </ModalButton>
            </RightSide>
          </WrapSides>
        </ModalContainer>
      </ModalBackground>
      </DivColor>
    );
  };
  
  export default Modal;
  
  