import { useUserContext } from "../../../components/contexts/useUserContext"
import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {Container, ProfileCard} from "./style"
import UserPlaceholder from '../../../assets/user-placeholder.png'
import Button from '../../../components/Button'
import { api } from "../../../services/api"
import { photo } from "../../../services/photo"
import MaskInput from "../../../components/Input/MaskInput"
import InputWithoutIcon from "../../../components/Input/InputWithoutIcon"
import {alert} from "../../../components/Alert"
import Select from 'react-select';

function RetailerUpdate() {
    // social_name,
    //             fantasy_name,
    //             icms_contribution,
    //             national_simple,
    //             state_registration,
    //             municipal_registration
    const history = useHistory()
    const {user, updateRetailer, showRetailer} = useUserContext()
    const [image, setImage] = useState([])
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [city, setCity] = useState(user.city)
    const [cep, setCep] = useState(user.cep)
    const [cepFailure, setCepFailure] = useState(false)
    const [states, setStates] = useState([]);
    const [optionStates, setOptionStates] = useState([]);
    const [state, setState] = useState(user.state)
    const [street, setStreet] = useState(user.street)
    const [neighborhood, setNeighborhood] = useState(user.neighborhood)
    const [street_number, setStreet_number] = useState(user.street_number)
    const [ddd, setDdd] = useState(user.ddd)
    const [phone, setPhone] = useState(user.phone)
    const [icmsContribution, setIcmsContribution] = useState(user.icms_contribution)
    const [nationalSimple, setNationalSimple] = useState(user.national_simple)
    const [stateRegistration, setStateRegistration] = useState(user.state_registration)
    const [municipalRegistration, setMunicipalRegistration] = useState(user.municipal_registration)
    const [banks, setBanks] = useState([]);
    const [optionsBank, setOptionsBank] = useState([]);
    

// Info about bank
    const [bank, setBank] = useState(user.bank);
    const [branchNumber, setBranchNumber] = useState(user.branch_number);
    const [branchCheckDigit, setBranchCheckDigit] = useState(user.branch_check_digit);
    const [accountNumber, setAccountNumber] = useState(user.account_number);
    const [accountCheckDigit, setAccountCheckDigit] = useState(user.account_check_digit);
    const [accountType, setAccountType] = useState(user.account_type);

    useEffect(() => {
        api.get('states').then((response) => {
            setStates(response.data);
        })
    },[])

    useEffect(() => {
        setOptionStates(states.map((state) => {
            return { value: state.abbreviation, label: state.name }
        }))
    }, [states, state])


    useEffect(() => {
        fetch("https://guibranco.github.io/BancosBrasileiros/data/bancos.json")
        .then((response) => response.json()).then((data) => {
            setBanks(data);
        })
    },[])

    useEffect(() => {
        setOptionsBank(banks.map((bank) => {
          return { value: bank.COMPE, label: bank.LongName }
        }))
    }, [banks, bank])

    const optionsIcmsContribution = [
        { value: 'Contribuinte ICMS', label: 'Contribuinte ICMS'},
        { value: 'Contribuinte Isento de ICMS', label: 'Contribuinte Isento de ICMS'},
        { value: 'Não Contibuinte', label: 'Não Contibuinte'}
    ]


    const optionsAccountType = [
        { value: 'checking', label: 'Conta Corrente'},
        { value: 'savings', label: 'Poupança'}
    ]

    const changeICMStype = (value) => {
        setIcmsContribution(value);
        if (value !== 'Contribuinte ICMS')
            setNationalSimple(false);
    }

    const changeImage = async (e) => {
        e.preventDefault()
        try{
            const formData = new FormData ()
                formData.append('image[]', image[0])
        

            const response = await api.post(`/retailers/add_image/${user.id}`, formData)
            
            if(response.data){
                alert('Foto alterada!', 'success');
                setImage(response.data)
                showRetailer()                
            }
            
        }catch(error){
            alert(error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (city === ''){
            alert('Insira a cidade!')
            return
        }

        if (cep === ''){
            alert('Insira o cep!')
            return
        }

        if (cepFailure) {
            alert('Cep inválido!', 'warn')
            return
        }

        if (state === ''){
            alert('Insira o estado!')
            return
        }

        if (street === ''){
            alert('Insira a rua!')
            return
        }

        if (neighborhood === ''){
            alert('Insira o bairro!')
            return
        }

        if (street_number === ''){
            alert('Insira o número da rua!')
            return
        }
        
        await updateRetailer({
            city,
            cep,
            state,
            street,
            neighborhood,
            street_number,
            ddd,
            phone,
            bank,
            account_type: accountType,
            branch_number: branchNumber,
            branch_check_digit: branchCheckDigit,
            account_number: accountNumber,
            account_check_digit: accountCheckDigit,
            icms_contribution: icmsContribution,
            national_simple: nationalSimple,
            municipal_registration: municipalRegistration,
            state_registration: stateRegistration
        })

    }

    const handlePasswordChange = async (e) => {
        e.preventDefault()
        if (password.length < 6){
            alert('Senha muito curta', 'warn')
            return
        }

        if (password !== passwordConfirmation){
            alert('Confirmação de senha não bate com a senha', 'warn')
            return
        }

        try{
            await api.patch(`retailers/password_edit/${user.id}`, {
                password,
                password_confirmation: passwordConfirmation,
                current_password: currentPassword
            })
            alert("Senha modificada com sucesso.", 'success')
            history.push('/profile')
        }
        catch(e){
            alert("Erro, tente novamente", 'error')
        }
    }

    const onBlurCep = (event) => {
        const { value } = event.target;
        const cep = value?.replace(/[^0-9]/g, "");

        if (cep?.length !== 8) {
            setCepFailure(true)
            return;
        }

        fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json()) // Retorna o json da api viacep
        .then((data) => {
            if (data.erro)
                setCepFailure(true);
            else {
                setStreet(data.logradouro)
                setCity(data.localidade)
                setState(data.uf)
                setDdd(data.ddd)
                setNeighborhood(data.bairro)
                setCepFailure(false)
            }
        });
    }
    
    return (
        <>
        <Container>
                <div className='title'>
                <h1>Editar perfil</h1>
                <h2>Olá, <span>{user.name ? user.name : user?.fantasy_name}!</span> </h2>

                </div>
            
            <ProfileCard>
                <form onSubmit={changeImage}>
                    <div className='client-info'>
                        <div className='profile-pic'>
                            <img alt="Foto de Perfil" src={user.retailer_image_url? `${photo.defaults.baseURL +user.retailer_image_url}`: UserPlaceholder} />
                            <input
                                placeholder='Foto' type='file' name='file' id='file'
                                onChange={(value) => setImage(value.target.files)}
                            />
                        </div>
                    </div>
                    <div className='custom-button'>
                    <Button format='profile' type='submit' className='button'>Confirmar nova foto de perfil</Button>

                    </div>
                </form>
                <form onSubmit={handleSubmit}>

                <div className='client-info'>
                    <InputWithoutIcon
                        label='Cidade'
                        type='text'
                        value={city}
                        onChange={value => setCity(value.target.value)}
                    />
                </div>

                <div className='client-info'>
                    <label>Estado</label>
                    <div className="select">
                        <Select
                            name='uf'
                            options={optionStates} isSearchable placeholder="Estado"
                            onChange={(value) => {setState(value?.value)}}
                            value={state && optionStates.filter((option) => {return option.value === state})[0]}
                            failMessage="O campo Estado não pode estar vazio"
                        />
                    </div>
                </div>

                <div className='client-info'>
                    <MaskInput
                        mask='99999-999'
                        placeholder='CEP'
                        label='CEP'
                        name='cep'
                        value={cep}
                        onChange={value => {setCep(value.target.value); setCepFailure(false)}}
                        onBlur={onBlurCep}
                        format='cep-sm3'
                        required
                    />
                </div>

                <div className='client-info'>
                    <InputWithoutIcon
                        label='Rua'
                        type='text'
                        value={street}
                        onChange={value => setStreet(value.target.value)}
                    />
                </div>

                <div className='client-info'>
                    <InputWithoutIcon
                        label='Bairro'
                        type='text'
                        value={neighborhood}
                        onChange={value => setNeighborhood(value.target.value)}
                    />
                </div>

                <div className='client-info'>
                    <InputWithoutIcon
                        label='Número da rua'
                        type='text'
                        value={street_number}
                        onChange={value => setStreet_number(value.target.value)}
                    />
                </div>

                <div className='client-info'>

                    <label className="label-client-update">Telefone</label>
                    <div className='client-info-phone'>
                        <MaskInput
                            mask='(99)'
                            placeholder='DDD'
                            name='ddd'
                            value={ddd}
                            onChange={value => setDdd(value.target.value)}
                            format='ddd'
                            required
                        />
                        <MaskInput
                            mask="99999-9999"
                            value={phone}
                            onChange={value => setPhone(value.target.value)}
                            placeholder="Telefone"
                            format="phone"
                            required
                        />
                    </div>
                </div>

                <div className='client-info'>
                    <label>Banco</label>
                    <div className='select'>
                        <Select 
                            options={optionsBank} placeholder="Banco"
                            onChange={(value) => {setBank(value?.value)}}
                            value={bank && optionsBank.filter((option) => {return option.value === bank})[0]}
                        />
                    </div>
                </div>

                <div className="client-info">
                    <label>Tipo de Conta</label>
                    <div className='select'>
                        <Select 
                            options={optionsAccountType} placeholder="Tipo da conta"
                            onChange={(value) => {setAccountType(value?.value)}}
                            value={accountType && optionsAccountType.filter((option) => {return option.value === accountType})[0]}
                        />
                    </div>
                </div>

                <div className='client-info'>
                    <div className="number-with-digit">
                        <InputWithoutIcon
                            label='Agência'
                            placeholder="Agência"
                            value={branchNumber}
                            onChange={(e) => setBranchNumber(e.target.value)}
                            format="agency"
                            required
                        />
                        <InputWithoutIcon
                            label="Dígito"
                            placeholder="Dígito"
                            value={branchCheckDigit}
                            onChange={(e) => setBranchCheckDigit(e.target.value)}
                            format="agency_digit"
                            required
                        />
                    </div>
                </div>

                <div className='client-info'>
                    <div className="number-with-digit">

                        <InputWithoutIcon
                            label="Número da Conta"
                            placeholder="Número da Conta"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            format="account_number"
                            required
                        />
                        <InputWithoutIcon
                            label="Dígito"
                            placeholder="Dígito Verificador"
                            value={accountCheckDigit}
                            onChange={(e) => setAccountCheckDigit(e.target.value)}
                            format="account_digit"
                            required
                        />
                    </div>
                </div>

                {user?.person_type === 'company'? (
                    <>
                    <div className='client-info'>
                        <div className="icms-contribution">
                            <div>
                                <label>Contribuição do ICMS</label>
                                <div className='select'>
                                    <Select
                                        options={optionsIcmsContribution} placeholder="Contribuição ICMS"
                                        onChange={(value) => {changeICMStype(value?.value);}}
                                        value={icmsContribution && optionsIcmsContribution.filter((option) => {return option.value === icmsContribution})[0]}
                                    />
                                </div>
                            </div>
                            <div>
                                { icmsContribution === 'Contribuinte ICMS' ?
                                    <div className='checkbox'>
                                    <input id='nacional' value={nationalSimple} type="checkbox" onChange={(e) => setNationalSimple(e.target.checked)}/>
                                    <label for='nacional'>Simples Nacional</label>
                                    </div>
                                : 
                                    <div className='checkbox'>
                                    <input checked={false} type="checkbox" disabled={true}/>
                                    <label>Simples Nacional</label>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                        {state === 'DF'? (
                            null
                        ) : (
                            <div className='client-info'>
                                <InputWithoutIcon
                                    label='Registro Municipal'
                                    type='text'
                                    value={municipalRegistration}
                                    onChange={value => setMunicipalRegistration(value.target.value)}
                                />
                            </div>
                        )}
                        <div className='client-info'>
                            <InputWithoutIcon
                                label='Registro Estadual'
                                type='text'
                                value={stateRegistration}
                                onChange={value => setStateRegistration(value.target.value)}
                            />
                        </div>
                    </>
                ) : (
                    null
                )}

                <div className='custom-button'>
                    <Button type='submit' format='profile'>Confirmar mudanças</Button>
                </div>

                </form>

                <form onSubmit={handlePasswordChange}>
                    <div className='client-info'>
                        <InputWithoutIcon
                            label='Senha atual'
                            type='password'
                            value={currentPassword}
                            onChange={value => setCurrentPassword(value.target.value)}
                        />
                    </div>

                    <div className='client-info'>
                        <InputWithoutIcon
                            label='Senha'
                            type='password'
                            value={password}
                            onChange={value => setPassword(value.target.value)}
                        />
                    </div>

                    <div className='client-info'>
                        <InputWithoutIcon
                            label='Confirmar Senha'
                            type='password'
                            value={passwordConfirmation}
                            onChange={value => setPasswordConfirmation(value.target.value)}
                        />
                    </div>

                    <div className='custom-button'>
                        <Button type='submit' format='profile'>Alterar Senha</Button>
                    </div>

                </form>

                <div className='custom-button'>
                    <Button format='logout' onClick={() => history.goBack()}>Voltar</Button>
                </div>

            </ProfileCard>  
        </Container>

        </>
    )
}

export default RetailerUpdate;