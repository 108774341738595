import { useEffect } from "react"
import { useState } from "react"
import BlankModal from ".."
import { api } from "../../../services/api"
import Button from "../../Button"
import {alert} from "../../Alert"
import { Container } from "./styles"

const PermissionsModal = ({showModal, setShowModal, id}) => {

    const [permissions, setPermissions] = useState([])
    const [adminPermissions, setAdminPermissions] = useState([])

    const loadPermissions = async () => {
        const response = await api.get('/permissions/index')
        setPermissions(response.data.map(permission => {return(
            {label: permission.description, value: permission.id}
        )}))
    }

    const loadAdminPermissions = async (id) => {
        const response = await api.get('/admins/index')
        let admin = response.data.filter((admin) => admin.id === id)
        setAdminPermissions(admin[0].permissions)
    }

    useEffect(() => {
        loadPermissions()
        if (id)
            loadAdminPermissions(id)
    }, [id])

    const handleCreate = async (permission) => {
        try {
            const response = await api.post(`/admins/create_permission/${id}`, {
                permission_id: permission
            })
            if(response.data){
                alert("Permissão adicionada", 'success')
                setAdminPermissions([...adminPermissions, permission])
            }
        }catch(error){
            alert("Erro, tente novamente", "error")
        }
    }

    const handleDelete = async (permission) => {
        try {
            const response = await api.delete(`/admins/delete_permission/${id}/${permission}`)
            if(response.data){
                alert("Permissão deletada", "success")
                const temp = [...adminPermissions];
                const index = temp.indexOf(permission)
                temp.splice(index, 1);
                setAdminPermissions(temp)
            }
        }catch(error){
            alert("Erro, tente novamente.","error")
        }
    }

    return (
        <BlankModal showModal={showModal} setShowModal={setShowModal}>
            <Container>
                <h1>Editar permissões do Administrador</h1>
                {permissions.map((permission, index) => {return(
                    adminPermissions.includes(permission.value) ?
                    <Button key={index} format='return' style={{backgroundColor: "#BC0004"}} onClick={() => {handleDelete(permission.value)}}>Remover {permission.label}</Button> :
                    <Button key={index} format='submit' style={{background: "#04BC00"}} onClick={() => {handleCreate(permission.value)}}>Adicionar {permission.label}</Button>
                )})}
                <Button format='return' style={{backgroundColor: "#000"}} onClick={() => {setShowModal(false)}}>Fechar</Button>
            </Container>
        </BlankModal>
    )
}

export default PermissionsModal