
import styled from 'styled-components';

export const Page = styled.section`
    min-height: calc(100vh - 4.5rem); // Navbar size
    margin-left: 10rem;           // Distâncai da sidebar
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    color: #3A3B3C;

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    @media screen and (max-width: 992px){
        margin-left: 0;
        padding: 4rem 0;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-left: 10%;

    h1 {
        font-size: 3.125rem;
        margin: 0 0 1rem;
    }

    h5 {
        margin: 0 0 .5rem;
    }

    .filter {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 3rem;

        > div {
            width: 30rem;
            padding: 0;
            margin: 0;
        }
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        /* justify-content: space-between; */
    }

    @media screen and (max-width: 1430px){
        padding: 4vw;

        h1 {
            text-align: center;
        }

        .cards, .filter {
            justify-content: center;
        }
    }

    @media screen and (max-width: 576px){
        h1 {
            font-size: 1.6rem;
        }

        .filter .filter-button {
            width: 80%;
        }
    }
`;

