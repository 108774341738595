import ArrowBack from '../../../../components/ArrowBack'
import { Container, Page } from './styles';
import Button from '../../../../components/Button';
import { useState, useEffect } from 'react';
import { api } from '../../../../services/api';
import { useHistory, useParams } from 'react-router-dom';

const ShowPackages = () => {

    const history = useHistory()

    const { id } = useParams();
    const [userPackages, setUserPackages] = useState([]);
    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
            api.get(`/packages/client_index/${id}`)
            .then(response => setUserPackages(response.data));
            api.get(`/clients/show/${id}`)
            .then(response => setUserInfo(response.data))
    }, [id])

    return (
        <Page>
            <ArrowBack/>
            <Container>
                <h1>Pacotes de {userInfo ? userInfo.name : ''}</h1>
                <div>
                    <div>
                        <div className="row">
                            <h6 className="sm-col">Número do Pacote</h6>
                            <h6 className="lg-col">
                                Pacote <br/> 
                                Método de pagamento
                            </h6>
                            <h6 className="md-col">Status do Pacote</h6>
                            <h6 className="md-col">Diárias restantes</h6>
                            <span className="md-col"/>
                        </div>
                        <ul>
                            {userPackages.map((userPackage, index) => {
                                return(
                                    <li key={index}>
                                        <span className="sm-col">
                                            <h6 className="responsive-header">Número do Pacote:</h6>
                                            <p>{userPackage.id}</p>
                                        </span>
                                        <span className="lg-col">
                                            <h6 className="responsive-header">
                                                Pacote <br/> 
                                                Método de pagamento:
                                            </h6>
                                            <p>{userPackage.package_plan?.name}</p>
                                        </span>
                                        <span className="sm-col">
                                            <h6 className="responsive-header">Status do Pacote:</h6>
                                            <p className={userPackage.is_active ? 'ativo' : 'inativo'}>
                                                {userPackage.is_active ? 'Ativo' : 'Inativo'}
                                            </p>
                                        </span>
                                        <span className="md-col">
                                            <h6 className="responsive-header">Diárias restantes:</h6> 
                                            <p>
                                                {`${userPackage.available_vacation_days || 0} diária${userPackage.available_vacation_days !== 1 ? 's' : ''}`}
                                            </p>
                                        </span>
                                        <span className="md-col">
                                            <Button format="submit" onClick={() => history.push(`details/${userPackage.id}`)}>
                                                    Ver Mais
                                            </Button>
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </Container>
        </Page>
    )
}

export default ShowPackages;