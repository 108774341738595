import {
  ModalBackground,
  ModalContainer,
  Title,
  WrapSides,
  LeftSide,
  RightSide,
  FlexDivLeft,
  FlexDivRight,
  BorderDiv,
  BorderDivIn,
  MakeSideDiv,
  LeftDiv,
  RightDiv,
  Check,
  ModalButton,
  CloseBtn,
  DivColor,
  ButtonDiv
} from "./styles";

import { useHistory } from "react-router-dom"
import { api } from "../../services/api"
import { alert } from "../Alert"

const Modal = ({closeModal, name, cpf, rg, email, phone, ddd,
  cep, state, city, neighborhood, number, street, birthdate,
  hotel, adults, kids, dates, room_id, diarias, user_id, user_package}) => {
  
  const history = useHistory()
  let Diarias = diarias
  let vacation_days = user_package.available_vacation_days
  
  let used_days
  if(user_package.used_days == null){
    used_days = 0
  }else{
    used_days = user_package.used_days
  }

  let last_booking
  if(user_package.last_booking == null){
    last_booking = false
  }else{
    last_booking = user_package.last_booking
  }

  let lost_days = vacation_days - (used_days + Diarias)

  let room = hotel.room_types.filter(item => {
    return item.id === room_id
  })

  const months = [
    "Janeiro", "Fevereiro", "Março", "Abril",
    "Maio", "Junho", "Julho", "Agosto",
    "Setembro", "Outubro", "Novembro", "Dezembro"
  ]

  const formatBirthdate = (date) => {
    return(`${date.substring(8)} de ${months[Number(date.substring(5,7))-1]} de ${date.substring(0,4)}`)
  }

  const formatDate = (dateInput) => {
    let d = new Date(dateInput)
    let ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('pt-br', { month: 'long' }).format(d);
    let da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
    return(`${da} de ${mo} de ${ye}`);
  }

  const formatPhone = (number) => {
    if(number.length === 9){
      return(`${number.charAt(0)} ${number.substring(1,5)}-${number.substring(5)}`)
    }else{
      return(`${number.substring(0,4)}-${number.substring(4)}`)
    }
  }

  const makeReservation = async () => {
      await api.post('bookings/create', {
        booking: {
          name: name,
          email: email,
          cpf: cpf,
          rg: rg,
          adults: adults.value,
          children: kids.value,
          cep: cep,
          city: city,
          state: state,
          neighborhood: neighborhood,
          street_number: number,
          street: street,
          ddd: ddd,
          telephone: phone,
          birthdate: birthdate,
          room_type_id: room[0].id,
          client_id: user_id,
          start_time: dates.startDate,
          end_time: dates.endDate
        }
      }).then((response) => {
        response.status===201 ? alert("Reserva solicitada com sucesso!", 'success') : alert("Erro, tente novamente mais tarde!", 'error')
        history.push("/user/my-requests");
      }).catch((error) => {
        alert(error.response.data.message? error.response.data.message : 'Algum erro ocorreu. Por favor, tente novamente.', error.response.data.type? error.response.data.type : 'error')
        closeModal(false)
      })
  }

  return (
    
    <DivColor>
    <ModalBackground>
      <ModalContainer>
        <CloseBtn onClick={() => closeModal(false)}>X</CloseBtn>
        <Title>
          Confirme os detalhes da sua solicitação de reserva em {hotel.name}
        </Title>
        <WrapSides>
            <LeftSide>
            <h3>Responsável da Reserva</h3>
            <h4>Nome: {name}</h4>
            <h4>CPF: {cpf} RG: {rg}</h4>
            <h4>Email: {email}</h4>
            <h4>Data de Nascimento: {formatBirthdate(birthdate)}</h4>
            <h4>Telefone: ({ddd}) {formatPhone(phone)}</h4> 
            <h4>Cidade: {city}, {state}</h4>
            <h4>CEP: {cep}</h4>
            <h4>Bairro: {neighborhood} </h4>
            <h4>Rua: {street}, Número {number}</h4>
            
            <h3>Destino</h3>
            <h4>{hotel.destination.name}</h4>
            <h3>Hotel</h3>
            <h4>Hotel {hotel.name}</h4>
            <h4>CEP {hotel.cep}</h4>
            <h3>Data de Estadia</h3>
            <h4>{formatDate(dates.startDate)} - {formatDate(dates.endDate)}</h4>
            <h4>{Diarias} diárias</h4>
          </LeftSide>
          <RightSide>
            <FlexDivLeft>
              <h3>Quarto</h3>
              <h4>{room[0].name}</h4>
              <h4>- Acomoda {room[0].capacity} pessoa(s) </h4>
              <h4>- {room[0].description} </h4>
            </FlexDivLeft>

            <FlexDivRight>
              <h3>Número de Hóspedes</h3>
              <h4>{adults.label} e {kids.label}</h4>
            </FlexDivRight>

            <BorderDiv>
              <BorderDivIn>
                <MakeSideDiv>
                  <LeftDiv>
                    <h1>Minhas Diárias</h1>
                    <h1>Estadia</h1>
                  </LeftDiv>

                  <RightDiv>
                    <h1>{vacation_days - used_days} diárias</h1>
                    <h1>{Diarias} diárias</h1> 
                  </RightDiv>
                </MakeSideDiv>
                <hr />
                <MakeSideDiv>
                  <LeftDiv>
                    <h1>Diárias Restantes</h1>
                  </LeftDiv>

                  <RightDiv>
                    <h1 className="gradienteH1">{vacation_days - (Diarias + used_days)} diárias</h1>
                  </RightDiv>
                </MakeSideDiv>
                
              </BorderDivIn>
            </BorderDiv>
            { Diarias < 2 ? 
              <h3 className="disabled">As estadias são de no mínimo de 2 (dois) dias</h3>
            :<>
            <Check>
              <label>
                <input type="checkbox" />
                <span>
                  Confirmar os detalhes da solicitação
                  {(last_booking && used_days + Diarias < vacation_days) ? 
                    <span className="lost_days"> e confirmar em perder {lost_days} dia(s)</span>
                  : 
                    <>
                      {(!last_booking  && vacation_days - Diarias === 1) ? <span className="lost_days"> e confirmar em perder 1 dia</span> : null}
                    </>
                  }
                </span>
              </label>
            </Check>
            <ButtonDiv>
              <ModalButton 
                onClick={() => {
                  makeReservation();
                }}
              >
                <h1>Solicitar minhas Férias!</h1>
              </ModalButton>
            </ButtonDiv>
            </>}
          </RightSide>
        </WrapSides>
      </ModalContainer>
    </ModalBackground>
    </DivColor>
  );
};

export default Modal;

