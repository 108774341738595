import ArrowBack from "../../components/ArrowBack";
import { useParams } from "react-router-dom";
import { useState } from "react";
import SignUpInformation from "../../components/SignUpInformation";
import PaymentInformation from "../../components/PaymentInformation";
import PaymentConfirmation from "../../components/PaymentConfirmation";
import { useRetailerContext } from "../../components/contexts/useRetailerContext";

const SignUpPage = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [cpf, setCpf] = useState("");
  const [cep, setCep] = useState("");
  const [rg, setRg] = useState("");
  const [street, setStreet] = useState('');
  const [street_number, setStreetNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [ddd, setDdd] = useState('');
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [payment_method, setPaymentMethod] = useState("boleto");
  const [boleto_payment_day, setBoletoPaymentDay] = useState("");

  const { retailer, setRetailer } = useRetailerContext();

  const { id } = useParams();
  setRetailer(!!id ? id : retailer);

  const [pageNumber, setPageNumber] = useState(1)
  
  const firstPage = () => {
    setPageNumber(1)
  }

  const secondPage = () => {
    setPageNumber(2)
  }

  const thirdPage = () => {
    setPageNumber(3)
  }

  return (
    <>
      <ArrowBack signUp pageNumber={pageNumber} setPageNumber={setPageNumber}/>
      {
        pageNumber === 1
        ?
        <SignUpInformation
          secondPage = {secondPage}
          name = {name}
          setName = {setName}
          email = {email}
          setEmail = {setEmail}
          password = {password}
          setPassword = {setPassword}
          password_confirmation = {password_confirmation}
          setPasswordConfirmation = {setPasswordConfirmation}
          cpf = {cpf}
          setCpf = {setCpf}
          cep = {cep}
          setCep = {setCep}
          rg = {rg}
          setRg = {setRg}
          street = {street}
          setStreet = {setStreet}
          street_number = {street_number}
          setStreetNumber = {setStreetNumber}
          city = {city}
          setCity = {setCity}
          state = {state}
          setState = {setState}
          ddd = {ddd}
          setDdd = {setDdd}
          number = {number}
          setNumber = {setNumber}
          neighborhood = {neighborhood}
          setNeighborhood = {setNeighborhood}
          birthdate = {birthdate}
          setBirthdate = {setBirthdate}
        />
        : (pageNumber === 2 ?
        <>
          <PaymentInformation
            firstPage = {firstPage}
            thirdPage = {thirdPage}
            payment_method = {payment_method}
            setPaymentMethod = {setPaymentMethod}
            boleto_payment_day = {boleto_payment_day}
            setBoletoPaymentDay = {setBoletoPaymentDay}
          />
        </>
        : <PaymentConfirmation
            firstPage = {firstPage}
            secondPage = {secondPage}
            name = {name}
            email = {email}
            password = {password}
            password_confirmation = {password_confirmation}
            cpf = {cpf}
            cep = {cep}
            rg = {rg}
            street = {street}
            street_number = {street_number}
            city = {city}
            state = {state}
            ddd = {ddd}
            number = {number}
            neighborhood = {neighborhood}
            birthdate = {birthdate}
            payment_method = {payment_method}
            boleto_payment_day = {boleto_payment_day}
            id={retailer}
          />
        )
      }
  </>

      
  );
};

export default SignUpPage;
