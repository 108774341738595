import { Link } from "react-router-dom"
import { Container } from "./styles"

const OrderConfirmation = ({confirmation_number}) => {
    return (
        <>
            <Container>
                <div className='mensagem'>
                    <svg width="30" height="30" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M43.75 49H5.25C2.35047 49 0 46.6495 0 43.75V5.25C0 2.35047 2.35047 0 5.25 0H43.75C46.6495 0 49 2.35047 49 5.25V43.75C49 46.6495 46.6495 49 43.75 49ZM21.3625 38.2748L41.4875 18.1498C42.1708 17.4664 42.1708 16.3583 41.4875 15.675L39.0126 13.2001C38.3293 12.5168 37.2212 12.5167 36.5377 13.2001L20.125 29.6127L12.4623 21.95C11.7789 21.2667 10.6708 21.2667 9.98736 21.95L7.51253 24.4249C6.82916 25.1082 6.82916 26.2163 7.51253 26.8997L18.8875 38.2747C19.571 38.9583 20.679 38.9583 21.3625 38.2748Z" fill="white"/>
                    </svg>
                    {confirmation_number === '1' ?
                    <p>Seu pedido foi registrado. Acesse seu boleto pelo seu email ou pela página Meu Perfil.</p>
                    :
                    confirmation_number === '2' ?
                    <p>Seu pedido foi registrado.</p>
                    :
                    ''
                    }
                </div>
                <div className='closes'>
                    <Link to='/' className='desktop'>Fechar</Link>
                    <Link to='/' className='mobile'>X</Link>
                </div>
            </Container>
        </>
    )
}

export default OrderConfirmation