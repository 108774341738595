/* 'Format' options:
    -submit
    -return
    -delete
    -update
    -show
    -home
    -profile
*/

import { ButtonContainer } from './styles'

const Button = ({children, format, ...props}) => {
    return (
        <ButtonContainer format={format} {...props}> {children} </ButtonContainer>
    )
}

export default Button
