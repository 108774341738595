import { useState, useEffect } from 'react'
import { Container } from './styles'
import { api } from '../../../../services/api'
import { Link } from 'react-router-dom'
import Button from '../../../../components/Button'
import { useUserContext } from '../../../../components/contexts/useUserContext'
import Pagination from '../../../../components/Pagination';
import { Page } from "../../../AdminDashboard/styles"

const RetailerManager = () => {
    const { user } = useUserContext()
    const [retailer, setRetailer] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0)
    const [showPermission, setShowPermission] = useState(false)

    const getCommissionValue = (commissionInCents) => {
      let formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
      });
      return formatter.format(commissionInCents/100);
    }

    useEffect(() => {
        api.get('/permissions/index')
        .then(response => {
          response.data.forEach((permission) => {
            if (permission.name === 'modify_commission_payments' &&  user.permissions.indexOf(permission.id) !== -1)
              setShowPermission(true)
          })
        })
    }, [user])
    
    useEffect(() => {
        window.scrollTo(0, 0)
        let offset = (currentPage - 1)*10
        api.get('retailers/payment_index/'+offset)
        .then((response) => {
            setRetailer(response.data)
        })
        api.get('retailers/total_records/')
        .then((response) => {
          setTotalItems(response.data)
        })
      },[currentPage, user])

    return (
      <Page>
        <Container>
        <h1>Comissão dos Revendedores</h1>
        { retailer.length === 0 ? 
          <h3>Todos os Revendedores estão com suas comissões zeradas.</h3>
        : 
        <table>
            <thead>
            <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Comissão Acumulada</th>
                <th>Opções</th>
            </tr>
            </thead>
            <tbody>
              
            { retailer.map((retailer, index) => { return(
                <tr key={retailer.id}>
                <td>{retailer.name}</td>
                <td>{retailer.email}</td>
                <td>{getCommissionValue(retailer.commission_in_cents)}</td>
                <td className="buttons" style={{minWidth: "35%"}}>
                    { showPermission &&                     
                        <Link to={{ pathname: `commission_payments/show/${retailer.id}`, state: { retailer }}}>
                            <Button className="info" format="update">Gerenciar Comissão</Button>
                        </Link>
                    }
                </td>
                </tr>
            )})}
            </tbody>
        </table>
        }
        <div className = 'pagination-box'>
        <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalItems}
            pageSize={10}
            onPageChange={page => setCurrentPage(page)}
            />
        </div>
        </Container>
      </Page>
    )
}

export default RetailerManager