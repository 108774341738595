import React from "react"

import { ContainerWrapper } from './styles'
import icon from '../../assets/question_mark.svg'
import ArrowBack from '../../components/ArrowBack'


function Faq() {

    const faq_info = [
        {
            "title": "1. O que é o programa Vai de Férias?", 
            "body": "O Vai de Férias é um programa com venda facilitada de pacotes de 7 diárias a serem utilizados em hotéis conveniados localizados nos melhores 50 destinos do Brasil."
        },
        {
            "title": "2. Como posso adquirir o meu pacote?", 
            "body": "É muito simples e rápido. Basta se cadastrar no site www.vaideferias.com.br e aguardar a aprovação do seu cadastro que será realizada por um de nossos atendentes."
        },
        {
            "title": "3. Quantas diárias possuem o pacote?", 
            "body": "Cada pacote possui 7 diárias."
        },
        {
            "title": "4. Qual a forma de pagamento?", 
            "body": "O Vai de Férias chegou para facilitar e mudar a sua forma de tirar férias. O pacote poderá ser parcelado em 24 vezes no boleto, não comprometendo o seu limite de cartão de crédito."
        },
        {
            "title": "5. O plano de hospedagem permite acomodação de quantas pessoas?", 
            "body": "Permite a acomodação de até 2 adultos e 1 criança de até 7 anos."
        },
        {
            "title": "6. Após a compra do pacote, em quanto tempo já posso fazer uma reserva?", 
            "body": "A reserva poderá ser efetuada a partir de 30 dias após pagamento da primeira parcela."
        },
        {
            "title": "7. Como posso fazer uma reserva?", 
            "body": "Após o período de carência, as reservas devem ser realizadas na área no cliente. Você selecionará o seu destino e selecionaremos os melhores hotéis para lhe atender."
        },
        {
            "title": "8. Com quanto tempo de antecedência devo solicitar uma reserva?", 
            "body": "A reserva deverá ser efetuada com no mínimo 30 dias da data pretendida de acordo com a disponibilidade no hotel escolhido."
        },
        {
            "title": "9. É possível cancelar ou alterar uma reserva?", 
            "body": "Sim. Em ambos os casos, a remarcação somente poderá ser efetuada no mesmo hotel."
        },
        {
            "title": "10. Com quanto tempo de antecedência posso cancelar ou alterar uma reserva?", 
            "body": "O cancelamento deverá ser realizado com no mínimo 15 dias de antecedência da data do check-in e a modificação com mínimo de 20 dias."
        },
        {
            "title": "11. Como sei que minha reserva está confirmada?", 
            "body": "Após a solicitação de reserva e confirmação pelo programa, você receberá um voucher com os dados da hospedagem."
        },
        {
            "title": "12. Se eu não comparecer no hotel de reserva, eu perco as minhas diárias?", 
            "body": "Sim, caso não compareça nas primeiras 24 horas do início da hospedagem, a reserva será automaticamente cancelada e as diárias não reembolsadas."
        },
        {
            "title": "13. Posso disponibilizar minha diária para terceiros?", 
            "body": "Sim. Na área de reservas, escolha a opção hospedagem para terceiros."
        },
        {
            "title": "14. Qual o prazo para utilização do meu pacote?", 
            "body": "O pacote poderá ser utilizado em até 24 meses após a data da compra."
        },
        {
            "title": "15. Posso cancelar o meu pacote?", 
            "body": "Sim, em conformidade com os artigos 32, 33 e 34 do regulamento Vai de Férias."
        }
    ]

    return(
        <>
            <ArrowBack/>
            <ContainerWrapper>
                <div className="header-style">
                    <h1><img src={icon} alt="Ícone de interrogação"/>Dúvidas Frequentes</h1>
                </div>
                <div className="text-container">
                    {faq_info.map((info, index) => {
                        return(
                            <>
                                <h1 key={index}>{info.title}</h1>
                                <p key={index}>{info.body}</p>
                            </>
                        )
                    })}
                </div>
            </ContainerWrapper>
        </>
    )
}

export default Faq