import { TagContainer } from './styles'
import IconTag from '../IconTag'

const Tag = ({name, iconName}) => {

    return (
        <TagContainer>
            <IconTag iconName={iconName}/>
            <span>{name}</span>
        </TagContainer>
    )
}

export default Tag
