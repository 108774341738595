import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container } from './styles';
import { api } from '../../../services/api'
import Form from '../../../components/Forms';
import TextArea from '../../../components/TextArea'
import StyledSelect from '../../../components/Select';
import {alert} from '../../../components/Alert';
import { Page } from '../../AdminDashboard/styles.js'

const RoomTypesUpdate = () =>{

    let{id} = useParams()

    const[name, setName] = useState('')
    const[capacity, setCapacity] = useState(0)
    const[description, setDescription] = useState('')
    const[hotelId, setHotelId] = useState(0)
    const[hotels, setHotels] = useState([])

    const history = useHistory()

    const loadHotel = async () => {
        const response = await api.get('/hotels/index')
        setHotels(response.data.map(hotel => {return(
            {label: hotel.name, value: hotel.id}
        )}))
    }

    const loadRoomType = async () => {
        const response = await api.get(`/room_types/show/${id}`)
        setName(response.data.name)
        setCapacity(response.data.capacity)
        setDescription(response.data.description)
        setHotelId({label: response.data.hotel.name, value: response.data.hotel.id})
    }

    useEffect(() => {
        loadHotel()
        loadRoomType()
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(name === ''){
            alert('Nome deve estar preenchido.', 'warn')
        }else if(description === ''){
            alert('Descrição deve estar preenchida.', 'warn')
        }else if(capacity === ''){
            alert('Capacidade deve estar preenchida.', 'warn')
        }

        try{
            const response = await api.put(`/room_types/update/${id}`, {
                room_type: {
                    name,
                    capacity,
                    description,
                    hotel_id: hotelId.value,
                    destination_id: hotelId.value,
                },
            })
            if(response.data){
                alert('Tipo de quarto atualizado.', 'success')
                history.push('/rooms')
            }
        }catch(error){
            alert('Erro, tente novamente.',"error")
        }

    }

    return(
        <Page>
            <Container>
                <h1>Editar tipo de quarto</h1>
                <Form 
                    onSubmit={handleSubmit}
                    title='Tipo de Quarto'
                    text='Atualize os campos para Editar o tipo de quarto.'
                    button={
                        <>
                            <Button format='submit' type='submit'>Editar tipo de quarto</Button>
                            <Button format='return' type='button' onClick={() => history.goBack()}>Voltar</Button>
                        </>
                    }
                >
                    <Input
                        placeholder='Nome'
                        value={name}
                        onChange={(value) => setName(value.target.value)}
                    />
                    <Input
                        placeholder='Capacidade'
                        type='number'
                        value={capacity}
                        onChange={(value) => setCapacity(value.target.value)}
                    />
                    <TextArea
                        placeholder='Descrição'
                        value={description}
                        onChange={(value) => setDescription(value.target.value)}
                    />
                    <StyledSelect
                        label='Hotel'
                        placeholder='Selecione...'
                        defaultValue={hotelId}
                        options={hotels}
                        value={hotelId}
                        onChange={category => setHotelId(category)}
                    />
                </Form>
            </Container>
        </Page>
    )

}

export default RoomTypesUpdate