import styled from "styled-components";

export const Container = styled.nav`
  position: relative;
  top: 0;
  margin: 0;
  height: 6rem;
  display: flex;
  background: linear-gradient(103.6deg, #cc302a -13.96%, #bf1827 134.95%);
  z-index: 2;
  padding: 2rem 4vw 0 6vw;
  gap: 10vw;

  a {
    cursor: pointer;
  }

  ${(props) => {
    if (props.home) {
      return "margin-bottom: -8rem; background: transparent;";
    }
  }}
  .home-navbar {
    margin-bottom: -8rem;
    background: transparent;
  }

  li span {
    text-decoration: none;
    color: #f2f2f2;
    font-size: 24px;
    font-weight: bolder;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }

  .middle {
    display: flex;
    column-gap: 3rem;
    justify-content: space-between;
    height: fit-content;
    width: 100%;
  }

  .middle a {
    text-decoration: none;
    font-style: normal;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    font-family: Poppins;
    padding: .5rem 1.5rem;
  }
  .current-item {
    border: 2px solid #fcc046;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .other-itens {
    border: 1px solid transparent;
    transition: 0.2s linear;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .other-itens:hover {
    border: 1px solid #fcc046;
  }

  .logo a {
    display: flex;
    align-items: center;
    height: 6rem;
    img {
      height: 5rem;
    }
  }

  .header-login {
    right: 0;
    color: #f9f6f7;
    font-size: 1.8rem;
    margin-right: 2vw;
    cursor: pointer;
    font-weight: 900;
    height: 100%;
    //display: table;
    border-style: none none solid none;
    border-color: transparent;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .buy{
      background-color: #FCC046;
      border-radius: 3px;
      margin-left: 10px;
    }


    span {
      height: 100%;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      //display: table-cell;
      vertical-align: middle;
      color: #fff;
      display: inline-block;
      text-align: center;
      margin-top: 0.5rem;
      box-sizing: border-box;
    }

    span:hover {
      transition: 0.6s;
      border: 1px solid #fcc046;
    }

    a:first-of-type:hover {
      transition: 0.6s;
      border: 1px solid #fcc046;
    }

    button {
      border: none;
      background-color: transparent;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      margin: 5px;

      border-radius: 5px;
      color: #fff;
      
    }

    button:hover {
      cursor: pointer;
    }

    .src-icon {
      width: 1.75rem;
      height: 1.75rem;
      vertical-align: middle;
    }
  }

  .menu-bars {
    margin-right: 2rem;
    margin-top: 1rem;
    font-size: 2rem;
    background: none;
    color: #f9f6f7;
    cursor: pointer;
  }



  span {
    margin-right: 16px;
  }
  
  .right {
    overflow-y: auto;
    display: flex;
    transition: 850ms;

  } 

  .nav-menu-right {
    background-color: #1e2535;
    width: 250px;
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
  }

  .nav-menu-right.active {
    right: 0;
    transition: 350ms;
  }

  .hide {
    display: none;
  }

  @media (min-width: 1000px) and (orientation: landscape) {
    .menu-bars {
      display: none;
    }
  }

  @media (max-width: 1240px) {
    .logo a img{
      display: none;
    }

    .middle {
      display: none;
      a {
        font-size: 1.1rem;
      }
    }
    .menu-bars {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 0;
    }
  }

  .nav-user {
    display: none;
    background-color: #0d0c22;
    width: 250px;
    overflow-y: auto;
    justify-content: center;
    position: absolute;
    top: 7rem;
    right: 5rem;
    transition: 850ms;
    border-radius: 5px;
  }

  .nav-user.active {
    display: flex;
  }

  .arrow-up {
    display: none;
    position: absolute;
    overflow-y: auto;
    top: 7rem;
    right: 8rem;
    margin-top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid #0d0c22;
  }

  .arrow-up.active {
    display: flex;
  }

  .user-text {
    display: flex;
    justify-content: start;
    align-items: left;
    list-style: none;
  }

  .user-text a {
    text-decoration: none;
    width: 100%;
    padding: 0 7px;
  }

  .user-text p {
    font-family: Poppins;
    font-style: normal;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .user-text:hover {
    transition-timing-function: ease;
    transition-duration: 0.6s;
    background-color: #E47D3A;
  }
  p:hover {
    /* color:#FCC046;
        transition-timing-function: ease;
        transition-duration: 0.6s; */
  }

  .user-menu-items {
    padding: 0;
    width: 100%;
  }

  .user-text.logout {
    margin-top: 1rem;
    height: 42px;
    p{
        width: 100%;
        height: 80%;
        padding: 0 8px;
        padding-top: 8px;
        margin: auto 0;
    }

    p:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1750px) { // Navbar responsivo
    gap: 7vw;
    .middle {
      column-gap: 0.5rem;
      a {
        font-size: 1.25rem;
        padding: 0.5rem 1rem;
      }
    }
  }

  @media screen and (max-width: 1500px) { //Navbar responsivo
    .middle {
      column-gap: 0.5rem;
      a {
        font-size: 1.2rem;
        padding: 0.5rem 0.75rem;
      }
    }
  }


  @media screen and (max-width: 1400px) { //Navbar responsivo
    .middle {
      column-gap: 0.5rem;
      a {
        font-size: 1.1rem;
        padding: 0.5rem 0.75rem;
      }
    }
  }


  @media screen and (max-width: 1300px) { //Navbar responsivo
      gap: 1.0vw;
      .middle {
        column-gap: 0.5rem;
        a {
          font-size: 1.1rem;
          padding: 0.5rem 0.5rem;
        }
      }

      .header-login {
        margin-right: 0;

        a {
          font-size: 1.1rem;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
  }

  @media screen and (max-width: 1100px) { //Navbar responsivo
      gap: 1vw;
  }
`;