import Select, { components } from "react-select"
import { Container, customStyles } from "./styles"

const StyledSelect = ({ failed, failMessage, label, ...props}) => {

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0L5 5L10 0H0Z" fill="#242426"/>
                </svg>
            </components.DropdownIndicator>
        )
    }

    return(
        <Container>
            <label>{label}</label>
            <Select
                styles={customStyles}
                isClearable
                isSearchable
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null
                }}
                {...props}
            />
            {failed && <span>{failMessage}</span>}
        </Container>
    )
}

export default StyledSelect