import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 20rem;
    height: 30rem;
    position: relative;
    border-radius: .5rem;

    .images {
        height: 26rem;
        min-height: 26rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        margin: 0;

        .rec-dot {
            display: none;
        }
        
        .rec-arrow {
            position: absolute;
            background: #CC2A55;
            color: #fff;
            font-size: 1rem;
            line-height: 2rem;
            text-align: center;
            vertical-align: middle;
            width: 1rem;
            height: 2rem;
            border-radius: 0.5rem;
            top: 50%;
        }

        .rec-arrow-right {
            right: .25rem;
            
            &:hover {
                transform: translateX(10px);
            }
        }


        .rec-arrow:disabled {
            visibility: hidden;
        }

        .rec-arrow-left {
            left: 0.25rem;
            z-index: 1;

            &:hover {
                transform: translateX(-10px);
            }
        }

        img {
            height: 26rem;
            width: 20rem;
            object-fit: cover;
        }
    }
    
    .info {
        position: absolute;
        width: 100%;
        height: 8rem;
        bottom: 4rem;
        background: linear-gradient(to bottom, #ffffff00, #00000099);
        display: flex;
        flex-direction: column;
        justify-content: end;
        color: #fff;
        padding: 0.5rem;

        h4 {
            font-size: 1.5rem;
        }

        li {
            font-size: 1rem;
        }
    }

    button {
        position: relative;
        bottom: 0;
        margin: 0;
        height: 4rem;
        width: 100%;
        border-radius: 0 0 .75rem .75rem;
    }

    @media (max-width: 1300px) {
        width: 16rem;
        height: 24rem;

        .images {
            height: 21rem;

            .rec-arrow {
                top: 10.5rem;
            }

            img {
                height: 21rem;
                width: 16rem;
            }
        }

        .info {
            bottom: 3rem;
        }

        button {
            position: absolute;
            height: 3rem;
        }
    }

    @media (max-width: 600px) {
        width: 100%;
        height: 24rem;

        .images {
            height: 21rem;

            .rec-arrow {
                top: 10.5rem;
            }

            img {
                height: 21rem;
                width: 100%;
            }
        }

        .info {
            bottom: 3rem;
        }

        button {
            position: absolute;
            height: 3rem;
        }
    }
`