import styled from "styled-components";

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 28.5rem;
    margin: auto;
    align-items: center;
`

export const FormContainer = styled.div`
    width: 100%;
    border: 1px solid #E2E4E5;
    border-radius: 0.5rem;
    box-sizing: border-box;
    padding: 1rem 3rem;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    span {
        font-weight: 300;
        font-size: 1rem;
    }
    h1 {
        font-size: 1.5rem;
        font-weight: 600;
    }
    @media screen and (max-width: 1000px){
        width: 90%;
    }
`