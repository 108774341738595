import { Container } from "./styles"
import DateRangePicker from "@wojtekmaj/react-daterange-picker"


const RangedCalendar = ({...props}) => {

  
    return(
        <Container>
            <DateRangePicker {...props} format="d/M/y"/>
        </Container>
 );
}

export default RangedCalendar